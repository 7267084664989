windowReady.ready(function () {
  let width = 800;
  let height = 800;

  let duracion = isMobile.phone ? 600 : 400;

  let debounceTimer = 200;
  let debouncedBuscarActividadScatterplot = null;
  let debouncedCalcularMedidas = null;
  let debouncedBuscarActividadConjugados = null;
  let debouncedBuscarActividadBarchart = null;
  let debouncedActualizarOrdenStackedBarchart = null;
  let debouncedActualizarBarrasStackedBarchart = null;

  let datos = {
    main: [],
    shared: [],
    actividades: [],
    paises: [],
    valores: [],
    grupos: [],
  };

  let viz0 = {
    title: null,
    box: null,
    svg: null,
    ejeX: d3.scaleLinear(),
    ejeY: d3.scaleLinear(),
    gAxisX: null,
    gAxisY: null,
    line: { x1: 0, y1: 0, x2: 0, y2: 0 },
    prefix: "viz0",
    datos: null,
    container: null,
    actividad: null,
  };

  let viz1 = {
    title: null,
    box: null,
    svg: null,
    ejeX: d3.scaleLinear(),
    ejeY: d3.scaleLinear(),
    gAxisX: null,
    gAxisY: null,
    line: { x1: 0, y1: 0, x2: 0, y2: 0 },
    prefix: "viz1",
    datos: null,
    container: null,
    actividad: null,
  };

  let viz2 = {
    title: null,
    box: null,
    svg: null,
    ejeX: d3.scaleLinear(),
    ejeY: d3.scaleLinear(),
    gAxisX: null,
    gAxisY: null,
    line: { x1: 0, y1: 0, x2: 0, y2: 0 },
    prefix: "viz2",
    datos: null,
    container: null,
    actividad: null,
  };

  let vizMain = {
    title: null,
    box: null,
    svg: null,
    ejeX: d3.scaleLinear(),
    ejeY: d3.scaleLinear(),
    gAxisX: null,
    gAxisY: null,
    line: { x1: 0, y1: 0, x2: 0, y2: 0 },
    prefix: "main",
    datos: null,
    container: null,
    actividad: null,
  };

  let vizBarChart = {
    box: null,
    boxBarra: null,
    boxLeyenda: null,
    svg: null,
    ejeXBarra: d3.scaleLinear(),
    ejeX: d3.scaleLinear(),
    ejeY: d3.scaleLinear(),
    gAxisX: null,
    gAxisY: null,
    prefix: "barchart",
    datos: null,
    container: null,
    actividad: null,
    anchoStroke: 1,
  };

  let vizStackedBarChart = {
    box: null,
    boxBarra: null,
    boxLeyenda: null,
    svg: null,
    ejeXBarra: d3.scaleLinear().domain([0, 24 * 60]),
    ejeY: d3.scaleLinear(),
    prefix: "stackedbarchart",
    datos: null,
    container: null,
    anchoStroke: 1,
    anchoBuffer: 10,
  };

  let vizPersonStackedBarChart = {
    box: null,
    boxBarra: null,
    boxLeyenda: null,
    svg: null,
    prefix: "personstackedbarchart",
    container: null,
  };

  let flagRegression = false;
  let scroller = null;
  let capas = { anterior: null, actual: null };
  let pasos = [];
  let isReduced = null;
  let actividades = [];

  let radio = 24;
  let bandera = 36;

  function crearScroller() {
    let elementos = d3.selectAll("[data-capas]");
    if (elementos.size() > 0) {
      elementos.each(function (d, i) {
        pasos.push(d3.select(this).attr("data-capas"));
      });
      scroller = scrollama();
      scroller
        .setup({
          step: "[data-capas]",
          offset: 0.9,
          debug: false,
        })
        .onStepEnter(handleStepEnter);
    }
  }

  function handleStepEnter(response) {
    let capa = response.element.getAttribute("data-capas");
    if (response.direction == "up") {
      let i = response.index;
      if (i >= 1) {
        capa = pasos[i - 1];
      }
    }
    mostrarCapas(capa, response.direction);
  }

  function setup() {
    let container = d3.select("div[data-container='story-2-1']");
    if (container.node()) {
      isReduced =
        window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
        window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
      if (isReduced) {
        duracion = 0;
      }
      setupHistoria();
      debouncedCalcularMedidas = _.debounce(calcularMedidas, debounceTimer);
      window.onresize = debouncedCalcularMedidas;
    }
  }

  function crearBusquedaConjugados() {
    debouncedBuscarActividadConjugados = _.debounce(
      buscarActividadConjugados,
      debounceTimer
    );
    d3.selectAll("[data-update-conjugated-scatterplots]").on(
      "change",
      debouncedBuscarActividadConjugados
    );
  }

  function crearBusquedaScatterplot() {
    debouncedBuscarActividadScatterplot = _.debounce(
      buscarActividadScatterplot,
      debounceTimer
    );
    d3.selectAll("[data-update-scatterplot]").on(
      "change",
      debouncedBuscarActividadScatterplot
    );

    let input = document.getElementById("activity");
    if (input.value.trim()) {
      buscarActividadScatterplot();
    }

    crearAutocomplete(input, buscarActividadScatterplot);
  }

  function crearBusquedaBarchart() {
    debouncedBuscarActividadBarchart = _.debounce(
      buscarActividadBarchart,
      debounceTimer
    );
    d3.selectAll("[data-update-barchart]").on(
      "change",
      debouncedBuscarActividadBarchart
    );

    let input = document.getElementById("activity_barchart");
    if (input.value.trim()) {
      buscarActividadBarchart();
    }

    crearAutocomplete(input, buscarActividadBarchart);
  }

  function crearBusquedaStackedBarchart() {
    let fActualizarOrdenStackedBarchart = function () {
      actualizarStackedBarchart();
      actualizarOrdenStackedBarchart(false);
    };

    let fActualizarBarrasStackedBarchart = function () {
      actualizarStackedBarchart();
      actualizarBarrasStackedBarchart();
    };

    debouncedActualizarOrdenStackedBarchart = _.debounce(
      fActualizarOrdenStackedBarchart,
      debounceTimer
    );
    debouncedActualizarBarrasStackedBarchart = _.debounce(
      fActualizarBarrasStackedBarchart,
      debounceTimer
    );

    d3.selectAll("[data-update-stackedbarchart--order]").on(
      "change",
      debouncedActualizarOrdenStackedBarchart
    );
    d3.selectAll("[data-update-stackedbarchart--bars]").on(
      "change",
      debouncedActualizarBarrasStackedBarchart
    );
    actualizarStackedBarchart();
    actualizarOrdenStackedBarchart(true);
  }

  function crearAutocomplete(input, funcionBuscar) {
    let allowedChars = new RegExp(/^[a-zA-Z\s]+$/);
    autocomplete({
      input: input,
      minLength: 1,
      fetch: function (text, update) {
        text = text.toLowerCase().trim();
        var suggestions = actividades.filter((n) =>
          n.label.toLowerCase().includes(text)
        );
        suggestions = _.orderBy(suggestions, "value");
        update(suggestions);
      },
      onSelect: function (item) {
        input.value = item.label;
        funcionBuscar();
      },
      emptyMsg: "No activities found",
      preventSubmit: 1,
      render: function (item, value) {
        value = value.trim();
        var itemElement = document.createElement("div");
        if (allowedChars.test(value)) {
          var regex = new RegExp(value, "gi");
          itemElement.innerHTML = item.label.replace(regex, function (match) {
            return `<strong>${match}</strong>`;
          });
        } else {
          itemElement.textContent = item.label;
        }
        return itemElement;
      },
    });
  }

  function calcularMedidas() {
    ajustarAnchuraScatterplots();
    scroller.resize();
  }

  function isSmallScreen() {
    return window.innerWidth <= 1024;
  }

  function ajustarAnchuraScatterplots() {
    let box = d3
      .select("div[data-container='story-2-1']")
      .node()
      .getBoundingClientRect();

    if (isSmallScreen()) {
      let width = box.width;
      viz0.container.select("svg").style("width", `${width}px`);
      viz1.container.select("svg").style("width", `${width}px`);
      viz2.container.select("svg").style("width", `${width}px`);
    } else {
      viz0.container.select("svg").style("width", null);
      viz1.container.select("svg").style("width", null);
      viz2.container.select("svg").style("width", null);
    }
  }

  function setupHistoria() {
    crearSVGs();
    fetchDatos();
  }

  function buscarActividadConjugados() {
    cambiarActividadScatterplot(
      viz0,
      viz0.actividad,
      d3.select("#conjugated_scatterplots_members").node().checked,
      flagRegression
    );
    cambiarActividadScatterplot(
      viz1,
      viz1.actividad,
      d3.select("#conjugated_scatterplots_members").node().checked,
      flagRegression
    );
    cambiarActividadScatterplot(
      viz2,
      viz2.actividad,
      d3.select("#conjugated_scatterplots_members").node().checked,
      flagRegression
    );
  }

  function buscarActividadScatterplot() {
    let actividad = encontrarActividadPorNombre(
      datos.main,
      d3.select("#activity").node().value.trim()
    );
    cambiarActividadScatterplot(
      vizMain,
      actividad,
      d3.select("#members").node().checked
    );
  }

  function buscarActividadBarchart() {
    let actividad = encontrarObjetoActividadPorNombre(
      d3.select("#activity_barchart").node().value.trim()
    );
    let miembro = d3.select("#members_barchart").node().checked;
    let pais = d3.select("#country_barchart").node().value.trim();
    let edad = d3.select("#age_barchart").node().value.trim();
    let genero = d3.select("#sex_barchart").node().value.trim();

    cambiarActividadBarchart(actividad, miembro, pais, edad, genero);
  }

  function actualizarStackedBarchart() {
    let pais = d3.select("#country_stackedbarchart").node().value.trim();
    let edad = d3.select("#age_stackedbarchart").node().value.trim();
    let genero = d3.select("#sex_stackedbarchart").node().value.trim();

    cambiarGruposStackedBarchart(pais, edad, genero);
  }

  function crearSVGs() {
    let containers = document.querySelectorAll("div[data-clave]");
    crearSVG(containers[0], viz0);
    crearSVG(containers[1], viz1);
    crearSVG(containers[2], viz2);
    crearSVG(containers[3], vizMain);
    asignarSVG(containers[4], vizPersonStackedBarChart);
    asignarSVG(containers[5], vizStackedBarChart);
    asignarSVG(containers[6], vizBarChart);
  }

  function fetchDatos() {
    Promise.all([
      d3.csv("csv/story2/actividades.csv", function (d) {
        return {
          code: d.code,
          activity: d.activity,
          group: d.group,
          isGroup: false,
        };
      }),
      d3.csv("csv/story2/paises.csv", function (d) {
        return {
          code: d.code,
          country: d.country,
          member: d.member === "TRUE",
          gini: +d.gini,
        };
      }),
      d3.csv("csv/story2/valores.csv", function (d) {
        return {
          sex: d.sex,
          age: d.age,
          activity_code: d.activity_code,
          country_code: d.country_code,
          time: d.time,
          minutes: _.isEmpty(d.minutes) ? null : +d.minutes,
          percentage: _.isEmpty(d.minutes) ? null : +d.percentage,
        };
      }),
      d3.csv("csv/story2/groups.csv", function (d) {
        return {
          group: d.group,
          order: +d.order,
          color: d.color,
          border: d.border,
        };
      }),
    ])
      .then(function ([dataActividades, dataPaises, dataValores, dataGrupos]) {
        let grupos = _.map(dataGrupos, function (d) {
          return {
            group: d.group,
            order: +d.order,
            color: d.color,
            border: d.border,
            code: d.group,
            activity: d.group,
            isGroup: true,
          };
        });

        let actividadesPorGrupo = _.groupBy(dataActividades, "group");
        actividadesPorGrupo = _.map(actividadesPorGrupo, function (d, clave) {
          return { group: clave, actividades: _.map(d, "code") };
        });

        let valoresPorDemografia = _.groupBy(dataValores, function (d) {
          return `${d.sex}||${d.age}||${d.country_code}`;
        });

        let valoresAgrupados = [];
        _.each(actividadesPorGrupo, function (g) {
          let group = _.find(grupos, { group: g.group }) || false;
          if (group) {
            _.each(valoresPorDemografia, function (valores, k) {
              k = k.split("||");
              let sex = k[0];
              let age = k[1];
              let country_code = k[2];

              valores = _.filter(valores, function (v) {
                return g.actividades.indexOf(v.activity_code) >= 0;
              });
              let sumaMinutos = _.sumBy(valores, "minutes");
              let sumaPorcentajes =
                _.sumBy(valores, "percentage") + 0.000000000001;
              sumaPorcentajes = +sumaPorcentajes.toFixed(2);

              let r = {
                activity_code: group.group,
                age: age,
                country_code: country_code,
                minutes: sumaMinutos,
                percentage: sumaPorcentajes,
                sex: sex,
                time: null,
                isGroup: true,
                order: group.order,
                color: group.color,
                border: group.border,
              };
              valoresAgrupados.push(r);
            });
          }
        });

        dataActividades = _.concat(dataActividades, grupos);
        dataActividades = _.filter(dataActividades, (d) => d.code !== "Sleep");
        dataValores = _.concat(dataValores, valoresAgrupados);
        dataGrupos = _.filter(dataValores, (d) => d.isGroup === true);

        actividades = _.map(dataActividades, function (o) {
          return { label: o.activity, value: o.activity };
        });

        let data = juntaDatos(dataActividades, dataPaises, dataValores);
        let dataScatterplot = filtraDatosScatterplot(_.cloneDeep(data));

        datos.main = dataScatterplot;
        datos.shared = _.cloneDeep(dataScatterplot);
        datos.actividades = dataActividades;
        datos.paises = dataPaises;
        datos.valores = dataValores;
        datos.grupos = dataGrupos;

        crearEjesScatterplot(viz0, datos.shared);
        crearEjesScatterplot(viz1, datos.shared);
        crearEjesScatterplot(viz2, datos.shared);
        crearEjesScatterplot(vizMain, datos.main);
        ajustarAnchuraScatterplots();

        /************************* */
        crearSVGBarchart(dataPaises, dataValores);
        crearSVGStackedBarchart(dataPaises, grupos);
        /************************* */

        crearScroller();
        crearBusquedaConjugados();
        crearBusquedaScatterplot();
        crearBusquedaBarchart();
        crearBusquedaStackedBarchart();
      })
      .catch((e) => {
        console.log("CSV file not found");
      });
  }

  function juntaDatos(actividades, paises, valores) {
    actividades = _.orderBy(actividades, ["activity"]);
    paises = _.orderBy(paises, ["country"]);
    let arreglo = [];
    _.each(actividades, function (a) {
      let aCode = a.code;
      _.each(paises, function (p) {
        let pCode = p.code;
        let values = _.filter(valores, function (v) {
          return (
            v.activity_code === aCode &&
            v.country_code === pCode &&
            !_.isNull(v.minutes)
          );
        });
        _.each(values, function (v) {
          arreglo.push({
            activity: a,
            country: p,
            value: v,
          });
        });
      });
    });
    return arreglo;
  }

  function filtraDatosScatterplot(data) {
    let arreglo = [];
    let objeto = {};
    data = _.filter(data, function (d) {
      return d.value.age === "Total" && d.value.sex === "Total";
    });

    _.each(data, function (d) {
      if (!objeto.hasOwnProperty(d.activity.code)) {
        objeto[d.activity.code] = {
          code: d.activity.code,
          name: d.activity.activity,
          countries: {},
        };
      }
      if (!objeto[d.activity.code].countries.hasOwnProperty(d.country.code)) {
        objeto[d.activity.code].countries[d.country.code] = {
          code: d.country.code,
          gini: d.country.gini,
          member: d.country.member,
          name: d.country.country,
          minutes: d.value.minutes,
          percentage: d.value.percentage,
          time: d.value.time,
          pair: [d.country.gini, d.value.minutes],
        };
      }
    });

    arreglo = _.sortBy(_.values(objeto), ["name"]);
    _.each(arreglo, function (a) {
      a.countries = _.sortBy(_.values(a.countries), ["name"]);
    });
    return arreglo;
  }

  function crearSVG(container, viz) {
    let box = new Box({
      size: { width: width, height: height },
      padding: { top: 60, right: 20, bottom: 90, left: 60 },
    });

    let c = d3.select(container).style("opacity", 1);

    let title = c.append("h3").html("&nbsp;");

    let svg = c
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${box.exterior.width} ${box.exterior.height}`);

    let defs = svg.append("defs");

    defs
      .append("circle")
      .attr("r", radio)
      .attr("id", `${viz.prefix}-circle-enter`);
    defs
      .append("circle")
      .attr("r", radio)
      .attr("id", `${viz.prefix}-circle-exit`);

    defs
      .append("rect")
      .attr("width", box.content.width)
      .attr("height", box.content.height)
      .attr("id", "main-rect");

    let clip = defs
      .append("clipPath")
      .attr("id", `${viz.prefix}-circle-enter-clip`);
    clip.append("use").attr("href", `#${viz.prefix}-circle-enter`);

    clip = defs.append("clipPath").attr("id", `${viz.prefix}-circle-exit-clip`);
    clip.append("use").attr("href", `#${viz.prefix}-circle-exit`);

    clip = defs.append("clipPath").attr("id", "main-rect-clip");
    clip.append("use").attr("href", "#main-rect");

    svg = svg
      .append("g")
      .attr("transform", `translate(${box.padding.left}, ${box.padding.top})`);

    let g = svg
      .append("g")
      .attr("class", "main")
      .attr("clip-path", "url(#main-rect-clip)");

    g.append("rect")
      .attr("fill", "#ECECEC")
      .attr("width", box.content.width)
      .attr("height", box.content.height);

    viz.line.y1 = box.content.height;
    viz.line.y2 = box.content.height;

    g.append("line")
      .datum(viz.line)
      .attr("stroke", "#000000")
      .attr("stroke-width", "6px")
      .attr("stroke-dasharray", "5, 5")
      .attr("x1", (d) => d.x1)
      .attr("y1", (d) => d.y1)
      .attr("x2", (d) => d.x2)
      .attr("y2", (d) => d.y2);

    viz.title = title;
    viz.svg = svg;
    viz.box = box;
    viz.container = c;
  }

  function asignarSVG(container, viz) {
    let c = d3.select(container);
    viz.container = c;
  }

  function crearSVGBarchart(paises, valores) {
    paises = _.map(paises, function (d) {
      return {
        country: { code: d.code, country: d.country, member: d.member },
        activity: null,
        value: null,
      };
    });

    let numPaises = paises.length;
    let alturaBoxEjeX = 30;

    let boxLeyenda = new Box({
      size: { width: 200, height: 44 },
      padding: { top: 4, bottom: 4, right: 10 },
    });
    //console.log("boxLeyenda");
    //console.log(boxLeyenda);

    let boxBarra = new Box({
      size: { width: width, height: boxLeyenda.height },
      padding: {
        top: boxLeyenda.padding.top,
        bottom: boxLeyenda.padding.bottom,
      },
    });
    //console.log("boxBarra");
    //console.log(boxBarra);

    let box = new Box({
      size: {
        width: boxLeyenda.width + boxBarra.width + 30,
        height: numPaises * boxLeyenda.height + 20 + alturaBoxEjeX,
      },
      padding: { top: 10, right: 10, bottom: 10, left: 10 },
    });
    //console.log("box");
    //console.log(box);

    let boxEjeX = new Box({
      size: { width: boxBarra.content.width, height: alturaBoxEjeX },
      margin: { left: boxLeyenda.width },
    });
    //console.log("boxEjeX");
    //console.log(boxEjeX);

    let svg = vizBarChart.container
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${box.exterior.width} ${box.exterior.height}`);

    svg = svg
      .append("g")
      .attr("transform", `translate(${box.padding.left}, ${box.padding.top})`);

    vizBarChart.gAxisX = svg
      .append("g")
      .attr("class", "axisX")
      .attr("transform", `translate(${boxEjeX.margin.left},30)`);

    let gMain = svg
      .append("g")
      .attr("class", "main")
      .attr("transform", `translate(${boxLeyenda.width},${boxEjeX.height})`);

    gMain
      .append("rect")
      .attr("fill", "#ECECEC")
      .attr("fill", "transparent")
      .attr("width", boxBarra.width)
      .attr("height", box.content.height);

    let gBarras = svg
      .append("g")
      .attr("class", "barras")
      .attr("transform", `translate(0,${boxEjeX.height})`);

    gBarras
      .append("rect")
      .attr("fill", "#aa0000")
      .attr("fill", "transparent")
      .attr("width", boxLeyenda.content.width)
      .attr("height", box.content.height);

    paises = _.sortBy(paises, function (o) {
      return o.country.country;
    });

    let barras = gBarras
      .selectAll("g.barra")
      .data(paises, (d) => d.country.code)
      .enter()
      .append("g")
      .attr("class", "barra")
      .attr("transform", function (d, i) {
        return `translate(0,${i * boxBarra.height})`;
      });

    barras
      .append("text")
      .attr("x", boxLeyenda.content.width - (bandera + 10))
      .attr("y", 2 + boxBarra.padding.top + boxBarra.height / 2)
      .attr("text-anchor", "end")
      .attr("fill", "#777777")
      .attr("font-size", "20px")
      .style("font-family", "IBM Plex Sans")
      .style("font-weight", "400")
      .text((d) => d.country.country);

    barras
      .append("image")
      .attr("xlink:href", (d) => `img/flags/${d.country.code}.png`)
      .attr("width", bandera)
      .attr("height", bandera)
      .attr("x", boxLeyenda.content.width - bandera)
      .attr("y", boxBarra.padding.top - 1);

    barras
      .append("rect")
      .attr("width", 0)
      .attr("height", boxBarra.content.height)
      .attr("x", boxLeyenda.width + vizBarChart.anchoStroke / 2)
      .attr("y", boxBarra.padding.top)
      .attr("stroke", "#2564F1")
      .attr("stroke-width", `${vizBarChart.anchoStroke}px`)
      .attr("fill", "#2564F160")
      .attr("rx", "5px")
      .attr("ry", "5px");

    vizBarChart.svg = svg;
    vizBarChart.box = box;
    vizBarChart.boxBarra = boxBarra;
    vizBarChart.boxLeyenda = boxLeyenda;
    vizBarChart.datos = paises;

    let max = _.maxBy(valores, "minutes") || false;
    if (max) {
      vizBarChart.ejeXBarra.domain([0, roundUpNice(max.minutes)]);
      vizBarChart.ejeX.domain([0, roundUpNice(max.minutes)]);
    }
  }

  function crearSVGStackedBarchart(paises, grupos) {
    paises = _.map(paises, function (d) {
      let gs = _.map(grupos, function (g) {
        return {
          activity: g.activity,
          border: g.border,
          color: g.color,
          code: g.code,
          group: g.group,
          isGroup: g.isGroup,
          order: g.order,
          minutes: 0,
        };
      });
      return {
        country: { code: d.code, country: d.country, member: d.member },
        groups: _.orderBy(gs, "order"),
      };
    });

    let minutes = (24 * 60) / grupos.length;
    let personGroups = _.orderBy(
      _.map(grupos, function (g) {
        return {
          activity: g.activity,
          border: g.border,
          color: g.color,
          code: g.code,
          group: g.group,
          isGroup: g.isGroup,
          order: g.order,
          minutes: minutes,
        };
      }),
      "order"
    );

    let numPaises = paises.length + 1;

    let boxLeyenda = new Box({
      size: { width: 200, height: 44 },
      padding: { top: 4, bottom: 4, right: 10 },
    });
    //console.log("boxLeyenda");
    //console.log(boxLeyenda);

    let boxBarra = new Box({
      size: { width: width, height: boxLeyenda.height },
      padding: {
        top: boxLeyenda.padding.top,
        bottom: boxLeyenda.padding.bottom,
      },
    });
    //console.log("boxBarra");
    //console.log(boxBarra);

    let box = new Box({
      size: {
        width: boxLeyenda.width + boxBarra.width + 30,
        height: numPaises * boxLeyenda.height + 20,
      },
      padding: { top: 10, right: 10, bottom: 10, left: 10 },
    });
    //console.log("box");
    //console.log(box);

    let personBox = new Box({
      size: {
        width: boxLeyenda.width + boxBarra.width + 30,
        height: boxLeyenda.height + 20,
      },
      padding: { top: 10, right: 10, bottom: 10, left: 10 },
    });
    //console.log("personBox");
    //console.log(personBox);

    vizStackedBarChart.ejeXBarra.range([
      0,
      boxBarra.content.width -
        (vizStackedBarChart.anchoStroke + vizStackedBarChart.anchoBuffer * 6),
    ]);

    let svg = vizStackedBarChart.container
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${box.exterior.width} ${box.exterior.height}`);

    svg = svg
      .append("g")
      .attr("transform", `translate(${box.padding.left}, ${box.padding.top})`);

    let gMain = svg
      .append("g")
      .attr("class", "main")
      .attr("transform", `translate(${boxLeyenda.width},0)`);

    gMain
      .append("rect")
      .attr("fill", "#ECECEC")
      .attr("fill", "transparent")
      .attr("width", boxBarra.width)
      .attr("height", box.content.height);

    let gBarras = svg
      .append("g")
      .attr("class", "rows")
      .attr("transform", "translate(0,0)");

    gBarras
      .append("rect")
      .attr("fill", "#aa0000")
      .attr("fill", "transparent")
      .attr("width", boxLeyenda.content.width)
      .attr("height", box.content.height);

    paises = _.sortBy(paises, function (o) {
      return o.country.country;
    });

    let gPerson = gBarras
      .append("g")
      .datum(personGroups)
      .attr("class", "person")
      .attr("transform", "translate(0,0)");

    gPerson
      .append("text")
      .attr("x", boxLeyenda.content.width - (bandera + 10))
      .attr("y", 2 + boxBarra.padding.top + boxBarra.height / 2)
      .attr("text-anchor", "end")
      .attr("fill", "#777777")
      .attr("font-size", "20px")
      .style("font-family", "IBM Plex Sans")
      .style("font-weight", "700")
      .text("Your day");

    gPerson
      .append("g")
      .attr("class", "bars")
      .attr(
        "transform",
        `translate(${boxLeyenda.width + vizStackedBarChart.anchoStroke / 2},0)`
      )
      .selectAll("rect.group")
      .data(
        (d) => d,
        (d) => d.code
      )
      .enter()
      .append("rect")
      .attr("class", "group")
      .attr("width", function (d) {
        return vizStackedBarChart.ejeXBarra(d.minutes);
      })
      .attr("height", boxBarra.content.height)
      .attr("x", function (d, i) {
        return (
          vizStackedBarChart.ejeXBarra(i * d.minutes) +
          (d.order - 1) * vizStackedBarChart.anchoBuffer
        );
      })
      .attr("y", boxBarra.padding.top)
      .attr("stroke", (d) => d.border)
      .attr("stroke-width", `${vizStackedBarChart.anchoStroke}px`)
      .attr("fill", (d) => d.color)
      .attr("rx", "5px")
      .attr("ry", "5px");

    let barras = gBarras
      .selectAll("g.row")
      .data(paises, (d) => d.country.code)
      .enter()
      .append("g")
      .attr("class", "row")
      .attr("transform", function (d, i) {
        return `translate(0,${(i + 1) * boxBarra.height})`;
      });

    barras
      .append("text")
      .attr("x", boxLeyenda.content.width - (bandera + 10))
      .attr("y", 2 + boxBarra.padding.top + boxBarra.height / 2)
      .attr("text-anchor", "end")
      .attr("fill", "#777777")
      .attr("font-size", "20px")
      .style("font-family", "IBM Plex Sans")
      .style("font-weight", "400")
      .text((d) => d.country.country);

    barras
      .append("image")
      .attr("xlink:href", (d) => `img/flags/${d.country.code}.png`)
      .attr("width", bandera)
      .attr("height", bandera)
      .attr("x", boxLeyenda.content.width - bandera)
      .attr("y", boxBarra.padding.top - 1);

    barras
      .append("g")
      .attr("class", "bars")
      .attr(
        "transform",
        `translate(${boxLeyenda.width + vizStackedBarChart.anchoStroke / 2},0)`
      )
      .selectAll("rect.group")
      .data(
        (d) => d.groups,
        (d) => d.code
      )
      .enter()
      .append("rect")
      .attr("class", "group")
      .attr("width", 0)
      .attr("height", boxBarra.content.height)
      .attr("x", 0)
      .attr("y", boxBarra.padding.top)
      .attr("stroke", (d) => d.border)
      .attr("stroke-width", `${vizStackedBarChart.anchoStroke}px`)
      .attr("fill", (d) => d.color)
      .attr("rx", "5px")
      .attr("ry", "5px");

    vizStackedBarChart.svg = svg;
    vizStackedBarChart.box = box;
    vizStackedBarChart.boxBarra = boxBarra;
    vizStackedBarChart.boxLeyenda = boxLeyenda;
    vizStackedBarChart.datos = paises;

    svg = vizPersonStackedBarChart.container
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr(
        "viewBox",
        `0 0 ${personBox.exterior.width} ${personBox.exterior.height}`
      );

    svg = svg
      .append("g")
      .attr(
        "transform",
        `translate(${personBox.padding.left}, ${personBox.padding.top})`
      );

    gMain = svg
      .append("g")
      .attr("class", "main")
      .attr("transform", `translate(${boxLeyenda.width},0)`);

    gMain
      .append("rect")
      .attr("fill", "#ECECEC")
      .attr("fill", "transparent")
      .attr("width", boxBarra.width)
      .attr("height", personBox.content.height);

    gBarras = svg
      .append("g")
      .attr("class", "rows draggable")
      .attr("transform", "translate(0,0)");

    gBarras
      .append("rect")
      .attr("fill", "#aa0000")
      .attr("fill", "transparent")
      .attr("width", boxLeyenda.content.width)
      .attr("height", personBox.content.height);

    gPerson = gBarras
      .append("g")
      .datum(personGroups)
      .attr("class", "person")
      .attr("transform", "translate(0,0)");

    gPerson
      .append("text")
      .attr("x", boxLeyenda.content.width - (bandera + 10))
      .attr("y", 2 + boxBarra.padding.top + boxBarra.height / 2)
      .attr("text-anchor", "end")
      .attr("fill", "#777777")
      .attr("font-size", "20px")
      .style("font-family", "IBM Plex Sans")
      .style("font-weight", "700")
      .text("Your day");

    gPerson
      .append("g")
      .attr("class", "bars")
      .attr(
        "transform",
        `translate(${boxLeyenda.width + vizStackedBarChart.anchoStroke / 2},0)`
      )
      .selectAll("g.group")
      .data(
        (d) => d,
        (d) => d.code
      )
      .enter()
      .append("g")
      .attr("class", "group");

    let gGrupos = gPerson.selectAll("g.group").each(function (d) {
      let x =
        vizStackedBarChart.ejeXBarra((d.order - 1) * d.minutes) +
        (d.order - 1) * vizStackedBarChart.anchoBuffer;
      let w = vizStackedBarChart.ejeXBarra(d.minutes);
      d.x = x;
      d.width = w;
    });
    /*.attr("transform", function (d, i) {
        return `translate(${d.x},0)`;
      });*/
    gGrupos
      .append("rect")
      .attr("class", "group")
      .attr("width", (d) => d.width)
      .attr("height", boxBarra.content.height)
      .attr("x", (d) => d.x)
      .attr("y", boxBarra.padding.top)
      .attr("stroke", (d) => d.border)
      .attr("stroke-width", `${vizStackedBarChart.anchoStroke}px`)
      .attr("fill", (d) => d.color)
      .attr("rx", "5px")
      .attr("ry", "5px");

    gGrupos.each(function (d) {
      let g = d3.select(this);
      if (d.order !== 1) {
        g.append("rect")
          .attr("class", "drag left")
          .attr("x", (d) => d.x)
          .attr("y", boxBarra.padding.top)
          //.attr("y", boxBarra.padding.top + 20)
          .attr("width", (d) => d.width / 2)
          .attr("height", boxBarra.content.height)
          .attr("fill", "blue")
          .attr("fill", "transparent");
      }
      if (d.order !== grupos.length) {
        g.append("rect")
          .attr("class", "drag right")
          .attr("x", (d) => d.x + d.width / 2)
          .attr("y", boxBarra.padding.top)
          //.attr("y", boxBarra.padding.top - 20)
          .attr("width", (d) => d.width / 2)
          .attr("height", boxBarra.content.height)
          .attr("fill", "red")
          .attr("fill", "transparent");
      }
    });
    gPerson.selectAll("rect.drag.left").call(dragLeft);
    gPerson.selectAll("rect.drag.right").call(dragRight);

    vizPersonStackedBarChart.svg = svg;
    vizPersonStackedBarChart.box = personBox;
    vizPersonStackedBarChart.boxBarra = boxBarra;
    vizPersonStackedBarChart.boxLeyenda = boxLeyenda;
  }

  const dragLeft = d3.drag().on("drag", leftDragHandler);
  const dragRight = d3.drag().on("drag", rightDragHandler);

  function leftDragHandler(e, d) {
    let el = d3.select(this);
    let rect = d3.select(el.node().parentNode).select("rect.group");
    let elRight = false;

    let anchoMaximo = d.width;
    let anchoMinimo = 5;
    let topeIzquierdo = 0;
    let topeDerecho =
      vizPersonStackedBarChart.boxBarra.content.width -
      vizStackedBarChart.anchoStroke / 2 -
      anchoMinimo;
    let rectAnterior = false;
    let elAnterior = false;
    let elAnteriorRight = false;
    let rectPosterior = false;
    let elPosterior = false;
    let elPosteriorRight = false;
    let dAnterior = false;
    let dPosterior = false;

    let gBars = d3.select(el.node().parentNode.parentNode);
    if (d.order > 1) {
      rectAnterior = gBars
        .selectAll("rect.group")
        .filter((a) => a.order === d.order - 1);
      elAnterior = d3
        .select(rectAnterior.node().parentNode)
        .select("rect.drag.left");
      elAnteriorRight = d3
        .select(rectAnterior.node().parentNode)
        .select("rect.drag.right");
      dAnterior = rectAnterior.datum();
      topeIzquierdo =
        dAnterior.x + anchoMinimo + vizStackedBarChart.anchoBuffer;
    }
    if (d.order < 7) {
      elRight = d3.select(el.node().parentNode).select("rect.drag.right");
      rectPosterior = gBars
        .selectAll("rect.group")
        .filter((a) => a.order === d.order + 1);
      elPosterior = d3
        .select(rectPosterior.node().parentNode)
        .select("rect.drag.left");
      elPosteriorRight = d3
        .select(rectPosterior.node().parentNode)
        .select("rect.drag.right");
      dPosterior = rectPosterior.datum();
      topeDerecho = dPosterior.x - vizStackedBarChart.anchoBuffer - anchoMinimo;
    }

    if (d.order === 7) {
      anchoMaximo =
        d.x +
        d.width -
        (dAnterior.x + anchoMinimo + vizStackedBarChart.anchoBuffer);
    } else {
      anchoMaximo =
        dPosterior.x -
        vizStackedBarChart.anchoBuffer -
        (dAnterior.x + anchoMinimo + vizStackedBarChart.anchoBuffer);
    }

    d.x += e.dx;
    d.width = d.width -= e.dx;

    d.width = d.width >= anchoMaximo ? anchoMaximo : d.width;
    d.width = d.width <= anchoMinimo ? anchoMinimo : d.width;

    d.x = d.x <= topeIzquierdo ? topeIzquierdo : d.x;
    d.x = d.x >= topeDerecho ? topeDerecho : d.x;

    el.attr("x", (d) => d.x).attr("width", (d) => d.width / 2);
    rect.attr("x", (d) => d.x).attr("width", (d) => d.width);
    if (rectAnterior) {
      dAnterior.width = d.x - vizStackedBarChart.anchoBuffer - +dAnterior.x;
      rectAnterior.attr("width", (dAnterior) => dAnterior.width);
      elAnterior.attr("width", (dAnterior) => dAnterior.width / 2);
      elAnteriorRight
        .attr("x", (dAnterior) => dAnterior.x + dAnterior.width / 2)
        .attr("width", (dAnterior) => dAnterior.width / 2);
    }
    if (elRight) {
      elRight
        .attr("x", (d) => d.x + d.width / 2)
        .attr("width", (d) => d.width / 2);
    }
    vizStackedBarChart.svg
      .select("g.person")
      .selectAll("rect.group")
      .attr("x", (d) => d.x)
      .attr("width", (d) => d.width);
  }

  function rightDragHandler(e, d) {
    let el = d3.select(this);
    let rect = d3.select(el.node().parentNode).select("rect.group");
    let elLeft = false;

    let anchoMaximo = d.width;
    let anchoMinimo = 5;
    let rectAnterior = false;
    let elAnterior = false;
    let elAnteriorLeft = false;
    let rectPosterior = false;
    let elPosterior = false;
    let elPosteriorRight = false;
    let dAnterior = false;
    let dPosterior = false;

    let gBars = d3.select(el.node().parentNode.parentNode);
    if (d.order > 1) {
      rectAnterior = gBars
        .selectAll("rect.group")
        .filter((a) => a.order === d.order - 1);
      elAnterior = d3
        .select(rectAnterior.node().parentNode)
        .select("rect.drag.right");
      elAnteriorLeft = d3
        .select(rectAnterior.node().parentNode)
        .select("rect.drag.left");
      dAnterior = rectAnterior.datum();
    }
    if (d.order < 7) {
      elLeft = d3.select(el.node().parentNode).select("rect.drag.left");
      rectPosterior = gBars
        .selectAll("rect.group")
        .filter((a) => a.order === d.order + 1);
      elPosterior = d3
        .select(rectPosterior.node().parentNode)
        .select("rect.drag.left");
      elPosteriorRight = d3
        .select(rectPosterior.node().parentNode)
        .select("rect.drag.right");
      dPosterior = rectPosterior.datum();

      anchoMaximo =
        dPosterior.x +
        dPosterior.width -
        vizStackedBarChart.anchoBuffer -
        anchoMinimo -
        d.x;
    }

    d.width = d.width += e.dx;

    d.width = d.width >= anchoMaximo ? anchoMaximo : d.width;
    d.width = d.width <= anchoMinimo ? anchoMinimo : d.width;

    el.attr("x", (d) => d.x + d.width / 2).attr("width", (d) => d.width / 2);
    rect.attr("width", (d) => d.width);

    if (rectPosterior) {
      let xPosteriorDerecha = dPosterior.x + dPosterior.width;
      dPosterior.x = d.x + d.width + vizStackedBarChart.anchoBuffer;
      dPosterior.width = xPosteriorDerecha - dPosterior.x;

      rectPosterior
        .attr("x", (d) => d.x)
        .attr("width", (dPosterior) => dPosterior.width);

      elPosterior
        .attr("x", (dPosterior) => dPosterior.x)
        .attr("width", (dPosterior) => dPosterior.width / 2);

      elPosteriorRight
        .attr("x", (dPosterior) => dPosterior.x + dPosterior.width / 2)
        .attr("width", (dPosterior) => dPosterior.width / 2);
    }

    if (elLeft) {
      elLeft.attr("width", (d) => d.width / 2);
    }

    vizStackedBarChart.svg
      .select("g.person")
      .selectAll("rect.group")
      .attr("x", (d) => d.x)
      .attr("width", (d) => d.width);
  }

  function roundUpNice(n, escala) {
    escala = escala || 10;
    let temp = Math.floor(n / escala);
    if (temp < n) {
      temp += 1;
    }
    if (escala === 1) {
      if (temp % 2 === 1) {
        temp += 1;
      }
    }
    n = temp * escala;
    return n;
  }

  function roundDownNice(n, escala) {
    escala = escala || 10;
    let temp = Math.floor(n / escala);
    if (escala === 1) {
      if (temp % 2 === 1) {
        temp -= 1;
      }
    }
    n = temp * escala;
    return n;
  }

  function crearEjesScatterplot(viz, datos) {
    viz.datos = datos;

    let min = 0;
    let max = 0;

    let arreglo = _.flatten(_.map(viz.datos, "countries"));
    let obj = _.minBy(arreglo, "gini");
    min = roundDownNice(obj.gini, 1);

    obj = _.maxBy(arreglo, "gini");
    max = roundUpNice(obj.gini, 1);

    viz.ejeX.domain([min, max]).range([0, viz.box.content.width]);

    viz.gAxisX = viz.svg
      .append("g")
      .attr("transform", `translate(0, ${viz.box.content.height})`)
      .call(
        d3
          .axisBottom(viz.ejeX)
          .tickFormat((d) => d)
          .ticks((max - min) / 2 + 1)
      );

    viz.gAxisX
      .selectAll("text")
      .style("font-size", "24px")
      .attr("fill", "grey");

    viz.gAxisX.selectAll("path").attr("stroke", "grey");
    viz.gAxisX.selectAll("line").attr("stroke", "grey");

    viz.svg
      .append("g")
      .attr("transform", `translate(0, ${viz.box.content.height})`)
      .append("text")
      .attr("text-anchor", "end")
      .style("font-size", "30px")
      .attr("fill", "grey")
      .attr("x", viz.box.content.width + viz.box.padding.right)
      .attr("y", 80)
      .text("More income inequality →");

    viz.ejeY.range([0, viz.box.content.height]);

    viz.gAxisY = viz.svg.append("g");

    viz.svg
      .append("g")
      .append("text")
      .attr("text-anchor", "start")
      .style("font-size", "30px")
      .attr("fill", "grey")
      .attr("y", -30)
      .attr("x", -viz.box.padding.left)
      .text("↑ More time spent on activity");
  }

  function encontrarActividadPorCodigo(datos, codigo) {
    return _.find(datos, { code: codigo });
  }

  function encontrarActividadPorNombre(datos, nombre) {
    return _.find(datos, { name: nombre });
    //cambiarActividadScatterplot(viz, objActividad, miembro);
  }

  function encontrarObjetoActividadPorNombre(nombre) {
    return _.find(datos.actividades, { activity: nombre });
  }

  let codigoResaltadoConjugados = null;
  function resaltarPaisesConjugados(e, d) {
    codigoResaltadoConjugados =
      codigoResaltadoConjugados === d.code ? null : d.code;
    let arreglo = _.isNull(codigoResaltadoConjugados)
      ? []
      : [codigoResaltadoConjugados];
    resetPaises(datos.shared, arreglo);
    resaltarPaises(viz0);
    resaltarPaises(viz1);
    resaltarPaises(viz2);
  }

  let codigoResaltadoPrincipal = null;
  function resaltarPaisesPrincipal(e, d) {
    codigoResaltadoPrincipal =
      codigoResaltadoPrincipal === d.code ? null : d.code;
    let arreglo = _.isNull(codigoResaltadoPrincipal)
      ? []
      : [codigoResaltadoPrincipal];
    resetPaises(datos.main, arreglo);
    resaltarPaises(vizMain);
  }

  function cambiarActividadBarchart(actividad, miembro, pais, edad, genero) {
    actividad = actividad || null;
    miembro = miembro || false;

    let max = 0;

    vizBarChart.actividad = actividad;
    vizBarChart.ejeXBarra.range([
      0,
      vizBarChart.boxBarra.content.width - vizBarChart.anchoStroke,
    ]);
    vizBarChart.ejeX.range([0, vizBarChart.boxBarra.content.width]);

    let data = [];
    if (!_.isNull(actividad)) {
      data = _.cloneDeep(vizBarChart.datos);
      _.each(data, function (d) {
        d.activity = actividad;
        let valor =
          _.find(datos.valores, function (v) {
            return (
              v.country_code === d.country.code &&
              v.activity_code === d.activity.code &&
              v.age === edad &&
              v.sex === genero
            );
          }) || null;
        d.value = valor;
        d.country_name = d.country.country;
        d.minutes = d.value.minutes;
        d.percentage = d.value.percentage;
      });

      max = _.max(_.map(data, "minutes"));

      if (miembro) {
        data = _.filter(data, (d) => d.country.member);
      }

      data = _.orderBy(data, ["minutes", "country_name"], ["desc", "asc"]);
      if (pais !== "None") {
        let temp = _.remove(data, (d) => d.country.code === pais);
        data = _.concat(temp, data);
      }
    }

    vizBarChart.ejeX.domain([0, max]);
    vizBarChart.ejeXBarra.domain([0, max]);
    vizBarChart.gAxisX.call(
      d3.axisTop(vizBarChart.ejeX).tickFormat((d) => `${d} min`)
      //.ticks((max - min) / 10 + 1)
    );

    vizBarChart.gAxisX
      .selectAll("text")
      .style("font-size", "24px")
      .attr("fill", "grey");
    vizBarChart.gAxisX.selectAll("path").attr("stroke", "grey").remove();
    vizBarChart.gAxisX
      .selectAll("line")
      .attr("stroke", "grey")
      .attr("y1", -6)
      .attr("y2", vizBarChart.box.content.height)
      .attr("stroke-dasharray", "5, 5");
    vizBarChart.gAxisX.selectAll("text").style("font-size", "14px");

    let grupos = vizBarChart.svg
      .select("g.barras")
      .selectAll("g.barra")
      .data(data, (d) => d.country.code);

    let gruposEnter = grupos
      .enter()
      .append("g")
      .attr("class", "barra")
      .attr("transform", `translate(0,${-vizBarChart.boxBarra.height})`);

    gruposEnter
      .append("text")
      .attr("x", vizBarChart.boxLeyenda.content.width - (bandera + 10))
      .attr(
        "y",
        2 + vizBarChart.boxBarra.padding.top + vizBarChart.boxBarra.height / 2
      )
      .attr("text-anchor", "end")
      .attr("fill", "#777777")
      .attr("font-size", "20px")
      .style("font-family", "IBM Plex Sans")
      .style("font-weight", "400")
      .text((d) => d.country.country);

    gruposEnter
      .append("image")
      .attr("xlink:href", (d) => `img/flags/${d.country.code}.png`)
      .attr("width", bandera)
      .attr("height", bandera)
      .attr("x", vizBarChart.boxLeyenda.content.width - bandera)
      .attr("y", vizBarChart.boxBarra.padding.top);

    gruposEnter
      .append("rect")
      .attr("width", 0)
      .attr("height", vizBarChart.boxBarra.content.height)
      .attr("x", vizBarChart.boxLeyenda.width + vizBarChart.anchoStroke / 2)
      .attr("y", vizBarChart.boxBarra.padding.top)
      .attr("stroke", "#2564F1")
      .attr("stroke-width", `${vizBarChart.anchoStroke}px`)
      .attr("fill", "#2564F160")
      .attr("rx", "5px")
      .attr("ry", "5px");

    gruposEnter
      .transition("transform")
      .duration(duracion)
      .attr("transform", function (d, i) {
        return `translate(0,${i * vizBarChart.boxBarra.height})`;
      })
      .end()
      .catch((e) => {
        console.log(
          "function cambiarActividadBarchart enter() transition failed"
        );
      })
      .finally(function () {
        gruposEnter
          .select("rect")
          .transition("width")
          .duration(duracion)
          .attr("width", (d) => vizBarChart.ejeXBarra(d.minutes));
      });

    grupos
      .transition("transform")
      .duration(duracion)
      .attr("transform", function (d, i) {
        return `translate(0,${i * vizBarChart.boxBarra.height})`;
      })
      .end()
      .catch((e) => {
        console.log(
          "function cambiarActividadBarchart update() transition failed"
        );
      })
      .finally(function () {
        grupos
          .select("rect")
          .transition("width")
          .duration(duracion)
          .attr("width", (d) => vizBarChart.ejeXBarra(d.minutes));
      });

    let gruposExit = grupos
      .exit()
      .transition("transform")
      .duration(duracion)
      .attr("transform", function (d, i) {
        return `translate(0,${vizBarChart.box.height})`;
      })
      .remove();
  }

  function cambiarGruposStackedBarchart(pais, edad, genero) {
    vizStackedBarChart.datos = _.orderBy(
      vizStackedBarChart.datos,
      function (d) {
        return d.country.country;
      }
    );
    let data = _.filter(datos.grupos, { sex: genero, age: edad });

    _.each(vizStackedBarChart.datos, function (p) {
      let x = 0;
      _.each(p.groups, function (g) {
        let group =
          _.find(data, {
            country_code: p.country.code,
            activity_code: g.code,
          }) || false;
        if (group) {
          g.minutes = group.minutes;
          if (g.code === "Other activities") {
            g.minutes = 24 * 60 - x;
          }
          g.offset = x;
          x += group.minutes;
        }
      });
    });

    if (pais !== "None") {
      let temp = _.remove(
        vizStackedBarChart.datos,
        (d) => d.country.code === pais
      );
      vizStackedBarChart.datos = _.concat(temp, vizStackedBarChart.datos);
    }

    vizStackedBarChart.svg
      .select("g.rows")
      .selectAll("g.row")
      .data(vizStackedBarChart.datos, (d) => d.country.code)
      .each(function (d, i) {
        d.i = i;
      });
  }

  function actualizarOrdenStackedBarchart(flagBarras) {
    flagBarras = flagBarras || false;
    let grupos = vizStackedBarChart.svg.select("g.rows").selectAll("g.row");

    grupos
      .transition("transform")
      .duration(duracion)
      .attr("transform", function (d) {
        return `translate(0,${(d.i + 1) * vizStackedBarChart.boxBarra.height})`;
      })
      .end()
      .catch((e) => {
        console.log(
          "function actualizarOrdenStackedBarchart update() transition failed"
        );
      })
      .finally(function () {
        if (flagBarras) {
          actualizarBarrasStackedBarchart();
        }
      });
  }

  function actualizarBarrasStackedBarchart() {
    let grupos = vizStackedBarChart.svg.select("g.rows").selectAll("g.row");
    grupos
      .select("g.bars")
      .selectAll("rect.group")
      .data(
        (d) => d.groups,
        (d) => d.code
      )
      .transition("width")
      .duration(duracion)
      .attr(
        "x",
        (d) =>
          vizStackedBarChart.ejeXBarra(d.offset) +
          (d.order - 1) * vizStackedBarChart.anchoBuffer
      )
      .attr("width", (d) => vizStackedBarChart.ejeXBarra(d.minutes));
  }

  function cambiarActividadScatterplot(
    viz,
    objActividad,
    miembro,
    flagMostrarLinea
  ) {
    objActividad = objActividad || null;
    flagMostrarLinea = _.isUndefined(flagMostrarLinea)
      ? true
      : flagMostrarLinea;
    miembro = miembro || false;
    let data = [];
    let min = 0;
    let max = 60;
    let pares = [];

    viz.actividad = objActividad;

    let main = viz.svg.select("g.main");
    viz.title.html("&nbsp;");

    let linea = viz.svg.select("line");

    if (objActividad) {
      viz.title.html(objActividad.name);
      data = objActividad.countries;
      let obj = _.minBy(data, "minutes");
      min = roundDownNice(obj.minutes);
      obj = _.maxBy(data, "minutes");
      max = roundUpNice(obj.minutes);
      if (miembro) {
        data = _.filter(objActividad.countries, { member: miembro });
      }
      pares = _.map(data, "pair");
    }

    viz.ejeY.domain([max, min]);
    viz.gAxisY.call(
      d3
        .axisLeft(viz.ejeY)
        .tickFormat((d) => d)
        .ticks((max - min) / 10 + 1)
    );

    viz.gAxisY
      .selectAll("text")
      .style("font-size", "24px")
      .attr("fill", "grey");
    viz.gAxisY.selectAll("path").attr("stroke", "grey");
    viz.gAxisY.selectAll("line").attr("stroke", "grey");

    if (pares.length > 0 && flagMostrarLinea) {
      let iguales = viz.line.x1 === viz.line.x2;
      let dominioX = viz.ejeX.domain();
      let regresion = linearRegression(pares);
      /*
      let x1 = dominioX[0];
      let x2 = dominioX[1];

      let regresion = linearRegression(pares);
      let y1 = regresion.m * x1 + regresion.b;
      let y2 = regresion.m * x2 + regresion.b;
      */
      viz.line.x1 = dominioX[0];
      viz.line.x2 = dominioX[1];

      viz.line.y1 = regresion.m * viz.line.x1 + regresion.b;
      viz.line.y2 = regresion.m * viz.line.x2 + regresion.b;

      if (iguales) {
        linea
          .attr("x1", (d) => viz.ejeX(d.x1))
          .attr("y1", (d) => viz.ejeY(d.y1))
          .attr("x2", (d) => viz.ejeX(d.x1))
          .attr("y2", (d) => viz.ejeY(d.y1))
          .transition("adjust")
          .duration(duracion)
          .attr("x2", (d) => viz.ejeX(d.x2))
          .attr("y2", (d) => viz.ejeY(d.y2));
      } else {
        linea
          .transition("adjust")
          .duration(duracion)
          .attr("x1", (d) => viz.ejeX(d.x1))
          .attr("y1", (d) => viz.ejeY(d.y1))
          .attr("x2", (d) => viz.ejeX(d.x2))
          .attr("y2", (d) => viz.ejeY(d.y2));
      }
    } else {
      viz.line.x2 = viz.line.x1;
      viz.line.y2 = viz.line.y1;

      let lineaX1 = linea.attr("x1");
      let lineaY1 = linea.attr("y1");
      linea
        .transition("adjust")
        .duration(duracion)
        .attr("x2", lineaX1)
        .attr("y2", lineaY1);
    }

    let grupos = main.selectAll("g").data(data, (d) => d.code);

    let gruposEnter = grupos.enter().append("g");
    let gruposExit = grupos.exit();

    d3.select(`#${viz.prefix}-circle-enter`)
      .attr("r", 0)
      .transition()
      .duration(duracion)
      .attr("r", radio)
      .on("start", function () {
        gruposEnter
          .attr("clip-path", `url(#${viz.prefix}-circle-enter-clip)`)
          .attr("transform", function (d) {
            return `translate(${viz.ejeX(d.gini)},${viz.ejeY(d.minutes)})`;
          });
        gruposEnter
          .append("circle")
          .attr("class", "flag")
          .attr("r", radio)
          .attr("x", 0)
          .attr("y", 0)
          .style("fill", "#FFFFFF")
          .style("stroke", "#000000")
          .style("stroke-width", (d) =>
            d.estado === "highlight" ? "4px" : "0px"
          );

        let circulos = gruposEnter.selectAll("circle.flag");
        if (viz.prefix === "main") {
          circulos.on("click", resaltarPaisesPrincipal);
        } else {
          circulos.on("click", resaltarPaisesConjugados);
        }

        gruposEnter
          .append("image")
          .attr("class", "cover")
          .attr("xlink:href", (d) => `img/flags/${d.code}.png`)
          .attr("width", bandera)
          .attr("height", bandera)
          .attr("x", -bandera / 2)
          .attr("y", -bandera / 2);

        gruposEnter
          .append("circle")
          .attr("class", "cover")
          .attr("r", radio)
          .attr("x", 0)
          .attr("y", 0)
          .style("fill", "#FFFFFF")
          .style("opacity", (d) => (d.estado === "fade" ? 0.7 : 0));

        gruposEnter
          .append("text")
          .attr("class", "cover")
          .style("font-family", "IBM Plex Sans")
          .style("font-weight", "700")
          .style("font-size", "24px")
          .style("opacity", (d) => (d.estado === "highlight" ? 1 : 0))
          .attr("y", 7)
          .attr("x", 20)
          .attr("x", (d) => (d.gini >= 38 ? -32 : 32))
          .attr("text-anchor", "start")
          .attr("text-anchor", (d) => (d.gini >= 38 ? "end" : "start"))
          .text((d) => d.name);
      })
      .on("end", function () {
        gruposEnter.attr("clip-path", null);
      });

    d3.select(`#${viz.prefix}-circle-exit`)
      .attr("r", radio)
      .transition()
      .duration(duracion)
      .attr("r", 0)
      .on("start", function () {
        gruposExit.attr("clip-path", `url(#${viz.prefix}-circle-exit-clip)`);
      })
      .on("end", function () {
        gruposExit.remove();
      });

    grupos
      .transition()
      .duration(duracion)
      .attr("transform", function (d) {
        return `translate(${viz.ejeX(d.gini)},${viz.ejeY(d.minutes)})`;
      });
  }

  function resetPaises(datos, paises) {
    paises = paises || [];
    _.each(datos, function (d) {
      _.each(d.countries, function (p) {
        p.estado = "regular";
        if (paises.length !== 0) {
          p.estado = paises.indexOf(p.code) >= 0 ? "highlight" : "fade";
        }
      });
    });
  }

  function mostrarPaises(viz) {
    let main = viz.svg.select("g.main");

    main
      .selectAll("circle.cover")
      .transition()
      .duration(duracion)
      .style("opacity", (d) => (d.estado === "fade" ? 0.7 : 0));

    main
      .selectAll("circle.flag")
      .style("stroke-width", (d) => (d.estado === "highlight" ? "4px" : "0px"));

    main
      .selectAll("text")
      .transition()
      .duration(duracion)
      .style("opacity", (d) => (d.estado === "highlight" ? 1 : 0));
  }

  function resaltarPaises(viz) {
    let main = viz.svg.select("g.main");

    main
      .selectAll("circle.flag")
      .transition()
      .duration(duracion)
      .style("stroke-width", (d) => (d.estado === "highlight" ? "4px" : "0px"));

    main
      .selectAll("circle.cover")
      .transition()
      .duration(duracion)
      .style("opacity", (d) => (d.estado === "fade" ? 0.7 : 0));

    main
      .selectAll("text")
      .style("opacity", (d) => (d.estado === "highlight" ? 1 : 0));
  }

  function mostrarCapas(clave, direccion) {
    let actividad = null;
    let a = parseFloat(viz0.container.select("svg").style("width"));
    let p = parseFloat(getComputedStyle(document.documentElement).fontSize);
    let container_carrousel = d3
      .select("div[data-container='story-2-1']")
      .select("div.carrousel")
      .node();

    let conjugated_members = d3
      .select("#conjugated_scatterplots_members")
      .node().checked;

    capas.anterior = capas.actual;
    capas.actual = clave;

    flagRegression = true;

    if (capas.actual === "base") {
      resetPaises(datos.shared);
      if (isSmallScreen()) {
        container_carrousel.scrollLeft = 0;
      }
      flagRegression = false;

      viz0.container.select("svg").classed("ignore", false);
      viz1.container.select("svg").classed("ignore", false);
      viz2.container.select("svg").classed("ignore", false);

      viz0.container.transition("fade").duration(duracion).style("opacity", 1);
      viz1.container.transition("fade").duration(duracion).style("opacity", 1);
      viz2.container.transition("fade").duration(duracion).style("opacity", 1);

      mostrarPaises(viz0);
      mostrarPaises(viz1);
      mostrarPaises(viz2);

      actividad = encontrarActividadPorCodigo(datos.shared, "AC321");
      cambiarActividadScatterplot(
        viz0,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "AC313");
      cambiarActividadScatterplot(
        viz1,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "Personal care");
      cambiarActividadScatterplot(
        viz2,
        actividad,
        conjugated_members,
        flagRegression
      );

      /*
      cambiarActividadScatterplot(
        viz0,
        null,
        conjugated_members,
        flagRegression
      );
      cambiarActividadScatterplot(
        viz1,
        null,
        conjugated_members,
        flagRegression
      );
      cambiarActividadScatterplot(
        viz2,
        null,
        conjugated_members,
        flagRegression
      );
*/
    }

    if (capas.actual === "positive") {
      resetPaises(datos.shared);
      if (isSmallScreen()) {
        container_carrousel.scrollLeft = 0;
      }
      flagRegression = false;

      viz0.container.select("svg").classed("ignore", false);
      viz1.container.select("svg").classed("ignore", false);
      viz2.container.select("svg").classed("ignore", false);

      viz0.container.transition("fade").duration(duracion).style("opacity", 1);
      viz1.container.transition("fade").duration(duracion).style("opacity", 1);
      viz2.container.transition("fade").duration(duracion).style("opacity", 1);

      mostrarPaises(viz0);
      mostrarPaises(viz1);
      mostrarPaises(viz2);

      actividad = encontrarActividadPorCodigo(datos.shared, "AC321");
      cambiarActividadScatterplot(
        viz0,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "AC313");
      cambiarActividadScatterplot(
        viz1,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "Personal care");
      cambiarActividadScatterplot(
        viz2,
        actividad,
        conjugated_members,
        flagRegression
      );
    }

    if (capas.actual === "correlations") {
      resetPaises(datos.shared);
      if (isSmallScreen()) {
        container_carrousel.scrollLeft = 0;
      }

      viz0.container.select("svg").classed("ignore", false);
      viz1.container.select("svg").classed("ignore", false);
      viz2.container.select("svg").classed("ignore", false);

      viz0.container.transition("fade").duration(duracion).style("opacity", 1);
      viz1.container.transition("fade").duration(duracion).style("opacity", 1);
      viz2.container.transition("fade").duration(duracion).style("opacity", 1);

      mostrarPaises(viz0);
      mostrarPaises(viz1);
      mostrarPaises(viz2);

      actividad = encontrarActividadPorCodigo(datos.shared, "AC321");
      cambiarActividadScatterplot(
        viz0,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "AC313");
      cambiarActividadScatterplot(
        viz1,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "Personal care");
      cambiarActividadScatterplot(
        viz2,
        actividad,
        conjugated_members,
        flagRegression
      );
    }

    if (capas.actual === "highlight-positive") {
      resetPaises(datos.shared, ["IT"]);
      if (isSmallScreen()) {
        container_carrousel.scrollLeft = a + p;
      }

      viz0.container.select("svg").classed("ignore", true);
      viz1.container.select("svg").classed("ignore", true);
      viz2.container.select("svg").classed("ignore", true);

      viz0.container.transition("fade").duration(duracion).style("opacity", 1);
      viz1.container
        .transition("fade")
        .duration(duracion)
        .style("opacity", 0.4);
      viz2.container
        .transition("fade")
        .duration(duracion)
        .style("opacity", 0.4);

      mostrarPaises(viz0);

      actividad = encontrarActividadPorCodigo(datos.shared, "AC321");
      cambiarActividadScatterplot(
        viz0,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "AC313");
      cambiarActividadScatterplot(
        viz1,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "Personal care");
      cambiarActividadScatterplot(
        viz2,
        actividad,
        conjugated_members,
        flagRegression
      );
    }

    if (capas.actual === "negative") {
      resetPaises(datos.shared);
      if (isSmallScreen()) {
        container_carrousel.scrollLeft = 0;
      }

      viz0.container.select("svg").classed("ignore", false);
      viz1.container.select("svg").classed("ignore", false);
      viz2.container.select("svg").classed("ignore", false);

      viz0.container.transition("fade").duration(duracion).style("opacity", 1);
      viz1.container.transition("fade").duration(duracion).style("opacity", 1);
      viz2.container.transition("fade").duration(duracion).style("opacity", 1);

      mostrarPaises(viz0);
      mostrarPaises(viz1);
      mostrarPaises(viz2);

      actividad = encontrarActividadPorCodigo(datos.shared, "AC9D");
      cambiarActividadScatterplot(
        viz0,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "AC52");
      cambiarActividadScatterplot(
        viz1,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "AC51A");
      cambiarActividadScatterplot(
        viz2,
        actividad,
        conjugated_members,
        flagRegression
      );
    }

    if (capas.actual === "highlight-negative-1") {
      resetPaises(datos.shared, ["RS"]);
      if (isSmallScreen()) {
        container_carrousel.scrollLeft = a + p;
      }

      viz0.container.select("svg").classed("ignore", true);
      viz1.container.select("svg").classed("ignore", true);
      viz2.container.select("svg").classed("ignore", true);

      viz0.container
        .transition("fade")
        .duration(duracion)
        .style("opacity", 0.4);
      viz1.container
        .transition("fade")
        .duration(duracion)
        .style("opacity", 0.4);
      viz2.container.transition("fade").duration(duracion).style("opacity", 1);

      mostrarPaises(viz2);

      actividad = encontrarActividadPorCodigo(datos.shared, "AC9D");
      cambiarActividadScatterplot(
        viz0,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "AC52");
      cambiarActividadScatterplot(
        viz1,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "AC51A");
      cambiarActividadScatterplot(
        viz2,
        actividad,
        conjugated_members,
        flagRegression
      );
    }

    if (capas.actual === "highlight-negative-2") {
      resetPaises(datos.shared, ["HU"]);
      if (isSmallScreen()) {
        container_carrousel.scrollLeft = 2 * (a + p);
      }

      viz0.container.select("svg").classed("ignore", true);
      viz1.container.select("svg").classed("ignore", true);
      viz2.container.select("svg").classed("ignore", true);

      viz0.container
        .transition("fade")
        .duration(duracion)
        .style("opacity", 0.4);
      viz1.container
        .transition("fade")
        .duration(duracion)
        .style("opacity", 0.4);
      viz2.container.transition("fade").duration(duracion).style("opacity", 1);

      mostrarPaises(viz2);

      actividad = encontrarActividadPorCodigo(datos.shared, "AC9D");
      cambiarActividadScatterplot(
        viz0,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "AC52");
      cambiarActividadScatterplot(
        viz1,
        actividad,
        conjugated_members,
        flagRegression
      );

      actividad = encontrarActividadPorCodigo(datos.shared, "AC51A");
      cambiarActividadScatterplot(
        viz2,
        actividad,
        conjugated_members,
        flagRegression
      );
    }
  }

  function linearRegression(data) {
    let m;
    let b;

    // Store data length in a local variable to reduce
    // repeated object property lookups
    const dataLength = data.length;

    //if there's only one point, arbitrarily choose a slope of 0
    //and a y-intercept of whatever the y of the initial point is
    if (dataLength === 1) {
      m = 0;
      b = data[0][1];
    } else {
      // Initialize our sums and scope the `m` and `b`
      // variables that define the line.
      let sumX = 0;
      let sumY = 0;
      let sumXX = 0;
      let sumXY = 0;

      // Use local variables to grab point values
      // with minimal object property lookups
      let point;
      let x;
      let y;

      // Gather the sum of all x values, the sum of all
      // y values, and the sum of x^2 and (x*y) for each
      // value.
      //
      // In math notation, these would be SS_x, SS_y, SS_xx, and SS_xy
      for (let i = 0; i < dataLength; i++) {
        point = data[i];
        x = point[0];
        y = point[1];

        sumX += x;
        sumY += y;

        sumXX += x * x;
        sumXY += x * y;
      }

      // `m` is the slope of the regression line
      m =
        (dataLength * sumXY - sumX * sumY) / (dataLength * sumXX - sumX * sumX);

      // `b` is the y-intercept of the line.
      b = sumY / dataLength - (m * sumX) / dataLength;
    }

    // Return both values as an object.
    return {
      m: m,
      b: b,
    };
  }

  setup();
});
