windowReady.ready(function () {
  let width = 1580;
  let height = 880;

  let duracion = isMobile.phone ? 600 : 400;
  let debounceTimer = 200;
  let debouncedCalcularMedidas = null;

  let scroller = null;
  let capas = { anterior: null, actual: null };
  let pasos = [];
  let isReduced = null;

  let container = null;
  let sensitivity = 75;
  let projectionGlobe = d3
    .geoOrthographic()
    .scale(365)
    .center([0, 0])
    .rotate([-3.337711684132892, -46.7520939522693, 0])
    .translate([width / 2, height / 2]);

  let projectionFrance = d3
    .geoOrthographic()
    .scale(5000)
    .center([0, 0])
    .rotate([-3.337711684132892, -46.7520939522693, 0])
    .translate([width / 2, height / 2]);

  let pathGlobe = d3.geoPath().projection(projectionGlobe);
  let pathFrance = d3.geoPath().projection(projectionFrance);

  let initialScale = projectionGlobe.scale();
  let escalaCirculos = d3.scaleSqrt().range([5, 50]);
  let escalaFlechas = d3.scaleLog().range([1, 20]);

  let timerGlobe = null;

  let sankeyPrincipalBox = new Box({
    size: { width: width, height: height },
    padding: { top: 50, right: 180, bottom: 50, left: 180 },
  });
  let sankeyPrincipal = d3
    .sankey()
    .nodeId((d) => d.code)
    .nodeWidth(40)
    .nodePadding(5)
    .size([
      sankeyPrincipalBox.content.width,
      sankeyPrincipalBox.content.height,
    ]);

  let sankeyCarruselBox = new Box({
    size: {
      width: sankeyPrincipalBox.width,
      height: sankeyPrincipalBox.height,
    },
    padding: sankeyPrincipalBox.padding,
    margin: sankeyPrincipalBox.margin,
  });
  let sankeyCarrusel = d3
    .sankey()
    .nodeId((d) => d.code)
    .nodeWidth(40)
    .nodePadding(5)
    .size([sankeyCarruselBox.content.width, sankeyCarruselBox.content.height]);

  let lineasCarruselBox = new Box({
    size: {
      width: sankeyCarruselBox.width,
      height: sankeyCarruselBox.height,
    },
    padding: sankeyCarruselBox.padding,
    margin: sankeyCarruselBox.margin,
  });

  let escalaX = d3.scaleLinear().range([0, lineasCarruselBox.content.width]);
  let escalaY = d3.scaleLinear().range([lineasCarruselBox.content.height, 0]);

  let svg = null;
  let defs = null;
  let gGlobo = null;
  let gMapa = null;
  let gSankey = null;

  let cajasEtiquetas = {};

  let interpoladorFranciaANodoFrancia = null;
  function crearInterpoladores(params) {
    let dFranciaPeninsular = defs.select("#peninsular-france").attr("d");
    let francia = _.find(params.sankeyFrancia.nodes, { id: "SOURCE" });
    interpoladorFranciaANodoFrancia = flubber.toRect(
      dFranciaPeninsular,
      francia.x0 + sankeyPrincipalBox.padding.left,
      francia.y0 + sankeyPrincipalBox.padding.top,
      francia.x1 - francia.x0,
      francia.y1 - francia.y0
    );
  }

  function crearScroller(params) {
    let elementos = d3.selectAll("[data-capas]");
    if (elementos.size() > 0) {
      elementos.each(function (d, i) {
        pasos.push(d3.select(this).attr("data-capas"));
      });
      scroller = scrollama();
      scroller
        .setup({
          step: "[data-capas]",
          offset: 0.9,
          debug: false,
        })
        .onStepEnter(function (r) {
          handleStepEnter(r, params);
        });
    }
  }

  function handleStepEnter(response, params) {
    let capa = response.element.getAttribute("data-capas");
    if (response.direction == "up") {
      let i = response.index;
      if (i >= 1) {
        capa = pasos[i - 1];
      }
    }
    mostrarCapas(capa, response.direction, params);
  }

  function setup() {
    container = d3.select("div[data-container='story-3-1']");
    if (container.node()) {
      isReduced =
        window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
        window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
      if (isReduced) {
        duracion = 0;
      }
      setupHistoria();
      debouncedCalcularMedidas = _.debounce(calcularMedidas, debounceTimer);
      window.onresize = debouncedCalcularMedidas;
    }
  }

  function calcularMedidas() {
    calcularAlturaSVG();
    scroller.resize();
  }

  function isSmallScreen() {
    return window.innerWidth <= 1024;
  }

  function isMobileSize() {
    return window.innerWidth <= 480;
  }

  function calcularAlturaSVG() {
    let box = container.node().getBoundingClientRect();
    let root = document.documentElement;
    if (isMobileSize()) {
      root.style.setProperty(
        "--svg-height",
        `calc(8rem + ${2 * box.height}px)`
      );
    } else {
      root.style.setProperty("--svg-height", `calc(8rem + ${box.height}px)`);
    }
  }

  function setupHistoria() {
    crearSVG();
    calcularAlturaSVG();
    fetchDatos();
  }

  function crearSVG() {
    svg = container
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${width} ${height}`);

    defs = svg.append("defs");

    svg = svg.append("g").attr("class", "svg");

    gGlobo = svg.append("g").attr("id", "gGlobo");

    gMapa = svg.append("g").attr("id", "gMapa");

    gSankey = svg.append("g").attr("id", "gSankey").call(hide);
  }

  function fetchDatos() {
    //TODO:Usar el comando "points centroid" en mapshaper para agregar centroides al mapa final
    Promise.all([
      d3.json("json/story3/globe_temp.json"),
      d3.csv("csv/story3/trips_all.csv", function (d) {
        let origen = { code: d.code_origin, name: d.country_origin };
        let destino = {
          code: d.code_destination,
          name: d.country_destination,
        };
        return {
          origen: origen,
          destino: destino,
          num: +d.number_trips,
          member: d.member === "TRUE",
        };
      }),
      d3.csv("csv/story3/trips_from_eu.csv", function (d) {
        let origen = { code: d.code_origin, name: d.country_origin };
        let destino = {
          code: d.code_destination,
          name: d.country_destination,
        };
        return {
          origen: origen,
          destino: destino,
          num: +d.number_trips,
          member: d.member === "TRUE",
        };
      }),
      d3.csv("csv/story3/trips_from_france.csv", function (d) {
        let origen = { code: d.code_origin, name: d.country_origin };
        let destino = {
          code: d.code_destination,
          name: d.country_destination,
        };
        return {
          origen: origen,
          destino: destino,
          num: +d.number_trips,
          member: d.member === "TRUE",
        };
      }),
      d3.csv("csv/story3/trips_domestic_foreign.csv", function (d) {
        return {
          code: d.code,
          country: d.country,
          year: +d.year,
          domestic: +d.domestic,
          foreign: +d.foreign,
        };
      }),
    ])
      .then(function ([
        mapa,
        viajes,
        viajesDesdeUE,
        viajesDesdeFrancia,
        viajesComparados,
      ]) {
        let paises = topojson.feature(mapa, mapa.objects["countries"]);
        let departamentos = topojson.feature(mapa, mapa.objects["departments"]);
        let centroides = topojson.feature(mapa, mapa.objects["centroids"]);

        let france = _.remove(
          paises.features,
          (d) => d.properties.EC_CODE === "FR"
        );
        paises.features = _.concat(paises.features, france);

        centroides = _.map(centroides.features, function (d) {
          let obj = {
            code: d.properties.EC_CODE,
            coords: d.geometry,
          };
          return obj;
        });

        viajesDesdeFrancia = _.filter(viajesDesdeFrancia, (d) => d.num > 0);
        viajesDesdeFrancia = _.orderBy(viajesDesdeFrancia, ["num"], ["desc"]);
        let pathsDesdeFrancia = _.map(viajesDesdeFrancia, function (d) {
          return {
            type: "LineString",
            coordinates: [],
            properties: {
              origen: d.origen,
              destino: d.destino,
              num: d.num,
            },
          };
        });

        escalaCirculos.domain(
          d3.extent(
            _.map(departamentos.features, (d) => d.properties.total_trips)
          )
        );
        escalaFlechas.domain(
          d3.extent(_.map(pathsDesdeFrancia, (d) => d.properties.num))
        );
        escalaX.domain(d3.extent(_.map(viajesComparados, (d) => d.year)));

        console.log("viajes");
        console.log(viajes);
        console.log(viajesDesdeFrancia);
        console.log(viajesDesdeUE);
        console.log(viajesComparados);

        let sankeyFrancia = sankeyPrincipal(
          generarObjetoSankey(viajesDesdeFrancia)
        );
        console.log("sankeyFrancia - og");
        console.log(sankeyFrancia);

        let sankeyUE = sankeyPrincipal(generarObjetoSankey(viajesDesdeUE));
        console.log("sankeyUE - og");
        console.log(sankeyUE);

        _.each(pathsDesdeFrancia, function (d) {
          let code = d.properties.origen.code;
          let obj = _.find(centroides, (d) => d.code === code);
          d.coordinates.push(obj.coords.coordinates);

          code = d.properties.destino.code;
          obj = _.find(centroides, (d) => d.code === code);
          d.coordinates.push(obj.coords.coordinates);
        });

        console.log("viajes");
        console.log(viajes);

        let viajesPorPais = _.groupBy(viajes, function (d) {
          return d.origen.code;
        });

        console.log("viajesPorPais");
        console.log(viajesPorPais);

        let viajesComparadosPorPais = _.groupBy(viajesComparados, function (d) {
          return d.code;
        });
        console.log("viajesComparadosPorPais");
        console.log(viajesComparadosPorPais);

        let sankeysPorPais = [];
        _.each(viajesPorPais, function (v, k) {
          sankeysPorPais.push(generarObjetoSankey(v));
        });
        sankeysPorPais = _.orderBy(
          sankeysPorPais,
          function (d) {
            let src = _.find(d.nodes, { id: "SOURCE" });
            return src.name;
          },
          ["asc"]
        );

        let params = {
          paises: paises,
          departamentos: departamentos,
          viajes: viajes,
          pathsDesdeFrancia: pathsDesdeFrancia,
          viajesDesdeFrancia: viajesDesdeFrancia,
          viajesDesdeUE: viajesDesdeUE,
          sankeyFrancia: sankeyFrancia,
          sankeyUE: sankeyUE,
          sankeysPorPais: sankeysPorPais,
          viajesComparadosPorPais: viajesComparadosPorPais,
        };

        crearGlobo(params);
        crearSankey(gSankey, sankeyPrincipal, params.sankeyFrancia);
        crearCarrusel(params.sankeysPorPais, params.viajesComparadosPorPais);

        //Pasan cosas, crearScroller va hacia/hasta el final del resto de funciones
        crearInterpoladores(params);
        crearScroller(params);

        //volver
        /*
        duracion = 0;
        mostrarCapas("base");
        mostrarCapas("france");
        mostrarCapas("zoom");
        mostrarCapas("globe");
        mostrarCapas("arrows");
        mostrarCapas("sankey_france");
        mostrarCapas("sankey_eu", "down", params);
        mostrarCapas("sankey_charts");
        mostrarCapas("line_charts");
        */
      })
      .catch((e) => {
        console.log("fetchDatos: failed");
        console.log(e);
      });
  }

  function setCajaEtiqueta(d) {
    if (!cajasEtiquetas.hasOwnProperty(d.code)) {
      let t = svg
        .append("text")
        .attr("x", 0)
        .attr("y", width / 2)
        .style("font-size", "18px")
        .style("font-weight", 600)
        .style("font-family", "IBM Plex Sans")
        .text(d.name);
      let caja = t.node().getBBox();
      cajasEtiquetas[d.code] = caja;
      t.remove();
    }
  }

  function getCajaEtiqueta(code) {
    return cajasEtiquetas[code];
  }

  function generarObjetoSankey(viajes) {
    let nodes = {};
    let links = [];

    viajes = _.filter(viajes, (d) => d.num > 0);
    viajes = _.orderBy(viajes, ["num"], ["desc"]);

    _.each(viajes, function (v) {
      let o = v.origen;
      setCajaEtiqueta(o);
      if (!nodes.hasOwnProperty(o.code)) {
        nodes[o.code] = {
          code: o.code,
          name: o.name,
          member: true,
          id: "SOURCE",
        };
      }

      let d = v.destino;
      setCajaEtiqueta(d);
      if (!nodes.hasOwnProperty(d.code)) {
        nodes[d.code] = {
          code: d.code,
          name: d.name,
          member: v.member,
          id: d.code,
        };
      }

      links.push({
        source: o.code,
        target: d.code,
        value: v.num,
        member: v.member,
        id: d.code,
      });
    });

    return { nodes: _.values(nodes), links: links };
  }

  function crearGlobo(params) {
    let paises = params.paises;
    let departamentos = params.departamentos;
    let pathsDesdeFrancia = params.pathsDesdeFrancia;

    /*
    El código del globo salió de acá: https://observablehq.com/@michael-keith/draggable-globe-in-d3
    */
    /*
   Otra versión del globo: https://plnkr.co/edit/AzianFOknvgRBR2f7TvC?p=preview&preview
   */
    const markerBoxWidth = 20;
    const markerBoxHeight = 20;
    const refX = markerBoxWidth / 2;
    const refY = markerBoxHeight / 2;
    const markerWidth = markerBoxWidth / 2;
    const markerHeight = markerBoxHeight / 2;
    const arrowPoints = [
      [0, 0],
      [0, 20],
      [20, 10],
    ];

    defs
      .append("marker")
      .attr("id", "arrow")
      .attr("viewBox", [0, 0, 200, 200])
      .attr("refX", refX)
      .attr("refY", refY)
      .attr("markerWidth", markerBoxWidth)
      .attr("markerHeight", markerBoxHeight)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(arrowPoints))
      .attr("stroke", "none")
      .attr("fill", "blue");

    let france = _.find(paises.features, function (d) {
      return d.properties.EC_CODE === "FR";
    });
    defs
      .append("path")
      .attr("id", "peninsular-france")
      .attr("d", pathGlobe(france.geometry));

    let globo = gGlobo
      .append("circle")
      .attr("fill", "#ffffff")
      .attr("stroke", "#000000")
      .attr("stroke-width", "0.2")
      .attr("cx", width / 2)
      .attr("cy", height / 2)
      .attr("r", initialScale);

    gMapa
      .append("g")
      .attr("class", "countries")
      .selectAll("path")
      .data(paises.features, (d) => d.properties.EC_CODE)
      .enter()
      .append("path")
      .attr("d", pathGlobe)
      .attr("fill", "#EEEEEE")
      .style("stroke", "black")
      .style("stroke-width", 0.3)
      .style("opacity", 0.8);

    gMapa
      .append("g")
      .attr("class", "departments")
      .call(hide)
      .selectAll("path")
      .data(departamentos.features, (d) => d.properties.NUTS_ID)
      .enter()
      .append("path")
      .attr("d", pathGlobe)
      .attr("fill", "#EEEEEE")
      .style("stroke", "transparent")
      .style("stroke-width", 0.3)
      .style("opacity", 0.8);

    gMapa
      .append("g")
      .attr("class", "circles")
      .call(hide)
      .selectAll("circle")
      .data(departamentos.features)
      .enter()
      .append("circle")
      .attr("r", 0)
      .attr("fill", "blue")
      .attr("opacity", 0.6);

    //console.clear();

    gMapa
      .append("g")
      .attr("class", "trips")
      .call(hide)
      .selectAll("path")
      .data(pathsDesdeFrancia)
      .enter()
      .append("path")
      .attr("d", pathGlobe)
      .style("fill", "none")
      .style("stroke", "transparent")
      .style("stroke-width", (d) => escalaFlechas(d.properties.num))
      .style("opacity", 0.3)
      //.style("stroke", "blue")
      //.style("stroke-width", 1)
      .attr("marker-end", "url(#arrow)")
      .each(function (d) {
        d.properties.length = d3.select(this).node().getTotalLength();
      });

    gMapa
      .call(
        d3.drag().on("drag", (event) => {
          const rotate = projectionGlobe.rotate();
          console.log("rotate 1");
          console.log(rotate);
          const k = sensitivity / projectionGlobe.scale();
          projectionGlobe.rotate([
            rotate[0] + event.dx * k,
            rotate[1] - event.dy * k,
          ]);
          console.log("rotate 2");
          console.log(projectionGlobe.rotate());
          pathGlobe.projection(projectionGlobe);
          gMapa.selectAll("path").attr("d", pathGlobe);
        })
      )
      .call(
        d3.zoom().on("zoom", (event) => {
          if (event.transform.k > 0.3) {
            projectionGlobe.scale(initialScale * event.transform.k);
            pathGlobe.projection(projectionGlobe);
            gMapa.selectAll("path").attr("d", pathGlobe);
            console.log("scale");
            console.log(projectionGlobe.scale());
            globo.attr("r", projectionGlobe.scale());
          } else {
            event.transform.k = 0.3;
          }
        })
      );

    timerGlobe = d3.timer(function (elapsed) {
      let rotate = projectionGlobe.rotate();
      let k = sensitivity / projectionGlobe.scale();
      projectionGlobe.rotate([rotate[0] - 1 * k, rotate[1]]);
      pathGlobe.projection(projectionGlobe);
      gMapa.selectAll("path").attr("d", pathGlobe);
    }, 200);

    //timerGlobe.stop(); //TODO: Remover
  }

  function centrarFrancia() {
    timerGlobe.stop();
    gMapa
      .select("g.countries")
      .selectAll("path")
      .filter((d) => d.properties.EC_CODE === "FR")
      .transition("path-stroke-width")
      .duration(duracion)
      .style("stroke-width", 2)
      .end()
      .catch((e) => {
        console.log("centrarFrancia: transition('path-stroke-width') failed");
        console.log(e);
      })
      .finally(function () {
        gMapa
          .select("g.countries")
          .selectAll("path")
          .filter((d) => d.properties.EC_CODE === "FR")
          .style("stroke-width", 2);
      });

    gMapa
      .selectAll("path")
      .transition("path-center")
      .duration(duracion)
      .attrTween("d", function (d) {
        let r = d3.interpolate(
          projectionGlobe.rotate(),
          projectionFrance.rotate()
        );
        return function (t) {
          projectionGlobe.rotate(r(t));
          pathGlobe.projection(projectionGlobe);
          return pathGlobe(d);
        };
      })
      .end()
      .catch((e) => {
        console.log("centrarFrancia: transition('path-center') failed");
        console.log(e);
      })
      .finally(function () {
        gMapa.selectAll("path").attr("d", pathGlobe);
      });
  }

  function zoomFrancia() {
    let projectionTransition = projectionFrance;
    let pathTransition = pathFrance;

    gMapa.select("g.departments").call(unhide);
    gMapa.select("g.circles").call(unhide);

    gGlobo
      .select("circle")
      .transition("circle-zoom")
      .duration(duracion)
      .attr("r", projectionFrance.scale())
      .end()
      .catch((e) => {
        console.log("zoomFrancia: transition('circle-zoom') failed");
        console.log(e);
      })
      .finally(function () {
        gGlobo.select("circle").attr("r", projectionFrance.scale());
      });

    gMapa
      .selectAll("path")
      .transition("path-zoom")
      .duration(duracion)
      .attrTween("d", function (d) {
        let r = d3.interpolate(
          projectionGlobe.rotate(),
          projectionTransition.rotate()
        );
        let s = d3.interpolate(
          projectionGlobe.scale(),
          projectionTransition.scale()
        );
        return function (t) {
          projectionTransition.rotate(r(t)).scale(s(t));
          pathTransition.projection(projectionTransition);
          return pathTransition(d);
        };
      })
      .end()
      .catch((e) => {
        console.log("zoomFrancia: transition('path-zoom') failed");
        console.log(e);
      })
      .finally(function () {
        gMapa.selectAll("path").attr("d", pathFrance);

        gMapa
          .select("g.departments")
          .selectAll("path")
          .each(function (d) {
            d.properties.centroide = pathFrance.centroid(d);
          })
          .transition("path-color")
          .duration(duracion)
          .style("stroke", "blue")
          .end()
          .catch((e) => {
            console.log("zoomFrancia: transition('path-color') failed");
            console.log(e);
          })
          .finally(function () {
            gMapa
              .select("g.departments")
              .selectAll("path")
              .style("stroke", "blue");
          });

        gMapa
          .select("g.circles")
          .selectAll("circle")
          .attr("cx", (d) => d.properties.centroide[0])
          .attr("cy", (d) => d.properties.centroide[1])
          .transition("circle-viajes")
          .duration(duracion)
          .attr("r", (d) => escalaCirculos(d.properties.total_trips))
          .end()
          .catch((e) => {
            console.log("zoomFrancia: transition('circle-viajes') failed");
            console.log(e);
          })
          .finally(function () {
            gMapa
              .select("g.circles")
              .selectAll("circle")
              .attr("r", (d) => escalaCirculos(d.properties.total_trips));
          });
      });
  }

  function mostrarGlobo() {
    let projectionTransition = projectionGlobe;
    let pathTransition = pathGlobe;

    gMapa
      .select("g.departments")
      .selectAll("path")
      .each(function (d) {
        d.properties.centroide = pathGlobe.centroid(d);
      })
      .transition("path-color")
      .duration(duracion)
      .style("stroke", "transparent")
      .end()
      .catch((e) => {
        console.log("mostrarGlobo: transition('path-color') failed");
        console.log(e);
      })
      .finally(function () {
        gMapa
          .select("g.departments")
          .call(hide)
          .selectAll("path")
          .style("stroke", "transparent");
      });

    gMapa
      .select("g.circles")
      .selectAll("circle")
      .transition("circle-viajes")
      .duration(duracion)
      //.attr("cx", (d) => d.properties.centroide[0])
      //.attr("cy", (d) => d.properties.centroide[1])
      .attr("r", 0)
      .end()
      .catch((e) => {
        console.log("mostrarGlobo: transition('circle-viajes') failed");
        console.log(e);
      })
      .finally(function () {
        gMapa
          .select("g.circles")
          .call(hide)
          .selectAll("circle")
          .attr("cx", (d) => d.properties.centroide[0])
          .attr("cy", (d) => d.properties.centroide[1])
          .attr("r", 0);

        gGlobo
          .select("circle")
          .transition("circle-zoom")
          .duration(duracion)
          .attr("r", projectionGlobe.scale())
          .end()
          .catch((e) => {
            console.log("mostrarGlobo: transition('circle-zoom') failed");
            console.log(e);
          })
          .finally(function () {
            gGlobo.select("circle").attr("r", projectionGlobe.scale());
          });

        gMapa
          .selectAll("path")
          .transition("path-zoom")
          .duration(duracion)
          .attrTween("d", function (d) {
            let s = d3.interpolate(
              projectionFrance.scale(),
              projectionTransition.scale()
            );
            return function (t) {
              projectionTransition.scale(s(t));
              pathTransition.projection(projectionTransition);
              return pathTransition(d);
            };
          })
          .end()
          .catch((e) => {
            console.log("mostrarGlobo: transition('path-zoom') failed");
            console.log(e);
          })
          .finally(function () {
            gMapa.selectAll("path").attr("d", pathGlobe);
          });
      });
  }

  function mostrarFlechas() {
    //defs.select("#arrow").select("path").style("fill", "blue");

    gMapa.select("g.trips").call(unhide);

    gMapa
      .select("g.trips")
      .selectAll("path")
      .attr(
        "stroke-dasharray",
        (d) => `${d.properties.length} ${d.properties.length}`
      )
      .attr("stroke-dashoffset", (d) => d.properties.length)
      .style("stroke", "blue")
      .transition("dah-offset")
      .duration(duracion)
      .attr("stroke-dashoffset", 0)
      .end()
      .catch((e) => {
        console.log("mostrarFlechas: transition('dah-offset') failed");
        console.log(e);
      })
      .finally(function () {
        gMapa
          .select("g.trips")
          .selectAll("path")
          .attr("stroke-dashoffset", null)
          .attr("stroke-dasharray", null);
      });
  }

  function estilizarNodo(el) {
    el.style("fill", (d) => (d.member ? "#165ce9" : "#4fc9a4"))
      .style("stroke", (d) => (d.member ? "#165ce9" : "#4fc9a4"))
      .attr("x", (d) => d.x0)
      .attr("y", (d) => d.y0)
      .attr("height", (d) => d.y1 - d.y0);
  }

  function estilizarNodoTexto(el, ancho) {
    el.attr("x", (d) =>
      d.targetLinks.length === 0 ? d.x0 - 10 : d.x0 + ancho + 10
    )
      .attr("y", (d) => d.y0 + (d.y1 - d.y0) / 2)
      .style("font-size", "18px")
      .style("font-weight", 600)
      .style("font-family", "IBM Plex Sans")
      .attr("text-anchor", (d) =>
        d.targetLinks.length === 0 ? "end" : "start"
      )
      .attr("dominant-baseline", "middle")
      .style("fill", (d) => (d.member ? "#165ce9" : "#4fc9a4"))
      .text((d) => d.name)
      .each(function (d) {
        d.opacity = 0;
        let box = getCajaEtiqueta(d.code);
        if (d.y1 - d.y0 >= box.height) {
          d.opacity = 1;
        }
      });
  }

  function estilizarEnlace(el) {
    el.style("stroke", (d) => (d.member ? "#447dee" : "#73d4b7"))
      .style("fill", "none")
      .attr("stroke-width", function (d) {
        return d.width;
      })
      .attr("d", d3.sankeyLinkHorizontal());
  }

  function crearLineas(grupoLineas, data) {
    grupoLineas
      .append("g")
      .attr("class", "name")
      .call(hide)
      .append("text")
      .datum({ code: data[0].code, country: data[0].country })
      .attr("x", -60)
      .attr("y", -20)
      .style("font-size", "40px")
      .style("font-weight", 700)
      .style("fill", "black")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .text((d) => d.country);

    grupoLineas
      .append("g")
      .attr("class", "x")
      .attr("transform", `translate(0, ${sankeyCarruselBox.content.height})`)
      .call(d3.axisBottom(escalaX).tickFormat(d3.format("d")))
      .call(hide)
      .selectAll("text")
      .style("font-size", "24px")
      .attr("fill", "grey");
    grupoLineas.select("g.x").select("path").attr("stroke", "transparent");

    grupoLineas
      .append("g")
      .attr("class", "y")
      .call(d3.axisLeft(escalaY))
      .call(hide)
      .selectAll("text")
      .style("font-size", "24px")
      .attr("fill", "grey");
    grupoLineas.select("g.y").select("path").attr("stroke", "transparent");

    grupoLineas.append("g").attr("class", "main").call(hide);

    grupoLineas
      .append("g")
      .attr("class", "covid")
      .call(hide)
      .append("line")
      .attr("x1", escalaX(2020))
      .attr("y1", 0)
      .attr("x2", escalaX(2020))
      .attr("y2", 780)
      .style("stroke", "#000000")
      .style("stroke-width", "3px")
      .style("stroke-dasharray", "5, 5");

    grupoLineas
      .select("g.covid")
      .append("text")
      .attr("x", escalaX(2020) - 20)
      .attr("y", 0)
      .style("font-size", "26px'")
      .style("font-weight", 700)
      //.style("font-family", "font-family")
      .style("fill", "grey")
      .attr("text-anchor", "end")
      .attr("dominant-baseline", "middle")
      .text("Pandemic starts");

    escalaY.domain(
      d3.extent(
        _.concat(
          _.map(data, (d) => d.domestic),
          _.map(data, (d) => d.foreign)
        )
      )
    );

    grupoLineas
      .select("g.main")
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#165ce9")
      .attr("stroke-width", 10)
      .attr("stroke-linecap", "round")
      .attr(
        "d",
        d3
          .line()
          .curve(d3.curveCatmullRom.alpha(0.5))
          .x(function (d) {
            return escalaX(d.year);
          })
          .y(function (d) {
            return escalaY(d.domestic);
          })
      );

    grupoLineas
      .select("g.main")
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#4fc9a4")
      .attr("stroke-width", 10)
      .attr("stroke-linecap", "round")
      .attr(
        "d",
        d3
          .line()
          .curve(d3.curveCatmullRom.alpha(0.5))
          .x(function (d) {
            return escalaX(d.year);
          })
          .y(function (d) {
            return escalaY(d.foreign);
          })
      );

    //WIP
  }

  function crearSankey(grupoSankey, sankey, data) {
    grupoSankey.attr(
      "transform",
      `translate(${sankeyPrincipalBox.padding.left},${sankeyPrincipalBox.padding.top})`
    );
    grupoSankey.append("g").attr("class", "links").call(hide);
    grupoSankey.append("g").attr("class", "nodes").call(hide);

    let src = _.find(data.nodes, { id: "SOURCE" });

    let node = grupoSankey
      .select("g.nodes")
      .append("g")
      .attr("class", "node")
      .datum(src, (d) => d.id);

    node.append("rect").call(estilizarNodo).attr("width", sankey.nodeWidth());

    node
      .append("text")
      .call(estilizarNodoTexto, sankey.nodeWidth())
      .style("opacity", (d) => d.opacity);
  }

  function mostrarSankeyInicial(params) {
    gSankey.select("g.links").style("display", null).style("opacity", 0);
    gSankey.select("g.nodes").style("display", null).style("opacity", 0);

    let francia = gSankey
      .append("path")
      .attr("d", d3.select("#peninsular-france").attr("d"))
      .attr(
        "transform",
        `translate(-${sankeyPrincipalBox.padding.left}, -${sankeyPrincipalBox.padding.top})`
      )
      .style("fill", "#EEEEEE")
      .style("stroke", "black")
      .style("stroke-width", 2)
      .style("opacity", 0.8);

    gGlobo
      .transition("gGlobo")
      .duration(duracion)
      .style("opacity", 0)
      .end()
      .catch((e) => {
        console.log("mostrarSankeyInicial: transition('gGlobo') failed");
        console.log(e);
      })
      .finally(function () {
        gGlobo.call(hide);
        gMapa.select("g.trips").call(hide);
      });

    gMapa
      .transition("gMapa")
      .duration(duracion)
      .style("opacity", 0)
      .end()
      .catch((e) => {
        console.log("mostrarSankeyInicial: transition('gMapa') failed");
        console.log(e);
      })
      .finally(function () {
        gMapa.call(hide);
      });

    gSankey
      .style("display", null)
      .transition("gSankey")
      .duration(duracion)
      .style("opacity", 1)
      .end()
      .catch((e) => {
        console.log("mostrarSankeyInicial: transition('gSankey') failed");
        console.log(e);
      })
      .finally(function () {
        gSankey.style("opacity", null);
        francia
          .transition()
          .duration(duracion)
          .attrTween("d", function () {
            return interpoladorFranciaANodoFrancia;
          })
          .style("fill", "#165ce9")
          .style("stroke", "#165ce9")
          .style("stroke-width", 1)
          .style("opacity", 1)
          .end()
          .catch((e) => {
            console.log("mostrarSankeyInicial: transition() failed");
            console.log(e);
          })
          .finally(function () {
            francia.remove();
            gSankey.select("g.links").style("opacity", null);
            gSankey.select("g.nodes").style("opacity", null);
            /*
            console.log("actualizarSankey");
            console.log("params.sankeyFrancia");
            console.log(params.sankeyFrancia);
            */
            actualizarSankey(gSankey, sankeyPrincipal, params.sankeyFrancia);
          });
      });
  }

  function actualizarSankey(grupoSankey, sankey, data) {
    let links = grupoSankey
      .select("g.links")
      .selectAll(".link")
      .data(data.links, (d) => d.id);

    let nodes = grupoSankey
      .select("g.nodes")
      .selectAll(".node")
      .data(data.nodes, (d) => d.id);

    let grupos = nodes.enter().append("g").attr("class", "node");

    grupos.append("rect").call(estilizarNodo).attr("width", 0);

    grupos
      .append("text")
      .call(estilizarNodoTexto, sankey.nodeWidth())
      .style("opacity", 0);

    let enlaces = links
      .enter()
      .append("path")
      .attr("class", "link")
      .call(estilizarEnlace)
      .each(function (d) {
        d.length = d3.select(this).node().getTotalLength();
      })
      .attr("stroke-dasharray", (d) => `${d.length} ${d.length}`)
      .attr("stroke-dashoffset", (d) => d.length);

    enlaces
      .transition("link_enter")
      .duration(duracion * 0.8)
      .attr("stroke-dashoffset", 0)
      .end()
      .catch((e) => {
        console.log("actualizarSankey: transition('link_enter') failed");
        console.log(e);
      })
      .finally(function () {
        enlaces.attr("stroke-dashoffset", null).attr("stroke-dasharray", null);

        grupos
          .selectAll("rect")
          .transition("rect_enter")
          .duration(duracion * 0.4)
          .attr("width", sankey.nodeWidth())
          .end()
          .catch((e) => {
            console.log("actualizarSankey: transition('rect_enter') failed");
            console.log(e);
          })
          .finally(function () {
            grupos.selectAll("rect").attr("width", sankey.nodeWidth());

            grupos
              .selectAll("text")
              .style("opacity", 0)
              .transition("text_enter")
              .duration(duracion * 0.2)
              .style("opacity", (d) => d.opacity)
              .end()
              .catch((e) => {
                console.log(
                  "actualizarSankey: transition('text_enter') failed"
                );
                console.log(e);
              })
              .finally(function () {
                grupos
                  .selectAll("text")
                  .style("opacity", (d) =>
                    d.opacity === 1 ? null : d.opacity
                  );
              });
          });
      });

    links
      .transition("link_update")
      .duration(duracion)
      .call(estilizarEnlace)
      .end()
      .catch((e) => {
        console.log("actualizarSankey: transition('link_update') failed");
        console.log(e);
      })
      .finally(function () {
        links.call(estilizarEnlace).each(function (d) {
          d.length = d3.select(this).node().getTotalLength();
        });
      });

    links
      .exit()
      .transition("link_remove")
      .duration(duracion)
      .style("opacity", 0)
      .end()
      .catch((e) => {
        console.log("actualizarSankey: transition('link_remove') failed");
        console.log(e);
      })
      .finally(function () {
        links.exit().remove();
      });

    nodes
      .select("rect")
      .transition("rect_update")
      .duration(duracion)
      .call(estilizarNodo)
      .end()
      .catch((e) => {
        console.log("actualizarSankey: transition('rect_update') failed");
        console.log(e);
      })
      .finally(function () {
        nodes.select("rect").call(estilizarNodo);
      });

    nodes
      .select("text")
      .text((d) => d.name)
      .transition("text_update")
      .duration(duracion)
      .call(estilizarNodoTexto, sankey.nodeWidth())
      .style("opacity", (d) => d.opacity)
      .end()
      .catch((e) => {
        console.log("actualizarSankey: transition('text_update') failed");
        console.log(e);
      })
      .finally(function () {
        nodes
          .select("text")
          .call(estilizarNodoTexto, sankey.nodeWidth())
          .style("opacity", (d) => d.opacity);
      });

    nodes
      .exit()
      .transition("node_remove")
      .duration(duracion)
      .style("opacity", 0)
      .end()
      .catch((e) => {
        console.log("actualizarSankey: transition('node_remove') failed");
        console.log(e);
      })
      .finally(function () {
        nodes.exit().remove();
      });
  }

  function actualizarSankeySinTransicion(grupoSankey, sankey, data) {
    let links = grupoSankey
      .select("g.links")
      .selectAll(".link")
      .data(data.links, (d) => d.id);

    let nodes = grupoSankey
      .select("g.nodes")
      .selectAll(".node")
      .data(data.nodes, (d) => d.id);

    let grupos = nodes.enter().append("g").attr("class", "node");

    grupos.append("rect").call(estilizarNodo).attr("width", sankey.nodeWidth());

    grupos
      .append("text")
      .call(estilizarNodoTexto, sankey.nodeWidth())
      .style("opacity", (d) => (d.opacity === 1 ? null : d.opacity));

    let enlaces = links
      .enter()
      .append("path")
      .attr("class", "link")
      .call(estilizarEnlace)
      .each(function (d) {
        d.length = d3.select(this).node().getTotalLength();
      });

    links.call(estilizarEnlace);

    links.exit().remove();

    nodes.select("rect").call(estilizarNodo);

    nodes
      .select("text")
      .text((d) => d.name)
      .call(estilizarNodoTexto, sankey.nodeWidth())
      .style("opacity", (d) => d.opacity);

    nodes.exit().remove();
  }

  function crearCarrusel(sankeysPorPais, viajesComparadosPorPais) {
    let div = d3.select("div[data-container='story-3-2']").call(hide);

    let contenedorCarrusel = div.select("div.carrousel");
    _.each(sankeysPorPais, function (s) {
      let source = _.find(s.nodes, { id: "SOURCE" });

      s = sankeyCarrusel(s);

      let svg = contenedorCarrusel
        .append("div")
        .attr("data-clave", "")
        .append("svg")
        .attr("preserveAspectRatio", "xMidYMid")
        .attr(
          "viewBox",
          `0 0 ${sankeyCarruselBox.exterior.width} ${sankeyCarruselBox.exterior.height}`
        );

      svg = svg
        .append("g")
        .attr("class", "svg")
        .attr(
          "transform",
          `translate(${sankeyCarruselBox.padding.left}, ${sankeyCarruselBox.padding.top})`
        )
        .datum({ sankey: s });

      crearSankey(svg, sankeyCarrusel, s);

      crearLineas(svg, viajesComparadosPorPais[source.code]);
      svg.select("g.links").call(unhide);
      svg.select("g.nodes").call(unhide);
      /*
      console.log("actualizarSankey");
      console.log("s");
      console.log(s);
      */
      actualizarSankeySinTransicion(svg, sankeyCarrusel, s);
    });
    div.call(hide);
  }

  function actualizarSankeysPorPais(data) {
    //TODO: mandar el scroll al origen
    let carrusel = d3
      .select("div[data-container='story-3-2']")
      .select("div.carrousel");
    carrusel.node().scrollLeft = 0;

    d3.select("div[data-container='story-3-2']")
      .select("div.carrousel")
      .style("overflow-x", "clip");

    let grupos = d3
      .select("div[data-container='story-3-2']")
      .selectAll("g.svg");

    grupos.each(function (d) {
      /*
      console.log("actualizarSankeySinTransicion");
      console.log("data");
      console.log(data);
      */
      actualizarSankeySinTransicion(d3.select(this), sankeyCarrusel, data);
    });
    d3.select("div[data-container='story-3-1']").call(hide);
    d3.select("div[data-container='story-3-2']").call(unhide);
    carrusel.style("overflow-x", null);

    grupos.each(function (d) {
      /*
      console.log("actualizarSankey");
      console.log("d.sankey");
      console.log(d.sankey);
      */
      actualizarSankey(d3.select(this), sankeyCarrusel, d.sankey);
    });
  }

  function hide(el) {
    el.style("display", "none").style("opacity", 0);
  }

  function unhide(el) {
    el.style("display", null).style("opacity", null);
  }

  let definido = true;
  function mostrarCapas(clave, direccion, params) {
    capas.anterior = capas.actual;
    capas.actual = clave;

    if (capas.actual === null || capas.anterior === null) {
      definido = false;
    } else {
      definido = true;
    }

    if (definido) {
      if (
        capas.anterior == "base" &&
        capas.actual == "france" &&
        isReduced !== true
      ) {
        centrarFrancia();
        return 0;
      }
      if (
        capas.anterior == "france" &&
        capas.actual == "zoom" &&
        isReduced !== true
      ) {
        zoomFrancia();
        return 0;
      }
      if (
        capas.anterior == "zoom" &&
        capas.actual == "globe" &&
        isReduced !== true
      ) {
        mostrarGlobo();
        return 0;
      }
      if (
        capas.anterior == "globe" &&
        capas.actual == "arrows" &&
        isReduced !== true
      ) {
        mostrarFlechas();
        return 0;
      }
      if (
        capas.anterior == "arrows" &&
        capas.actual == "sankey_france" &&
        isReduced !== true
      ) {
        mostrarSankeyInicial(params);
        return 0;
      }
      if (
        capas.anterior == "sankey_france" &&
        capas.actual == "sankey_eu" &&
        isReduced !== true
      ) {
        /*
        console.log("actualizarSankey");
        console.log("params.sankeyUE");
        console.log(params.sankeyUE);
        */
        actualizarSankey(gSankey, sankeyPrincipal, params.sankeyUE);
        return 0;
      }
      if (
        capas.anterior == "sankey_eu" &&
        capas.actual == "sankey_charts" &&
        isReduced !== true
      ) {
        actualizarSankeysPorPais(params.sankeyUE);
        return 0;
      }
      if (
        capas.anterior == "sankey_charts" &&
        capas.actual == "line_charts" &&
        isReduced !== true
      ) {
        d3.select("div[data-container='story-3-2']")
          .selectAll("g.links")
          .call(hide);
        d3.select("div[data-container='story-3-2']")
          .selectAll("g.nodes")
          .call(hide);
        d3.select("div[data-container='story-3-2']")
          .selectAll("g.name")
          .call(unhide);
        d3.select("div[data-container='story-3-2']")
          .selectAll("g.x")
          .call(unhide);
        d3.select("div[data-container='story-3-2']")
          .selectAll("g.y")
          .call(unhide);
        d3.select("div[data-container='story-3-2']")
          .selectAll("g.main")
          .call(unhide);
        d3.select("div[data-container='story-3-2']")
          .selectAll("g.covid")
          .call(unhide);
        return 0;
      }
    }
  }

  setup();
});
