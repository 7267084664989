windowReady.ready(function () {
  let widthGlobo = 900;
  let heightGlobo = 400;

  let widthEuropa = 880;
  let heightEuropa = 880;

  let widthScatterplot = 880;
  let heightScatterplot = 880;

  let duracion = isMobile.phone ? 600 : 400;
  let debounceTimer = 200;
  let debouncedCalcularMedidas = null;
  let debouncedAjustarMapaEuropa = null;
  let debouncedAjustarMapaGlobo = null;
  let debouncedAjustarScatterPlot = null;

  let scroller1 = null;
  let scroller2 = null;
  let capas = { anterior: null, actual: null };
  let isReduced = null;

  let container_4_1 = null;
  let container_4_2 = null;
  let container_4_3 = null;

  let svgGlobo = null;
  let gPaisesGlobo = null;

  let svgEuropa = null;
  let gPaisesEuropa = null;

  let formatter = new Intl.NumberFormat("en-US", {});
  let svgScatterPlot = null;

  let escalaLadoGlobo = d3.scaleSqrt().domain([0, 1000000000]).range([2, 30]);
  let escalaColorGlobo = d3.scaleQuantize([0, 10], d3.schemeReds[9]);
  let projectionGlobo = d3
    .geoEqualEarth()
    .scale(150)
    .center([0, 0])
    .translate([widthGlobo / 2, heightGlobo / 2]);
  let pathGlobo = d3.geoPath().projection(projectionGlobo);
  let k = 1;

  let escalaLadoEuropa = d3.scaleSqrt().range([10, 100]);
  let escalaColorEuropa = d3.scaleQuantize([0, 10], d3.schemeReds[9]);
  let projectionEuropa = d3
    .geoEqualEarth()
    .scale(1100)
    .center([15, 63])
    .translate([widthGlobo / 2, heightGlobo / 2]);
  let pathEuropa = d3.geoPath().projection(projectionEuropa);

  let zoom = d3.zoom().scaleExtent([1, 8]).on("zoom", zoomed);

  let scatterplotBox = new Box({
    size: { width: widthScatterplot, height: heightScatterplot },
    padding: { top: 30, right: 32, bottom: 50, left: 70 },
  });
  let escalaX = d3.scaleLinear().range([0, scatterplotBox.content.width]);
  let escalaY = d3
    .scaleLinear()
    .range([scatterplotBox.content.height, 0])
    .domain([0, 10]);

  function setup() {
    container_4_1 = d3.select("div[data-container='story-4-1']");
    container_4_2 = d3.select("div[data-container='story-4-2']");
    container_4_3 = d3.select("div[data-container='story-4-3']");
    if (container_4_1.node()) {
      isReduced =
        window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
        window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
      if (isReduced) {
        duracion = 0;
      }
      setupHistoria();
      debouncedCalcularMedidas = _.debounce(calcularMedidas, debounceTimer);
      window.onresize = debouncedCalcularMedidas;
    }
  }

  function calcularMedidas() {
    calcularAlturaSVG();
    scroller1.resize();
    scroller2.resize();
  }

  function isMobileSize() {
    return window.innerWidth <= 480;
  }

  function calcularAlturaSVG() {
    let box = container_4_1.node().getBoundingClientRect();
    let root = document.documentElement;
    if (isMobileSize()) {
      root.style.setProperty(
        "--svg-height",
        `calc(8rem + ${2 * box.height}px)`
      );
    } else {
      root.style.setProperty("--svg-height", `calc(8rem + ${box.height}px)`);
    }
  }

  function setupHistoria() {
    crearSVGGlobo();
    crearSVGEuropa();
    crearSVGScatterPlot();
    calcularAlturaSVG();
    fetchDatos();
  }

  function crearSVGGlobo() {
    svgGlobo = container_4_1
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${widthGlobo} ${heightGlobo}`)
      .call(zoom);

    svgGlobo = svgGlobo.append("g").attr("class", "svg");
    gPaisesGlobo = svgGlobo
      .append("g")
      .attr("id", "gPaisesGlobo")
      .attr("stroke-width", 0.5);
    //    gCuadros = svg.append("g").attr("id", "gCuadros");
  }

  function crearSVGEuropa() {
    svgEuropa = container_4_2
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${widthEuropa} ${heightEuropa}`);

    svgEuropa = svgEuropa.append("g").attr("class", "svg");
    gPaisesEuropa = svgEuropa.append("g").attr("id", "gPaisesEuropa");
  }

  function crearSVGScatterPlot() {
    svgScatterPlot = container_4_3
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${widthScatterplot} ${heightScatterplot}`);

    let defs = svgScatterPlot.append("defs");

    defs
      .append("rect")
      .attr("id", "scatterplot_frame")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", scatterplotBox.content.width)
      .attr("height", scatterplotBox.content.height);

    let clip = defs.append("clipPath").attr("id", "main-rect-clip");
    clip.append("use").attr("href", "#scatterplot_frame");

    svgScatterPlot = svgScatterPlot.append("g").attr("class", "svg");
  }

  function fetchDatos() {
    Promise.all([
      d3.json("json/story4/globe.json"),
      d3.csv("csv/story4/keys.csv"),
      /*
      d3.csv("csv/story4/countries.csv"),
      d3.csv("csv/story4/population.csv", function (d) {
        return {
          iso3: d.iso3,
          population: +d.population,
        };
      }),
      d3.csv("csv/story4/risk.csv", function (d) {
        return {
          iso3: d.iso3,
          inform_risk: parseFloat(d.inform_risk).toFixed(2),
          lack_of_reliability: parseFloat(d.lack_of_reliability).toFixed(2),
          hazard_and_exposure: parseFloat(d.hazard_and_exposure).toFixed(2),
          natural: parseFloat(d.natural).toFixed(2),
          earthquake: parseFloat(d.earthquake).toFixed(2),
          river_flood: parseFloat(d.river_flood).toFixed(2),
          tsunami: parseFloat(d.tsunami).toFixed(2),
          tropical_cyclone: parseFloat(d.tropical_cyclone).toFixed(2),
          coastal_flood: parseFloat(d.coastal_flood).toFixed(2),
          drought: parseFloat(d.drought).toFixed(2),
          epidemic: parseFloat(d.epidemic).toFixed(2),
          human: parseFloat(d.human).toFixed(2),
          projected_conflict_probability: parseFloat(
            d.projected_conflict_probability
          ).toFixed(2),
          current_conflict_intensity: parseFloat(
            d.current_conflict_intensity
          ).toFixed(2),
          vulnerability: parseFloat(d.vulnerability).toFixed(2),
          socio_economic_vulnerability: parseFloat(
            d.socio_economic_vulnerability
          ).toFixed(2),
          development_and_deprivation: parseFloat(
            d.development_and_deprivation
          ).toFixed(2),
          inequality: parseFloat(d.inequality).toFixed(2),
          economic_dependency: parseFloat(d.economic_dependency).toFixed(2),
          vulnerable_groups: parseFloat(d.vulnerable_groups).toFixed(2),
          uprooted_people: parseFloat(d.uprooted_people).toFixed(2),
          health_conditions: parseFloat(d.health_conditions).toFixed(2),
          children_u5: parseFloat(d.children_u5).toFixed(2),
          recent_shocks: parseFloat(d.recent_shocks).toFixed(2),
          food_security: parseFloat(d.food_security).toFixed(2),
          other_vulnerable_groups: parseFloat(
            d.other_vulnerable_groups
          ).toFixed(2),
          lack_of_coping_capacity: parseFloat(
            d.lack_of_coping_capacity
          ).toFixed(2),
          institutional: parseFloat(d.institutional).toFixed(2),
          drr: parseFloat(d.drr).toFixed(2),
          governance: parseFloat(d.governance).toFixed(2),
          infrastructure: parseFloat(d.infrastructure).toFixed(2),
          communication: parseFloat(d.communication).toFixed(2),
          physical_infrastructure: parseFloat(
            d.physical_infrastructure
          ).toFixed(2),
          access_to_health_care: parseFloat(d.access_to_health_care).toFixed(2),
        };
      }),
      */
    ])
      .then(function ([mapa, riesgos]) {
        console.log(mapa);
        console.log(riesgos);

        let paises = topojson.feature(mapa, mapa.objects["countries"]);
        paises = paises.features;
        console.log(paises);

        let paisesFiltrados = _.filter(paises, function (p) {
          return (
            !_.isNull(p.properties.risks) &&
            !_.isNull(p.properties.name) &&
            !_.isNull(p.properties.ISO_A3_EH)
          );
        });
        console.log(paisesFiltrados);

        let paisesEuropa = _.filter(paisesFiltrados, function (d) {
          return d.properties.europe === true;
        });

        let paisesUE = _.filter(paisesFiltrados, function (d) {
          return d.properties.member === true;
        });

        /*
        let europa = topojson.feature(mapa, mapa.objects["europe"]);
        let centroides = topojson.feature(mapa, mapa.objects["centroids"]);
        window.centroides = centroides;
        centroides = _.map(centroides.features, function (d) {
          let obj = {
            code: d.properties.EC_CODE,
            coords: d.geometry,
          };
          return obj;
        });
        */

        /*
        _.each(pathsDesdeFrancia, function (d) {
          let code = d.properties.origen.code;
          let obj = _.find(centroides, (d) => d.code === code);
          d.coordinates.push(obj.coords.coordinates);

          code = d.properties.destino.code;
          obj = _.find(centroides, (d) => d.code === code);
          d.coordinates.push(obj.coords.coordinates);
        });
*/
        let params = {
          paises: paises,
          paisesFiltrados: paisesFiltrados,
          paisesEuropa: paisesEuropa,
          paisesUE: paisesUE,
          riesgos: riesgos,
          /*
          europa: europa.features,
          claves: claves,
          riesgo: riesgo,
          poblacion: poblacion,
          */
        };
        window.params = params;

        crearMapaGlobo(params.paises, true);
        crearControlesGlobo(params.paisesFiltrados, true);
        crearControlesEuropa(params.paisesEuropa, params.riesgos, true);
        crearMapaEuropa(params.paisesFiltrados);
        actualizarMapaEuropa(
          params.paisesEuropa,
          d3.select("#risk_europe_map").node().value,
          d3.select("#variable_europe_map").node().value,
          true
        );
        crearControlesScatterPlot(params.paisesUE, params.riesgos, true);
        crearScatterPlot();
        actualizarScatterPlot(
          params.paisesUE,
          d3.select("#risk_europe_scatter_plot").node().value,
          d3.select("#variable_europe_scatter_plot").node().value
        );

        //crearScroller(params);
      })
      .catch((e) => {
        console.log("fetchDatos: failed");
        console.log(e);
      });
  }

  function crearScroller(params) {
    let elementos = d3.selectAll("[data-capas]");
    if (elementos.size() > 0) {
      scroller1 = scrollama();
      scroller1
        .setup({
          step: "[data-capas]",
          offset: 0.9,
          debug: false,
        })
        .onStepEnter(function (response) {
          if (response.direction == "down") {
            mostrarCapas(response.element.getAttribute("data-capas"), params);
          }
        });

      scroller2 = scrollama();
      scroller2
        .setup({
          step: "[data-capas]",
          offset: 0.2,
          debug: false,
        })
        .onStepEnter(function (response) {
          if (response.direction == "up") {
            mostrarCapas(response.element.getAttribute("data-capas"), params);
          }
        });
    }
  }

  function crearMapaGlobo(paises, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let paisesFiltrados = _.filter(paises, function (d) {
      return d.properties.population > 0;
    });

    let arregloVariable = _.map(paises, function (d) {
      return d.properties.population;
    });

    escalaLadoGlobo.domain([0, _.max(arregloVariable)]);

    let gruposPaises = gPaisesGlobo
      .append("g")
      .attr("class", "countries")
      .selectAll("g")
      .data(paises)
      .enter()
      .append("g");

    let gruposRiesgo = gPaisesGlobo
      .append("g")
      .attr("class", "risk")
      .selectAll("g")
      .data(paisesFiltrados, (d) => d.properties.ISO_A3_EH)
      .enter()
      .append("g")
      .attr("transform", function (d) {
        let c = projectionGlobo(d.properties.coordinates);
        return `translate(${c[0]}, ${c[1]})`;
      });

    gruposPaises
      .append("path")
      .attr("d", pathGlobo)
      //.attr("fill", (d) => d.properties.fill)
      .attr("fill", "#EBEAEA")
      .style("stroke", (d) => d.properties.stroke)
      //.style("stroke-width", (d) => d.properties["stroke-width"])
      .style("opacity", (d) => d.properties.opacity)
      .on("click", function (e, d) {
        let el = d3.select(this);
        clicked(e, el, d, flagTransicion, factor * 1.5);
      });

    gruposRiesgo
      .append("rect")
      .attr("class", "risk")
      .attr("x", 0)
      .attr("y", 0)
      .attr("rx", 1)
      .attr("ry", 1)
      .style("stroke", "#878787")
      .style("stroke-width", 0.2)
      .attr("width", (d) => escalaLadoGlobo(d.properties.population))
      .attr("height", (d) => escalaLadoGlobo(d.properties.population))
      .style("fill", function (d) {
        let obj = _.find(d.properties.risks, { key: "inform_risk" });
        return escalaColorGlobo(obj.value);
      })
      .attr("transform", function (d) {
        let deltaX = escalaLadoGlobo(d.properties.population) / 2;
        let deltaY = escalaLadoGlobo(d.properties.population) / 2;
        return `translate(${-deltaX},${-deltaY})`;
      })
      .on("mouseover", crearTooltipGlobo)
      .on("mouseout", deshacerTooltipGlobo);

    let svg = d3.select(gPaisesGlobo.node().closest("svg"));

    svg.on("click", function (e) {
      reset(e, flagTransicion, factor * 1.5);
    });
  }

  //NOTE: Salió de https://observablehq.com/@d3/zoom-to-bounding-box
  //TODO: Hacerla promesa como a la otra
  function reset(event, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let svg = d3.select(svgGlobo.node().closest("svg"));

    descoloreaPaisesNoTarget(null, flagTransicion, factor);
    svg
      .transition()
      .duration(750)
      .call(
        zoom.transform,
        d3.zoomIdentity,
        d3.zoomTransform(svg.node()).invert([widthGlobo / 2, heightGlobo / 2])
      );
  }

  function clicked(event, el, d, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    event.stopPropagation();

    return new Promise(function (resolveFn, rejectFn) {
      Promise.all([
        actualizarZoomMapa(d, flagTransicion, factor),
        descoloreaPaisesNoTarget(d, flagTransicion, factor),
        coloreaPaisTarget(el, flagTransicion, factor),
      ]).finally(function () {
        resolveFn(0);
      });
    });
  }

  function actualizarZoomMapa(d, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "path:zoom";

    let nuevoD = _.cloneDeep(d);
    nuevoD.geometry.coordinates = [nuevoD.geometry.coordinates[0]];
    let [[x0, y0], [x1, y1]] = pathGlobo.bounds(nuevoD);
    let svg = d3.select(svgGlobo.node().closest("svg"));

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        let scale = Math.min(
          8,
          0.9 / Math.max((x1 - x0) / widthGlobo, (y1 - y0) / heightGlobo)
        );
        k = scale;
        svg
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .call(
            zoom.transform,
            d3.zoomIdentity
              .translate(widthGlobo / 2, heightGlobo / 2)
              .scale(
                Math.min(
                  8,
                  0.9 /
                    Math.max((x1 - x0) / widthGlobo, (y1 - y0) / heightGlobo)
                )
              )
              .translate(-(x0 + x1) / 2, -(y0 + y1) / 2)
            //d3.pointer(event, svg.node())
          )
          .end()
          .catch((e) => {
            console.log(
              `actualizarZoomMapa: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            svg.call(
              zoom.transform,
              d3.zoomIdentity
                .translate(widthGlobo / 2, heightGlobo / 2)
                .scale(
                  Math.min(
                    8,
                    0.9 /
                      Math.max((x1 - x0) / widthGlobo, (y1 - y0) / heightGlobo)
                  )
                )
                .translate(-(x0 + x1) / 2, -(y0 + y1) / 2)
              //d3.pointer(event, svg.node())
            );
            resolveFn(0);
          });
      });
    } else {
      svg.call(
        zoom.transform,
        d3.zoomIdentity
          .translate(widthGlobo / 2, heightGlobo / 2)
          .scale(
            Math.min(
              8,
              0.9 / Math.max((x1 - x0) / widthGlobo, (y1 - y0) / heightGlobo)
            )
          )
          .translate(-(x0 + x1) / 2, -(y0 + y1) / 2)
        //d3.pointer(event, svg.node())
      );
    }
  }

  function descoloreaPaisesNoTarget(d, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "path:color";

    let paths = gPaisesGlobo
      .select("g.countries")
      .selectAll("g")
      .select("path");

    if (!_.isNull(d)) {
      paths = paths.filter(function (p) {
        return p.properties.ISO_A3_EH != d.properties.ISO_A3_EH;
      });
    }

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        paths
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("fill", (d) => d.properties.fill)
          .end()
          .catch((e) => {
            console.log(
              `descoloreaPaisesNoTarget: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            paths.attr("fill", (d) => d.properties.fill);
            resolveFn(0);
          });
      });
    } else {
      paths.attr("fill", (d) => d.properties.fill);
    }
  }

  function coloreaPaisTarget(el, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "path:color";

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        el.transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("fill", (d) => d.properties["fill-selected"])
          .end()
          .catch((e) => {
            console.log(
              `coloreaPaisTarget: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            el.attr("fill", (d) => d.properties["fill-selected"]);
            resolveFn(0);
          });
      });
    } else {
      el.attr("fill", (d) => d.properties["fill-selected"]);
    }
  }

  function zoomed(event) {
    let { transform } = event;
    gPaisesGlobo.attr("transform", transform);
    gPaisesGlobo.attr("stroke-width", 0.5 / transform.k);
    k = transform.k;
    deshacerTooltipGlobo();
  }

  function getLado(d) {
    let lado = 0;
    if (d.properties.hasOwnProperty("risk")) {
      if (d.properties.risks.hasOwnProperty("inform_risk")) {
        lado = d.properties.risks.inform_risk * 10;
      }
    }
    return lado;
  }

  function getColor(d) {
    let color = "#000000";
    if (d.properties.hasOwnProperty("risk")) {
      if (d.properties.risks.hasOwnProperty("inform_risk")) {
        color = "#ff00ff"; //d.properties.risks.inform_risk * 10;
      }
    }
    return color;
  }

  function crearMapaEuropa(paises) {
    let gruposPaises = gPaisesEuropa
      .append("g")
      .attr("class", "countries")
      .selectAll("g")
      .data(paises, (d) => d.properties.ISO_A3_EH)
      .enter()
      .append("g");

    gPaisesEuropa.append("g").attr("class", "risk");

    gruposPaises
      .append("path")
      .attr("d", pathEuropa)
      .attr("fill", (d) => d.properties["fill-member"])
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"])
      .style("opacity", (d) => d.properties.opacity);
  }

  //TODO: Deberían ser sólo los países con índice, no todos los del mundo
  function crearControlesGlobo(paises, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let input = document.getElementById("country_name");
    ajustarMapaGlobo(input.value.trim(), paises, flagTransicion, factor);

    crearAutocomplete(
      input,
      buscarPaisMapaGlobo,
      paises,
      flagTransicion,
      factor
    );
  }

  function ajustarMapaGlobo(nombre, paises, flagTransicion, factor) {
    console.log("ajustarMapaGlobo");
    if (!_.isEmpty(nombre.trim())) {
      let obj =
        _.find(paises, function (p) {
          return p.properties.name.toLowerCase() == nombre.toLowerCase();
        }) || false;
      if (obj !== false) {
        buscarPaisMapaGlobo(obj, flagTransicion, factor);
      }
    }
  }

  function buscarPaisMapaGlobo(d, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    factor *= 2;

    let paths = gPaisesGlobo
      .select("g.countries")
      .selectAll("g")
      .select("path");
    let el = paths.filter(function (p) {
      return p.properties.ISO_A3_EH == d.properties.ISO_A3_EH;
    });

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          actualizarZoomMapa(d, flagTransicion, factor),
          descoloreaPaisesNoTarget(d, flagTransicion, factor),
          coloreaPaisTarget(el, flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      actualizarZoomMapa(d, flagTransicion, factor);
      descoloreaPaisesNoTarget(d, flagTransicion, factor);
      coloreaPaisTarget(el, flagTransicion, factor);
    }
  }

  function crearAutocomplete(
    input,
    funcionBuscar,
    arreglo,
    flagTransicion,
    factor
  ) {
    //TODO: Change RegExp to allow other characters?
    let allowedChars = new RegExp(/^[a-zA-Z\s]+$/);
    autocomplete({
      input: input,
      minLength: 1,
      fetch: function (text, update) {
        text = text.toLowerCase().trim();
        var suggestions = arreglo.filter((n) =>
          n.properties.name.toLowerCase().includes(text)
        );
        suggestions = _.orderBy(suggestions, "value");
        update(suggestions);
      },
      onSelect: function (item) {
        input.value = item.properties.name;
        funcionBuscar(item, flagTransicion, factor);
      },
      emptyMsg: "No country found",
      preventSubmit: 1,
      render: function (item, value) {
        value = value.trim();
        var itemElement = document.createElement("div");
        if (allowedChars.test(value)) {
          var regex = new RegExp(value, "gi");
          itemElement.innerHTML = item.properties.name.replace(
            regex,
            function (match) {
              return `<strong>${match}</strong>`;
            }
          );
        } else {
          itemElement.textContent = item.properties.name;
        }
        return itemElement;
      },
    });
  }

  //TODO: Ordenarlos y jerarquizarlos
  function crearControlesEuropa(paises, riesgos, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    debouncedAjustarMapaEuropa = _.debounce(
      (e) => ajustarMapaEuropa(paises, flagTransicion, factor),
      debounceTimer
    );

    let dropdown = d3.select("#risk_europe_map");
    dropdown
      .selectAll("option")
      .data(riesgos)
      .enter()
      .append("option")
      .attr("value", (d) => d.key)
      .html((d) => d.label);
    dropdown.on("change", debouncedAjustarMapaEuropa);

    d3.select("#variable_europe_map").on("change", debouncedAjustarMapaEuropa);
  }

  function ajustarMapaEuropa(paises, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let riesgo = d3.select("#risk_europe_map").node().value;
    let variable = d3.select("#variable_europe_map").node().value;
    actualizarMapaEuropa(paises, riesgo, variable, flagTransicion, factor);
  }

  function actualizarMapaEuropa(
    paises,
    riesgo,
    variable,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    riesgo = riesgo.trim();
    variable = variable.trim();

    let arregloVariable = _.map(paises, function (d) {
      return d.properties[variable];
    });
    console.log("arregloVariable");
    console.log(arregloVariable);
    console.log(_.max(arregloVariable));
    escalaLadoEuropa.domain([0, _.max(arregloVariable)]);

    console.log("paises");
    console.log(paises);

    let paisesFiltrados = _.filter(paises, function (d) {
      return (
        !_.isNull(d.properties.risks[riesgo].value) &&
        !_.isNull(d.properties[variable])
      );
    });

    console.log("paisesFiltrados");
    console.log(paisesFiltrados);

    let gruposRiesgo = gPaisesEuropa
      .select("g.risk")
      .selectAll("g")
      .data(paisesFiltrados, (d) => d.properties.ISO_A3_EH);
    let gruposRiesgoEnter = gruposRiesgo.enter();
    let gruposRiesgoExit = gruposRiesgo.exit();

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          actualizarMapaEuropaEnter(
            gruposRiesgoEnter,
            riesgo,
            variable,
            flagTransicion,
            factor
          ),
          actualizarMapaEuropaUpdate(
            gruposRiesgo,
            riesgo,
            variable,
            flagTransicion,
            factor
          ),
          actualizarMapaEuropaExit(gruposRiesgoExit, flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      actualizarMapaEuropaEnter(gruposRiesgoEnter, riesgo, variable);
      actualizarMapaEuropaUpdate(gruposRiesgo, riesgo, variable);
      actualizarMapaEuropaExit(gruposRiesgoExit);
    }
  }

  function actualizarMapaEuropaEnter(
    gruposRiesgo,
    riesgo,
    variable,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          actualizarMapaEuropaEnterRiesgo(
            gruposRiesgo,
            riesgo,
            variable,
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      actualizarMapaEuropaEnterRiesgo(gruposRiesgo, riesgo, variable);
    }
  }

  function actualizarMapaEuropaUpdate(
    gruposRiesgo,
    riesgo,
    variable,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          actualizarMapaEuropaUpdateRiesgo(
            gruposRiesgo,
            riesgo,
            variable,
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      actualizarMapaEuropaUpdateRiesgo(gruposRiesgo, riesgo, variable);
    }
  }

  function actualizarMapaEuropaExit(gruposRiesgo, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          actualizarMapaEuropaExitRiesgo(gruposRiesgo, flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      actualizarMapaEuropaExitRiesgo(gruposRiesgo);
    }
  }

  function actualizarMapaEuropaEnterRiesgo(
    grupos,
    riesgo,
    variable,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let nombreTransicion = "rect:actualizar";

    let gruposNuevos = grupos.append("g").attr("transform", function (d) {
      let c = projectionEuropa(d.properties.coordinates);
      return `translate(${c[0]}, ${c[1]})`;
    });

    gruposNuevos
      .append("rect")
      .attr("class", "risk")
      .attr("x", 0)
      .attr("y", 0)
      .attr("rx", 2)
      .attr("ry", 2)
      .style("stroke", "#878787")
      .style("stroke-width", 1)
      .attr("width", 0)
      .attr("height", 0)
      .style("fill", "transparent")
      .attr("transform", "translate(0, 0)");

    let rects = gruposNuevos
      .select("rect.risk")
      .on("mouseover", crearTooltipEuropa)
      .on("mouseout", deshacerTooltipEuropa);

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        rects
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("fill", (d) =>
            escalaColorEuropa(d.properties.risks[riesgo].value)
          )
          .attr("width", (d) => escalaLadoEuropa(d.properties[variable]))
          .attr("height", (d) => escalaLadoEuropa(d.properties[variable]))
          .attr("transform", function (d) {
            let deltaX = escalaLadoEuropa(d.properties[variable]) / 2;
            let deltaY = escalaLadoEuropa(d.properties[variable]) / 2;
            return `translate(${-deltaX},${-deltaY})`;
          })
          .end()
          .catch((e) => {
            console.log(
              `actualizarMapaEuropaEnterRiesgo: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            rects
              .style("fill", (d) =>
                escalaColorEuropa(d.properties.risks[riesgo].value)
              )
              .attr("width", (d) => escalaLadoEuropa(d.properties[variable]))
              .attr("height", (d) => escalaLadoEuropa(d.properties[variable]))
              .attr("transform", function (d) {
                let deltaX = escalaLadoEuropa(d.properties[variable]) / 2;
                let deltaY = escalaLadoEuropa(d.properties[variable]) / 2;
                return `translate(${-deltaX},${-deltaY})`;
              });
            resolveFn(0);
          });
      });
    } else {
      rects
        .style("fill", (d) =>
          escalaColorEuropa(d.properties.risks[riesgo].value)
        )
        .attr("width", (d) => escalaLadoEuropa(d.properties[variable]))
        .attr("height", (d) => escalaLadoEuropa(d.properties[variable]))
        .attr("transform", function (d) {
          let deltaX = escalaLadoEuropa(d.properties[variable]) / 2;
          let deltaY = escalaLadoEuropa(d.properties[variable]) / 2;
          return `translate(${-deltaX},${-deltaY})`;
        });
    }
  }

  function actualizarMapaEuropaUpdateRiesgo(
    grupos,
    riesgo,
    variable,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let nombreTransicion = "rect:actualizar";

    let rects = grupos.select("rect");

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        rects
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("fill", (d) =>
            escalaColorEuropa(d.properties.risks[riesgo].value)
          )
          .attr("width", (d) => escalaLadoEuropa(d.properties[variable]))
          .attr("height", (d) => escalaLadoEuropa(d.properties[variable]))
          .attr("transform", function (d) {
            let deltaX = escalaLadoEuropa(d.properties[variable]) / 2;
            let deltaY = escalaLadoEuropa(d.properties[variable]) / 2;
            return `translate(${-deltaX},${-deltaY})`;
          })
          .end()
          .catch((e) => {
            console.log(
              `actualizarMapaEuropaEnterRiesgo: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            rects
              .style("fill", (d) =>
                escalaColorEuropa(d.properties.risks[riesgo].value)
              )
              .attr("width", (d) => escalaLadoEuropa(d.properties[variable]))
              .attr("height", (d) => escalaLadoEuropa(d.properties[variable]))
              .attr("transform", function (d) {
                let deltaX = escalaLadoEuropa(d.properties[variable]) / 2;
                let deltaY = escalaLadoEuropa(d.properties[variable]) / 2;
                return `translate(${-deltaX},${-deltaY})`;
              });
            resolveFn(0);
          });
      });
    } else {
      rects
        .style("fill", (d) =>
          escalaColorEuropa(d.properties.risks[riesgo].value)
        )
        .attr("width", (d) => escalaLadoEuropa(d.properties[variable]))
        .attr("height", (d) => escalaLadoEuropa(d.properties[variable]))
        .attr("transform", function (d) {
          let deltaX = escalaLadoEuropa(d.properties[variable]) / 2;
          let deltaY = escalaLadoEuropa(d.properties[variable]) / 2;
          return `translate(${-deltaX},${-deltaY})`;
        });
    }
  }

  function actualizarMapaEuropaExitRiesgo(grupos, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let nombreTransicion = "rect:actualizar";

    let rects = grupos.select("rect");

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        rects
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("fill", "transparent")
          .attr("width", 0)
          .attr("height", 0)
          .attr("transform", "translate(0, 0)")
          .end()
          .catch((e) => {
            console.log(
              `actualizarMapaEuropaExitRiesgo: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            grupos.remove();
            resolveFn(0);
          });
      });
    } else {
      grupos.remove();
    }
  }

  function crearControlesScatterPlot(paises, riesgos, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    debouncedAjustarScatterPlot = _.debounce(
      (e) => ajustarScatterPlot(paises, flagTransicion, factor),
      debounceTimer
    );

    let dropdown = d3.select("#risk_europe_scatter_plot");
    dropdown
      .selectAll("option")
      .data(riesgos)
      .enter()
      .append("option")
      .attr("value", (d) => d.key)
      .html((d) => d.label);
    dropdown.on("change", debouncedAjustarScatterPlot);

    d3.select("#variable_europe_scatter_plot").on(
      "change",
      debouncedAjustarScatterPlot
    );
  }

  function ajustarScatterPlot(paises, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let riesgo = d3.select("#risk_europe_scatter_plot").node().value;
    let variable = d3.select("#variable_europe_scatter_plot").node().value;
    actualizarScatterPlot(paises, riesgo, variable, flagTransicion, factor);
  }

  function crearScatterPlot() {
    console.log("crearScatterPlot");
    console.log(scatterplotBox);
    let gEjeX = svgScatterPlot
      .append("g")
      .attr("class", "x")
      .attr(
        "transform",
        `translate(${scatterplotBox.padding.left}, ${
          scatterplotBox.content.height + scatterplotBox.padding.top
        })`
      )
      .style("font-size", "24px")
      .attr("fill", "grey");
    gEjeX.select("path").attr("stroke", "transparent");

    /*
    gEjeX
      .append("rect")
      .attr("width", scatterplotBox.content.width)
      .attr("height", scatterplotBox.padding.bottom)
      .style("fill", "transparent")
      .style("stroke", "black")
      .style("stroke-width", 1);
      */

    let gEjeY = svgScatterPlot
      .append("g")
      .attr("class", "y")
      .attr(
        "transform",
        `translate(${scatterplotBox.padding.left}, ${scatterplotBox.padding.top})`
      )
      .style("font-size", "24px")
      .attr("fill", "grey");
    gEjeY.select("path").attr("stroke", "transparent");

    /*
    gEjeY
      .append("rect")
      .attr("width", scatterplotBox.padding.left)
      .attr("height", scatterplotBox.content.height)
      .style("fill", "transparent")
      .style("stroke", "black")
      .style("stroke-width", 1);
      */

    let gMain = svgScatterPlot
      .append("g")
      .attr("class", "main")
      .attr(
        "transform",
        `translate(${scatterplotBox.padding.left}, ${scatterplotBox.padding.top})`
      )
      .style("font-size", "24px")
      .attr("fill", "grey");

    gMain
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("rx", 20)
      .attr("ry", 20)
      .attr("width", scatterplotBox.content.width)
      .attr("height", scatterplotBox.content.height)
      .style("fill", "#FEE3D7")
      .style("stroke", "grey")
      .style("stroke-width", 1);

    let gLinea = gMain
      .append("g")
      .attr("class", "line")
      .attr("clip-path", "url(#main-rect-clip)");

    let dLinea = {
      x1: 0,
      y1: scatterplotBox.content.height,
      x2: 0,
      y2: scatterplotBox.content.height,
    };
    gLinea
      .append("line")
      .datum(dLinea)
      .attr("class", "ignore")
      .attr("stroke", "#ffffff")
      .attr("stroke-width", "12px")
      .attr("x1", (d) => d.x1)
      .attr("y1", (d) => d.y1)
      .attr("x2", (d) => d.x2)
      .attr("y2", (d) => d.y2);

    svgScatterPlot
      .append("g")
      .attr("class", "voronoi")
      .attr(
        "transform",
        `translate(${scatterplotBox.padding.left}, ${scatterplotBox.padding.top})`
      );
  }

  function getEscala(n, correccion) {
    correccion = correccion || false;
    let escala = 1;
    while (escala < n) {
      escala *= 10;
    }
    escala = correccion ? escala / 100 : escala;
    return escala;
  }

  function getSpread(arr, escala) {
    escala = escala || 10;
    let min = arr[0];
    let max = arr[1];
    let arreglo = [];
    let i = 0;
    for (i = min; i <= max; i *= 10) {
      arreglo.push(i);
    }
    return arreglo;
  }

  function actualizarScatterPlot(
    paises,
    riesgo,
    variable,
    flagTransicion,
    factor
  ) {
    //console.clear();
    console.log("actualizarScatterPlot");
    console.log(paises);
    console.log(riesgo);
    console.log(variable);

    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    actualizarVoronoiScatterPlot([], riesgo, variable);

    let arregloVariable = _.map(paises, (d) => d.properties[variable]);
    escalaX.domain([0, _.max(arregloVariable)]);

    let arregloRiesgo = _.map(
      _.map(paises, (d) => d.properties.risks[riesgo]),
      "value"
    );
    arregloRiesgo = d3.extent(arregloRiesgo);
    let minY = _.floor(arregloRiesgo[0]);
    let maxY = _.ceil(arregloRiesgo[1]);
    escalaY.domain([minY, maxY]);

    let paisesFiltrados = _.filter(paises, function (d) {
      return (
        !_.isNull(d.properties.risks[riesgo].value) &&
        d.properties.risks[riesgo].value > 0 &&
        !_.isNull(d.properties[variable]) &&
        d.properties[variable] > 0
      );
    });

    let gMain = svgScatterPlot.select("g.main");
    let grupos = gMain
      .selectAll("g.pais")
      .data(paisesFiltrados, (d) => d.properties.ISO_A3_EH);
    let gruposEnter = grupos.enter();
    let gruposExit = grupos.exit();

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          actualizarScatterPlotEjeX(flagTransicion, factor),
          actualizarScatterPlotEjeY(flagTransicion, factor),
          actualizarScatterPlotEnter(
            gruposEnter,
            riesgo,
            variable,
            flagTransicion,
            factor
          ),
          actualizarScatterPlotUpdate(
            grupos,
            riesgo,
            variable,
            flagTransicion,
            factor
          ),
          actualizarScatterPlotExit(
            gruposExit,
            riesgo,
            variable,
            flagTransicion,
            factor
          ),
          actualizarScatterPlotRegresion(
            paisesFiltrados,
            riesgo,
            variable,
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          actualizarVoronoiScatterPlot(paisesFiltrados, riesgo, variable);
          resolveFn(0);
        });
      });
    } else {
      actualizarScatterPlotEjeX();
      actualizarScatterPlotEjeY();
      actualizarScatterPlotEnter(gruposEnter, riesgo, variable);
      actualizarScatterPlotUpdate(grupos, riesgo, variable);
      actualizarScatterPlotExit(gruposExit, riesgo, variable);
      actualizarScatterPlotRegresion(paisesFiltrados, riesgo, variable);
      actualizarVoronoiScatterPlot(paisesFiltrados, riesgo, variable);
    }
  }

  function actualizarScatterPlotRegresion(
    paises,
    riesgo,
    variable,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "line:update";

    let pares = [];
    _.each(paises, function (p) {
      pares.push([
        escalaX(p.properties[variable]),
        escalaY(p.properties.risks[riesgo].value),
      ]);
    });

    let xMin = escalaX.invert(-10);
    let xMax = escalaX.invert(scatterplotBox.content.width + 10);

    let dLinea = {
      x1: escalaX(xMin),
      y1: scatterplotBox.content.height,
      x2: escalaX(xMin),
      y2: scatterplotBox.content.height,
    };
    if (pares.length > 0) {
      let regresion = linearRegression(pares);

      dLinea = {
        x1: escalaX(xMin),
        y1: regresion.m * escalaX(xMin) + regresion.b,
        x2: escalaX(xMax),
        y2: regresion.m * escalaX(xMax) + regresion.b,
      };
    }

    let linea = svgScatterPlot
      .select("g.main")
      .select("g.line")
      .select("line")
      .datum(dLinea);

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        linea
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("x1", (d) => d.x1)
          .attr("y1", (d) => d.y1)
          .attr("x2", (d) => d.x2)
          .attr("y2", (d) => d.y2)
          .end()
          .catch((e) => {
            console.log(
              `actualizarScatterPlotRegresion: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            linea
              .attr("x1", (d) => d.x1)
              .attr("y1", (d) => d.y1)
              .attr("x2", (d) => d.x2)
              .attr("y2", (d) => d.y2);
            resolveFn(0);
          });
      });
    } else {
      linea
        .attr("x1", (d) => d.x1)
        .attr("y1", (d) => d.y1)
        .attr("x2", (d) => d.x2)
        .attr("y2", (d) => d.y2);
    }
  }

  function actualizarVoronoiScatterPlot(paises, riesgo, variable) {
    svgScatterPlot.select("g.voronoi").selectAll("g.tooltip").remove();

    let v = getVoronoiGrid(paises, riesgo, variable);
    let pathVoronoi = svgScatterPlot
      .select("g.voronoi")
      .selectAll("path")
      .data(v.data);

    pathVoronoi
      .enter()
      .append("path")
      .attr("d", (d, i) => v.voronoi.renderCell(i))
      .attr("fill", "transparent")
      .attr("stroke", "transparent")
      .on("mouseover", crearTooltipVoronoi)
      .on("mouseout", deshacerTooltipVoronoi);

    pathVoronoi.attr("d", (d, i) => v.voronoi.renderCell(i));

    pathVoronoi.exit().remove();
  }

  function getVoronoiGrid(paises, riesgo, variable) {
    let voronoiData = [];

    _.each(paises, function (p) {
      voronoiData.push({
        x: escalaX(p.properties[variable]),
        y: escalaY(p.properties.risks[riesgo].value),
        name: p.properties.name,
        variable: variable,
        variableValue: formatter.format(p.properties[variable]),
        risk: p.properties.risks[riesgo].label,
        riskValue: p.properties.risks[riesgo].value,
      });
    });

    let delaunay = d3.Delaunay.from(
      voronoiData,
      (d) => d.x,
      (d) => d.y
    );
    let voronoi = delaunay.voronoi([
      0,
      0,
      scatterplotBox.content.width,
      scatterplotBox.content.height,
    ]);

    return { data: voronoiData, voronoi: voronoi };
  }

  function crearTooltipVoronoi(e, d) {
    let el = d3.select(this);

    let gVoronoi = d3.select(el.node().closest("g.voronoi"));
    let gTooltip = gVoronoi.append("g").attr("class", "tooltip ignore");

    let variable = d.variable == "gdp" ? "GDP" : "Population";

    let rect = gTooltip
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 0)
      .attr("height", 0)
      .style("fill", "#ffffff");

    let text = gTooltip
      .append("text")
      .attr("x", 0)
      .attr("y", 0)
      .attr("dy", 0)
      .style("font-size", "24px")
      .style("font-weight", 400)
      .attr("text-anchor", "middle")
      .style("fill", "black");

    text
      .append("tspan")
      .attr("x", 0)
      .attr("dy", 0)
      .style("font-weight", 700)
      .text(`${d.name}`);

    text
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .style("font-weight", 700)
      .text(`${d.risk}: `);
    text.append("tspan").text(d.riskValue);

    text
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .style("font-weight", 700)
      .text(`${variable}: `);
    text.append("tspan").text(d.variableValue);

    let box = text.node().getBBox();

    let rectBox = {
      x: box.x - 5,
      y: box.y - 5,
      width: box.width + 10,
      height: box.height + 10,
    };

    rect
      .attr("x", rectBox.x)
      .attr("y", rectBox.y)
      .attr("width", rectBox.width)
      .attr("height", rectBox.height);

    let offset = { x: d.x, y: d.y };

    if (d.y - (rectBox.height + 10) > 0) {
      offset.y = offset.y - (rectBox.height + 10);
    } else {
      offset.y = offset.y + (60 + 10);
    }

    if (d.x + rectBox.width / 2 > scatterplotBox.content.width) {
      offset.x = offset.x - (10 + 30 + rectBox.width / 2);
      offset.y =
        d.y - (rectBox.height - 60) / 2 > 0
          ? d.y - (rectBox.height - 60) / 2
          : 0;
    } else if (d.x - rectBox.width / 2 < 0) {
      offset.x = offset.x + (10 + 30 + rectBox.width / 2);
      offset.y =
        d.y - (rectBox.height - 60) / 2 > 0
          ? d.y - (rectBox.height - 60) / 2
          : 0;
    }

    gTooltip.attr("transform", `translate(${offset.x}, ${offset.y})`);
  }

  function deshacerTooltipVoronoi(e, d) {
    let el = d3.select(this);
    let gVoronoi = d3.select(el.node().closest("g.voronoi"));
    gVoronoi.selectAll("g.tooltip").remove();
  }

  function actualizarScatterPlotEjeX(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "g.x:actualizarScatterplot";

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        svgScatterPlot
          .select("g.x")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .call(d3.axisBottom(escalaX).tickFormat(d3.format(".2s")))
          .end()
          .catch((e) => {
            console.log(
              `actualizarScatterPlotEjeX: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            svgScatterPlot
              .select("g.x")
              .call(d3.axisBottom(escalaX).tickFormat(d3.format(".2s")));
            resolveFn(0);
          });
      });
    } else {
      svgScatterPlot
        .select("g.x")
        .call(d3.axisBottom(escalaX).tickFormat(d3.format(".2s")));
    }
  }

  function actualizarScatterPlotEjeY(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "g.y:actualizarScatterplot";

    let dominio = escalaY.domain();
    let valores = [];
    let min = dominio[0];
    let max = dominio[1];
    let i = 0;
    for (i = min; i <= max; i++) {
      valores.push(i);
    }

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        svgScatterPlot
          .select("g.y")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .call(
            d3.axisLeft(escalaY).tickValues(valores).tickFormat(d3.format("d"))
          )
          .end()
          .catch((e) => {
            console.log(
              `actualizarScatterPlotEjeX: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            svgScatterPlot
              .select("g.y")
              .call(
                d3
                  .axisLeft(escalaY)
                  .tickValues(valores)
                  .tickFormat(d3.format("d"))
              );
            resolveFn(0);
          });
      });
    } else {
      svgScatterPlot
        .select("g.y")
        .call(
          d3.axisLeft(escalaY).tickValues(valores).tickFormat(d3.format("d"))
        );
    }
  }

  function actualizarScatterPlotEnter(
    gruposEnter,
    riesgo,
    variable,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let gruposNuevos = gruposEnter
      .append("g")
      .attr("class", "pais")
      .attr("transform", function (d) {
        return `translate(
      ${escalaX(d.properties[variable])},
      ${escalaY(d.properties.risks[riesgo].value)}
      )`;
      });

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          actualizarScatterPlotEnterRect(gruposNuevos, flagTransicion, factor),
          actualizarScatterPlotEnterText(gruposNuevos, flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      actualizarScatterPlotEnterRect(gruposNuevos);
      actualizarScatterPlotEnterText(gruposNuevos);
    }
  }

  function actualizarScatterPlotEnterRect(grupos, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "rect:update";

    grupos
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("rx", 10)
      .attr("ry", 10)
      .attr("width", 0)
      .attr("height", 0)
      .style("fill", "#ffffff")
      .style("stroke", "#878787")
      .style("stroke-width", 1)
      .attr("transform", "translate(0, 0)");

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        grupos
          .select("rect")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("width", 60)
          .attr("height", 60)
          .attr("transform", "translate(-30, -30)")
          .end()
          .catch((e) => {
            console.log(
              `actualizarScatterPlotEnterRect: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            grupos
              .select("rect")
              .attr("width", 60)
              .attr("height", 60)
              .attr("transform", "translate(-30, -30)");
            resolveFn(0);
          });
      });
    } else {
      grupos
        .select("rect")
        .attr("width", 60)
        .attr("height", 60)
        .attr("transform", "translate(-30, -30)");
    }
  }

  function actualizarScatterPlotEnterText(grupos, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "text:update";

    grupos
      .append("text")
      .attr("x", 0)
      .attr("y", 0)
      .style("font-weight", 700)
      .style("font-size", "0px")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .style("fill", "black")
      .style("opacity", 0)
      .text((d) => d.properties.ISO_A3_EH);

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        grupos
          .select("text")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 1)
          .style("font-size", "24px")
          .end()
          .catch((e) => {
            console.log(
              `actualizarScatterPlotEnterText: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            grupos
              .select("text")
              .style("opacity", null)
              .style("font-size", "24px");
            resolveFn(0);
          });
      });
    } else {
      grupos.select("text").style("opacity", null).style("font-size", "24px");
    }
  }

  function actualizarScatterPlotUpdate(
    grupos,
    riesgo,
    variable,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "g:actualizarScatterplot";

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        grupos
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("transform", function (d) {
            return `translate(
          ${escalaX(d.properties[variable])},
          ${escalaY(d.properties.risks[riesgo].value)}
      )`;
          })
          .end()
          .catch((e) => {
            console.log(
              `actualizarScatterPlotUpdate: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            grupos.attr("transform", function (d) {
              return `translate(
      ${escalaX(d.properties[variable])},
      ${escalaY(d.properties.risks[riesgo].value)}
      )`;
            });
            resolveFn(0);
          });
      });
    } else {
      grupos.attr("transform", function (d) {
        return `translate(
      ${escalaX(d.properties[variable])},
      ${escalaY(d.properties.risks[riesgo].value)}
      )`;
      });
    }
  }

  function actualizarScatterPlotExit(
    gruposExit,
    riesgo,
    variable,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "rect:actualizarScatterplot";

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          actualizarScatterPlotExitRect(gruposExit, flagTransicion, factor),
          actualizarScatterPlotExitText(gruposExit, flagTransicion, factor),
        ]).finally(function () {
          gruposExit.remove();
          resolveFn(0);
        });
      });
    } else {
      gruposExit.remove();
    }
  }

  function actualizarScatterPlotExitRect(grupos, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "rect:update";

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        grupos
          .select("rect")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("width", 0)
          .attr("height", 0)
          .attr("transform", "translate(0, 0)")
          .end()
          .catch((e) => {
            console.log(
              `actualizarScatterPlotExitRect: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            grupos.select("rect").remove();
            resolveFn(0);
          });
      });
    } else {
      grupos.select("rect").remove();
    }
  }

  function actualizarScatterPlotExitText(grupos, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "text:update";

    grupos.select("text").style("opacity", 1);

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        grupos
          .select("text")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 0)
          .style("font-size", "0px")
          .end()
          .catch((e) => {
            console.log(
              `actualizarScatterPlotExitText: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            grupos.select("text").remove();
            resolveFn(0);
          });
      });
    } else {
      grupos.select("text").remove();
    }
  }

  function crearTooltipGlobo(e, d) {
    let el = d3.select(this);
    let width = +el.attr("width");
    let height = +el.attr("height");

    let riesgo = "inform_risk";
    let variable = "population";

    let gTooltip = gPaisesGlobo.append("g").attr("class", "tooltip ignore");

    let c = projectionGlobo(d.properties.coordinates);
    let t = { x: c[0], y: c[1] };

    let variableLabel = variable == "gdp" ? "GDP" : "Population";

    let rect = gTooltip
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 0)
      .attr("height", 0)
      .style("fill", "#ffffff");

    let text = gTooltip
      .append("text")
      .attr("x", 0)
      .attr("y", 0)
      .attr("dy", 0)
      .style("font-size", 10 / k + "px")
      .style("font-weight", 400)
      .attr("text-anchor", "middle")
      .style("fill", "black");

    text
      .append("tspan")
      .attr("x", 0)
      .attr("dy", 0)
      .style("font-weight", 700)
      .text(`${d.properties.name}`);

    text
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .style("font-weight", 700)
      .text(`${d.properties.risks[riesgo].label}: `);
    text
      .append("tspan")
      .text(formatter.format(d.properties.risks[riesgo].value));

    text
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .style("font-weight", 700)
      .text(`${variableLabel}: `);
    text.append("tspan").text(formatter.format(d.properties[variable]));

    let box = text.node().getBBox();

    let rectBox = {
      x: box.x - 5 / k,
      y: box.y - 5 / k,
      width: box.width + 10 / k,
      height: box.height + 10 / k,
    };

    rect
      .attr("x", rectBox.x)
      .attr("y", rectBox.y)
      .attr("width", rectBox.width)
      .attr("height", rectBox.height);

    let diffY = height - rectBox.height;

    let offset = {
      x: t.x,
      y:
        t.y - height / 2 + rectBox.height / 2 - 5 / k - rectBox.height - 10 / k,
    };

    if (t.x + rectBox.width / 2 > widthGlobo) {
      offset.x = offset.x - (10 / k + width / 2 + rectBox.width / 2);
      offset.y = t.y - height / 2 + rectBox.height / 2 - 5 / k + diffY / 2;
    } else if (t.x - rectBox.width / 2 < 0) {
      offset.x = offset.x + (10 / k + width / 2 + rectBox.width / 2);
      offset.y = t.y - height / 2 + rectBox.height / 2 - 5 / k + diffY / 2;
    }

    gTooltip.attr("transform", `translate(${offset.x}, ${offset.y})`);
  }

  function deshacerTooltipGlobo(e, d) {
    gPaisesGlobo.selectAll("g.tooltip").remove();
    /*
    d3.select(gPaisesGlobo.node().closest("g.svg"))
      .selectAll("g.tooltip")
      .remove();
      */
  }

  function crearTooltipEuropa(e, d) {
    let el = d3.select(this);
    let width = +el.attr("width");
    let height = +el.attr("height");

    let riesgo = d3.select("#risk_europe_map").node().value;
    let variable = d3.select("#variable_europe_map").node().value;

    let gTooltip = gPaisesEuropa.append("g").attr("class", "tooltip ignore");

    let c = projectionEuropa(d.properties.coordinates);
    let t = { x: c[0], y: c[1] };

    let variableLabel = variable == "gdp" ? "GDP" : "Population";

    let rect = gTooltip
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 0)
      .attr("height", 0)
      .style("fill", "#ffffff");

    let text = gTooltip
      .append("text")
      .attr("x", 0)
      .attr("y", 0)
      .attr("dy", 0)
      .style("font-size", "16px")
      .style("font-weight", 400)
      .attr("text-anchor", "middle")
      .style("fill", "black");

    text
      .append("tspan")
      .attr("x", 0)
      .attr("dy", 0)
      .style("font-weight", 700)
      .text(`${d.properties.name}`);

    text
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .style("font-weight", 700)
      .text(`${d.properties.risks[riesgo].label}: `);
    text
      .append("tspan")
      .text(formatter.format(d.properties.risks[riesgo].value));

    text
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .style("font-weight", 700)
      .text(`${variableLabel}: `);
    text.append("tspan").text(formatter.format(d.properties[variable]));

    let box = text.node().getBBox();

    let rectBox = {
      x: box.x - 5,
      y: box.y - 5,
      width: box.width + 10,
      height: box.height + 10,
    };

    rect
      .attr("x", rectBox.x)
      .attr("y", rectBox.y)
      .attr("width", rectBox.width)
      .attr("height", rectBox.height);

    let diffY = height - rectBox.height;

    let offset = {
      x: t.x,
      y: t.y - height / 2 + rectBox.height / 2 - 12 - rectBox.height - 10,
    };

    if (t.x + rectBox.width / 2 > widthEuropa) {
      offset.x = offset.x - (10 + width / 2 + rectBox.width / 2);
      offset.y = t.y - height / 2 + rectBox.height / 2 - 12 + diffY / 2;
    } else if (t.x - rectBox.width / 2 < 0) {
      offset.x = offset.x + (10 + width / 2 + rectBox.width / 2);
      offset.y = t.y - height / 2 + rectBox.height / 2 - 12 + diffY / 2;
    }

    gTooltip.attr("transform", `translate(${offset.x}, ${offset.y})`);
  }

  function deshacerTooltipEuropa(e, d) {
    let el = d3.select(this);
    gPaisesEuropa.selectAll("g.tooltip").remove();
  }

  function linearRegression(data) {
    let m;
    let b;

    // Store data length in a local variable to reduce
    // repeated object property lookups
    const dataLength = data.length;

    //if there's only one point, arbitrarily choose a slope of 0
    //and a y-intercept of whatever the y of the initial point is
    if (dataLength === 1) {
      m = 0;
      b = data[0][1];
    } else {
      // Initialize our sums and scope the `m` and `b`
      // variables that define the line.
      let sumX = 0;
      let sumY = 0;
      let sumXX = 0;
      let sumXY = 0;

      // Use local variables to grab point values
      // with minimal object property lookups
      let point;
      let x;
      let y;

      // Gather the sum of all x values, the sum of all
      // y values, and the sum of x^2 and (x*y) for each
      // value.
      //
      // In math notation, these would be SS_x, SS_y, SS_xx, and SS_xy
      for (let i = 0; i < dataLength; i++) {
        point = data[i];
        x = point[0];
        y = point[1];

        sumX += x;
        sumY += y;

        sumXX += x * x;
        sumXY += x * y;
      }

      // `m` is the slope of the regression line
      m =
        (dataLength * sumXY - sumX * sumY) / (dataLength * sumXX - sumX * sumX);

      // `b` is the y-intercept of the line.
      b = sumY / dataLength - (m * sumX) / dataLength;
    }

    // Return both values as an object.
    return {
      m: m,
      b: b,
    };
  }

  function hide(el) {
    el.style("display", "none").style("opacity", 0);
  }

  function unhide(el) {
    el.style("display", null).style("opacity", null);
  }

  setup();
});
