windowReady.ready(function () {
  let status = null;
  let flag = false;
  d3.select("button[aria-controls='dropdown']").on("click", function () {
    d3.select("#dropdown").attr("hidden", status);
    status = status === true ? null : true;
    flag = flag === true ? false : true;
    d3.select(this).attr("aria-expanded", flag);
  });
});
