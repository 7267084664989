windowReady.ready(function () {
  function setup() {
    MicroModal.init({
      disableScroll: true,
      disableFocus: true,
    });
  }

  setup();
});
