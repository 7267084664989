windowReady.ready(function () {
  let mobileHeight = 1180;
  let desktopHeight = 880;
  let deltaHeight = mobileHeight - desktopHeight;

  let width = 1580;
  let height = isMobile.phone ? mobileHeight : desktopHeight;

  let duracion = isMobile.phone ? 600 : 400;

  let debounceTimer = 200;
  let debouncedCalcularMedidas = null;

  let offsets = {
    spain: { x: 0, y: isMobile.phone ? -(deltaHeight / 2) : 0 },
    guadalquivir: { x: -50, y: isMobile.phone ? -(deltaHeight / 2) : 0 },
    areaDeRiesgo: { x: 150, y: 15 },
    circle: { x: 0, y: isMobile.phone ? -(deltaHeight / 2) : 0 },
  };

  let projections = {
    spain: {
      center: [-2.59, 39.9],
      translate: [width / 2 + offsets.spain.x, height / 2 + offsets.spain.y],
      scale: 6350,
    },
    guadalquivir: {
      center: [-4.62, 37.615],
      translate: [
        width / 2 + offsets.guadalquivir.x,
        height / 2 + offsets.guadalquivir.y,
      ],
      scale: 18400,
    },
    areaDeRiesgo: {
      center: [-6.1428674175, 37.129307594],
      translate: [
        width / 2 + offsets.areaDeRiesgo.x,
        height / 2 + offsets.areaDeRiesgo.y,
      ],
      scale: isMobile.phone ? 82000 : 62000,
    },
  };
  //82000 --> 1:172528

  let cajaLeyenda = {
    x: 450,
    y: 50,
    escala: 0.5,
  };

  let projectionSpain = d3
    .geoEquirectangular()
    .center(projections.spain.center)
    .translate(projections.spain.translate)
    .scale(projections.spain.scale);

  let projectionGuadalquivir = d3
    .geoEquirectangular()
    .center(projections.guadalquivir.center)
    .translate(projections.guadalquivir.translate)
    .scale(projections.guadalquivir.scale);

  let projectionAreaDeRiesgo = d3
    .geoEquirectangular()
    .center(projections.areaDeRiesgo.center)
    .translate(projections.areaDeRiesgo.translate)
    .scale(projections.areaDeRiesgo.scale);

  let geoPathSpain = d3.geoPath().projection(projectionSpain);
  let geoPathGuadalquivir = d3.geoPath().projection(projectionGuadalquivir);
  let geoPathAreaDeRiesgo = d3.geoPath().projection(projectionAreaDeRiesgo);

  let container = null;
  let svg = null;
  let defs = null;
  let gImages = null;

  let gSpain = null;
  let gRios = null;
  let gInundaciones = null;
  let gLandmarks = null;
  let gBuffers = null;

  let gGuadalquivirMini = null;
  let gRiosGuadalquivirMini = null;
  let gBuffersGuadalquivirMini = null;
  let gInundacionesGuadalquivirMini = null;
  let gCordoba = null;
  let gSevilla = null;

  let gGuadalquivir = null;
  let gRiosGuadalquivir = null;
  let gInundacionesGuadalquivir = null;
  let gBuffersGuadalquivir = null;
  let gLandmarksGuadalquivir = null;

  let gAreaDeRiesgo = null;
  let gAreaMarismasBG = null;
  let gAreaMarismas = null;
  let gAreaDonanaBG = null;
  let gAreaDonana = null;
  let gInundacionesAreaDeRiesgo = null;
  let gPuntosInundacionAreaDeRiesgo = null;
  let gPropiedadAreaDeRiesgo = null;
  let gPoblacionAreaDeriesgo = null;
  let gEtiquetasAreaDeRiesgo = null;
  let gLeyendaPuntosAreaDeRiesgo = null;
  let gLeyendaPoblacionAreaDeRiesgo = null;
  let gLeyendaPropiedadAreaDeRiesgo = null;

  let gSanlucar = null;
  let gIsla = null;
  let gPuebla = null;
  let gPoblado = null;

  let gInundacionesJuntas = null;
  let gBuffersJuntos = null;

  let gCirculo = null;

  let scroller = null;
  let capas = { anterior: null, actual: null };
  let pasos = [];

  let map = null;
  let definido = true;
  let isReduced = null;

  const constantes = {
    rios: { opacity: 1 },
    espania: { opacity: 0.8 },
    cuenca: { opacity: 0.8 },
    azul: {
      1: "#EEFBFF",
      2: "#CFEBF8",
      3: "#9DD1EF",
      4: "#2859E0",
    },
  };

  function crearScroller() {
    let elementos = d3.selectAll("[data-capas]");
    if (elementos.size() > 0) {
      elementos.each(function (d, i) {
        pasos.push(d3.select(this).attr("data-capas"));
      });
      //let offset = isMobileSize() ? 0.8 : 0.9;
      scroller = scrollama();
      scroller
        .setup({
          step: "[data-capas]",
          offset: 0.9,
          debug: false,
        })
        .onStepEnter(handleStepEnter);
    }
  }

  function handleStepEnter(response) {
    let capa = response.element.getAttribute("data-capas");
    if (response.direction == "up") {
      let i = response.index;
      if (i >= 1) {
        capa = pasos[i - 1];
      }
    }
    mostrarCapas(capa, response.direction);
  }

  function getLocalizacion() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          map.panTo(
            new L.LatLng(position.coords.latitude, position.coords.longitude)
          );
        },
        (error) => {}
      );
    }
  }

  function crearMapaInteractivo() {
    let center = [37, -6.5];

    let CartoDB_Positron = L.tileLayer(
      "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: "abcd",
        maxZoom: 20,
      }
    );

    let div = null;
    div = d3.select("#interactivo");
    if (div.node()) {
      map = L.map(div.node(), {
        scrollWheelZoom: "center",
        center: center,
        zoom: 9,
        minZoom: 6,
        maxZoom: 12,
        layers: [CartoDB_Positron],
      });

      let circle = L.circle(map.getCenter(), {
        color: "#2859E0",
        fillColor: "#EEFBFF",
        fillOpacity: 0,
        radius: 26875,
      }).addTo(map);

      map.on("move", function (e) {
        circle.setLatLng(map.getCenter());
        map._renderer._update();
      });
    }
  }

  function crearBusqueda() {
    if (map) {
      mapboxgl.accessToken =
        "pk.eyJ1IjoiZ2lsYmVydG9sZW9uIiwiYSI6ImNtMGp4M2R6ODB6bnQybW9naHBlYzczdWYifQ.3FLwpnLPSieys3euaQZ66Q";
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        minLength: 3,
      });
      geocoder.addTo("#geocoder");
      geocoder.on("result", (e) => {
        map.panTo(new L.LatLng(e.result.center[1], e.result.center[0]));
      });
    }
  }

  function setup() {
    container = d3.select("div[data-container='story-1']");
    if (container.node()) {
      isReduced =
        window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
        window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
      if (isReduced) {
        duracion = 0;
      }
      setupHistoria();
      debouncedCalcularMedidas = _.debounce(calcularMedidas, debounceTimer);
      window.onresize = debouncedCalcularMedidas;
    }
  }

  function calcularMedidas() {
    calcularAlturaSVG();
    scroller.resize();
  }

  function isMobileSize() {
    return window.innerWidth <= 480;
  }

  function calcularAlturaSVG() {
    let box = container.node().getBoundingClientRect();
    let root = document.documentElement;
    if (isMobileSize()) {
      root.style.setProperty(
        "--svg-height",
        `calc(8rem + ${2 * box.height}px)`
      );
    } else {
      root.style.setProperty("--svg-height", `calc(8rem + ${box.height}px)`);
    }
  }

  function setupHistoria() {
    //getLocalizacion();
    crearSVG();
    calcularAlturaSVG();
    let params = {};
    fetchEspania(params);
    window.addEventListener("load", function () {
      crearMapaInteractivo();
      crearBusqueda();
    });
  }

  function fetchEspania(params) {
    Promise.all([
      d3.json("json/story1/spain.json"),
      d3.json("json/story1/rivers.json"),
    ])
      .then(function ([spainFile, objRios]) {
        let spain = topojson.feature(spainFile, spainFile.objects["spain"]);
        let peninsularSpain = topojson.feature(
          spainFile,
          spainFile.objects["peninsula"]
        );
        let donana = topojson.feature(
          spainFile,
          spainFile.objects["donana-recortada"]
        );
        let marismas = topojson.feature(
          spainFile,
          spainFile.objects["marisma-recortada"]
        );
        let guadalquivir = topojson.feature(
          spainFile,
          spainFile.objects["guadalquivir"]
        );
        let rios = topojson.feature(objRios, objRios.objects["rivers"]);
        let riosGuadalquivir = topojson.feature(
          objRios,
          objRios.objects["guadalquivir-rivers"]
        );
        crearEspania(spain, peninsularSpain);
        crearRios(rios);
        params.regiones = {
          donana: donana,
          marismas: marismas,
          guadalquivir: guadalquivir,
        };
        params.rios = { spain: rios, guadalquivir: riosGuadalquivir };
        fetchDetallesEspania(params);
      })
      .catch((e) => {
        //console.log("function fetchEspania failed");
      });
  }

  function fetchDetallesEspania(params) {
    Promise.all([
      d3.json("json/story1/flooding.json"),
      d3.json("json/story1/buffered.json"),
      d3.json("json/story1/landmarks.json"),
    ])
      .then(function ([objInundaciones, buffers, landmarksArchivo]) {
        let inundaciones = topojson.feature(
          objInundaciones,
          objInundaciones.objects["flooding"]
        );

        let inundacionesGuadalquivir = topojson.feature(
          objInundaciones,
          objInundaciones.objects["guadalquivir-flooding"]
        );

        let inundacionesJuntos = topojson.feature(
          objInundaciones,
          objInundaciones.objects["flooding-together"]
        );
        let objBuffers = {};
        objBuffers["banda-1"] = topojson.feature(
          buffers,
          buffers.objects["banda-1"]
        );
        objBuffers["banda-2"] = topojson.feature(
          buffers,
          buffers.objects["banda-2"]
        );
        objBuffers["banda-3"] = topojson.feature(
          buffers,
          buffers.objects["banda-3"]
        );
        objBuffers["banda-4"] = topojson.feature(
          buffers,
          buffers.objects["banda-4"]
        );
        let objBuffersGuadalquivir = {};
        objBuffersGuadalquivir["banda-1"] = topojson.feature(
          buffers,
          buffers.objects["guadalquivir-banda-1"]
        );
        objBuffersGuadalquivir["banda-2"] = topojson.feature(
          buffers,
          buffers.objects["guadalquivir-banda-2"]
        );
        objBuffersGuadalquivir["banda-3"] = topojson.feature(
          buffers,
          buffers.objects["guadalquivir-banda-3"]
        );
        objBuffersGuadalquivir["banda-4"] = topojson.feature(
          buffers,
          buffers.objects["guadalquivir-banda-4"]
        );
        let objBuffersArea = {};
        objBuffersArea["banda-1"] = topojson.feature(
          buffers,
          buffers.objects["area-banda-1"]
        );
        objBuffersArea["banda-2"] = topojson.feature(
          buffers,
          buffers.objects["area-banda-2"]
        );
        objBuffersArea["banda-3"] = topojson.feature(
          buffers,
          buffers.objects["area-banda-3"]
        );
        objBuffersArea["banda-4"] = topojson.feature(
          buffers,
          buffers.objects["area-banda-4"]
        );
        let objBuffersJuntos = {};
        objBuffersJuntos["banda-1"] = topojson.feature(
          buffers,
          buffers.objects["juntos-banda-1"]
        );
        objBuffersJuntos["banda-2"] = topojson.feature(
          buffers,
          buffers.objects["juntos-banda-2"]
        );
        objBuffersJuntos["banda-3"] = topojson.feature(
          buffers,
          buffers.objects["juntos-banda-3"]
        );
        objBuffersJuntos["banda-4"] = topojson.feature(
          buffers,
          buffers.objects["juntos-banda-4"]
        );
        let landmarks = topojson.feature(
          landmarksArchivo,
          landmarksArchivo.objects["landmarks"]
        );
        let landmarksRiskArea = topojson.feature(
          landmarksArchivo,
          landmarksArchivo.objects["landmarks-risk-area"]
        );
        let landmarksFloodingRisk = topojson.feature(
          landmarksArchivo,
          landmarksArchivo.objects["landmarks-flooding-risk"]
        );
        let landmarksDonana = topojson.feature(
          landmarksArchivo,
          landmarksArchivo.objects["landmarks-donana"]
        );
        let landmarksMarismas = topojson.feature(
          landmarksArchivo,
          landmarksArchivo.objects["landmarks-marismas"]
        );
        let landmarksGuadalquivir = topojson.feature(
          landmarksArchivo,
          landmarksArchivo.objects["landmarks-guadalquivir"]
        );
        let landmarksCordoba = topojson.feature(
          landmarksArchivo,
          landmarksArchivo.objects["landmarks-cordoba"]
        );
        let landmarksSevilla = topojson.feature(
          landmarksArchivo,
          landmarksArchivo.objects["landmarks-sevilla"]
        );

        crearInundaciones(inundaciones);
        crearBuffers(objBuffers);
        crearLandmarks(landmarks);
        params.landmarks = {
          spain: landmarks,
          area: landmarksRiskArea,
          flooding: landmarksFloodingRisk,
          donana: landmarksDonana,
          marismas: landmarksMarismas,
          guadalquivir: landmarksGuadalquivir,
          cordoba: landmarksCordoba,
          sevilla: landmarksSevilla,
        };
        params.inundaciones = {
          spain: inundaciones,
          guadalquivir: inundacionesGuadalquivir,
          juntos: inundacionesJuntos,
        };
        params.buffers = {
          guadalquivir: objBuffersGuadalquivir,
          juntos: objBuffersJuntos,
          area: objBuffersArea,
        };
        fetchGuadalquivirMini(params);
      })
      .catch((e) => {
        //console.log("function fetchDetallesEspania failed");
      });
  }

  function fetchGuadalquivirMini(params) {
    crearGuadalquivirMini(params.regiones.guadalquivir);
    crearRiosGuadalquivirMini(params.rios.guadalquivir);
    crearBuffersGuadalquivirMini(params.buffers.guadalquivir);
    crearInundacionesGuadalquivirMini(params.inundaciones.guadalquivir);
    crearCordoba(params.landmarks.cordoba);
    crearSevilla(params.landmarks.sevilla);
    fetchGuadalquivir(params);
  }

  function fetchGuadalquivir(params) {
    Promise.all([d3.json("json/story1/basin.json")])
      .then(function ([objCuenca]) {
        let inundaciones = topojson.feature(
          objCuenca,
          objCuenca.objects["flooding"]
        );

        crearGuadalquivir(params.regiones.guadalquivir);
        crearRiosGuadalquivir(params.rios.guadalquivir);
        crearBuffersGuadalquivir(params.buffers.area);
        crearInundacionesGuadalquivir(
          inundaciones,
          params.inundaciones.guadalquivir
        );
        crearLandmarksGuadalquivir(params.landmarks.guadalquivir);
        crearAreaDeRiesgo(params.buffers.area);
        crearAreaDonana(params.regiones.donana, params.landmarks.donana);
        crearAreaMarismas(params.regiones.marismas, params.landmarks.marismas);
        crearInundacionesAreaDeRiesgo(inundaciones);
        crearLeyendaPuntosAreaDeRiesgo();
        crearLeyendaPropiedadAreaDeRiesgo();
        crearLeyendaPoblacionAreaDeRiesgo();
        crearEtiquetasAreaDeRiesgo(params.landmarks.area);
        fetchRiesgos(params);
      })
      .catch((e) => {
        //console.log("function fetchGuadalquivir failed");
      });
  }

  function fetchRiesgos(params) {
    Promise.all([d3.json("json/story1/risk.json")])
      .then(function ([risk]) {
        let property = topojson.feature(risk, risk.objects["economico"]);
        let population = topojson.feature(risk, risk.objects["poblacion"]);
        crearPuntosInundacionAreaDeRiesgo(params.landmarks.flooding);
        crearPropiedadAreaDeRiesgo(property);
        crearPoblacionAreaDeRiesgo(population);
        fetchJuntos(params);
      })
      .catch((e) => {
        //console.log("function fetchRiesgos failed");
      });
  }

  function fetchJuntos(params) {
    crearBuffersJuntos(params.buffers.juntos);
    crearInundacionesJuntas(params.inundaciones.juntos);
    crearCirculo();
    crearInterpoladores();
    crearScroller();
  }

  let interpoladorAreaAEspania = null;
  let interpoladorEspaniaAArea = null;
  let interpoladorBanda1 = null;
  let interpoladorBanda2 = null;
  let interpoladorBanda3 = null;
  let interpoladorBanda4 = null;
  let interpoladorEspaniaACirculo = null;
  let interpoladorCirculoAEspania = null;

  function crearInterpoladores() {
    let dArea = defs.select("#risk-area-border-fixed").attr("d");
    let dEspaniaPeninsular = defs
      .select("#peninsular-spain-border-fixed")
      .attr("d");
    let dCirculo = defs.select("#circle-fixed").attr("d");

    interpoladorAreaAEspania = polymorph.interpolate([
      dArea,
      dEspaniaPeninsular,
    ]);
    interpoladorEspaniaAArea = polymorph.interpolate([
      dEspaniaPeninsular,
      dArea,
    ]);

    interpoladorBanda1 = polymorph.interpolate([
      dEspaniaPeninsular,
      defs.select("#banda-1-fixed").attr("d"),
    ]);
    interpoladorBanda2 = polymorph.interpolate([
      dEspaniaPeninsular,
      defs.select("#banda-2-fixed").attr("d"),
    ]);
    interpoladorBanda3 = polymorph.interpolate([
      dEspaniaPeninsular,
      defs.select("#banda-3-fixed").attr("d"),
    ]);
    interpoladorBanda4 = polymorph.interpolate([
      dEspaniaPeninsular,
      defs.select("#banda-4-fixed").attr("d"),
    ]);
    interpoladorEspaniaACirculo = flubber.toCircle(
      dEspaniaPeninsular,
      width / 2 + offsets.circle.x,
      height / 2 + offsets.circle.y,
      300
    );
    interpoladorCirculoAEspania = flubber.fromCircle(
      width / 2 + offsets.circle.x,
      height / 2 + offsets.circle.y,
      300,
      dEspaniaPeninsular
    );
    /*
    interpoladorEspaniaACirculo = polymorph.interpolate([
      dEspaniaPeninsular,
      dCirculo,
    ]);
    interpoladorCirculoAEspania = polymorph.interpolate([
      dCirculo,
      dEspaniaPeninsular,
    ]);
    */
  }

  function crearSVG() {
    svg = container
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${width} ${height}`);

    defs = svg.append("defs");
    gImages = svg.append("g").attr("class", "images").attr("id", "gImages"); //TODO: ¿Opacity?
    svg = svg.append("g").attr("class", "svg");
    gSpain = svg.append("g").style("opacity", 1).attr("id", "gSpain");

    gRios = svg.append("g").style("opacity", 0).attr("id", "gRios");

    gBuffers = svg.append("g").style("opacity", 0).attr("id", "gBuffers");

    gLandmarks = svg.append("g").style("opacity", 1).attr("id", "gLandmarks");

    gInundaciones = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gInundaciones");

    gGuadalquivirMini = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gGuadalquivirMini");

    gRiosGuadalquivirMini = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gRiosGuadalquivirMini");

    gBuffersGuadalquivirMini = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gBuffersGuadalquivirMini");

    gInundacionesGuadalquivirMini = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gInundacionesGuadalquivirMini");

    gCordoba = svg.append("g").style("opacity", 0).attr("id", "gCordoba");

    gSevilla = svg.append("g").style("opacity", 0).attr("id", "gSevilla");

    gGuadalquivir = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gGuadalquivir");

    gRiosGuadalquivir = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gRiosGuadalquivir");

    gBuffersGuadalquivir = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gBuffersGuadalquivir");

    gInundacionesGuadalquivir = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gInundacionesGuadalquivir");

    gLandmarksGuadalquivir = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gLandmarksGuadalquivir");

    gAreaDeRiesgo = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gAreaDeRiesgo");

    gAreaMarismasBG = svg.append("g").attr("id", "gAreaMarismasBG");
    gAreaMarismas = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gAreaMarismas");

    gAreaDonanaBG = svg.append("g").attr("id", "gAreaDonanaBG");
    gAreaDonana = svg.append("g").style("opacity", 0).attr("id", "gAreaDonana");

    gInundacionesAreaDeRiesgo = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gInundacionesAreaDeRiesgo");

    gLeyendaPuntosAreaDeRiesgo = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gLeyendaPuntosAreaDeRiesgo");

    gPropiedadAreaDeRiesgo = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gPropiedadAreaDeRiesgo");

    gLeyendaPropiedadAreaDeRiesgo = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gLeyendaPropiedadAreaDeRiesgo");

    gPoblacionAreaDeriesgo = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gPoblacionAreaDeriesgo");

    gLeyendaPoblacionAreaDeRiesgo = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gLeyendaPoblacionAreaDeRiesgo");

    gEtiquetasAreaDeRiesgo = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gEtiquetasAreaDeRiesgo");

    gPuntosInundacionAreaDeRiesgo = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gPuntosInundacionAreaDeRiesgo");

    gBuffersJuntos = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gBuffersJuntos");

    gInundacionesJuntas = svg
      .append("g")
      .style("opacity", 0)
      .attr("id", "gInundacionesJuntas");

    gCirculo = svg.append("g").style("opacity", 0).attr("id", "gCirculo");
  }

  function crearCirculo() {
    let path = d3.path();
    path.arc(
      width / 2 + offsets.circle.x,
      height / 2 + offsets.circle.y,
      300,
      0,
      2 * Math.PI
    );

    let d = gCirculo
      .append("path")
      .attr("d", path)
      .style("stroke", constantes.azul[4])
      .style("stroke-width", "8px")
      .style("fill", "transparent");

    defs.append("path").attr("id", "circle-fixed").attr("d", d.attr("d"));
  }

  function crearEspania(data, dataPeninsular) {
    defs
      .append("path")
      .attr("id", "spain-border-fixed")
      .attr("d", function () {
        return geoPathSpain(data.features[0]);
      });

    defs
      .append("path")
      .datum(data.features[0])
      .attr("fill", (d) => d.properties.fill)
      .attr("stroke", (d) => d.properties.stroke)
      .attr("stroke-width", (d) => d.properties["stroke-width"])
      .style("opacity", (d) => d.properties["stroke-opacity"])
      .attr("stroke-linejoin", "round")
      .attr("id", "spain-border")
      .attr("d", geoPathSpain);

    defs
      .append("path")
      .attr("id", "peninsular-spain-border-fixed")
      .attr("d", function () {
        return geoPathSpain(dataPeninsular.features[0]);
      });

    defs
      .append("path")
      .datum(dataPeninsular.features[0])
      .attr("fill", (d) => d.properties.fill)
      .attr("stroke", (d) => d.properties.stroke)
      .attr("stroke-width", (d) => d.properties["stroke-width"])
      .style("opacity", (d) => d.properties["stroke-opacity"])
      .attr("stroke-linejoin", "round")
      .attr("id", "peninsular-spain-border")
      .attr("d", geoPathSpain);

    let clip = defs.append("clipPath").attr("id", "spain-clip");
    clip.append("use").attr("href", "#spain-border");

    //TODO: Ajustar el blur
    let filter = defs.append("filter").attr("id", "spain-blur");
    filter
      .append("feGaussianBlur")
      .attr("stdDeviation", 5)
      .attr("id", "spain-blur");

    gImages
      .append("image")
      .attr("clip-path", "url(#spain-clip)")
      .attr("xlink:href", "img/story1/spain.png")
      .attr("id", "spain-background")
      .attr("width", width)
      .attr("height", height)
      .attr("x", offsets.spain.x)
      .attr("y", offsets.spain.y)
      .style("opacity", constantes.espania.opacity);

    gSpain
      .append("path")
      .datum(data.features[0])
      .attr("fill", (d) => d.properties.fill)
      .attr("stroke", (d) => d.properties.stroke)
      .attr("stroke-width", (d) => d.properties["stroke-width"])
      .style("opacity", (d) => d.properties["stroke-opacity"])
      .attr("stroke-linejoin", "round")
      .attr("d", geoPathSpain);

    gSpain.append("use").attr("href", "#spain-border");
  }

  function crearGuadalquivir(data) {
    defs
      .append("path")
      .attr("fill", "transparent")
      .attr("stroke", "#000000")
      .attr("stroke-linejoin", "round")
      .style("opacity", 0)
      .datum(data.features[0])
      .attr("id", "guadalquivir-border")
      .attr("d", geoPathGuadalquivir);

    let clip = defs.append("clipPath").attr("id", "guadalquivir-clip");
    clip.append("use").attr("href", "#guadalquivir-border");

    /*
		let filter = defs.append("filter").attr("id", "guadalquivir-blur");
		filter
			.append("feGaussianBlur")
			.attr("stdDeviation", 5)
			.attr("id", "guadalquivir-blur");
			*/

    gImages
      .append("image")
      .attr("clip-path", "url(#guadalquivir-clip)")
      .attr("xlink:href", "img/story1/guadalquivir.png")
      .attr("id", "guadalquivir-background")
      .attr("width", width)
      .attr("height", height)
      .attr("x", offsets.guadalquivir.x)
      .attr("y", offsets.guadalquivir.y)
      .style("opacity", 0);

    gGuadalquivir
      .selectAll("path")
      .data(data.features)
      .join("path")
      .style("fill", "transparent")
      .attr("d", geoPathGuadalquivir)
      //.attr("clip-path", "url(#guadalquivir-clip)")
      //.style("filter", "url(#guadalquivir-blur)")
      .style("stroke", "#000000")
      .style("stroke-width", "1px");

    gGuadalquivir.append("use").attr("href", "#guadalquivir-border");
  }

  function crearGuadalquivirMini(data) {
    defs
      .append("path")
      .attr("fill", "#ffffff")
      .attr("stroke", "#55aaaa")
      .attr("stroke-linejoin", "round")
      //.style("opacity", 0.8)
      .style("opacity", 0)
      .datum(data.features[0])
      .attr("id", "guadalquivir-mini-border")
      .attr("d", geoPathSpain);
    /*
    let clip = defs.append("clipPath").attr("id", "guadalquivir-mini-clip");
    clip.append("use").attr("href", "#guadalquivir-mini-border");
*/
    gGuadalquivirMini
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width)
      .attr("height", height)
      .style("fill", "#ffffff")
      .style("opacity", 0.8);

    gGuadalquivirMini
      .append("path")
      .datum(data.features[0])
      .attr("fill", (d) => d.properties.fill)
      .attr("fill-opacity", (d) => d.properties["fill-opacity"])
      .attr("stroke", (d) => d.properties.stroke)
      .attr("stroke-width", (d) => d.properties["stroke-width"])
      .attr("stroke-opacity", (d) => d.properties["stroke-opacity"])
      .attr("stroke-linejoin", "round")
      .attr("d", geoPathSpain);
  }

  function crearAreaDeRiesgo(data) {
    let clip = null;

    defs
      .append("path")
      .attr("id", "risk-area-border-fixed")
      .attr("d", function () {
        return geoPathAreaDeRiesgo(data["banda-4"].features[0]);
      });

    defs
      .append("path")
      .attr("fill", "transparent")
      .attr("stroke", "#55aaaa")
      .attr("stroke-linejoin", "round")
      .style("opacity", 0)
      .datum(data["banda-4"].features[0])
      .attr("id", "risk-area-border")
      .attr("d", geoPathAreaDeRiesgo);

    defs
      .append("path")
      .attr("id", "risk-area-inner-border-fixed")
      .attr("d", function () {
        return geoPathAreaDeRiesgo(data["banda-1"].features[0]);
      });

    defs
      .append("path")
      .attr("fill", "transparent")
      .attr("stroke", "transparent")
      .attr("stroke-linejoin", "round")
      .style("opacity", 1)
      .datum(data["banda-1"].features[0])
      .attr("id", "risk-area-inner-border")
      .attr("d", geoPathAreaDeRiesgo);

    clip = defs.append("clipPath").attr("id", "risk-area-inner-clip");
    clip.append("use").attr("href", "#risk-area-inner-border");

    let g4 = gAreaDeRiesgo
      .append("g")
      .datum(data["banda-4"].features[0])
      .attr("class", "layer-4")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g3 = gAreaDeRiesgo
      .append("g")
      .datum(data["banda-3"].features[0])
      .attr("class", "layer-3")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g2 = gAreaDeRiesgo
      .append("g")
      .datum(data["banda-2"].features[0])
      .attr("class", "layer-2")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let img = isMobile.phone ? "risk-area-mobile" : "risk-area";
    gAreaDeRiesgo
      .append("image")
      .attr("clip-path", "url(#risk-area-inner-clip)")
      .attr("xlink:href", `img/story1/${img}.png`)
      .attr("id", "risk-area-background")
      .attr("width", width)
      .attr("height", height)
      .attr("x", offsets.areaDeRiesgo.x)
      .attr("y", offsets.areaDeRiesgo.y)
      .style("opacity", 0);

    let g1 = gAreaDeRiesgo
      .append("g")
      .datum(data["banda-1"].features[0])
      .attr("class", "layer-1")
      .style("opacity", 0.7)
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let path4 = g4.append("path").attr("d", geoPathAreaDeRiesgo);
    let path3 = g3.append("path").attr("d", geoPathAreaDeRiesgo);
    let path2 = g2.append("path").attr("d", geoPathAreaDeRiesgo);
    let path1 = g1.append("path").attr("d", geoPathAreaDeRiesgo);

    defs
      .append("path")
      .attr("id", "banda-4-fixed")
      .attr("d", function () {
        return path4.attr("d");
      });

    defs
      .append("path")
      .attr("id", "banda-3-fixed")
      .attr("d", function () {
        return path3.attr("d");
      });

    defs
      .append("path")
      .attr("id", "banda-2-fixed")
      .attr("d", function () {
        return path2.attr("d");
      });

    defs
      .append("path")
      .attr("id", "banda-1-fixed")
      .attr("d", function () {
        return path1.attr("d");
      });

    gAreaDeRiesgo.append("use").attr("href", "#risk-area-border");
  }

  function crearRios(data) {
    //gRios.attr("clip-path", "url(#spain-clip)").style("opacity", 0);
    gRios
      .selectAll("path")
      .data(data.features)
      .join("path")
      .attr("d", geoPathSpain)
      .style("fill", (d) => d.properties.fill)
      .style("fill-opacity", (d) => d.properties["fill-opacity"])
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-opacity", (d) => d.properties["stroke-opacity"])
      .style("stroke-width", (d) => d.properties["stroke-width"]);
  }

  function crearRiosGuadalquivirMini(data) {
    gRiosGuadalquivirMini
      .selectAll("path")
      .data(data.features)
      .join("path")
      .attr("d", geoPathSpain)
      .style("fill", (d) => d.properties.fill)
      .style("fill-opacity", (d) => d.properties["fill-opacity"])
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-opacity", (d) => d.properties["stroke-opacity"])
      .style("stroke-width", (d) => d.properties["stroke-width"]);
  }

  function crearRiosGuadalquivir(data) {
    //    gRiosGuadalquivir.attr("clip-path", "url(#guadalquivir-clip)");

    gRiosGuadalquivir
      .selectAll("path")
      .data(data.features)
      .join("path")
      .attr("d", geoPathGuadalquivir)
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-opacity", (d) => d.properties["stroke-opacity"])
      .style("stroke-width", (d) => d.properties["stroke-width"]);
  }

  function crearInundaciones(data) {
    //gInundaciones.attr("clip-path", "url(#spain-clip)");

    gInundaciones
      .append("path")
      .datum(data.features[0])
      .attr("fill", (d) => d.properties.fill)
      .attr("stroke", (d) => d.properties.stroke)
      .attr("stroke-width", (d) => d.properties["stroke-width"])
      .style("opacity", (d) => d.properties["stroke-opacity"])
      .attr("d", geoPathSpain);
  }

  function crearInundacionesGuadalquivirMini(data) {
    gInundacionesGuadalquivirMini
      .append("path")
      .datum(data.features[0])
      .attr("fill", (d) => d.properties.fill)
      .attr("stroke", (d) => d.properties.stroke)
      .attr("stroke-width", (d) => d.properties["stroke-width"])
      .style("opacity", (d) => d.properties["stroke-opacity"])
      .attr("d", geoPathSpain);
  }

  function crearCordoba(data) {
    let grupos = gCordoba
      .selectAll("g")
      .data(data.features)
      .join("g")
      .attr("transform", function (d) {
        let coordenadas = projectionSpain(d.geometry.coordinates);
        return `translate(${coordenadas})`;
      });

    grupos
      .append("circle")
      .attr("r", 5)
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("fill", "#000000");

    grupos
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", 180)
      .style("stroke", "#000000")
      .style("fill", "#000000")
      .style("stroke-width", "1px");

    grupos
      .append("line")
      .attr("x1", 0)
      .attr("y1", 180)
      .attr("x2", 40)
      .attr("y2", 180)
      .style("stroke", "#000000")
      .style("fill", "#000000")
      .style("stroke-width", "1px");

    grupos
      .append("text")
      .attr("font-size", "20px")
      .attr("dominant-baseline", "middle")
      .attr("transform", function (d) {
        let x = 45;
        let y = 180;
        return `translate(${x},${y})`;
      })
      .text(function (d) {
        return d.properties.city;
      });
  }

  function crearSevilla(data) {
    let grupos = gSevilla
      .selectAll("g")
      .data(data.features)
      .join("g")
      .attr("transform", function (d) {
        let coordenadas = projectionSpain(d.geometry.coordinates);
        return `translate(${coordenadas})`;
      });
    grupos
      .append("circle")
      .attr("r", 5)
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("fill", "#000000");

    grupos
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", 125)
      .style("stroke", "#000000")
      .style("fill", "#000000")
      .style("stroke-width", "1px");

    grupos
      .append("line")
      .attr("x1", 0)
      .attr("y1", 125)
      .attr("x2", -40)
      .attr("y2", 125)
      .style("stroke", "#000000")
      .style("fill", "#000000")
      .style("stroke-width", "1px");

    grupos
      .append("text")
      .attr("font-size", "20px")
      .attr("dominant-baseline", "middle")
      .attr("text-anchor", "end")
      .attr("transform", function (d) {
        let x = -45;
        let y = 125;
        return `translate(${x},${y})`;
      })
      .text(function (d) {
        return d.properties.city;
      });
  }

  function crearInundacionesGuadalquivir(data, dataSpain) {
    //gInundacionesGuadalquivir.attr("clip-path", "url(#guadalquivir-clip)");

    gInundacionesGuadalquivir
      .append("g")
      .selectAll("path")
      .data(dataSpain.features)
      .join("path")
      .attr("d", geoPathGuadalquivir)
      .attr("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    gInundacionesGuadalquivir
      .append("g")
      .selectAll("path")
      .data(data.features)
      .join("path")
      .attr("d", geoPathGuadalquivir)
      .attr("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);
  }

  function crearAreaDonana(data, landmarks) {
    gAreaDonanaBG
      .selectAll("path")
      .data(data.features)
      .join("path")
      .attr("d", geoPathAreaDeRiesgo)
      .attr("fill", function (d) {
        let c = d3.color(d.properties.fill);
        c.opacity = 0;
        d.properties.fill = c;
        return d.properties.fill;
      })
      .style("mix-blend-mode", "multiply");

    let grupos = gAreaDonana
      .selectAll("g")
      .data(landmarks.features)
      .join("g")
      .attr("transform", function (d) {
        let coordenadas = projectionAreaDeRiesgo(d.geometry.coordinates);
        return `translate(${coordenadas})`;
      });

    let x2 = isMobile.phone ? -205 : -155;
    grupos
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", x2)
      .attr("y2", 0)
      .style("stroke", "#000000")
      .style("fill", "#000000")
      .style("stroke-width", "1px");

    grupos
      .append("text")
      .attr("font-size", "20px")
      .attr("dominant-baseline", "middle")
      .attr("text-anchor", "end")
      .attr("x", x2 - 5)
      .attr("y", 0)
      .text(function (d) {
        return d.properties.city;
      });
  }

  function crearAreaMarismas(data, landmarks) {
    gAreaMarismasBG
      .selectAll("path")
      .data(data.features)
      .join("path")
      .attr("d", geoPathAreaDeRiesgo)
      .attr("fill", function (d) {
        let c = d3.color(d.properties.fill);
        c.opacity = 0;
        d.properties.fill = c;
        return d.properties.fill;
      })
      .style("mix-blend-mode", "multiply");

    let grupos = gAreaMarismas
      .selectAll("g")
      .data(landmarks.features)
      .join("g")
      .attr("transform", function (d) {
        let coordenadas = projectionAreaDeRiesgo(d.geometry.coordinates);
        return `translate(${coordenadas})`;
      });

    let x2 = isMobile.phone ? -498 : -373;
    grupos
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", x2)
      .attr("y2", -0)
      .style("stroke", "#000000")
      .style("fill", "#000000")
      .style("stroke-width", "1px");

    grupos
      .append("text")
      .attr("font-size", "20px")
      .attr("dominant-baseline", "middle")
      .attr("text-anchor", "end")
      .attr("x", x2 - 5)
      .attr("y", 0)
      .text(function (d) {
        return d.properties.city;
      });
  }

  function crearInundacionesAreaDeRiesgo(data) {
    gInundacionesAreaDeRiesgo
      .selectAll("path")
      .data(data.features)
      .join("path")
      .attr("d", geoPathAreaDeRiesgo)
      .attr("fill", "transparent")
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);
  }

  function crearInundacionesJuntas(data) {
    //gInundacionesJuntas.attr("clip-path", "url(#spain-clip)");

    gInundacionesJuntas
      .selectAll("path")
      .data(data.features)
      .join("path")
      .attr("d", geoPathSpain)
      .attr("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", 2);
  }

  function crearBuffers(data) {
    let g4 = gBuffers
      .append("g")
      .datum(data["banda-4"].features[0])
      .attr("class", "layer-4")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g3 = gBuffers
      .append("g")
      .datum(data["banda-3"].features[0])
      .attr("class", "layer-3")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g2 = gBuffers
      .append("g")
      .datum(data["banda-2"].features[0])
      .attr("class", "layer-2")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g1 = gBuffers
      .append("g")
      .datum(data["banda-1"].features[0])
      .attr("class", "layer-1")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    g4.append("path").attr("d", geoPathSpain);
    g3.append("path").attr("d", geoPathSpain);
    g2.append("path").attr("d", geoPathSpain);
    g1.append("path").attr("d", geoPathSpain);
  }

  function crearBuffersGuadalquivirMini(data) {
    let g4 = gBuffersGuadalquivirMini
      .append("g")
      .datum(data["banda-4"].features[0])
      .attr("class", "layer-4")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g3 = gBuffersGuadalquivirMini
      .append("g")
      .datum(data["banda-3"].features[0])
      .attr("class", "layer-3")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g2 = gBuffersGuadalquivirMini
      .append("g")
      .datum(data["banda-2"].features[0])
      .attr("class", "layer-2")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g1 = gBuffersGuadalquivirMini
      .append("g")
      .datum(data["banda-1"].features[0])
      .attr("class", "layer-1")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    g4.append("path").attr("d", geoPathSpain);
    g3.append("path").attr("d", geoPathSpain);
    g2.append("path").attr("d", geoPathSpain);
    g1.append("path").attr("d", geoPathSpain);
  }

  function crearBuffersGuadalquivir(data) {
    let g4 = gBuffersGuadalquivir
      .append("g")
      .datum(data["banda-4"].features[0])
      .attr("class", "layer-4")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g3 = gBuffersGuadalquivir
      .append("g")
      .datum(data["banda-3"].features[0])
      .attr("class", "layer-3")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g2 = gBuffersGuadalquivir
      .append("g")
      .datum(data["banda-2"].features[0])
      .attr("class", "layer-2")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g1 = gBuffersGuadalquivir
      .append("g")
      .datum(data["banda-1"].features[0])
      .attr("class", "layer-1")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    g4.append("path").attr("d", geoPathGuadalquivir);
    g3.append("path").attr("d", geoPathGuadalquivir);
    g2.append("path").attr("d", geoPathGuadalquivir);
    g1.append("path").attr("d", geoPathGuadalquivir);
  }

  function crearBuffersJuntos(data) {
    let g4 = gBuffersJuntos
      .append("g")
      .datum(data["banda-4"].features[0])
      .attr("class", "layer-4")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g3 = gBuffersJuntos
      .append("g")
      .datum(data["banda-3"].features[0])
      .attr("class", "layer-3")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g2 = gBuffersJuntos
      .append("g")
      .datum(data["banda-2"].features[0])
      .attr("class", "layer-2")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    let g1 = gBuffersJuntos
      .append("g")
      .datum(data["banda-1"].features[0])
      .attr("class", "layer-1")
      .style("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"]);

    g4.append("path").attr("d", geoPathSpain);
    g3.append("path").attr("d", geoPathSpain);
    g2.append("path").attr("d", geoPathSpain);
    g1.append("path").attr("d", geoPathSpain);
  }

  function crearLandmarks(data) {
    let grupos = gLandmarks
      .selectAll("g")
      .data(data.features)
      .join("g")
      .attr("transform", function (d) {
        let coordenadas = projectionSpain(d.geometry.coordinates);
        return `translate(${coordenadas})`;
      });

    grupos
      .append("circle")
      .attr("r", 5)
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("fill", (d) => d.properties.fill);

    grupos
      .append("text")
      .attr("font-size", "14px")
      .attr("transform", function (d) {
        let x = 5;
        let y = -10;
        switch (d.properties.city) {
          case "La Puebla del Rio":
            x = 13;
            y = 0;
            break;
          case "Doñana National Park":
            x = -143;
            y = 3;
            break;
          case "Isla Mayor":
            x = 8;
            y = 13;
            break;
          case "Poblado de Alfonso XIII":
            x = 13;
            y = 3;
            break;
          case "Sanlúcar de Barrameda":
            x = -155;
            y = 3;
            break;
          case "Isla Mayor":
            x = 8;
            y = 8;
            break;
          default:
            break;
        }
        return `translate(${x},${y})`;
      })
      .text(function (d) {
        return d.properties.city;
      });
  }

  function crearLandmarksGuadalquivir(data) {
    data.features.forEach(function (d) {
      let puntoLinea = { x2: 0, y2: 0 };
      let puntoTexto = { x: 0, y: 0 };
      let anchor = "start";
      let baseline = "middle";
      switch (d.properties.city) {
        case "Granada":
          puntoTexto = { x: 15, y: 0 };
          break;
        case "Jaén":
          puntoTexto = { x: -15, y: 0 };
          anchor = "end";
          break;
        case "Córdoba":
          puntoTexto = { x: 0, y: 25 };
          break;
        case "Seville":
          puntoTexto = { x: 15, y: 0 };
          break;
        case "Poblado de Alfonso XIII":
          puntoLinea = { x2: 85, y2: 0 };
          puntoTexto = { x: 90, y: 0 };
          break;
        case "Isla Mayor":
          puntoLinea = { x2: 100, y2: 115 };
          puntoTexto = { x: 105, y: 115 };
          break;
        case "La Puebla del Rio":
          puntoTexto = { x: -15, y: -10 };
          anchor = "end";
          break;
        case "Sanlúcar de Barrameda":
          puntoLinea = { x2: 0, y2: 60 };
          puntoTexto = { x: 0, y: 65 };
          baseline = "hanging";
          anchor = "middle";
          break;
        default:
          break;
      }
      d.properties.ajustesGuadalquivir = {
        linea: puntoLinea,
        texto: puntoTexto,
        anchor: anchor,
        baseline: baseline,
      };
    });

    let grupos = gLandmarksGuadalquivir
      .selectAll("g")
      .data(data.features)
      .join("g")
      .attr("transform", function (d) {
        let coordenadas = projectionGuadalquivir(d.geometry.coordinates);
        return `translate(${coordenadas})`;
      });

    grupos
      .append("circle")
      .attr("r", 5)
      .attr("cx", 0)
      .attr("cy", 0)
      .style("fill", "#000000");

    grupos
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", (d) => d.properties.ajustesGuadalquivir.linea.x2)
      .attr("y2", (d) => d.properties.ajustesGuadalquivir.linea.y2)
      .style("stroke", "#000000")
      .style("fill", "#000000")
      .style("stroke-width", "1px");

    grupos
      .append("text")
      .attr("font-size", "20px")
      .attr(
        "dominant-baseline",
        (d) => d.properties.ajustesGuadalquivir.baseline
      )
      .attr("text-anchor", (d) => d.properties.ajustesGuadalquivir.anchor)
      .attr("x", (d) => d.properties.ajustesGuadalquivir.texto.x)
      .attr("y", (d) => d.properties.ajustesGuadalquivir.texto.y)
      .text((d) => d.properties.city);
  }

  function crearEtiquetasAreaDeRiesgo(data) {
    data.features.forEach(function (d) {
      let id = null;
      let opacity = null;
      let puntoLinea = { x2: 0, y2: 0 };
      let puntoTexto = { x: 0, y: 0 };
      let anchor = "start";
      let baseline = "middle";
      let x = 0;

      switch (d.properties.city) {
        case "Seville":
          id = "gSeville";
          x = isMobile.phone ? 90 : 70;
          puntoLinea = { x2: x, y2: 0 };
          puntoTexto = { x: x + 5, y: 0 };
          break;
        case "Poblado de Alfonso XIII":
          opacity = 0;
          id = "gPoblado";
          x = isMobile.phone ? 370 : 300;
          puntoLinea = { x2: x, y2: 0 };
          puntoTexto = { x: x + 5, y: 0 };
          break;
        case "Isla Mayor":
          opacity = 0;
          id = "gIsla";
          x = isMobile.phone ? 405 : 326;
          puntoLinea = { x2: x, y2: 0 };
          puntoTexto = { x: x + 5, y: 0 };
          break;
        case "La Puebla del Rio":
          opacity = 0;
          id = "gPuebla";
          x = isMobile.phone ? -120 : -100;
          puntoLinea = { x2: x, y2: 0 };
          puntoTexto = { x: x - 5, y: 0 };
          anchor = "end";
          break;
        case "Sanlúcar de Barrameda":
          opacity = 0;
          id = "gSanlucar";
          puntoLinea = { x2: -100, y2: 0 };
          puntoTexto = { x: -105, y: 0 };
          anchor = "end";
          break;
        default:
          break;
      }
      d.properties.ajustesAreaDeRiesgo = {
        linea: puntoLinea,
        texto: puntoTexto,
        anchor: anchor,
        baseline: baseline,
      };
      d.properties.id = id;
      d.properties.opacity = opacity;
    });

    let grupos = gEtiquetasAreaDeRiesgo
      .selectAll("g")
      .data(data.features)
      .join("g")
      .attr("transform", function (d) {
        let coordenadas = projectionAreaDeRiesgo(d.geometry.coordinates);
        return `translate(${coordenadas})`;
      })
      .attr("id", (d) => d.properties.id)
      .style("opacity", (d) => d.properties.opacity);

    grupos.each(function (d) {
      switch (d.properties.city) {
        case "Poblado de Alfonso XIII":
          gPoblado = d3.select(this);
          break;
        case "Isla Mayor":
          gIsla = d3.select(this);
          break;
        case "La Puebla del Rio":
          gPuebla = d3.select(this);
          break;
        case "Sanlúcar de Barrameda":
          gSanlucar = d3.select(this);
          break;
        default:
          break;
      }
    });

    grupos
      .append("circle")
      .attr("r", 5)
      .attr("cx", 0)
      .attr("cy", 0)
      .style("fill", "#000000");

    grupos
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", (d) => d.properties.ajustesAreaDeRiesgo.linea.x2)
      .attr("y2", (d) => d.properties.ajustesAreaDeRiesgo.linea.y2)
      .style("stroke", "#000000")
      .style("fill", "#000000")
      .style("stroke-width", "1px");

    grupos
      .append("text")
      .attr("font-size", "20px")
      .attr(
        "dominant-baseline",
        (d) => d.properties.ajustesAreaDeRiesgo.baseline
      )
      .attr("text-anchor", (d) => d.properties.ajustesAreaDeRiesgo.anchor)
      .attr("x", (d) => d.properties.ajustesAreaDeRiesgo.texto.x)
      .attr("y", (d) => d.properties.ajustesAreaDeRiesgo.texto.y)
      .text((d) => d.properties.city);
  }

  function crearLeyendaPoblacionAreaDeRiesgo() {
    let x = cajaLeyenda.x;
    let y = cajaLeyenda.y;
    let escala = cajaLeyenda.escala;
    let grupo = gLeyendaPoblacionAreaDeRiesgo
      .append("g")
      .attr("transform", `translate(${x},${y})scale(${escala})`);

    grupo
      .append("text")
      .text("INHABITANTS")
      .attr("x", 0)
      .attr("y", 0)
      .style("font-size", "80px")
      .style("font-weight", 800);

    let arreglo = [
      {
        texto: "500+",
        color: "#957FEB",
      },
      { texto: "1 - 499", color: "#CEC4F4" },
    ];

    let etiquetas = grupo
      .selectAll("g")
      .data(arreglo)
      .join("g")
      .attr("transform", function (d, i) {
        let x = 27;
        let y = 50 + 80 * i;
        return `translate(${x}, ${y})`;
      });

    etiquetas
      .append("rect")
      .attr("x", -24)
      .attr("y", -24)
      .attr("width", 48)
      .attr("height", 48)
      .attr("stroke", function (d) {
        return d.color;
      })
      .attr("fill", function (d) {
        return d.color;
      });

    etiquetas
      .append("text")
      .text(function (d) {
        return d.texto;
      })
      .style("font-size", "50px")
      .attr("x", 40)
      .attr("y", 15);
  }

  function crearLeyendaPropiedadAreaDeRiesgo() {
    let x = cajaLeyenda.x;
    let y = cajaLeyenda.y;
    let escala = cajaLeyenda.escala;
    let grupo = gLeyendaPropiedadAreaDeRiesgo
      .append("g")
      .attr("transform", `translate(${x},${y})scale(${escala})`);

    grupo
      .append("text")
      .text("PROPERTY DAMAGE")
      .attr("x", 0)
      .attr("y", 0)
      .style("font-size", "80px")
      .style("font-weight", 800);

    let arreglo = [
      {
        texto: "Property affected",
        color: "#947FEB",
      },
    ];

    let etiquetas = grupo
      .selectAll("g")
      .data(arreglo)
      .join("g")
      .attr("transform", function (d, i) {
        let x = 27;
        let y = 50 + 80 * i;
        return `translate(${x}, ${y})`;
      });

    etiquetas
      .append("rect")
      .attr("x", -24)
      .attr("y", -24)
      .attr("width", 48)
      .attr("height", 48)
      .attr("stroke", function (d) {
        return d.color;
      })
      .attr("fill", function (d) {
        return d.color;
      });

    etiquetas
      .append("text")
      .text(function (d) {
        return d.texto;
      })
      .style("font-size", "50px")
      .attr("x", 40)
      .attr("y", 15);
  }

  function crearLeyendaPuntosAreaDeRiesgo() {
    let x = cajaLeyenda.x;
    let y = cajaLeyenda.y;
    let escala = cajaLeyenda.escala;
    let grupo = gLeyendaPuntosAreaDeRiesgo
      .append("g")
      .attr("transform", `translate(${x},${y})scale(${escala})`);

    grupo
      .append("text")
      .text("AREAS AT RISK")
      .attr("x", 0)
      .attr("y", 0)
      .style("font-size", "80px")
      .style("font-weight", 800);

    let arreglo = [
      {
        texto: "Low risk",
        color: "#DAD0F6",
      },
      { texto: "High risk", color: "#AE9EED" },
      { texto: "Very high risk", color: "#4e2add" },
    ];

    let etiquetas = grupo
      .selectAll("g")
      .data(arreglo)
      .join("g")
      .attr("transform", function (d, i) {
        let x = 27;
        let y = 50 + 80 * i;
        return `translate(${x}, ${y})`;
      });

    etiquetas
      .append("circle")
      .attr("r", 24)
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("stroke", "#000000")
      .attr("stroke-width", "4px")
      .attr("fill", "transparent");

    etiquetas
      .append("circle")
      .attr("r", 16)
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("fill", function (d) {
        return d.color;
      });

    etiquetas
      .append("text")
      .text(function (d) {
        return d.texto;
      })
      .style("font-size", "50px")
      .attr("x", 40)
      .attr("y", 15);
  }

  function crearPuntosInundacionAreaDeRiesgo(data) {
    let grupos = gPuntosInundacionAreaDeRiesgo
      .selectAll("g")
      .data(data.features)
      .join("g")
      .attr("transform", function (d) {
        let coordenadas = projectionAreaDeRiesgo(d.geometry.coordinates);
        return `translate(${coordenadas})`;
      });

    let subgrupos = grupos.append("g");

    subgrupos
      .append("circle")
      .attr("r", 8)
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("stroke", "#000000")
      .attr("stroke-width", "1px")
      .attr("fill", "transparent");

    subgrupos
      .append("circle")
      .attr("r", 6)
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("fill", function (d) {
        let color = "#DAD0F6";
        switch (d.properties.CLASIF_AFE) {
          case "GRAVE":
            color = "#AE9EED";
            break;
          case "MUY GRAVE":
            color = "#4e2add";
            break;
          default:
            break;
        }
        return color;
      });
  }

  function crearPropiedadAreaDeRiesgo(data) {
    gPropiedadAreaDeRiesgo
      .selectAll("path")
      .data(data.features)
      .join("path")
      .classed("geopath", true)
      .style("fill", "#947FEB")
      .attr("d", geoPathAreaDeRiesgo);
  }

  function crearPoblacionAreaDeRiesgo(data) {
    gPoblacionAreaDeriesgo
      .selectAll("path")
      .data(data.features)
      .join("path")
      .classed("geopath", true)
      .style("stroke", function (d) {
        let color = "transparent";
        if (1 <= d.properties.NUM_HAB_ZI && d.properties.NUM_HAB_ZI <= 499) {
          color = "#CEC4F4";
        } else if (d.properties.NUM_HAB_ZI >= 500) {
          color = "#957FEB";
        }
        return color;
      })
      .style("fill", function (d) {
        let color = "transparent";
        if (1 <= d.properties.NUM_HAB_ZI && d.properties.NUM_HAB_ZI <= 499) {
          color = "#CEC4F4";
        } else if (d.properties.NUM_HAB_ZI >= 500) {
          color = "#957FEB";
        }
        return color;
      })
      .attr("d", geoPathAreaDeRiesgo);
  }

  function ocultarCapas() {
    ocultarCapasEspania();
    ocultarCapasGuadalquivirMini();
    ocultarCapasGuadalquivir();
    ocultarCapasAreaDeRiesgo();
    ocultarCapasCirculo();
  }

  function ocultarCapasEspania() {
    gSpain.interrupt().transition().duration(duracion).style("opacity", 0);
    gRios.interrupt().transition().duration(duracion).style("opacity", 0);
    gInundaciones
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gBuffers.interrupt().transition().duration(duracion).style("opacity", 0);
    gLandmarks.interrupt().transition().duration(duracion).style("opacity", 0);
    gBuffersJuntos
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gInundacionesJuntas
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);

    defs
      .select("#spain-border")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gImages
      .select("#spain-background")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
  }

  function ocultarCapasGuadalquivirMini() {
    gGuadalquivirMini
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gRiosGuadalquivirMini
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gBuffersGuadalquivirMini
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gInundacionesGuadalquivirMini
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gCordoba.interrupt().transition().duration(duracion).style("opacity", 0);
    gSevilla.interrupt().transition().duration(duracion).style("opacity", 0);

    defs
      .select("#guadalquivir-mini-border")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);

    gGuadalquivirMini
      .select("rect")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
  }

  function ocultarCapasGuadalquivir() {
    gGuadalquivir
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gRiosGuadalquivir
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gInundacionesGuadalquivir
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gBuffersGuadalquivir
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gLandmarksGuadalquivir
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);

    defs
      .select("#guadalquivir-border")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gImages
      .select("#guadalquivir-background")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
  }

  function ocultarCapasAreaDeRiesgo() {
    gAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gAreaMarismasBG
      .select("path")
      .interrupt()
      .transition()
      .duration(duracion)
      .attr("fill", function (d) {
        d.properties.fill.opacity = 0;
        return d.properties.fill;
      });
    gAreaMarismas
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gAreaDonanaBG
      .select("path")
      .interrupt()
      .transition()
      .duration(duracion)
      .attr("fill", function (d) {
        d.properties.fill.opacity = 0;
        return d.properties.fill;
      });
    gAreaDonana.interrupt().transition().duration(duracion).style("opacity", 0);
    gInundacionesAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gPuntosInundacionAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gPropiedadAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gPoblacionAreaDeriesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gEtiquetasAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gLeyendaPuntosAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gLeyendaPoblacionAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gLeyendaPropiedadAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);

    if (gPoblado !== null) {
      gPoblado.interrupt().transition().duration(duracion).style("opacity", 0);
    }
    if (gIsla !== null) {
      gIsla.interrupt().transition().duration(duracion).style("opacity", 0);
    }
    if (gPuebla !== null) {
      gPuebla.interrupt().transition().duration(duracion).style("opacity", 0);
    }
    if (gSanlucar !== null) {
      gSanlucar.interrupt().transition().duration(duracion).style("opacity", 0);
    }

    defs
      .select("#risk-area-border")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
    gAreaDeRiesgo
      .select("#risk-area-background")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 0);
  }

  function ocultarCapasCirculo() {
    gCirculo.interrupt().transition().duration(duracion).style("opacity", 0);
  }

  function agrandarGuadalquivir() {
    ocultarCapas();

    gGuadalquivirMini.select("path").interrupt().style("stroke", "transparent");
    gRiosGuadalquivirMini.interrupt().style("opacity", 0);
    gBuffersGuadalquivirMini.interrupt().style("opacity", 0);
    gInundacionesGuadalquivirMini.interrupt().style("opacity", 0);
    gInundacionesGuadalquivir.selectAll("path").attr("d", geoPathGuadalquivir);

    gGuadalquivir
      .interrupt()
      .style("opacity", 1)
      .selectAll("path")
      .style("opacity", 1)
      .attr("d", geoPathSpain)
      .transition()
      .duration(duracion)
      .attr("d", geoPathGuadalquivir)
      .end()
      .catch((e) => {
        //console.log("agrandarGuadalquivir 1 Transition failed");
      })
      .finally(function () {
        gGuadalquivir.interrupt().attr("d", geoPathGuadalquivir);
        gGuadalquivirMini.select("path").interrupt().style("stroke", "#000000");
        gLandmarksGuadalquivir
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
      });

    defs
      .select("#guadalquivir-border")
      .interrupt()
      .style("opacity", 1)
      .attr("d", geoPathSpain)
      .transition()
      .duration(duracion)
      .attr("d", geoPathGuadalquivir);

    gImages.select("#guadalquivir-background").interrupt().style("opacity", 1);

    gRiosGuadalquivir
      .interrupt()
      .style("opacity", 1)
      .selectAll("path")
      .attr("d", geoPathSpain)
      .transition()
      .duration(duracion)
      .attr("d", geoPathGuadalquivir);

    gInundacionesGuadalquivir.interrupt().style("opacity", 1);

    gInundacionesGuadalquivir
      .select("g:first-of-type")
      .selectAll("path")
      .interrupt()
      .attr("d", geoPathSpain)
      .transition()
      .duration(duracion)
      .attr("d", geoPathGuadalquivir)
      .end()
      .catch((e) => {
        //console.log("agrandarGuadalquivir 2 Transition failed");
      })
      .finally(function () {
        gInundacionesGuadalquivir
          .select("g:first-of-type")
          .selectAll("path")
          .interrupt()
          .attr("d", geoPathGuadalquivir)
          .style("opacity", null);
      });

    gInundacionesGuadalquivir
      .select("g:nth-of-type(2)")
      .selectAll("path")
      .interrupt()
      .style("opacity", 0)
      .attr("d", geoPathSpain)
      .transition()
      .duration(duracion)
      .style("opacity", 1)
      .attr("d", geoPathGuadalquivir)
      .end()
      .catch((e) => {
        //console.log("agrandarGuadalquivir 3 Transition failed");
      })
      .finally(function () {
        gInundacionesGuadalquivir
          .select("g:nth-of-type(2)")
          .selectAll("path")
          .interrupt()
          .attr("d", geoPathGuadalquivir)
          .style("opacity", null);
      });

    gBuffersGuadalquivir
      .interrupt()
      .style("opacity", 1)
      .selectAll("path")
      .interrupt()
      .style("opacity", 0)
      .attr("d", geoPathSpain)
      .transition()
      .style("opacity", 1)
      .duration(duracion)
      .attr("d", geoPathGuadalquivir)
      .end()
      .catch((e) => {
        //console.log("agrandarGuadalquivir 4 Transition failed");
      })
      .finally(function () {
        gBuffersGuadalquivir
          .selectAll("path")
          .interrupt()
          .attr("d", geoPathGuadalquivir)
          .style("opacity", null);
      });
  }

  function achicarGuadalquivir() {
    ocultarCapas();

    gGuadalquivir.interrupt().style("opacity", 0);
    gRiosGuadalquivir.interrupt().style("opacity", 0);
    gBuffersGuadalquivir.interrupt().style("opacity", 0);
    gInundacionesGuadalquivir.interrupt().style("opacity", 0);

    gSpain.interrupt().style("opacity", 1);
    gRios.interrupt().style("opacity", 1);
    gInundaciones.interrupt().style("opacity", 1);
    gBuffers.interrupt().style("opacity", 1);

    gGuadalquivirMini
      .interrupt()
      .style("opacity", 1)
      .selectAll("path")
      .interrupt()
      .style("stroke", "#000000")
      .attr("d", geoPathGuadalquivir)
      .transition()
      .duration(duracion)
      .style("stroke", "#000000")
      .attr("d", geoPathSpain)
      .end()
      .catch((e) => {
        //console.log("achicarGuadalquivir 1 Transition failed");
      })
      .finally(function () {
        gGuadalquivirMini.selectAll("path").attr("d", geoPathSpain);
        gCordoba
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
        gSevilla
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
      });

    gGuadalquivirMini.select("rect").interrupt().style("opacity", 0.7);
    gImages.select("#spain-background").interrupt().style("opacity", 1);
    defs.select("#spain-border").interrupt().style("opacity", 1);
    defs.select("#guadalquivir-mini-border").interrupt().style("opacity", 0.8);

    gRiosGuadalquivirMini
      .interrupt()
      .style("opacity", 1)
      .selectAll("path")
      .interrupt()
      .attr("d", geoPathGuadalquivir)
      .transition()
      .duration(duracion)
      .attr("d", geoPathSpain);

    gInundacionesGuadalquivirMini.interrupt().style("opacity", 1);

    gInundacionesGuadalquivirMini
      .selectAll("path")
      .interrupt()
      .attr("d", geoPathGuadalquivir)
      .transition()
      .duration(duracion)
      .attr("d", geoPathSpain);

    gInundacionesGuadalquivir.interrupt().style("opacity", 1);

    gInundacionesGuadalquivir
      .select("g:first-of-type")
      .selectAll("path")
      .style("opacity", null);

    gInundacionesGuadalquivir
      .select("g:nth-of-type(2)")
      .selectAll("path")
      .interrupt()
      .style("opacity", 1)
      .attr("d", geoPathGuadalquivir)
      .transition()
      .duration(duracion)
      .style("opacity", 0)
      .attr("d", geoPathSpain)
      .end()
      .catch((e) => {
        //console.log("achicarGuadalquivir 3 Transition failed");
      })
      .finally(function () {
        gInundacionesGuadalquivir
          .select("g:nth-of-type(2)")
          .selectAll("path")
          .interrupt()
          .style("opacity", null)
          .attr("d", geoPathGuadalquivir);
        gInundacionesGuadalquivir.interrupt().style("opacity", 0);
      });

    gBuffersGuadalquivirMini
      .interrupt()
      .style("opacity", 1)
      .selectAll("path")
      .interrupt()
      .style("opacity", 0)
      .attr("d", geoPathGuadalquivir)
      .transition()
      .style("opacity", 1)
      .duration(duracion)
      .attr("d", geoPathSpain)
      .end()
      .catch((e) => {
        //console.log("achicarGuadalquivir 4 Transition failed");
      })
      .finally(function () {
        gBuffersGuadalquivirMini
          .selectAll("path")
          .interrupt()
          .attr("d", geoPathSpain)
          .style("opacity", null);
      });
  }

  function guadalquivirACuenca() {
    ocultarCapas();
    gBuffersGuadalquivir.interrupt().style("opacity", 0);
    gInundacionesGuadalquivir.interrupt().style("opacity", 0);
    gAreaDeRiesgo
      .interrupt()
      .style("opacity", 1)
      .selectAll("path")
      .interrupt()
      .attr("d", geoPathGuadalquivir)
      .transition()
      .duration(duracion)
      .attr("d", geoPathAreaDeRiesgo)
      .end()
      .catch((e) => {
        //console.log("guadalquivirACuenca Transition failed");
      })
      .finally(function () {
        gAreaDeRiesgo
          .selectAll("path")
          .interrupt()
          .attr("d", geoPathAreaDeRiesgo);
        gEtiquetasAreaDeRiesgo
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
      });

    defs
      .select("#risk-area-inner-border")
      .interrupt()
      .attr("d", geoPathGuadalquivir)
      .transition()
      .duration(duracion)
      .attr("d", geoPathAreaDeRiesgo);

    gAreaDeRiesgo
      .select("#risk-area-background")
      .interrupt()
      .style("opacity", 1);

    gInundacionesAreaDeRiesgo
      .interrupt()
      .style("opacity", 1)
      .selectAll("path")
      .interrupt()
      .attr("fill", (d) => d.properties.fill)
      .attr("d", geoPathGuadalquivir)
      .transition()
      .duration(duracion)
      .attr("fill", "transparent")
      .attr("d", geoPathAreaDeRiesgo);
  }

  function cuencaAGuadalquivir() {
    ocultarCapas();

    defs.select("#risk-area-border").interrupt().attr("d", geoPathAreaDeRiesgo);
    gAreaDeRiesgo
      .interrupt()
      .style("opacity", 1)
      .selectAll("path")
      .interrupt()
      .attr("d", geoPathAreaDeRiesgo);

    gInundacionesAreaDeRiesgo
      .interrupt()
      .style("opacity", 1)
      .selectAll("path")
      .interrupt()
      .attr("fill", "transparent")
      .attr("d", geoPathAreaDeRiesgo);

    defs
      .select("#risk-area-border")
      .interrupt()
      .attr("d", geoPathAreaDeRiesgo)
      .transition()
      .duration(duracion)
      .attr("d", geoPathGuadalquivir)
      .end()
      .catch((e) => {
        //console.log("cuencaAGuadalquivir 1 Transition failed");
      })
      .finally(function () {
        defs
          .select("#risk-area-border")
          .interrupt()
          .attr("d", geoPathAreaDeRiesgo);
      });

    gAreaDeRiesgo
      .interrupt()
      .style("opacity", 1)
      .selectAll("path")
      .interrupt()
      .attr("d", geoPathAreaDeRiesgo)
      .transition()
      .duration(duracion)
      .attr("d", geoPathGuadalquivir)
      .end()
      .catch((e) => {
        //console.log("cuencaAGuadalquivir 2 Transition failed");
      })
      .finally(function () {
        gAreaDeRiesgo
          .interrupt()
          .style("opacity", 0)
          .selectAll("path")
          .interrupt()
          .attr("d", geoPathAreaDeRiesgo);
        gBuffersGuadalquivir.interrupt().style("opacity", 1);
        gLandmarksGuadalquivir
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
      });

    gImages
      .select("#guadalquivir-background")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 1);
    defs
      .select("#guadalquivir-border")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 1);

    gGuadalquivir
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 1);
    gRiosGuadalquivir
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 1);

    defs
      .select("#risk-area-inner-border")
      .interrupt()
      .attr("d", geoPathAreaDeRiesgo)
      .transition()
      .duration(duracion)
      .attr("d", geoPathGuadalquivir)
      .end()
      .catch((e) => {
        //console.log("cuencaAGuadalquivir 3 Transition failed");
      })
      .finally(function () {
        defs
          .select("#risk-area-inner-border")
          .interrupt()
          .attr("d", geoPathAreaDeRiesgo);
      });

    gInundacionesAreaDeRiesgo
      .interrupt()
      .style("opacity", 1)
      .selectAll("path")
      .interrupt()
      .attr("fill", "transparent")
      .attr("d", geoPathAreaDeRiesgo)
      .transition()
      .duration(duracion)
      .attr("fill", (d) => d.properties.fill)
      .attr("d", geoPathGuadalquivir)
      .end()
      .catch((e) => {
        //console.log("cuencaAGuadalquivir 4 Transition failed");
      })
      .finally(function () {
        gInundacionesAreaDeRiesgo
          .interrupt()
          .style("opacity", 0)
          .selectAll("path")
          .interrupt()
          .attr("fill", "transparent")
          .attr("d", geoPathAreaDeRiesgo);
        gInundacionesGuadalquivir
          .interrupt()
          .select("g:nth-of-type(2)")
          .selectAll("path")
          .interrupt()
          .style("opacity", 1)
          .attr("d", geoPathGuadalquivir);
      });

    gInundacionesGuadalquivir.selectAll("path").interrupt().style("opacity", 0);
    gInundacionesGuadalquivir.interrupt().style("opacity", 1);

    gInundacionesGuadalquivir
      .interrupt()
      .select("g:first-of-type")
      .selectAll("path")
      .interrupt()
      .style("opacity", 0)
      .attr("d", geoPathGuadalquivir)
      .transition()
      .duration(duracion)
      .style("opacity", 1);
  }

  function cuencaAEspania() {
    ocultarCapas();
    let dTemp = defs.select("#risk-area-border-fixed").attr("d");
    let dFinal = defs.select("#spain-border-fixed").attr("d");

    gSpain.select("path").interrupt().attr("d", dTemp);
    gImages.select("#spain-background").interrupt().style("opacity", 1);
    defs
      .select("#spain-border")
      .interrupt()
      .style("opacity", 1)
      .transition()
      .duration(duracion)
      .attrTween("d", function () {
        return interpoladorAreaAEspania;
      })
      .end()
      .catch((e) => {
        //console.log("cuencaAEspania 1 Transition failed");
      })
      .finally(function () {
        defs.select("#spain-border").interrupt().attr("d", dFinal);
        gRios
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", constantes.rios.opacity); //Ríos
        gInundacionesJuntas
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1); //Floodings
        gBuffersJuntos
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1); //Buffers
      });

    gSpain.interrupt().transition().duration(duracion).style("opacity", 1);
    gSpain
      .select("path")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 1)
      .attrTween("d", function () {
        return interpoladorAreaAEspania;
      })
      .end()
      .catch((e) => {
        //console.log("cuencaAEspania 2 Transition failed");
      })
      .finally(function () {
        gSpain.select("path").interrupt().attr("d", dFinal);
      });
  }

  function espaniaACuenca() {
    ocultarCapas();
    let dTemp = defs.select("#spain-border-fixed").attr("d");
    let dFinal = defs.select("#risk-area-border-fixed").attr("d");

    let banda1 = defs.select("#banda-1-fixed").attr("d");
    let banda2 = defs.select("#banda-2-fixed").attr("d");
    let banda3 = defs.select("#banda-3-fixed").attr("d");
    let banda4 = defs.select("#banda-4-fixed").attr("d");

    gAreaDeRiesgo.selectAll("path").interrupt().attr("d", dTemp);

    gAreaDeRiesgo
      .select("#risk-area-background")
      .interrupt()
      .style("opacity", 1);

    defs
      .select("#risk-area-border")
      .interrupt()
      .style("opacity", 1)
      .transition()
      .duration(duracion)
      .attrTween("d", function () {
        return interpoladorEspaniaAArea;
      })
      .end()
      .catch((e) => {
        //console.log("espaniaACuenca 1 Transition failed");
      })
      .finally(function () {
        defs.select("#risk-area-border").interrupt().attr("d", dFinal);
        gAreaMarismasBG
          .select("path")
          .interrupt()
          .attr("fill", function (d) {
            d.properties.fill.opacity = 0;
            return d.properties.fill;
          })
          .transition()
          .duration(duracion)
          .attr("fill", function (d) {
            d.properties.fill.opacity = 1;
            return d.properties.fill;
          });
        gAreaMarismas
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
        gAreaDonanaBG
          .select("path")
          .interrupt()
          .attr("fill", function (d) {
            d.properties.fill.opacity = 0;
            return d.properties.fill;
          })
          .transition()
          .duration(duracion)
          .attr("fill", function (d) {
            d.properties.fill.opacity = 1;
            return d.properties.fill;
          });
        gAreaDonana
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
        gInundacionesAreaDeRiesgo
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
        gPoblacionAreaDeriesgo
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
        gLeyendaPoblacionAreaDeRiesgo
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
        gEtiquetasAreaDeRiesgo
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
        gIsla.interrupt().style("opacity", 1);
        gPoblado.interrupt().style("opacity", 1);
        gPuebla.interrupt().style("opacity", 1);
        gSanlucar.interrupt().style("opacity", 1);
      });

    defs
      .select("#risk-area-inner-border")
      .interrupt()
      .style("opacity", 1)
      .transition()
      .duration(duracion)
      .attrTween("d", function () {
        return interpoladorBanda1;
      })
      .end()
      .catch((e) => {
        //console.log("espaniaACuenca 2 Transition failed");
      })
      .finally(function () {
        defs.select("#risk-area-inner-border").attr("d", banda1);
      });

    gAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 1);

    gAreaDeRiesgo
      .selectAll("path")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 1)
      .attrTween("d", function (d, i) {
        let interpolador = interpoladorBanda1;
        switch (i) {
          case 0:
            interpolador = interpoladorBanda4;
            break;
          case 1:
            interpolador = interpoladorBanda3;
            break;
          case 2:
            interpolador = interpoladorBanda2;
            break;
          default:
            break;
        }
        return interpolador;
      })
      .end()
      .catch((e) => {
        //console.log("espaniaACuenca 3 Transition failed");
      })
      .finally(function () {
        gAreaDeRiesgo.selectAll("path").attr("d", function (d, i) {
          let banda = banda1;
          switch (i) {
            case 0:
              banda = banda4;
              break;
            case 1:
              banda = banda3;
              break;
            case 2:
              banda = banda2;
              break;
            default:
              break;
          }
          return banda;
        });
      });
  }

  function espaniaACirculo() {
    ocultarCapas();

    gCirculo.interrupt().style("opacity", 1);
    let dCirculo = defs.select("#circle-fixed").attr("d");

    gCirculo
      .interrupt()
      .select("path")
      .interrupt()
      .style("opacity", 1)
      .style("stroke", "#55aaaa")
      .style("stroke-width", "1px")
      .attr("stroke-linejoin", "round")
      .transition()
      .duration(duracion)
      .style("stroke-width", "8px")
      .style("stroke", constantes.azul[4])
      .attrTween("d", function () {
        return interpoladorEspaniaACirculo;
      })
      .end()
      .catch((e) => {
        //console.log("espaniaACirculo Transition failed");
      })
      .finally(function () {
        gCirculo
          .select("path")
          .interrupt()
          .attr("stroke-linejoin", null)
          .attr("d", dCirculo);
      });
  }

  function circuloAEspania() {
    ocultarCapas();

    gCirculo.interrupt().style("opacity", 1);
    let dCirculo = defs.select("#circle-fixed").attr("d");
    let dSpain = defs.select("#spain-border-fixed").attr("d");

    gCirculo
      .select("path")
      .interrupt()
      .style("opacity", 1)
      .style("stroke-width", "8px")
      .style("stroke", constantes.azul[4])
      .attr("stroke-linejoin", "round")
      .transition()
      .duration(duracion)
      .style("stroke", "#55aaaa")
      .style("stroke-width", "1px")
      .attrTween("d", function () {
        return interpoladorCirculoAEspania;
      })
      .end()
      .catch((e) => {
        //console.log("circuloAEspania 1 Transition failed");
      })
      .finally(function () {
        gCirculo
          .interrupt()
          .style("opacity", 0)
          .select("path")
          .interrupt()
          .attr("stroke-linejoin", null)
          .style("stroke-width", "8px")
          .style("stroke", constantes.azul[4])
          .attr("d", dCirculo)
          .style("opacity", 0);
        gSpain.interrupt().style("opacity", 1);
        gRios
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
        gBuffersJuntos
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
        gInundacionesJuntas
          .interrupt()
          .transition()
          .duration(duracion * 0.5)
          .style("opacity", 1);
      });
    gImages
      .select("#spain-background")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", constantes.espania.opacity);

    defs
      .select("#spain-border")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", 1)
      .attrTween("d", function () {
        return interpoladorCirculoAEspania;
      })
      .end()
      .catch((e) => {
        //console.log("circuloAEspania 2 Transition failed");
      })
      .finally(function () {
        defs.select("#spain-border").interrupt().attr("d", dSpain);
      });
  }

  function mostrarCapas(clave, direccion) {
    capas.anterior = capas.actual;
    capas.actual = clave;

    if (capas.actual === null || capas.anterior === null) {
      definido = false;
    } else {
      definido = true;
    }

    if (definido) {
      if (
        capas.anterior == "sevilla" &&
        capas.actual == "guadalquivir_rivers" &&
        isReduced !== true
      ) {
        agrandarGuadalquivir();
        return 0;
      }
      if (
        capas.anterior == "guadalquivir_rivers" &&
        capas.actual == "sevilla" &&
        isReduced !== true
      ) {
        achicarGuadalquivir();
        return 0;
      }

      if (
        capas.anterior == "guadalquivir_rivers" &&
        capas.actual == "risk_area" &&
        isReduced !== true
      ) {
        guadalquivirACuenca();
        return 0;
      }
      if (
        capas.anterior == "risk_area" &&
        capas.actual == "guadalquivir_rivers" &&
        isReduced !== true
      ) {
        cuencaAGuadalquivir();
        return 0;
      }

      if (
        capas.anterior == "population_at_risk" &&
        capas.actual == "together" &&
        isReduced !== true
      ) {
        cuencaAEspania();
        return 0;
      }
      if (
        capas.anterior == "together" &&
        capas.actual == "population_at_risk" &&
        isReduced !== true
      ) {
        espaniaACuenca();
        return 0;
      }

      if (
        capas.anterior == "together" &&
        capas.actual == "circle" &&
        isReduced !== true
      ) {
        espaniaACirculo();
        return 0;
      }
      if (
        capas.anterior == "circle" &&
        capas.actual == "together" &&
        isReduced !== true
      ) {
        circuloAEspania();
        return 0;
      }

      mostrarCapasEspania(clave, direccion);
      mostrarCapasGuadalquivirMini(clave, direccion);
      mostrarCapasGuadalquivir(clave, direccion);
      mostrarCapasAreaDeRiesgo(clave, direccion);
      mostrarCapasCirculo(clave, direccion);
    }
  }

  function mostrarCapasEspania(clave, direccion) {
    let opacitySpain =
      [
        "base",
        "rivers",
        "flooding",
        "buffers",
        "guadalquivir_mini",
        "cordoba",
        "sevilla",
        "together",
      ].indexOf(clave) >= 0
        ? 1
        : 0;
    let opacityRios =
      [
        "rivers",
        "flooding",
        "buffers",
        "guadalquivir_mini",
        "cordoba",
        "sevilla",
        "together",
      ].indexOf(clave) >= 0
        ? constantes.rios.opacity
        : 0;
    let opacityInundaciones =
      [
        "flooding",
        "buffers",
        "guadalquivir_mini",
        "cordoba",
        "sevilla",
      ].indexOf(clave) >= 0
        ? 1
        : 0;
    let opacityBuffers =
      ["buffers", "guadalquivir_mini", "cordoba", "sevilla"].indexOf(clave) >= 0
        ? 1
        : 0;
    let opacityLandmarks =
      ["base", "rivers", "flooding"].indexOf(clave) >= 0 ? 1 : 0;
    let opacityBuffersJuntos = ["together"].indexOf(clave) >= 0 ? 1 : 0;
    let opacityInundacionesJuntas = ["together"].indexOf(clave) >= 0 ? 1 : 0;

    let opacitySpainBorder =
      [
        "base",
        "rivers",
        "flooding",
        "buffers",
        "guadalquivir_mini",
        "cordoba",
        "sevilla",
        "together",
      ].indexOf(clave) >= 0
        ? 1
        : 0;
    let opacitySpainBackground =
      [
        "base",
        "rivers",
        "flooding",
        "buffers",
        "guadalquivir_mini",
        "cordoba",
        "sevilla",
        "together",
      ].indexOf(clave) >= 0
        ? constantes.espania.opacity
        : 0;

    let d = defs.select("#spain-border-fixed").attr("d");
    defs.select("#spain-border").attr("d", d);
    gSpain.select("path").interrupt().attr("d", d);

    gSpain
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacitySpain);

    gRios
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityRios);
    gInundaciones
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityInundaciones);
    gBuffers
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityBuffers);
    gLandmarks
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityLandmarks);
    gBuffersJuntos
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityBuffersJuntos);
    gInundacionesJuntas
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityInundacionesJuntas);

    defs
      .select("#spain-border")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacitySpainBorder);
    gImages
      .select("#spain-background")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacitySpainBackground);
  }

  function mostrarCapasGuadalquivirMini(clave, direccion) {
    let opacityGuadalquivirMini =
      ["guadalquivir_mini", "cordoba", "sevilla"].indexOf(clave) >= 0 ? 1 : 0;
    let opacityRiosGuadalquivirMini =
      ["guadalquivir_mini", "cordoba", "sevilla"].indexOf(clave) >= 0 ? 1 : 0;
    let opacityBuffersGuadalquivirMini =
      ["guadalquivir_mini", "cordoba", "sevilla"].indexOf(clave) >= 0 ? 1 : 0;
    let opacityInundacionesGuadalquivirMini =
      ["guadalquivir_mini", "cordoba", "sevilla"].indexOf(clave) >= 0 ? 1 : 0;
    let opacityCordoba = ["cordoba", "sevilla"].indexOf(clave) >= 0 ? 1 : 0;
    let opacitySevilla = ["sevilla"].indexOf(clave) >= 0 ? 1 : 0;

    let opacityGuadalquivirMiniBorder =
      ["guadalquivir_mini", "cordoba", "sevilla"].indexOf(clave) >= 0 ? 0.8 : 0;

    let opacityRectGuadalquivirMini =
      ["guadalquivir_mini", "cordoba", "sevilla"].indexOf(clave) >= 0 ? 0.7 : 0;

    defs
      .select("#guadalquivir-mini-border")
      .interrupt()
      .attr("d", geoPathSpain);
    gGuadalquivirMini.selectAll("path").interrupt().attr("d", geoPathSpain);

    gGuadalquivirMini
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityGuadalquivirMini);
    gRiosGuadalquivirMini
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityRiosGuadalquivirMini);
    gBuffersGuadalquivirMini
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityBuffersGuadalquivirMini);
    gInundacionesGuadalquivirMini
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityInundacionesGuadalquivirMini);

    gCordoba
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityCordoba);
    gSevilla
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacitySevilla);

    defs
      .select("#guadalquivir-mini-border")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityGuadalquivirMiniBorder);

    gGuadalquivirMini
      .select("rect")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityRectGuadalquivirMini);
  }

  function mostrarCapasGuadalquivir(clave, direccion) {
    let opacityGuadalquivir =
      ["guadalquivir_rivers"].indexOf(clave) >= 0 ? 1 : 0;
    let opacityRiosGuadalquivir =
      ["guadalquivir_rivers"].indexOf(clave) >= 0 ? constantes.rios.opacity : 0;
    let opacityInundacionesGuadalquivir =
      ["guadalquivir_rivers"].indexOf(clave) >= 0 ? 1 : 0;
    let opacityBuffersGuadalquivir =
      ["guadalquivir_rivers"].indexOf(clave) >= 0 ? 1 : 0;
    let opacityPuntosGuadalquivir =
      ["guadalquivir_rivers"].indexOf(clave) >= 0 ? 1 : 0;

    let opacityGuadalquivirBorder =
      ["guadalquivir_rivers"].indexOf(clave) >= 0 ? 1 : 0;
    let opacityGuadalquivirBackground =
      ["guadalquivir_rivers"].indexOf(clave) >= 0 ? 1 : 0;

    defs
      .select("#guadalquivir-border")
      .interrupt()
      .attr("d", geoPathGuadalquivir);
    gGuadalquivir.selectAll("path").interrupt().attr("d", geoPathGuadalquivir);

    gGuadalquivir
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityGuadalquivir);
    gRiosGuadalquivir
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityRiosGuadalquivir);
    gInundacionesGuadalquivir
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityInundacionesGuadalquivir);
    gBuffersGuadalquivir
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityBuffersGuadalquivir);
    gLandmarksGuadalquivir
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityPuntosGuadalquivir);

    defs
      .select("#guadalquivir-border")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityGuadalquivirBorder);
    gImages
      .select("#guadalquivir-background")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityGuadalquivirBackground);
  }

  function mostrarCapasAreaDeRiesgo(clave, direccion) {
    let opacityAreaDeRiesgo =
      [
        "risk_area",
        "risk_area_sanlucar",
        "risk_area_marshes",
        "risk_area_municipalities",
        "risk_area_park",
        "risk_area_points",
        "property_damage",
        "population_at_risk",
      ].indexOf(clave) >= 0
        ? 1
        : 0;

    let opacityAreaMarismas =
      [
        "risk_area_marshes",
        "risk_area_municipalities",
        "risk_area_park",
        "risk_area_points",
        "property_damage",
        "population_at_risk",
      ].indexOf(clave) >= 0
        ? 1
        : 0;

    let opacityAreaDonana =
      [
        "risk_area_park",
        "risk_area_points",
        "property_damage",
        "population_at_risk",
      ].indexOf(clave) >= 0
        ? 1
        : 0;

    let opacityInundacionesAreaDeRiesgo =
      [
        "risk_area",
        "risk_area_sanlucar",
        "risk_area_marshes",
        "risk_area_municipalities",
        "risk_area_park",
        "risk_area_points",
        "property_damage",
        "population_at_risk",
      ].indexOf(clave) >= 0
        ? 1
        : 0;

    let opacityPuntosInundacionAreaDeRiesgo =
      ["risk_area_points"].indexOf(clave) >= 0 ? 1 : 0;

    let opacityPropiedadAreaDeRiesgo =
      ["property_damage"].indexOf(clave) >= 0 ? 1 : 0;

    let opacityPoblacionAreaDeriesgo =
      ["population_at_risk"].indexOf(clave) >= 0 ? 1 : 0;

    let opacityEtiquetasAreaDeRiesgo =
      [
        "risk_area",
        "risk_area_sanlucar",
        "risk_area_marshes",
        "risk_area_municipalities",
        "risk_area_park",
        "risk_area_points",
        "property_damage",
        "population_at_risk",
      ].indexOf(clave) >= 0
        ? 1
        : 0;

    let opacityLeyendaPuntosAreaDeRiesgo =
      ["risk_area_points"].indexOf(clave) >= 0 ? 1 : 0;

    let opacityLeyendaPoblacionAreaDeriesgo =
      ["population_at_risk"].indexOf(clave) >= 0 ? 1 : 0;

    let opacityLeyendaPropiedadAreaDeRiesgo =
      ["property_damage"].indexOf(clave) >= 0 ? 1 : 0;

    let opacitySanlucar =
      [
        "risk_area_sanlucar",
        "risk_area_marshes",
        "risk_area_municipalities",
        "risk_area_park",
        "risk_area_points",
        "property_damage",
        "population_at_risk",
      ].indexOf(clave) >= 0
        ? 1
        : 0;

    let opacityIsla =
      [
        "risk_area_municipalities",
        "risk_area_park",
        "risk_area_points",
        "property_damage",
        "population_at_risk",
      ].indexOf(clave) >= 0
        ? 1
        : 0;

    let opacityPuebla =
      [
        "risk_area_municipalities",
        "risk_area_park",
        "risk_area_points",
        "property_damage",
        "population_at_risk",
      ].indexOf(clave) >= 0
        ? 1
        : 0;

    let opacityPoblado =
      [
        "risk_area_municipalities",
        "risk_area_park",
        "risk_area_points",
        "property_damage",
        "population_at_risk",
      ].indexOf(clave) >= 0
        ? 1
        : 0;

    let opacityRiskAreaBorder =
      [
        "risk_area",
        "risk_area_sanlucar",
        "risk_area_marshes",
        "risk_area_municipalities",
        "risk_area_park",
        "risk_area_points",
        "property_damage",
        "population_at_risk",
      ].indexOf(clave) >= 0
        ? 1
        : 0;

    let opacityRiskAreaBackground =
      [
        "risk_area",
        "risk_area_sanlucar",
        "risk_area_marshes",
        "risk_area_municipalities",
        "risk_area_park",
        "risk_area_points",
        "property_damage",
        "population_at_risk",
      ].indexOf(clave) >= 0
        ? 1
        : 0;

    let dArea = defs.select("#risk-area-border-fixed").attr("d");
    let dAreaInner = defs
      .select("#risk-area-inner-border-fixed")
      .interrupt()
      .attr("d");

    defs.select("#risk-area-border").interrupt().attr("d", dArea);
    defs.select("#risk-area-inner-border").interrupt().attr("d", dAreaInner);
    gAreaDeRiesgo
      .selectAll("g")
      .interrupt()
      .interrupt()
      .attr("d", geoPathAreaDeRiesgo);

    gAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityAreaDeRiesgo);
    gAreaMarismasBG
      .select("path")
      .interrupt()
      .transition()
      .duration(duracion)
      .attr("fill", function (d) {
        d.properties.fill.opacity = opacityAreaMarismas;
        return d.properties.fill;
      });
    gAreaMarismas
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityAreaMarismas);
    gAreaDonanaBG
      .select("path")
      .interrupt()
      .transition()
      .duration(duracion)
      .attr("fill", function (d) {
        d.properties.fill.opacity = opacityAreaDonana;
        return d.properties.fill;
      });
    gAreaDonana
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityAreaDonana);
    gInundacionesAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityInundacionesAreaDeRiesgo);
    gPuntosInundacionAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityPuntosInundacionAreaDeRiesgo);
    gPropiedadAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityPropiedadAreaDeRiesgo);
    gPoblacionAreaDeriesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityPoblacionAreaDeriesgo);
    gLeyendaPuntosAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityLeyendaPuntosAreaDeRiesgo);
    gEtiquetasAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityEtiquetasAreaDeRiesgo);
    gLeyendaPoblacionAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityLeyendaPoblacionAreaDeriesgo);
    gLeyendaPropiedadAreaDeRiesgo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityLeyendaPropiedadAreaDeRiesgo);

    if (gSanlucar !== null) {
      gSanlucar
        .interrupt()
        .transition()
        .duration(duracion)
        .style("opacity", opacitySanlucar);
    }
    if (gIsla !== null) {
      gIsla
        .interrupt()
        .transition()
        .duration(duracion)
        .style("opacity", opacityIsla);
    }
    if (gPuebla !== null) {
      gPuebla
        .interrupt()
        .transition()
        .duration(duracion)
        .style("opacity", opacityPuebla);
    }
    if (gPoblado !== null) {
      gPoblado
        .interrupt()
        .transition()
        .duration(duracion)
        .style("opacity", opacityPoblado);
    }

    defs
      .select("#risk-area-border")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityRiskAreaBorder);
    gAreaDeRiesgo
      .select("#risk-area-background")
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityRiskAreaBackground);
  }

  function mostrarCapasCirculo(clave, direccion) {
    let opacityCirculo = ["circle"].indexOf(clave) >= 0 ? 1 : 0;

    let d = defs.select("#circle-fixed").attr("d");
    gCirculo.select("path").interrupt().attr("d", d);

    gCirculo
      .interrupt()
      .transition()
      .duration(duracion)
      .style("opacity", opacityCirculo);
  }

  setup();
});
