windowReady.ready(function () {
  let duracion = isMobile.phone ? 600 : 400;
  let debounceTimer = 200;
  let debouncedCalcularMedidas = null;

  let scroller1 = null;
  let scroller2 = null;
  let capas = { anterior: null, actual: null };
  let isReduced = null;

  let container_5_1 = null;

  let svgs = [];

  let width = 100;
  let height = 140;

  let switchValue = "above";
  let switchShape = "bubble";

  let synth = null;
  let sample = null;

  let escalaRadio = d3.scaleSqrt().range([0, 40]);
  let escalaOndasX = d3.scaleLinear().range([0, width]);
  let escalaOndasY = d3.scaleLinear().range([height - width + 50, height + 50]);

  let escalaColor = d3.scaleSequential(d3.interpolateReds);
  let escalaNotas = null;

  function setup() {
    container_5_1 = d3.select("div[data-container='story-5-1']");
    if (container_5_1.node()) {
      isReduced =
        window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
        window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
      if (isReduced) {
        duracion = 0;
      }
      setupHistoria();
      debouncedCalcularMedidas = _.debounce(calcularMedidas, debounceTimer);
      window.onresize = debouncedCalcularMedidas;
    }
  }

  function calcularMedidas() {
    calcularAlturaSVG();
    scroller1.resize();
    scroller2.resize();
  }

  function isMobileSize() {
    return window.innerWidth <= 480;
  }

  function calcularAlturaSVG() {
    let box = container_5_1.node().getBoundingClientRect();
    let root = document.documentElement;
    if (isMobileSize()) {
      root.style.setProperty(
        "--svg-height",
        `calc(8rem + ${2 * box.height}px)`
      );
    } else {
      root.style.setProperty("--svg-height", `calc(8rem + ${box.height}px)`);
    }
  }

  function setupHistoria() {
    setupAudio();
    d3.select("#switch").on("click", function (d) {
      cambiarSwitch();
    });
    d3.select("#form").on("click", function (d) {
      cambiarForma();
    });
    fetchDatos();
  }

  function cambiarSwitch() {
    switchValue = switchValue == "above" ? "below" : "above";
    actualizarCirculos();
    actualizarOndas();
  }

  function cambiarForma() {
    switchShape = switchShape == "bubble" ? "wave" : "bubble";
    if (switchShape == "bubble") {
      cambiarFormaACirculos();
    } else {
      cambiarFormaAOndas();
    }
  }

  function actualizarCirculos() {
    console.log(svgs);

    svgs
      .select("g")
      .select("text")
      .text((d) => d.year);

    svgs
      .select("g")
      .selectAll("circle")
      .transition("circle:update")
      .duration(duracion)
      .attr("r", function (d) {
        let obj = _.find(d[switchValue], { year: d.year });
        return escalaRadio(obj.value);
      });

    container_5_1
      .select("div.grid")
      .selectAll("div")
      .select("svg")
      .select("defs")
      .select("radialGradient")
      .selectAll("stop")
      .transition("gradient:update")
      .duration(duracion)
      .attr("stop-color", function (d, i) {
        let color = "#ffffff";
        switch (i) {
          case 1:
            color = getColorIntermedio();
            break;
          case 2:
            color = getColor();
            break;
          default:
            break;
        }
        return color;
      });
  }

  function setupAudio() {
    Tone.start();
    //synth = new Tone.Synth().toDestination();
    synth = new Tone.PolySynth(Tone.Synth).toDestination();
    synth.volume.value = -5;
  }

  function getSample() {
    return new Promise(function (resolveFn, rejectFn) {
      sample = new Tone.Player({
        url: "assets/story5/sample.wav",
        loop: true,
      })
        .toDestination()
        .sync()
        .start(0);
      Tone.loaded().then(() => {
        resolveFn(0);
      });
    });
  }

  function fetchDatos() {
    Promise.all([d3.json("json/story5/noise.json", getSample())])
      .then(function ([paises, test]) {
        paises = _.sortBy(paises, "order");
        _.each(paises, function (p) {
          p.above = _.filter(p.above, function (d) {
            return !_.isNull(d.value);
          });
          p.below = _.filter(p.below, function (d) {
            return !_.isNull(d.value);
          });
          p.year = p[switchValue][0].year;
        });

        let params = { paises: paises };

        calcularDominioEscalaRadio(params);
        calcularDominioEscalasOnda(params);
        setEscalaNotas();
        crearSecuencias(params);
        Tone.Transport.bpm.value = 100;
        Tone.Transport.loop = true;
        Tone.Transport.loopStart = "4m";
        Tone.Transport.loopEnd = "8m";

        crearGruposRuido(params);
        crearCirculos();
        crearOndas();
      })
      .catch((e) => {
        console.log("fetchDatos: failed");
        console.log(e);
      });
  }

  function calcularDominioEscalaRadio(params) {
    let paises = params.paises;

    let valores = _.map(paises, function (d) {
      let above = _.map(d.above, "value");
      let below = _.map(d.below, "value");
      return _.flatten(above, below);
    });
    valores = _.flatten(valores);
    let max = _.max(valores);
    escalaRadio.domain([0, max]);
  }

  function calcularDominioEscalasOnda(params) {
    let paises = params.paises;

    let valores = _.map(paises, function (d) {
      let above = _.map(d.above, "value");
      let below = _.map(d.below, "value");
      return _.flatten(above, below);
    });
    valores = _.flatten(valores);
    let max = _.max(valores);
    escalaOndasY.domain([0, max]);

    valores = _.map(paises, function (d) {
      let above = _.map(d.above, "year");
      let below = _.map(d.below, "year");
      return _.flatten(above, below);
    });
    valores = _.flatten(valores);
    escalaOndasX.domain(d3.extent(valores));
  }

  function setEscalaNotas() {
    let dominio = escalaRadio.domain();
    let rango = ["C2", "D2", "E2", "G2", "A2", "C3", "D3", "E3", "G3", "A3"];

    escalaNotas = d3.scaleQuantize(dominio, rango);
  }

  function crearGruposRuido(params) {
    let paises = params.paises;
    let div = container_5_1.append("div").attr("class", "grid");

    let divs = div.selectAll("div").data(paises).enter().append("div");

    divs
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${width} ${height}`);

    divs.select("svg").append("defs");

    svgs = divs
      .select("svg")
      .append("g")
      .attr("class", "svg")
      .datum((d) => d);
  }

  function crearOndas() {
    /*
    svgs
      .select("g")
      .append("rect")
      .attr("fill", "red")
      .attr("x", 0)
      .attr("y", height - width)
      .attr("width", width)
      .attr("height", width)
      .style("opacity", 0.2);

    svgs
      .select("g")
      .append("line")
      .attr("x1", 0)
      .attr("y1", height - width + width / 2)
      .attr("x2", width)
      .attr("y2", height - width + width / 2)
      .style("stroke", "black");
*/
    let pathsAbove = svgs
      .select("g")
      .append("path")
      .attr("class", "above")
      .datum((d) => d.above)
      .attr("fill", "#d78f85")
      .attr("stroke", "#b0200b")
      .attr("stroke-width", 1)
      .attr(
        "d",
        d3
          .area()
          .curve(d3.curveCatmullRom)
          .x((d) => escalaOndasX(d.year))
          .y0((d) => escalaOndasY(-d.value))
          .y1((d) => escalaOndasY(d.value))
      )
      .call(hide);

    let pathsBelow = svgs
      .select("g")
      .append("path")
      .attr("class", "below")
      .datum((d) => d.below)
      .attr("fill", "#d07bfc")
      .attr("stroke", "#b224fa")
      .attr("stroke-width", 1)
      .attr(
        "d",
        d3
          .area()
          .curve(d3.curveCatmullRom)
          .x((d) => escalaOndasX(d.year))
          .y0((d) => escalaOndasY(-d.value))
          .y1((d) => escalaOndasY(d.value))
      )
      .call(hide);

    svgs.each(function (d, i) {
      //let root = d3.select(this.closest("svg"));
      let svg = d3.select(this);
      let above = svg.select("g").select("path.above");
      let below = svg.select("g").select("path.below");
      d.paths = { above: above.attr("d"), below: below.attr("d") };
      //above.remove();
      //below.remove();
    });
  }

  function crearCirculos() {
    let x = width / 2;
    let y = height - width + width / 2;

    svgs.each(function (d, i) {
      let defs = d3.select(this.previousSibling);

      let gradient = defs
        .append("radialGradient")
        .attr("id", `gggrain-gradient-${i}`)
        .attr("r", "0.65");

      gradient
        .append("stop")
        .attr("offset", "30%")
        .attr("stop-color", "#ffffff");
      gradient
        .append("stop")
        .attr("offset", "55%")
        .attr("stop-color", getColorIntermedio());
      gradient
        .append("stop")
        .attr("offset", "70%")
        .attr("stop-color", getColor());

      let filter = defs
        .append("filter")
        .attr("id", `gggrain-filter-${i}`)
        .attr("x", "0%")
        .attr("y", "0%")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("filterUnits", "objectBoundingBox")
        .attr("primitiveUnits", "userSpaceOnUse")
        .attr("color-interpolation-filters", "sRGB");

      filter
        .append("feTurbulence")
        .attr("type", "fractalNoise")
        .attr("baseFrequency", "02.50")
        .attr("numOctaves", "2")
        .attr("seed", "2")
        .attr("stitchTiles", "stitch")
        .attr("x", "0%")
        .attr("y", "0%")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("result", "turbulence");

      filter
        .append("feColorMatrix")
        .attr("type", "saturate")
        .attr("values", "0")
        .attr("x", "0%")
        .attr("y", "0%")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("in", "turbulence")
        .attr("result", "colormatrix");

      let component = filter
        .append("feComponentTransfer")
        .attr("x", "0%")
        .attr("y", "0%")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("in", "colormatrix")
        .attr("result", "componentTransfer");

      component.append("feFuncR").attr("type", "linear").attr("slope", "3");
      component.append("feFuncG").attr("type", "linear").attr("slope", "3");
      component.append("feFuncB").attr("type", "linear").attr("slope", "3");

      filter
        .append("feColorMatrix")
        .attr("x", "0%")
        .attr("y", "0%")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("in", "componentTransfer")
        .attr("result", "colormatrix2")
        .attr("type", "matrix")
        .attr(
          "values",
          "1 0 0 0 0           0 1 0 0 0           0 0 1 0 0           0 0 0 15 -7"
        );
    });

    let titulos = svgs
      .append("text")
      .attr("x", x)
      .attr("y", 20)
      .attr("text-anchor", "middle")
      .attr("font-family", "Instrument Serif")
      .attr("font-size", 12)
      .attr("font-weight", 400)
      .text((d) => d.name);

    let grupos = svgs.append("g"); //.attr("transform", `translate(${x},${y})`);

    grupos
      .append("circle")
      .attr("class", "base")
      .attr("cx", x)
      .attr("cy", y)
      .attr("r", getRadio)
      .attr("fill", (d, i) => `url(#gggrain-gradient-${i})`);

    grupos
      .append("circle")
      .attr("class", "noise")
      .attr("cx", x)
      .attr("cy", y)
      .attr("r", getRadio)
      .attr("fill", "transparent")
      .attr("filter", (d, i) => `url(#gggrain-filter-${i})`)
      .attr("opacity", 0.85)
      .style("mix-blend-mode", "soft-light")
      .on("mouseover", function (e, d) {
        reproducirTonada(d3.select(this), d);
      })
      .on("mouseout", function (e, d) {
        detenerTonada(d);
      });

    grupos
      .append("text")
      .attr("x", x)
      .attr("y", y)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("font-size", 8)
      .attr("font-weight", 400)
      .text((d) => d.year);
  }

  function getRadio(d) {
    let obj = _.find(d[switchValue], { year: d.year }) || {
      year: d.year,
      value: null,
    };
    let value = _.isNull(obj.value) ? 0 : obj.value;
    return escalaRadio(value);
  }

  function getColorIntermedio() {
    return switchValue == "above" ? "#d78f85" : "#d07bfc";
  }

  function getColor() {
    return switchValue == "above" ? "#b0200b" : "#b224fa";
  }

  function crearSecuencias(params) {
    let paises = params.paises;
    _.each(paises, function (p) {
      p.secuencias = { above: null, below: null };
      let versiones = ["above", "below"];
      _.each(versiones, function (v) {
        let arreglo = p[v];
        let valores = _.map(arreglo, "value");

        valores = _.filter(valores, function (v) {
          return !_.isNull(v);
        });
        escalaNotas.domain(d3.extent(valores));

        let objetos = _.filter(p[v], function (o) {
          return !_.isNull(o.value);
        });

        _.each(objetos, function (o) {
          o.note = escalaNotas(o.value);
        });

        p.secuencias[v] = new Tone.Sequence(
          (time, o) => {
            console.log(
              `version: ${v} - year: ${o.year} - value: ${o.value} - note: ${o.note} - time: ${time}`
            );
            p.year = o.year;
            ajustarRadioCirculo(p.name, v, o.year, o.value);
            synth.triggerAttackRelease(o.note, "1b", time);
            //synth.triggerAttackRelease(o.note, time, 0.3);
          },
          objetos,
          "4n"
        );
      });
    });
    console.log(paises);
  }

  function ajustarRadioCirculo(name, version, year, value) {
    let svg = svgs.filter((d) => d.name === name);
    console.log(svg);
    svg.select("g").select("text").text(year);
    svg
      .select("g")
      .selectAll("circle")
      .transition("circle:update")
      .duration(450)
      .attr("r", escalaRadio(value));
  }

  function detenerTonada(d) {
    d.secuencias[switchValue].stop();
    Tone.Transport.stop();
  }

  function reproducirTonada(el, d) {
    console.log(d.secuencias[switchValue]);
    d.secuencias[switchValue].stop();
    d.secuencias[switchValue].start();
    Tone.Transport.start();
  }

  function cambiarFormaACirculos0() {
    svgs.each(function (d, i) {
      let root = d3.select(this.closest("svg"));

      let x = width / 2;
      let y = height - width + width / 2;
      let r = getRadio(d);

      let interpolador = flubber.toCircle(d.paths[switchValue], x, y, r);
    });
  }

  function cambiarFormaACirculos() {
    svgs.each(function (d, i) {
      let svg = d3.select(this);
      svg.select("g").selectAll("circle").call(unhide);
      svg.select("g").select(`path.${switchValue}`).call(hide);
    });
  }

  function cambiarFormaAOndas() {
    svgs.each(function (d, i) {
      let svg = d3.select(this);
      svg.select("g").selectAll("circle").call(hide);
      svg.select("g").select(`path.${switchValue}`).call(unhide);
    });
  }

  function actualizarOndas() {
    if (switchShape == "wave") {
      if (switchValue == "above") {
        svgs.select("g").select(`path.above`).call(unhide);
        svgs.select("g").select(`path.below`).call(hide);
      } else {
        svgs.select("g").select(`path.above`).call(hide);
        svgs.select("g").select(`path.below`).call(unhide);
      }
    }
  }

  function reproduceTonada1(d) {
    let arreglo = d[switchValue];
    let valores = _.map(arreglo, "value");
    valores = _.filter(valores, function (v) {
      return !_.isNull(v);
    });
    escalaNotas.domain(d3.extent(valores));

    Tone.Transport.bpm.value = 108;
    Tone.Transport.loop = true;
    Tone.Transport.loopStart = "4m";
    Tone.Transport.loopEnd = "8m";

    let now = Tone.now();

    new Tone.Loop((time) => {
      _.each(valores, function (v, i) {
        synth.triggerAttackRelease(escalaNotas(v), 0.75, now + 0.35 * i);
      });
    }, valores.length * 0.75).start(0);

    Tone.Transport.start();
  }

  function reproduceTonada0(d) {
    console.log("reproducirTonada");
    console.log(sample);
    sample.start();
    let arreglo = d[switchValue];
    let valores = _.map(arreglo, "value");
    valores = _.filter(valores, function (v) {
      return !_.isNull(v);
    });
    escalaNotas.domain(d3.extent(valores));

    let now = Tone.now();
    console.log(`now: ${now}`);
    _.each(valores, function (v, i) {
      //console.log(`valor: ${v} - nota: ${escalaNotas(v)}`);
      synth.triggerAttackRelease(escalaNotas(v), 0.75, now + 0.35 * i);
    });
  }

  function mostrarCapasUp(response) {
    if (response.direction == "up") {
      mostrarCapas(response.element.getAttribute("data-capas"));
    }
  }

  function mostrarCapasDown(response) {
    if (response.direction == "down") {
      mostrarCapas(response.element.getAttribute("data-capas"));
    }
  }

  function crearScroller() {
    let elementos = d3.selectAll("[data-capas]");
    if (elementos.size() > 0) {
      let debouncedMostrarCapasUp = _.debounce(mostrarCapasUp, debounceTimer);
      let debouncedMostrarCapasDown = _.debounce(
        mostrarCapasDown,
        debounceTimer
      );

      scroller1 = scrollama();
      scroller1
        .setup({
          step: "[data-capas]",
          offset: 0.9,
          debug: false,
        })
        .onStepEnter(debouncedMostrarCapasDown);

      scroller2 = scrollama();
      scroller2
        .setup({
          step: "[data-capas]",
          offset: 0.2,
          debug: false,
        })
        .onStepEnter(debouncedMostrarCapasUp);
    }
  }

  function mostrarCapas(capa) {
    capas.anterior = capas.actual;
    capas.actual = capa || null;
    console.dir(capas);

    if (capas.actual == capas.anterior) {
      return 0;
    }

    let flagTransicion = !isReduced;
    /*
    if (capas.anterior == null && capas.actual == "level_1") {
      svg.selectAll(".level_1").call(unhide);
    }

    if (capas.anterior == null && capas.actual == "level_2") {
      svg.selectAll(".level_1").call(unhide);
      svg.selectAll(".level_1_a_2").call(unhide);
      svg.selectAll(".level_2").call(unhide);
    }

    if (capas.anterior == null && capas.actual == "level_3") {
      svg.selectAll(".level_1").call(unhide);
      svg.selectAll(".level_1_a_2").call(unhide);
      svg.selectAll(".level_2").call(unhide);
      svg.selectAll(".level_2_a_3").call(unhide);
      svg.selectAll(".level_3").call(unhide);
      svg.selectAll(".level_3_a_4").call(unhide);
      svg.selectAll(".level_4").call(unhide);
    }

    if (capas.anterior == "level_1" && capas.actual == "level_2") {
      transicionNivel_1_a_2(flagTransicion);
    }

    if (capas.anterior == "level_1" && capas.actual == "level_3") {
      transicionNivel_1_a_3(flagTransicion);
    }

    if (capas.anterior == "level_2" && capas.actual == "level_3") {
      transicionNivel_2_a_3(flagTransicion);
    }

    if (capas.anterior == "level_3" && capas.actual == "level_2") {
      transicionNivel_3_a_2(flagTransicion);
    }

    if (capas.anterior == "level_3" && capas.actual == "level_1") {
      transicionNivel_3_a_1(flagTransicion);
    }

    if (capas.anterior == "level_2" && capas.actual == "level_1") {
      transicionNivel_2_a_1(flagTransicion);
    }
*/
  }

  function hide(el) {
    el.style("display", "none").style("opacity", 0);
  }

  function unhide(el) {
    el.style("display", null).style("opacity", null);
  }

  setup();
});
