windowReady.ready(function () {
  let width = 1580;
  let height = 880;
  height = 960;

  let duracion = isMobile.phone ? 600 : 400;
  let debounceTimer = 200;

  let scroller1 = null;
  let scroller2 = null;
  let capas = { anterior: null, actual: null };
  let isReduced = null;

  let rotateArray = [-3.337711684132892, -46.7520939522693, 0];
  let container = null;
  let sensitivity = 75;
  let projectionGlobe = d3
    .geoOrthographic()
    .scale(450)
    .center([0, 0])
    .rotate(rotateArray)
    .translate([width / 2, height / 2]);

  let projectionFrance = d3
    .geoOrthographic()
    .scale(5000)
    .center([0, 0])
    .rotate(rotateArray)
    .translate([width / 2, height / 2]);

  let pathGlobe = d3.geoPath().projection(projectionGlobe);
  let pathFrance = d3.geoPath().projection(projectionFrance);

  let escalaColorCirculos = d3
    .scaleQuantize()
    .range(["#deebf7", "#9ecae1", "#3182bd"]);
  window.escalaColorCirculos = escalaColorCirculos;
  let escalaCirculos = d3.scaleSqrt().range([5, 50]);
  let escalaFlechas = d3.scaleLog().range([1, 20]);

  let timerGlobe = null;

  let sankeyPrincipalBox = new Box({
    size: { width: width, height: height },
    padding: { top: 100, right: 180, bottom: 50, left: 180 },
  });
  let sankeyPrincipal = d3
    .sankey()
    .nodeId((d) => d.code)
    .nodeWidth(40)
    .nodePadding(5)
    .size([
      sankeyPrincipalBox.content.width,
      sankeyPrincipalBox.content.height,
    ]);

  let lineasPrincipalBox = new Box({
    size: { width: width, height: height },
    padding: {
      top: 100,
      right: 180 + sankeyPrincipal.nodeWidth(),
      bottom: 50,
      left: 180 + sankeyPrincipal.nodeWidth(),
    },
  });

  let escalaX = d3
    .scaleLinear()
    .range([
      sankeyPrincipal.nodeWidth(),
      lineasPrincipalBox.content.width + sankeyPrincipal.nodeWidth(),
    ]);
  let escalaY = d3.scaleLinear().range([lineasPrincipalBox.content.height, 0]);

  let pathDomestico = d3
    .line()
    .curve(d3.curveCatmullRom.alpha(0.5))
    .x((d) => escalaX(d.year))
    .y((d) => escalaY(d.domestic));
  let pathForaneo = d3
    .line()
    .curve(d3.curveCatmullRom.alpha(0.5))
    .x((d) => escalaX(d.year))
    .y((d) => escalaY(d.foreign));

  let gap = 60;

  let miniGridBox = new Box({
    size: { width: (height - gap) / 2, height: (height - gap) / 2 },
    padding: { top: 60, right: 0, bottom: 40, left: 110 },
  });

  let formatter = new Intl.NumberFormat("en-US", {});

  let svg = null;
  let defs = null;
  let gControles = null;
  let gGlobo = null;
  let gMapa = null;
  let gSankey = null;
  let gLineas = null;
  let gMiniGrid = null;

  let cajasEtiquetas = {};

  let interpoladorFranciaANodoFrancia = null;
  let interpoladorNodoFranciaAFrancia = null;
  function crearInterpoladores(params) {
    let sankey = _.find(params.sankeysPorPais, { code: "FR" });
    let dFranciaPeninsular = defs.select("#peninsular-france").attr("d");
    let francia = _.find(sankey.nodes, { id: "SOURCE" });
    interpoladorFranciaANodoFrancia = flubber.toRect(
      dFranciaPeninsular,
      francia.x0 + sankeyPrincipalBox.padding.left,
      francia.y0 + sankeyPrincipalBox.padding.top,
      francia.x1 - francia.x0,
      francia.y1 - francia.y0
    );
    interpoladorNodoFranciaAFrancia = flubber.fromRect(
      francia.x0 + sankeyPrincipalBox.padding.left,
      francia.y0 + sankeyPrincipalBox.padding.top,
      francia.x1 - francia.x0,
      francia.y1 - francia.y0,
      dFranciaPeninsular
    );
  }

  function mostrarCapasUp(response) {
    if (response.direction == "up") {
      mostrarCapas(response.element.getAttribute("data-capas"), params);
    }
  }

  function mostrarCapasDown(response) {
    if (response.direction == "down") {
      mostrarCapas(response.element.getAttribute("data-capas"), params);
    }
  }

  function crearScroller(params) {
    let elementos = d3.selectAll("[data-capas]");
    if (elementos.size() > 0) {
      let debouncedMostrarCapasUp = _.debounce(mostrarCapasUp, debounceTimer);
      let debouncedMostrarCapasDown = _.debounce(
        mostrarCapasDown,
        debounceTimer
      );

      scroller1 = scrollama();
      scroller1
        .setup({
          step: "[data-capas]",
          offset: 0.9,
          debug: false,
        })
        .onStepEnter(debouncedMostrarCapasDown);

      scroller2 = scrollama();
      scroller2
        .setup({
          step: "[data-capas]",
          offset: 0.2,
          debug: false,
        })
        .onStepEnter(debouncedMostrarCapasUp);
    }
  }

  function setup() {
    container = d3.select("div[data-container='story-3-1']");
    if (container.node()) {
      isReduced =
        window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
        window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
      if (isReduced) {
        duracion = 0;
      }
      setupHistoria();
      let debouncedCalcularMedidas = _.debounce(calcularMedidas, debounceTimer);
      window.onresize = debouncedCalcularMedidas;
    }
  }

  function calcularMedidas() {
    calcularAlturaSVG();
    scroller1.resize();
    scroller2.resize();
  }

  function isMobileSize() {
    return window.innerWidth <= 480;
  }

  function calcularAlturaSVG() {
    let box = container.node().getBoundingClientRect();
    let root = document.documentElement;
    if (isMobileSize()) {
      root.style.setProperty(
        "--svg-height",
        `calc(8rem + ${2 * box.height}px)`
      );
    } else {
      root.style.setProperty("--svg-height", `calc(8rem + ${box.height}px)`);
    }
  }

  function setupHistoria() {
    crearSVG();
    calcularAlturaSVG();
    fetchDatos();
  }

  function crearSVG() {
    svg = container
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${width} ${height}`);

    defs = svg.append("defs");

    svg = svg.append("g").attr("class", "svg");

    gGlobo = svg.append("g").attr("id", "gGlobo");

    gMapa = svg.append("g").attr("id", "gMapa");

    gSankey = svg.append("g").attr("id", "gSankey").call(hide);

    gLineas = svg
      .append("g")
      .attr("id", "gLineas")
      .attr("class", "main")
      .call(hide);

    gControles = svg.append("g").attr("id", "gControles").call(hide);

    gMiniGrid = svg.append("g").attr("id", "gMiniGrid").call(hide);
  }

  function fetchDatos() {
    //TODO:Usar el comando "points centroid" en mapshaper para agregar centroides al mapa final
    Promise.all([
      d3.json("json/story3/globe.json"),
      d3.csv("csv/story3/trips_all.csv", function (d) {
        let origen = { code: d.code_origin, name: d.country_origin };
        let destino = {
          code: d.code_destination,
          name: d.country_destination,
        };
        return {
          origen: origen,
          destino: destino,
          num: +d.number_trips,
          member: d.member === "TRUE",
        };
      }),
      d3.csv("csv/story3/trips_from_eu.csv", function (d) {
        let origen = { code: d.code_origin, name: d.country_origin };
        let destino = {
          code: d.code_destination,
          name: d.country_destination,
        };
        return {
          origen: origen,
          destino: destino,
          num: +d.number_trips,
          member: d.member === "TRUE",
        };
      }),
      d3.csv("csv/story3/trips_from_france.csv", function (d) {
        let origen = { code: d.code_origin, name: d.country_origin };
        let destino = {
          code: d.code_destination,
          name: d.country_destination,
        };
        return {
          origen: origen,
          destino: destino,
          num: +d.number_trips,
          member: d.member === "TRUE",
        };
      }),
      d3.csv("csv/story3/trips_domestic_foreign.csv", function (d) {
        return {
          code: d.code,
          country: d.country,
          year: +d.year,
          domestic: parseInt(+d.domestic),
          foreign: parseInt(+d.foreign),
        };
      }),
    ])
      .then(function ([
        mapa,
        viajes,
        viajesDesdeUE,
        viajesDesdeFrancia,
        viajesComparados,
      ]) {
        let paises = topojson.feature(mapa, mapa.objects["globe"]);
        let france = topojson.feature(mapa, mapa.objects["france"]);
        let departamentos = topojson.feature(mapa, mapa.objects["departments"]);
        let centroides = topojson.feature(mapa, mapa.objects["centroids"]);

        centroides = _.map(centroides.features, function (d) {
          let obj = {
            code: d.properties.EC_CODE,
            coords: d.geometry,
          };
          return obj;
        });

        viajesDesdeFrancia = _.filter(viajesDesdeFrancia, (d) => d.num > 0);
        viajesDesdeFrancia = _.orderBy(viajesDesdeFrancia, ["num"], ["desc"]);
        let pathsDesdeFrancia = _.map(viajesDesdeFrancia, function (d) {
          return {
            type: "LineString",
            coordinates: [],
            properties: {
              origen: d.origen,
              destino: d.destino,
              num: d.num,
              member: d.member,
            },
          };
        });

        escalaColorCirculos.domain(
          d3.extent(
            _.map(departamentos.features, (d) => d.properties.total_trips)
          )
        );
        escalaCirculos.domain(
          d3.extent(
            _.map(departamentos.features, (d) => d.properties.total_trips)
          )
        );
        escalaFlechas.domain(
          d3.extent(_.map(pathsDesdeFrancia, (d) => d.properties.num))
        );
        escalaX.domain(getDomainX(viajesComparados));

        let sankeyUE = sankeyPrincipal(generarObjetoSankey(viajesDesdeUE));
        let viajesDesdeUEAgrupados = agrupaViajes(sankeyUE);
        sankeyUE = sankeyPrincipal(generarObjetoSankey(viajesDesdeUEAgrupados));
        sankeyUE.code = "EU";

        _.each(pathsDesdeFrancia, function (d) {
          let code = d.properties.origen.code;
          let obj = _.find(centroides, (d) => d.code === code);
          d.coordinates.push(obj.coords.coordinates);

          code = d.properties.destino.code;
          obj = _.find(centroides, (d) => d.code === code);
          d.coordinates.push(obj.coords.coordinates);
        });

        let viajesPorPais = _.groupBy(viajes, function (d) {
          return d.origen.code;
        });

        let viajesComparadosPorPais = _.groupBy(viajesComparados, function (d) {
          return d.code;
        });

        let codigo = window.location.hash.substring(1) || "AT";
        codigo = viajesPorPais.hasOwnProperty(codigo) ? codigo : "AT";

        let codigos = [];
        let sankeysPorPais = [];
        _.each(viajesPorPais, function (v, k) {
          codigos.push(k);
          let objSankey = generarObjetoSankey(v);
          let sankeyPorPais = sankeyPrincipal(objSankey);
          objSankey = agrupaViajes(sankeyPorPais);
          objSankey = generarObjetoSankey(objSankey);
          sankeyPorPais = sankeyPrincipal(objSankey);
          sankeyPorPais.code = k;
          sankeysPorPais.push(sankeyPorPais);
        });
        sankeysPorPais = _.orderBy(
          sankeysPorPais,
          function (d) {
            let src = _.find(d.nodes, { id: "SOURCE" });
            return src.name;
          },
          ["asc"]
        );

        sankeysPorPais.push(sankeyUE);

        let params = {
          paises: paises.features[0],
          francia: france.features[0],
          departamentos: departamentos,
          pathsDesdeFrancia: pathsDesdeFrancia,
          sankeysPorPais: sankeysPorPais,
          viajesComparadosPorPais: viajesComparadosPorPais,
          codigo: codigo,
          codigos: codigos,
        };
        window.params = params;

        crearGlobo(params);
        crearControles();
        crearSankey();
        actualizarSankey(getSankey(params.sankeysPorPais, "FR"));
        crearLineas();
        actualizarLineas(getViajes(params.viajesComparadosPorPais, "AT"));

        crearMiniGrid([
          getViajes(params.viajesComparadosPorPais, "AT"),
          getViajes(params.viajesComparadosPorPais, "DE"),
          getViajes(params.viajesComparadosPorPais, "NL"),
          getViajes(params.viajesComparadosPorPais, "SI"),
        ]);

        crearGrid(params.viajesComparadosPorPais);
        crearInterpoladores(params);
        crearScroller(params);
      })
      .catch((e) => {
        console.log("fetchDatos: failed");
        console.log(e);
      });
  }

  function crearMiniGrid(arregloViajes) {
    _.each(arregloViajes, function (v, i) {
      let x =
        i % 2 === 0
          ? (width - gap) / 2 - miniGridBox.exterior.width
          : (width + gap) / 2;
      let y = _.floor(i / 2) === 0 ? 0 : (height + gap) / 2;

      let g = gMiniGrid
        .append("g")
        .datum(v)
        .attr("class", "cell")
        .attr("transform", `translate(${x},${y})`)
        .call(hide);

      crearLineasGrid(g, miniGridBox);
    });
  }

  function agrupaViajes(sankey) {
    let arreglo = [];
    let numOtros = 0;
    let members = [];
    _.each(sankey.links, function (l) {
      let box = getCajaEtiqueta(l.target.code);
      if (l.target.y1 - l.target.y0 >= box.height) {
        arreglo.push({
          origen: { code: l.source.code, name: l.source.name },
          destino: { code: l.target.code, name: l.target.name },
          member: l.member,
          num: l.value,
        });
      } else {
        numOtros += l.value;
        members.push(l.member);
      }
    });
    if (numOtros >= 0) {
      let origen = _.find(sankey.nodes, { id: "SOURCE" });
      arreglo.push({
        origen: { code: origen.code, name: origen.name },
        destino: { code: "OTHER", name: "Other" },
        member: null,
        num: numOtros,
        agrupa: members,
      });
    }
    return arreglo;
  }

  function setCajaEtiqueta(d) {
    if (!cajasEtiquetas.hasOwnProperty(d.code)) {
      let t = svg
        .append("text")
        .attr("x", 0)
        .attr("y", width / 2)
        .style("font-size", "18px")
        .style("font-weight", 600)
        .style("font-family", "IBM Plex Sans")
        .text(d.name);
      let caja = t.node().getBBox();
      cajasEtiquetas[d.code] = caja;
      t.remove();
    }
  }

  function getCajaEtiqueta(code) {
    return cajasEtiquetas[code];
  }

  function generarObjetoSankey(viajes) {
    let nodes = {};
    let links = [];
    let code = null;

    viajes = _.filter(viajes, (d) => d.num > 0);
    viajes = _.orderBy(viajes, ["num"], ["desc"]);
    let otros = _.remove(viajes, function (v) {
      return v.destino.code === "OTHER";
    });
    viajes = _.concat(viajes, otros);

    _.each(viajes, function (v) {
      let o = v.origen;
      setCajaEtiqueta(o);
      if (!nodes.hasOwnProperty(o.code)) {
        code = o.code;
        nodes[o.code] = {
          code: o.code,
          name: o.name,
          member: true,
          id: "SOURCE",
        };
      }

      let d = v.destino;
      setCajaEtiqueta(d);
      if (!nodes.hasOwnProperty(d.code)) {
        nodes[d.code] = {
          code: d.code,
          name: d.name,
          member: v.member,
          id: d.code,
        };
      }

      let link = {
        source: o.code,
        target: d.code,
        value: v.num,
        member: v.member,
        id: d.code,
      };

      if (v.hasOwnProperty("agrupa")) {
        link.agrupa = _.uniq(v.agrupa);
      }

      links.push(link);
    });
    return { nodes: _.values(nodes), links: links, code: code };
  }

  function crearGlobo(params) {
    let paises = params.paises;
    let france = params.francia;

    //El código del globo salió de acá: https://observablehq.com/@michael-keith/draggable-globe-in-d3
    //Otra versión del globo: https://plnkr.co/edit/AzianFOknvgRBR2f7TvC?p=preview&preview
    const markerBoxWidth = 20;
    const markerBoxHeight = 20;
    const refX = markerBoxWidth / 2;
    const refY = markerBoxHeight / 2;
    const arrowPoints = [
      [0, 0],
      [0, 20],
      [20, 10],
    ];

    defs
      .append("marker")
      .attr("id", "arrow_member")
      .attr("viewBox", [0, 0, 200, 200])
      .attr("refX", refX)
      .attr("refY", refY)
      .attr("markerWidth", markerBoxWidth)
      .attr("markerHeight", markerBoxHeight)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(arrowPoints))
      .attr("stroke", "none")
      .attr("fill", "#165ce9");

    defs
      .append("marker")
      .attr("id", "arrow_non_member")
      .attr("viewBox", [0, 0, 200, 200])
      .attr("refX", refX)
      .attr("refY", refY)
      .attr("markerWidth", markerBoxWidth)
      .attr("markerHeight", markerBoxHeight)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(arrowPoints))
      .attr("stroke", "none")
      .attr("fill", "#4fc9a4");

    defs
      .append("path")
      .attr("id", "peninsular-france")
      .attr("d", pathGlobe(france.geometry));

    gGlobo
      .append("circle")
      .attr("fill", "#ffffff")
      .attr("stroke", "#000000")
      .attr("stroke-width", "0.2")
      .attr("cx", width / 2)
      .attr("cy", height / 2)
      .attr("r", projectionGlobe.scale());

    let gCountries = gMapa.append("g").attr("class", "countries");

    gCountries
      .append("path")
      .attr("id", "countries")
      .datum(paises)
      .attr("d", pathGlobe)
      .attr("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"])
      .style("opacity", (d) => d.properties.opacity);

    gCountries
      .append("path")
      .attr("id", "france")
      .datum(france)
      .attr("d", pathGlobe)
      .attr("fill", () => "transparent")
      .style("stroke", () => "transparent")
      .style("stroke-width", (d) => d.properties["stroke-width"])
      .style("opacity", (d) => d.properties.opacity);

    gMapa
      .append("g")
      .attr("class", "label")
      .attr("transform", `translate(${width / 2},${height / 2})`)
      .call(hide);
    gMapa.append("g").attr("class", "departments").call(hide);
    gMapa.append("g").attr("class", "circles").call(hide);
    gMapa.append("g").attr("class", "trips").call(hide);
    gMapa.append("g").attr("class", "gradient"); //.call(hide);
  }

  function startTimerGlobe(flagTransicion) {
    //    return 0;
    if (flagTransicion && _.isNull(timerGlobe)) {
      let fn = function () {
        let rotate = projectionGlobe.rotate();
        let k = sensitivity / projectionGlobe.scale();
        projectionGlobe.rotate([rotate[0] - 1 * k, rotate[1]]);
        pathGlobe.projection(projectionGlobe);
        gMapa.selectAll("path").attr("d", pathGlobe);
      };
      timerGlobe = d3.timer(fn, 200);
    }
  }

  function stopTimerGlobe() {
    if (!_.isNull(timerGlobe)) {
      timerGlobe.stop();
      timerGlobe = null;
    }
  }

  function transicionFranciaCentrar(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        gMapa
          .selectAll("path")
          .transition("path:centrar")
          .duration(duracion * factor)
          .attrTween("d", function (d) {
            let r = d3.interpolate(
              projectionGlobe.rotate(),
              projectionFrance.rotate()
            );
            return function (t) {
              projectionGlobe.rotate(r(t));
              pathGlobe.projection(projectionGlobe);
              return pathGlobe(d);
            };
          })
          .end()
          .catch((e) => {
            console.log(
              "transicionFranciaCentrar: transition('path:centrar') failed"
            );
            console.log(e);
          })
          .finally(function () {
            gMapa.selectAll("path").attr("d", pathGlobe);
            if (capas.actual == "france" || capas.actual == "globe") {
              Promise.all([crearEtiqueta(true, factor)]).finally(function () {
                resolveFn(0);
              });
            } else {
              resolveFn(0);
            }
          });
      });
    } else {
      projectionGlobe.rotate(rotateArray);
      pathGlobe.projection(projectionGlobe);
      gMapa.selectAll("path").attr("d", pathGlobe);
      if (capas.actual == "france" || capas.actual == "globe") {
        crearEtiqueta();
      }
    }
  }

  function crearDepartamentos(params, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    gMapa.select("g.departments").call(unhide);

    let departamentos = gMapa
      .select("g.departments")
      .selectAll("path")
      .data(params.departamentos.features, (d) => d.properties.NUTS_ID)
      .enter()
      .append("path");

    departamentos
      .attr("d", pathFrance)
      .each(function (d) {
        d.properties.centroide = pathFrance.centroid(d);
      })
      .attr("fill", "#EEEEEE")
      .style("stroke", flagTransicion ? "transparent" : "#165ce9")
      .style("stroke-width", 0.3)
      .style("opacity", 0.8);

    if (flagTransicion) {
      departamentos
        .transition("path-stroke")
        .duration(duracion * factor)
        .style("stroke", "#165ce9")
        .end()
        .catch((e) => {
          console.log("crearDepartamentos: transition('path-stroke') failed");
          console.log(e);
        })
        .finally(function () {
          departamentos.style("stroke", "#165ce9");
        });
    }
  }

  function quitarDepartamentos(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let departamentos = gMapa
      .select("g.departments")
      .selectAll("path")
      .data([])
      .exit();

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        departamentos
          .transition("path-stroke")
          .duration(duracion * factor)
          .style("stroke", "transparent")
          .end()
          .catch((e) => {
            console.log(
              "quitarDepartamentos: transition('path-stroke') failed"
            );
            console.log(e);
          })
          .finally(function () {
            departamentos.remove();
            gMapa.select("g.departments").call(hide);
            resolveFn(0);
          });
      });
    } else {
      departamentos.remove();
      gMapa.select("g.departments").call(hide);
    }
  }

  function crearCirculos(params, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    gMapa.select("g.circles").call(unhide);

    let circulos = gMapa
      .select("g.circles")
      .selectAll("circle")
      .data(params.departamentos.features, (d) => d.properties.NUTS_ID)
      .enter()
      .append("circle");

    circulos
      .attr("r", (d) =>
        flagTransicion ? 0 : escalaCirculos(d.properties.total_trips)
      )
      .attr("cx", (d) => d.properties.centroide[0])
      .attr("cy", (d) => d.properties.centroide[1])
      .attr("fill", (d) => escalaColorCirculos(d.properties.total_trips))
      .attr("opacity", 0.6)
      .attr("stroke", "#000000")
      .attr("stroke_width", "2px");

    if (flagTransicion) {
      circulos
        .transition("circle-r")
        .duration(duracion * factor)
        .attr("r", (d) => escalaCirculos(d.properties.total_trips))
        .end()
        .catch((e) => {
          console.log("crearCirculos: transition('circle-r') failed");
          console.log(e);
        })
        .finally(function () {
          circulos.attr("r", (d) => escalaCirculos(d.properties.total_trips));
        });
    }
  }

  function crearEtiqueta(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "text:update";

    let valor = null;
    switch (capas.actual) {
      case "france":
        valor = "90%";
        break;
      case "globe":
        valor = "10%";
        break;
      default:
        valor = "";
        break;
    }

    let gLabel = gMapa.select("g.label").call(unhide);

    let label = gLabel
      .append("text")
      .text(valor)
      .style("opacity", 0)
      .attr("x", -4)
      .attr("y", 0)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .style("font-size", "16px");

    //volver
    //translate([width / 2, height / 2]);
    //translate projectionGlobe
    //[2.451493944600429,46.61974133316687]

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        label
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 1)
          .end()
          .catch((e) => {
            console.log(
              `crearEtiqueta: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            label.style("opacity", null);
            resolveFn(0);
          });
      });
    } else {
      label.style("opacity", null);
    }
  }

  function quitarEtiqueta(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "text:update";

    let gLabel = gMapa.select("g.label");
    let label = gLabel.select("text");

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        label
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 0)
          .end()
          .catch((e) => {
            console.log(
              `quitarEtiqueta: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            label.remove();
            gLabel.call(hide);
            resolveFn(0);
          });
      });
    } else {
      label.remove();
      gLabel.call(hide);
    }
  }

  function quitarCirculos(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let circulos = gMapa
      .select("g.circles")
      .selectAll("circle")
      .data([])
      .exit();

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        circulos
          .transition("circle-r")
          .duration(duracion * factor)
          .attr("r", 0)
          .end()
          .catch((e) => {
            console.log("quitarCirculos: transition('circle-r') failed");
            console.log(e);
          })
          .finally(function () {
            circulos.remove();
            gMapa.select("g.circles").call(hide);
            resolveFn(0);
          });
      });
    } else {
      circulos.remove();
      gMapa.select("g.circles").call(hide);
    }
  }

  function crearFlechas(params, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let pathsDesdeFrancia = params.pathsDesdeFrancia;

    gMapa.select("g.trips").call(unhide);

    let grupos = gMapa
      .select("g.trips")
      .selectAll("g.trip")
      .data(pathsDesdeFrancia)
      .enter()
      .append("g")
      .attr("class", "trip");

    grupos
      .append("path")
      .attr("class", "arrow")
      .attr("d", pathGlobe)
      .style("fill", "none")
      .call(estilizarPath)
      .style("stroke-width", (d) => escalaFlechas(d.properties.num))
      .style("opacity", 0.3)
      /*
      .attr("marker-end", (d) =>
        d.properties.member ? "url(#arrow_member)" : "url(#arrow_non_member)"
      )
      */
      .each(function (d) {
        d.properties.length = d3.select(this).node().getTotalLength();
      });

    let flechas = grupos.select("path.arrow");

    /*
https://www.google.com/search?q=d3js+add+markers+to+rotating+globe
https://stackoverflow.com/questions/71670329/points-on-d3-js-globe-not-rotating-with-globe
https://stackoverflow.com/questions/60684423/connect-markers-on-spinning-globe-with-line-in-d3-js
https://stackoverflow.com/questions/64661449/how-to-add-height-to-a-d3-js-globe-marker-mimicking-a-push-pin
https://stackoverflow.com/questions/31479802/d3-js-spinning-globe-with-bars
https://stackoverflow.com/questions/39330892/d3-js-orthographic-markers-rotation
https://gist.github.com/atanumallick/8d18989cd538c72ae1ead1c3b18d7b54
    */
    grupos
      .append("path")
      .attr("class", "marker")
      .each(function (d) {
        let width = escalaFlechas(d.properties.num) * 2;
        let area = (width * width * 1.732) / 4;
        let height = (width * 1.732) / 2;
        d.triangle = { w: width, h: height, area: area };
      })
      .attr(
        "d",
        d3.symbol(d3.symbolTriangle).size((d) => d.triangle.area)
      )
      .call(estilizarMarcador)
      .style("opacity", 0.3)
      .attr("transform", function (d) {
        let p = d3.select(this.closest("g")).select("path.arrow");
        let obj = getInfoRuta(p.node(), d.triangle.h);
        return (
          "translate(" + obj.x + "," + obj.y + ")rotate(" + obj.angulo + " 0 0)"
        );
      });
    let marcadores = grupos.select("path.marker");

    if (flagTransicion) {
      console.log("flechas");
      console.log(flechas);
      console.log("flechas");
      console.log(marcadores);
      return new Promise(function (resolveFn) {
        flechas
          .transition("paths:flechas")
          .duration(duracion * factor)
          .attrTween("stroke-dasharray", tweenDash)
          .end()
          .catch((e) => {
            console.log("crearFlechas: transition('paths:flechas') failed");
            console.log(e);
          })
          .finally(function () {
            flechas.attr("stroke-dasharray", null);
            resolveFn(0);
          });

        let nT = "paths:markers";
        marcadores
          .transition(nT)
          .duration(duracion * factor)
          .attrTween("transform", function (d) {
            let p = d3.select(this.closest("g")).select("path.arrow");
            return translateAlong(p.node(), d.triangle.h);
          })
          .end()
          .catch((e) => {
            console.log(`crearFlechas: transition('${nT}') failed`);
            console.log(e);
          })
          .finally(function () {
            marcadores.attr("transform", function (d) {
              let p = d3.select(this.closest("g")).select("path.arrow");
              let obj = getInfoRuta(p.node(), d.triangle.h);
              return (
                "translate(" +
                obj.x +
                "," +
                obj.y +
                ")rotate(" +
                obj.angulo +
                " 0 0)"
              );
            });
            resolveFn(0);
          });
      });
    } else {
      flechas.attr("stroke-dashoffset", null).attr("stroke-dasharray", null);
    }
  }

  function getInfoRuta(nodo, h) {
    let largo = nodo.getTotalLength();
    let p0 = nodo.getPointAtLength(largo - 1);
    let p1 = nodo.getPointAtLength(largo);
    //let p3 = nodo.getPointAtLength(largo);
    let p3 = nodo.getPointAtLength(largo + 2 * h);
    let angle = getAngle(p1, p0);
    return { angulo: angle, x: p3.x, y: p3.y };
  }

  function tweenDash() {
    let len = this.getTotalLength();
    let interpolate = d3.interpolateString("0," + len, len + "," + len);
    return function (t) {
      return interpolate(t);
    };
  }

  function translateAlong(path, h) {
    //var l = path.getTotalLength() - h;
    var l = path.getTotalLength();
    var t0 = 0;
    //return function () {
    return function (t) {
      let p0 = path.getPointAtLength(t0 * l);
      let p = path.getPointAtLength(t * l);
      let angle = getAngle(p, p0);
      t0 = t;
      let centerX = p.x;
      let centerY = p.y;
      return (
        "translate(" + centerX + "," + centerY + ")rotate(" + angle + " 0 0)"
      );
    };
    //};
  }

  function getAngle(p1, p0) {
    var angle = (Math.atan2(p1.y - p0.y, p1.x - p0.x) * 180) / Math.PI;
    angle += 90;
    return angle;
  }

  //TODO: Hacer que las flechas se retraigan en vez del fade
  function quitarFlechas(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let flechas = gMapa.select("g.trips").selectAll("g.trip").data([]).exit();

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        flechas
          .transition("paths:flechas")
          .duration(duracion, factor)
          .style("opacity", 0)
          .end()
          .catch((e) => {
            console.log("quitarFlechas: transition('paths:flechas') failed");
            console.log(e);
          })
          .finally(function () {
            flechas.remove();
            gMapa.select("g.trips").call(hide);
            resolveFn(0);
          });
      });
    } else {
      flechas.remove();
      gMapa.select("g.trips").call(hide);
    }
  }

  function estilizarPath(el) {
    el.style("stroke", (d) => (d.properties.member ? "#165ce9" : "#4fc9a4"));
  }

  function estilizarMarcador(el) {
    el.style("fill", (d) => (d.properties.member ? "#165ce9" : "#4fc9a4"));
  }

  function estilizarNodo(el) {
    el.style("fill", colorearRectNode)
      .style("stroke", colorearRectNode)
      .attr("x", (d) => d.target.x0)
      .attr("y", (d) => d.target.y0)
      .attr("height", (d) => d.target.y1 - d.target.y0);
  }

  function estilizarNodoTexto(el, ancho) {
    el.attr("x", (d) => d.target.x0 + ancho + 10)
      .attr("y", (d) => d.target.y0 + (d.target.y1 - d.target.y0) / 2)
      .style("font-size", "18px")
      .style("font-weight", 600)
      .style("font-family", "IBM Plex Sans")
      .attr("text-anchor", "start")
      .attr("dominant-baseline", "middle")
      .style("fill", colorearRectNode)
      .text((d) => d.target.name)
      .each(function (d) {
        d.target.opacity = 0;
        let box = getCajaEtiqueta(d.target.code);
        if (d.target.y1 - d.target.y0 >= box.height) {
          d.target.opacity = 1;
        }
      });
  }

  function estilizarEnlace(el) {
    el.style("stroke", colorearPathLink)
      .style("fill", "none")
      .attr("stroke-width", function (d) {
        return d.width;
      })
      .attr("d", d3.sankeyLinkHorizontal())
      .attr("class", (d) => (d.member ? "link domestic" : "link foreign"));
  }

  function getDomainX(data) {
    return d3.extent(_.map(data, (d) => d.year));
  }

  function getDomainY(data) {
    let domain = [
      0,
      _.max(
        _.concat(
          _.map(data, (d) => d.domestic),
          _.map(data, (d) => d.foreign)
        )
      ),
    ];
    let max = domain[1];
    max = roundUpNice(max);
    domain[1] = max;
    return domain;
  }

  function roundUpNice(n) {
    let escala = getEscala(n);
    let temp = Math.floor(n / escala);
    if (temp < n) {
      temp += 1;
    }
    if (escala === 1) {
      if (temp % 2 === 1) {
        temp += 1;
      }
    }
    n = temp * escala;
    return n;
  }

  function getEscala(n) {
    let escala = 1;
    while (escala < n) {
      escala *= 10;
    }
    escala /= 10;
    return escala;
  }

  function crearLineas() {
    gLineas.attr(
      "transform",
      `translate(${sankeyPrincipalBox.padding.left},${sankeyPrincipalBox.padding.top})`
    );

    gLineas
      .append("g")
      .attr("class", "name")
      .call(hide)
      .append("text")
      .attr("x", -60)
      .attr("y", -70)
      .style("font-size", "40px")
      .style("font-weight", 700)
      .style("fill", "black")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .text("");

    gLineas
      .append("g")
      .attr("class", "x")
      .call(d3.axisBottom(escalaX).tickFormat(d3.format("d")))
      .attr("transform", `translate(0, ${lineasPrincipalBox.content.height})`)
      .call(hide)
      .selectAll("text")
      .style("font-size", "24px")
      .attr("fill", "grey");
    gLineas.select("g.x").select("path").attr("stroke", "transparent");

    gLineas
      .append("g")
      .attr("class", "y")
      .style("font-size", "24px")
      .attr("fill", "grey")
      //.attr("transform", `translate(${sankeyPrincipal.nodeWidth()}, 0)`)
      .call(hide);

    gLineas
      .append("g")
      .attr("class", "covid ignore")
      .call(hide)
      .append("line")
      .attr("x1", escalaX(2020))
      .attr("y1", 0)
      .attr("x2", escalaX(2020))
      .attr("y2", lineasPrincipalBox.content.height)
      .style("stroke", "#000000")
      .style("stroke-width", "3px")
      .style("stroke-dasharray", "5, 5");

    gLineas
      .select("g.covid")
      .append("text")
      .attr("x", escalaX(2020) - 20)
      .attr("y", 0)
      .style("font-size", "26px'")
      .style("font-weight", 700)
      .style("fill", "grey")
      .attr("text-anchor", "end")
      .attr("dominant-baseline", "middle")
      .text("Pandemic starts");

    gLineas.append("g").attr("class", "main").call(hide);
    gLineas.append("g").attr("class", "voronoi").call(hide);

    gLineas
      .select("g.main")
      .attr("fill", "none")
      .attr("stroke-linecap", "round");

    gLineas
      .select("g.main")
      .append("path")
      .attr("class", "domestic")
      .attr("stroke-width", "5px")
      .attr("stroke", "#165ce9");

    gLineas
      .select("g.main")
      .append("path")
      .attr("class", "foreign")
      .attr("stroke-width", "5px")
      .attr("stroke", "#4fc9a4");
  }

  //TODO: Hay que hacer una función intermedia que permita mandar las medidas de la caja de cada chart para poder limitar los tooltips al voronoi o leer las medidas del svg de la raíz
  function crearTooltipVoronoi(e, d) {
    let el = d3.select(this);
    let tipo = d.type;
    let color = tipo === "domestic" ? "#165ce9" : "#4fc9a4";
    let titulo = tipo === "domestic" ? "Domestic" : "Foreign";

    let gVoronoi = d3.select(el.node().closest("g.voronoi"));
    let gMain = d3.select(gVoronoi.node().previousSibling);

    let gTooltip = gMain.append("g").attr("class", "tooltip ignore");

    let rect = gTooltip
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 0)
      .attr("height", 0)
      .style("fill", "#ffffff");

    let text = gTooltip
      .append("text")
      .attr("x", 0)
      .attr("y", 0)
      .attr("dy", 0)
      .style("font-size", "24px")
      .style("font-weight", 400)
      .attr("text-anchor", "middle")
      .style("fill", "black");

    text
      .append("tspan")
      .attr("x", 0)
      .attr("dy", 0)
      .style("font-weight", 700)
      .style("fill", color)
      .text(`${titulo} country`);
    text
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .style("font-weight", 700)
      .text(`${d.year}: `);
    text.append("tspan").text(d.value);

    let box = text.node().getBBox();

    gTooltip.attr("transform", `translate(${d.x}, ${d.y})`);

    rect
      .attr("x", box.x - 5)
      .attr("y", box.y - 5)
      .attr("width", box.width + 10)
      .attr("height", box.height + 10);
  }

  function deshacerTooltipVoronoi(e, d) {
    let el = d3.select(this);
    let gVoronoi = d3.select(el.node().closest("g.voronoi"));
    let gMain = d3.select(gVoronoi.node().previousSibling);
    gMain.selectAll("g.tooltip").remove();
  }

  function crearControles() {
    gControles
      .append("circle")
      .attr("class", "backward")
      .attr("r", 25)
      .attr("cx", width / 2 - 50 + 20)
      .attr("cy", 29)
      .attr("fill", "#cdcdcd");
    gControles
      .append("image")
      .attr("href", "img/icons/backward.svg")
      .attr("class", "backward")
      .attr("width", 40)
      .attr("height", 40)
      .attr("x", width / 2 - 50)
      .attr("y", 8)
      .on("click", function () {
        if (capas.actual == "sankey_var" || capas.actual == "line_chart") {
          params.codigo = getCodigoAnterior(params.codigo, params.codigos);
          actualizarSankey(
            getSankey(params.sankeysPorPais, params.codigo),
            capas.actual == "sankey_var"
          );
          actualizarLineas(
            getViajes(params.viajesComparadosPorPais, params.codigo),
            capas.actual == "line_chart",
            params
          );
        }
      });
    /*
    gControles
      .append("circle")
      .attr("class", "backward")
      .attr("r", 20)
      .attr("cx", width / 2 - 50)
      .attr("cy", 20)
      .attr("fill", "black")
      .on("click", function () {
        if (capas.actual == "sankey_var" || capas.actual == "line_chart") {
          params.codigo = getCodigoAnterior(params.codigo, params.codigos);
          actualizarSankey(
            getSankey(params.sankeysPorPais, params.codigo),
            capas.actual == "sankey_var"
          );
          actualizarLineas(
            getViajes(params.viajesComparadosPorPais, params.codigo),
            capas.actual == "line_chart",
            params
          );
        }
      });
      */

    gControles
      .append("circle")
      .attr("class", "forward")
      .attr("r", 25)
      .attr("cx", 15 + width / 2 + 55)
      .attr("cy", 29)
      .attr("fill", "#cdcdcd");
    gControles
      .append("image")
      .attr("href", "img/icons/forward.svg")
      .attr("class", "forward")
      .attr("width", 40)
      .attr("height", 40)
      .attr("x", width / 2 + 50)
      .attr("y", 8)
      .on("click", function () {
        if (capas.actual == "sankey_var" || capas.actual == "line_chart") {
          params.codigo = getCodigoPosterior(params.codigo, params.codigos);
          actualizarSankey(
            getSankey(params.sankeysPorPais, params.codigo),
            capas.actual == "sankey_var"
          );
          actualizarLineas(
            getViajes(params.viajesComparadosPorPais, params.codigo),
            capas.actual == "line_chart",
            params
          );
        }
      });
  }

  function crearSankey() {
    gSankey.attr(
      "transform",
      `translate(${sankeyPrincipalBox.padding.left},${sankeyPrincipalBox.padding.top})`
    );
    gSankey.append("g").attr("class", "origin").call(hide);
    gSankey.append("g").attr("class", "trips").call(hide);

    gSankey
      .select("g.origin")
      .append("rect")
      .style("fill", "#165ce9")
      .style("stroke", "#165ce9")
      .attr("width", sankeyPrincipal.nodeWidth());

    gSankey
      .select("g.origin")
      .append("text")
      .style("font-size", "18px")
      .style("font-weight", 600)
      .style("font-family", "IBM Plex Sans")
      .attr("text-anchor", "end")
      .attr("dominant-baseline", "middle")
      .style("fill", "#165ce9")
      .text("");
  }

  function transicionFranciaASankey(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      gSankey
        .append("path")
        .attr("id", "contorno")
        .attr("d", d3.select("#peninsular-france").attr("d"))
        .attr(
          "transform",
          `translate(-${sankeyPrincipalBox.padding.left}, -${sankeyPrincipalBox.padding.top})`
        )
        .style("fill", "transparent")
        .style("stroke", "black")
        .style("stroke-width", 2)
        .style("opacity", 0.8);
      mostrarGrupoSankey();

      return new Promise(function (resolveFn) {
        Promise.all([
          ocultarGrupoGlobo(true, factor),
          ocultarGrupoMapa(true, factor),
        ]).finally(function () {
          Promise.all([transicionFranciaASankeyOrigin(data, factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
      });
    } else {
      ocultarGrupoGlobo();
      ocultarGrupoMapa();
      actualizarSankey(data);
      mostrarGrupoSankey();
      mostrarGrupoSankeyOrigin();
      mostrarGrupoSankeyTrips();
    }
  }

  function transicionFranciaASankeyOrigin(data, factor) {
    factor = factor || 1;
    let nombreTransicion = "france:transition";
    let francia = gSankey.select("#contorno");

    return new Promise(function (resolveFn) {
      francia
        .transition(nombreTransicion)
        .duration(duracion * factor)
        .attrTween("d", function () {
          return interpoladorFranciaANodoFrancia;
        })
        .style("fill", "#165ce9")
        .style("stroke", "#165ce9")
        .style("stroke-width", 1)
        .style("opacity", 1)
        .end()
        .catch((e) => {
          console.log(
            `transicionFranciaASankeyOrigin: transition('${nombreTransicion}') failed`
          );
          console.log(e);
        })
        .finally(function () {
          francia.remove();
          vaciarSankey();
          mostrarGrupoSankeyOrigin();
          mostrarGrupoSankeyTrips();

          Promise.all([actualizarSankey(data, true, factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
    });
  }

  function transicionFranciaASankeyConControles(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      gSankey
        .append("path")
        .attr("id", "contorno")
        .attr("d", d3.select("#peninsular-france").attr("d"))
        .attr(
          "transform",
          `translate(-${sankeyPrincipalBox.padding.left}, -${sankeyPrincipalBox.padding.top})`
        )
        .style("fill", "transparent")
        .style("stroke", "black")
        .style("stroke-width", 2)
        .style("opacity", 0.8);
      mostrarGrupoSankey();

      return new Promise(function (resolveFn) {
        Promise.all([
          ocultarGrupoGlobo(true, factor),
          ocultarGrupoMapa(true, factor),
        ]).finally(function () {
          Promise.all([
            transicionFranciaASankeyOriginConControles(data, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      ocultarGrupoGlobo();
      ocultarGrupoMapa();
      actualizarSankey(data);
      mostrarGrupoSankey();
      mostrarGrupoSankeyOrigin();
      mostrarGrupoSankeyTrips();
      mostrarGrupoControles();
    }
  }

  function transicionFranciaASankeyOriginConControles(data, factor) {
    factor = factor || 1;
    let nombreTransicion = "france:transition";
    let francia = d3.select("#contorno");

    return new Promise(function (resolveFn) {
      francia
        .transition(nombreTransicion)
        .duration(duracion * factor)
        .attrTween("d", function () {
          return interpoladorFranciaANodoFrancia;
        })
        .style("fill", "#165ce9")
        .style("stroke", "#165ce9")
        .style("stroke-width", 1)
        .style("opacity", 1)
        .end()
        .catch((e) => {
          console.log(
            `transicionFranciaASankeyOrigin: transition('${nombreTransicion}') failed`
          );
          console.log(e);
        })
        .finally(function () {
          francia.remove();
          vaciarSankey();
          mostrarGrupoSankeyOrigin();
          mostrarGrupoSankeyTrips();

          Promise.all([
            actualizarSankey(data, true, factor),
            mostrarGrupoControles(true, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
    });
  }

  //TODO: Hacer versión bien hecha y con flagTransicion para ambos casos

  //TODO: Hacer que regresen los paths en vez del fade
  function vaciarSankey(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "g.trip:actualizacion";

    let grupos = gSankey
      .select("g.trips")
      .selectAll("g.trip")
      .data([], (d) => d.id)
      .exit();

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        grupos
          .style("opacity", 1)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 0)
          .end()
          .catch((e) => {
            console.log(
              `vaciarSankey: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            grupos.remove();
            resolveFn(0);
          });
      });
    } else {
      grupos.remove();
    }
  }

  function getSankey(sankeys, code) {
    return _.find(sankeys, { code: code });
  }

  function getViajes(viajes, code) {
    return viajes[code];
  }

  function actualizarSankeyEnterPath(gruposEnter, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "path:actualizacion";

    let pathEnter = gruposEnter
      .append("path")
      .attr("class", "link")
      .call(estilizarEnlace)
      .each(function (d) {
        d.length = d3.select(this).node().getTotalLength();
      });

    if (flagTransicion === true) {
      return new Promise(function (resolveFn) {
        pathEnter
          .attr("stroke-dasharray", (d) => `${d.length} ${d.length}`)
          .attr("stroke-dashoffset", (d) => d.length)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("stroke-dashoffset", 0)
          .end()
          .catch((e) => {
            console.log(
              `actualizarSankeyEnterPath: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            pathEnter
              .attr("stroke-dashoffset", null)
              .attr("stroke-dasharray", null);
            resolveFn(0);
          });
      });
    }
  }

  function actualizarSankeyEnterRect(gruposEnter, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "rect:actualizacion";

    let rectEnter = gruposEnter
      .append("rect")
      .attr("class", "destination")
      .call(estilizarNodo)
      .attr("width", sankeyPrincipal.nodeWidth());

    if (flagTransicion === true) {
      return new Promise(function (resolveFn) {
        rectEnter
          .attr("width", 0)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("width", sankeyPrincipal.nodeWidth())
          .end()
          .catch((e) => {
            console.log(
              `actualizarSankeyEnterRect: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            rectEnter.attr("width", sankeyPrincipal.nodeWidth());
            resolveFn(0);
          });
      });
    }
  }

  function actualizarSankeyEnterText(gruposEnter, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "text:actualizacion";

    let textEnter = gruposEnter
      .append("text")
      .attr("class", "destination")
      .call(estilizarNodoTexto, sankeyPrincipal.nodeWidth())
      .style("opacity", (d) =>
        d.target.opacity === 1 ? null : d.target.opacity
      );

    if (flagTransicion === true) {
      return new Promise(function (resolveFn) {
        textEnter
          .style("opacity", 0)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", (d) => d.target.opacity)
          .end()
          .catch((e) => {
            console.log(
              `actualizarSankeyEnterText: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            textEnter.style("opacity", (d) =>
              d.target.opacity === 1 ? null : d.target.opacity
            );
            resolveFn(0);
          });
      });
    }
  }

  function actualizarSankeyEnter(tripsEnter, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let gruposEnter = tripsEnter.append("g").attr("class", "trip");

    if (flagTransicion === true) {
      return new Promise(function (resolveFn) {
        Promise.all([
          actualizarSankeyEnterPath(gruposEnter, true, factor * 0.6),
        ]).finally(function () {
          Promise.all([
            actualizarSankeyEnterRect(gruposEnter, true, factor * 0.3),
            actualizarSankeyEnterText(gruposEnter, true, factor * 0.3),
          ]).finally(function () {
            gruposEnter
              .on("mouseenter", crearTooltipSankey)
              .on("mouseleave", deshacerTooltipSankey);
            resolveFn(0);
          });
        });
      });
    } else {
      actualizarSankeyEnterPath(gruposEnter);
      actualizarSankeyEnterRect(gruposEnter);
      actualizarSankeyEnterText(gruposEnter);
      gruposEnter
        .on("mouseenter", crearTooltipSankey)
        .on("mouseleave", deshacerTooltipSankey);
    }
  }

  function actualizarSankeyUpdatePath(trips, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "path:actualizacion";

    let pathUpdate = trips.select("path.link");

    if (flagTransicion === true) {
      return new Promise(function (resolveFn) {
        pathUpdate
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .call(estilizarEnlace)
          .end()
          .catch((e) => {
            console.log(
              `actualizarSankeyUpdatePath: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            pathUpdate.call(estilizarEnlace).each(function (d) {
              d.length = d3.select(this).node().getTotalLength();
            });
            resolveFn(0);
          });
      });
    } else {
      pathUpdate.call(estilizarEnlace).each(function (d) {
        d.length = d3.select(this).node().getTotalLength();
      });
    }
  }

  function actualizarSankeyUpdateRect(trips, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "rect:actualizacion";

    let rectUpdate = trips.select("rect.destination");

    if (flagTransicion === true) {
      return new Promise(function (resolveFn) {
        rectUpdate
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .call(estilizarNodo)
          .end()
          .catch((e) => {
            console.log(
              `actualizarSankeyUpdateRect: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            rectUpdate.call(estilizarNodo);
            resolveFn(0);
          });
      });
    } else {
      rectUpdate.call(estilizarNodo);
    }
  }

  function actualizarSankeyUpdateText(trips, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "text:actualizacion";

    let textUpdate = trips
      .select("text.destination")
      .text((d) => d.target.name);
    if (flagTransicion === true) {
      return new Promise(function (resolveFn) {
        textUpdate
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .call(estilizarNodoTexto, sankeyPrincipal.nodeWidth())
          .style("opacity", (d) => d.target.opacity)
          .end()
          .catch((e) => {
            console.log(
              `actualizarSankeyUpdateText: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            textUpdate
              .call(estilizarNodoTexto, sankeyPrincipal.nodeWidth())
              .style("opacity", (d) => d.target.opacity);
            resolveFn(0);
          });
      });
    } else {
      textUpdate
        .call(estilizarNodoTexto, sankeyPrincipal.nodeWidth())
        .style("opacity", (d) => d.target.opacity);
    }
  }

  function actualizarSankeyUpdate(trips, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion === true) {
      return new Promise(function (resolveFn) {
        Promise.all([
          actualizarSankeyUpdatePath(trips, true, factor),
          actualizarSankeyUpdateRect(trips, true, factor),
          actualizarSankeyUpdateText(trips, true, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      actualizarSankeyUpdatePath(trips);
      actualizarSankeyUpdateRect(trips);
      actualizarSankeyUpdateText(trips);
    }
  }

  function actualizarSankeyExit(tripsExit, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "g.trip:actualizacion";

    if (flagTransicion === true) {
      return new Promise(function (resolveFn) {
        tripsExit
          .style("opacity", 1)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 0)
          .end()
          .catch((e) => {
            console.log(
              `actualizarSankeyExit: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            tripsExit.style("opacity", 0).remove();
            resolveFn(0);
          });
      });
    } else {
      tripsExit.style("opacity", 0).remove();
    }
  }

  function actualizarSankeyOriginRect(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "rect:actualizacion";

    if (flagTransicion === true) {
      return new Promise(function (resolveFn) {
        gSankey
          .select("g.origin")
          .select("rect")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("height", (d) => d.y1 - d.y0)
          .attr("x", (d) => d.x0)
          .attr("y", (d) => d.y0)
          .end()
          .catch((e) => {
            console.log(
              `actualizarSankeyOriginRect: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            gSankey
              .select("g.origin")
              .select("rect")
              .attr("height", (d) => d.y1 - d.y0)
              .attr("x", (d) => d.x0)
              .attr("y", (d) => d.y0);
            resolveFn(0);
          });
      });
    } else {
      gSankey
        .select("g.origin")
        .select("rect")
        .attr("height", (d) => d.y1 - d.y0)
        .attr("x", (d) => d.x0)
        .attr("y", (d) => d.y0);
    }
  }

  function actualizarSankeyOriginText(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "text:actualizacion";

    if (flagTransicion === true) {
      return new Promise(function (resolveFn) {
        gSankey
          .select("g.origin")
          .select("text")
          .text((d) => d.name)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("x", (d) => d.x0 - 10)
          .attr("y", (d) => d.y0 + (d.y1 - d.y0) / 2)
          .end()
          .catch((e) => {
            console.log(
              `actualizarSankeyOriginText: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            gSankey
              .select("g.origin")
              .select("text")
              .attr("x", (d) => d.x0 - 10)
              .attr("y", (d) => d.y0 + (d.y1 - d.y0) / 2);
            resolveFn(0);
          });
      });
    } else {
      gSankey
        .select("g.origin")
        .select("text")
        .text((d) => d.name)
        .attr("x", (d) => d.x0 - 10)
        .attr("y", (d) => d.y0 + (d.y1 - d.y0) / 2);
    }
  }

  function actualizarSankeyOrigin(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion === true) {
      return new Promise(function (resolveFn) {
        Promise.all([
          actualizarSankeyOriginRect(true, factor),
          actualizarSankeyOriginText(true, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      actualizarSankeyOriginRect();
      actualizarSankeyOriginText();
    }
  }

  function actualizarSankey(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let src = _.find(data.nodes, { id: "SOURCE" });
    gSankey.select("g.origin").datum(src);

    agregaOtros(data);

    let trips = gSankey
      .select("g.trips")
      .selectAll("g.trip")
      .data(data.links, (d) => d.id);
    let tripsEnter = trips.enter();
    let tripsExit = trips.exit();

    if (flagTransicion === true) {
      return new Promise(function (resolveFn) {
        Promise.all([
          actualizarSankeyOrigin(flagTransicion, factor),
          actualizarSankeyEnter(tripsEnter, flagTransicion, factor),
          actualizarSankeyUpdate(trips, flagTransicion, factor),
          actualizarSankeyExit(tripsExit, flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      actualizarSankeyOrigin();
      actualizarSankeyEnter(tripsEnter);
      actualizarSankeyUpdate(trips);
      actualizarSankeyExit(tripsExit);
    }
  }

  function agregaOtros(data) {
    let otherTrip = _.find(data.links, { id: "OTHER" }) || false;
    if (otherTrip) {
      if (otherTrip.hasOwnProperty("agrupa")) {
        let otherTrips = _.remove(data.links, { id: "OTHER" });
        let otherNodes = _.remove(data.nodes, { id: "OTHER" });

        if (otherTrips.length > 0) {
          let other = otherTrips[0];
          let index = other.index;
          let members = other.agrupa;
          delete other.agrupa;

          let otherN = otherNodes[0];
          let indexN = otherN.index;

          _.each(members, function (member) {
            let nuevo = _.clone(other);
            nuevo.index = index;
            nuevo.member = member;
            nuevo.other = true;
            nuevo.id = member === true ? "OTHERMEMBER" : "OTHERNONMEMBER";
            index++;

            let nuevoNodo = _.clone(otherN);
            nuevoNodo.index = indexN;
            nuevoNodo.member = member;
            nuevoNodo.other = true;
            nuevoNodo.id = member === true ? "OTHERMEMBER" : "OTHERNONMEMBER";
            indexN++;

            nuevo.target = nuevoNodo;
            nuevoNodo.targetLinks = [nuevo];

            data.links.push(nuevo);
            data.nodes.push(nuevoNodo);
          });
        }
      }
    }
  }

  function colorearPathLink(d) {
    let other = d.other || false;
    let color = d.member ? "#447dee" : "#73d4b7";
    color = other === false ? color : "#c8c8c8";
    return color;
  }

  function colorearRectNode(d) {
    let other = d.other || false;
    let color = d.target.member ? "#165ce9" : "#4fc9a4";
    color = other === false ? color : "#a0a0a0";
    return color;
  }

  function colorearPathLinkHover(d, id) {
    let other = d.other || false;
    let color = d.member ? "#d0defb" : "#e8f8f3";
    color = other === false ? color : "#d8d8d8";
    if (d.id === id) {
      color = d.member ? "#447dee" : "#73d4b7";
      color = other === false ? color : "#c8c8c8";
    }
    return color;
  }

  function colorearRectNodeHover(d, id) {
    let other = d.other || false;
    let color = d.target.member ? "#a2bef6" : "#c4ede1";
    color = other === false ? color : "#b0b0b0";
    if (d.target.id === id) {
      color = d.target.member ? "#165ce9" : "#4fc9a4";
      color = other === false ? color : "#a0a0a0";
    }
    return color;
  }

  //TODO: Rehacer las promesas bien crearTooltipSankey
  function crearTooltipSankey(e, d) {
    let trip = d3.select(this);
    let trips = d3.select(trip.node().closest("g.trips"));

    let link = trip.select("path");
    let punto = link.node().getPointAtLength(d.length / 2);

    trips
      .append("text")
      .attr("class", "tooltip ignore")
      .attr("x", punto.x)
      .attr("y", punto.y)
      .style("font-size", "30px")
      .style("font-weight", 700)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .style("fill", "#000000")
      .style("stroke", "#ffffff")
      .style("stroke-width", "1px")
      .text(formatter.format(d.value));

    let id = d.id;
    let estilizarPathLinkHoverConId = function (d) {
      return colorearPathLinkHover(d, id);
    };
    let estilizarRectNodeHoverConId = function (d) {
      return colorearRectNodeHover(d, id);
    };

    trips
      .selectAll("g.trip")
      .select("path.link")
      .transition("path.link_tooltip")
      .duration(duracion * 1)
      .style("stroke", estilizarPathLinkHoverConId)
      .end()
      .catch(() => {
        /*
        console.log(
          "crearTooltipSankey: transition('path.link_tooltip') failed"
        );
        console.log(e);
        */
      })
      .finally(function () {
        trips
          .selectAll("g.trip")
          .select("path.link")
          .style("stroke", estilizarPathLinkHoverConId);
      });

    trips
      .selectAll("g.trip")
      .select("rect.destination")
      .transition("rect_tooltip")
      .duration(duracion * 1)
      .style("fill", estilizarRectNodeHoverConId)
      .style("stroke", estilizarRectNodeHoverConId)
      .end()
      .catch(() => {
        /*
        console.log("crearTooltipSankey: transition('rect_tooltip') failed");
        console.log(e);
        */
      })
      .finally(function () {
        trips
          .selectAll("g.trip")
          .select("rect.destination")
          .style("fill", estilizarRectNodeHoverConId)
          .style("stroke", estilizarRectNodeHoverConId);
      });

    trips
      .selectAll("g.trip")
      .select("text.destination")
      .transition("text_tooltip")
      .duration(duracion * 1)
      .style("opacity", (d) => (d.target.id === id ? 1 : 0))
      .end()
      .catch(() => {
        /*
        console.log("crearTooltipSankey: transition('text_tooltip') failed");
        console.log(e);
        */
      })
      .finally(function () {
        trips
          .selectAll("g.trip")
          .select("text.destination")
          .style("opacity", (d) => (d.target.id === id ? 1 : 0));
      });
  }

  function deshacerTooltipSankey(e, d) {
    let trip = d3.select(this);
    let trips = d3.select(trip.node().closest("g.trips"));

    trips.select("text.tooltip").remove();

    trips
      .selectAll("g.trip")
      .select("path.link")
      .transition("path.link_tooltip")
      .duration(duracion * 1)
      .style("stroke", colorearPathLink)
      .end()
      .catch(() => {
        /*
        console.log(
          "deshacerTooltipSankey: transition('path.link_tooltip') failed"
        );
        console.log(e);
        */
      })
      .finally(function () {
        trips
          .selectAll("g.trip")
          .select("path.link")
          .style("stroke", colorearPathLink);
      });

    trips
      .selectAll("g.trip")
      .select("rect.destination")
      .transition("rect_tooltip")
      .duration(duracion * 1)
      .style("fill", colorearRectNode)
      .style("stroke", colorearRectNode)
      .end()
      .catch(() => {
        /*
        console.log("deshacerTooltipSankey: transition('rect_tooltip') failed");
        console.log(e);
        */
      })
      .finally(function () {
        trips
          .selectAll("g.trip")
          .select("rect.destination")
          .style("fill", colorearRectNode)
          .style("stroke", colorearRectNode);
      });

    trips
      .selectAll("g.trip")
      .select("text.destination")
      .transition("text_tooltip")
      .duration(duracion * 1)
      .style("opacity", (d) => d.target.opacity)
      .end()
      .catch(() => {
        /*
        console.log("deshacerTooltipSankey: transition('text_tooltip') failed");
        console.log(e);
        */
      })
      .finally(function () {
        trips
          .selectAll("g.trip")
          .select("text.destination")
          .style("opacity", (d) => d.target.opacity);
      });
  }

  function getCodigoPosterior(codigo, codigos) {
    let i = codigos.indexOf(codigo);
    i++;
    if (i >= codigos.length) {
      i -= codigos.length;
    }
    return codigos[i];
  }

  function getCodigoAnterior(codigo, codigos) {
    let i = codigos.indexOf(codigo);
    i--;
    if (i < 0) {
      i += codigos.length;
    }
    return codigos[i];
  }

  function preparaPaths(paths, destinoDomestico, destinoForaneo) {
    paths.each(function (d) {
      let el = d3.select(this);
      let destinoPath = this.classList.contains("domestic")
        ? destinoDomestico
        : destinoForaneo;

      d.paths = {
        sankey: { d: el.attr("d"), strokeWidth: el.attr("stroke-width") },
        linea: { d: destinoPath },
        interpolador: null,
      };
      d.paths.interpolador = d3.interpolatePath(d.paths.sankey.d, destinoPath);
    });
    return paths;
  }

  function transicionPathsSankeyAPathsLineas(paths, factor) {
    let nombreTransicion = "path:sankeyALineas";

    return new Promise(function (resolveFn) {
      paths
        .classed("ignore", true)
        .transition(nombreTransicion)
        .duration(duracion * factor)
        .attrTween("d", (f) => f.paths.interpolador)
        .attr("stroke-width", 5)
        .style("stroke", (d) => (d.member ? "#447dee" : "#73d4b7"))
        .end()
        .catch((e) => {
          console.log(
            `transicionPathsSankeyAPathsLineas: transition('${nombreTransicion}') failed`
          );
          console.log(e);
        })
        .finally(function () {
          resolveFn(0);
        });
    });
  }

  function transicionSankeyALineas(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    actualizarLineas(data);
    escalaY.domain(getDomainY(data));
    let destinoDomestico = pathDomestico(data);
    let destinoForaneo = pathForaneo(data);

    let paths = gSankey
      .select("g.trips")
      .selectAll("path")
      .call(preparaPaths, destinoDomestico, destinoForaneo);

    ocultarGrupoSankeyOrigin();
    gSankey.select("g.trips").selectAll("rect").call(hide);
    gSankey.select("g.trips").selectAll("text").call(hide);
    mostrarGrupoLineas();
    mostrarGrupoName();
    mostrarGrupoX();
    mostrarGrupoY();

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionPathsSankeyAPathsLineas(paths, factor),
          mostrarGrupoControles(true, factor),
        ]).finally(function () {
          ocultarGrupoSankeyTrips();
          gSankey.select("g.trips").selectAll("rect").call(unhide);
          gSankey.select("g.trips").selectAll("text").call(unhide);
          mostrarGrupoLineas();
          mostrarGrupoMain();
          mostrarGrupoVoronoi();
          mostrarGrupoCovid();
          paths
            .classed("ignore", false)
            .attr("d", (d) => d.paths.sankey.d)
            .attr("stroke-width", (d) => d.paths.sankey.strokeWidth)
            .style("stroke", colorearPathLink);
          paths.each(function (d) {
            d.paths.interpolador = null;
          });
          resolveFn(0);
        });
      });
    } else {
      ocultarGrupoSankeyTrips();
      gSankey.select("g.trips").selectAll("rect").call(unhide);
      gSankey.select("g.trips").selectAll("text").call(unhide);
      mostrarGrupoControles();
      mostrarGrupoLineas();
      mostrarGrupoMain();
      mostrarGrupoVoronoi();
      mostrarGrupoCovid();
      paths
        .classed("ignore", false)
        .attr("d", (d) => d.paths.sankey.d)
        .attr("stroke-width", (d) => d.paths.sankey.strokeWidth)
        .style("stroke", colorearPathLink);
      paths.each(function (d) {
        d.paths.interpolador = null;
      });
    }
  }

  function transicionLineasASankey(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    actualizarSankey(data);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          ocultarGrupoControles(flagTransicion, factor),
          mostrarGrupoSankey(flagTransicion, factor),
          mostrarGrupoSankeyOrigin(flagTransicion, factor),
          mostrarGrupoSankeyTrips(flagTransicion, factor),
          ocultarGrupoLineas(flagTransicion, factor),
          ocultarGrupoName(flagTransicion, factor),
          ocultarGrupoX(flagTransicion, factor),
          ocultarGrupoY(flagTransicion, factor),
          ocultarGrupoCovid(flagTransicion, factor),
          ocultarGrupoMain(flagTransicion, factor),
          ocultarGrupoVoronoi(flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      ocultarGrupoControles();
      mostrarGrupoSankey();
      mostrarGrupoSankeyOrigin();
      mostrarGrupoSankeyTrips();
      ocultarGrupoLineas();
      ocultarGrupoName();
      ocultarGrupoX();
      ocultarGrupoY();
      ocultarGrupoCovid();
      ocultarGrupoMain();
      ocultarGrupoVoronoi();
    }
  }

  function transicionLineasASankeyConControles(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    actualizarSankey(data);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          mostrarGrupoControles(flagTransicion, factor),
          mostrarGrupoSankey(flagTransicion, factor),
          mostrarGrupoSankeyOrigin(flagTransicion, factor),
          mostrarGrupoSankeyTrips(flagTransicion, factor),
          ocultarGrupoLineas(flagTransicion, factor),
          ocultarGrupoName(flagTransicion, factor),
          ocultarGrupoX(flagTransicion, factor),
          ocultarGrupoY(flagTransicion, factor),
          ocultarGrupoCovid(flagTransicion, factor),
          ocultarGrupoMain(flagTransicion, factor),
          ocultarGrupoVoronoi(flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      mostrarGrupoControles();
      mostrarGrupoSankey();
      mostrarGrupoSankeyOrigin();
      mostrarGrupoSankeyTrips();
      ocultarGrupoLineas();
      ocultarGrupoName();
      ocultarGrupoX();
      ocultarGrupoY();
      ocultarGrupoCovid();
      ocultarGrupoMain();
      ocultarGrupoVoronoi();
    }
  }

  //TODO: Convertirla en promesas
  function actualizarLineas(data, flagTransicion, params) {
    flagTransicion = flagTransicion || false;
    //factor = factor || 1;

    escalaY.domain(getDomainY(data));

    gLineas
      .select("g.name")
      .select("text")
      .datum({ code: data[0].code, country: data[0].country })
      .text((d) => d.country);

    let domesticPath = gLineas.select("g.main").select("path.domestic");
    let foreignPath = gLineas.select("g.main").select("path.foreign");

    if (flagTransicion) {
      gLineas
        .select("g.y")
        .transition("g.y")
        .duration(duracion)
        .call(d3.axisLeft(escalaY))
        .end()
        .catch((e) => {
          console.log("actualizarLineas: transition('g.y') failed");
          console.log(e);
        })
        .finally(function () {
          escalaY.domain(getDomainY(data));
          gLineas.select("g.y").call(d3.axisLeft(escalaY));
          gLineas.select("g.y").selectAll("line").style("fill", "grey");
          gLineas.select("g.y").select("path").attr("stroke", "transparent");
          gLineas
            .select("g.y")
            .selectAll("text")
            .style("fill", "grey")
            .style("font-size", "24px");
        });

      domesticPath
        .datum(data)
        .transition("path")
        .duration(duracion)
        .attr("d", pathDomestico)
        .end()
        .catch((e) => {
          console.log("actualizarLineas: transition('path.domestic') failed");
          console.log(e);
        })
        .finally(function () {
          escalaY.domain(getDomainY(data));
          domesticPath.attr("d", pathDomestico);
        });

      foreignPath
        .datum(data)
        .transition("path")
        .duration(duracion)
        .attr("d", pathForaneo)
        .end()
        .catch((e) => {
          console.log("actualizarLineas: transition('path.foreign') failed");
          console.log(e);
        })
        .finally(function () {
          escalaY.domain(getDomainY(data));
          foreignPath.attr("d", pathForaneo);
        });
    } else {
      escalaY.domain(getDomainY(data));
      gLineas.select("g.y").call(d3.axisLeft(escalaY));
      gLineas.select("g.y").selectAll("line").style("fill", "grey");
      gLineas.select("g.y").select("path").attr("stroke", "transparent");
      gLineas
        .select("g.y")
        .selectAll("text")
        .style("fill", "grey")
        .style("font-size", "24px");
      domesticPath.datum(data).attr("d", pathDomestico);
      foreignPath.datum(data).attr("d", pathForaneo);
    }

    escalaY.domain(getDomainY(data));

    actualizarVoronoiGrupo(
      gLineas,
      data,
      escalaX,
      escalaY,
      lineasPrincipalBox.content.width + 2 * sankeyPrincipal.nodeWidth(),
      lineasPrincipalBox.content.height
    );
  }

  function crearGrid(paises) {
    let contenedorGrid = d3.select("div[data-container='story-3-4']");
    console.log(paises);
    _.each(paises, function (pais) {
      let svg = contenedorGrid
        .append("div")
        .append("svg")
        .attr("preserveAspectRatio", "xMidYMid")
        .attr("viewBox", `0 0 450 450`);

      svg = svg.append("g").attr("class", "svg").datum(pais);
      crearLineasGrid(svg, miniGridBox);
    });
  }

  function actualizarLineasGrid(arreglo, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let arregloFunciones = [];

    let grupos = gMiniGrid.selectAll("g.cell");
    grupos.each(function (dataAnterior, i) {
      dataAnterior = dataAnterior || null;
      let grupo = d3.select(this);

      let d = arreglo[i];
      let operacion = null;
      grupo.datum(d);

      if (_.isNull(dataAnterior) && !_.isNull(d)) {
        operacion = "enter";
      }

      if (!_.isNull(dataAnterior) && _.isNull(d)) {
        operacion = "exit";
      }

      if (!_.isNull(dataAnterior) && !_.isNull(d)) {
        operacion = "update";
      }

      let f = null;

      if (operacion === "update") {
        if (flagTransicion) {
          f = new Promise(function (resolveFn) {
            Promise.all([
              actualizarLineasGrupo(grupo, miniGridBox, flagTransicion, factor),
            ]).finally(function () {
              resolveFn(0);
            });
          });
        } else {
          f = function () {
            actualizarLineasGrupo(grupo, miniGridBox, flagTransicion, factor);
          };
        }
        arregloFunciones.push(f);
      }
      if (operacion === "exit") {
        if (flagTransicion) {
          f = new Promise(function (resolveFn) {
            Promise.all([
              eliminarLineasGrupo(grupo, miniGridBox, flagTransicion, factor),
            ]).finally(function () {
              resolveFn(0);
            });
          });
        } else {
          f = function () {
            eliminarLineasGrupo(grupo, miniGridBox, flagTransicion, factor);
          };
        }
        arregloFunciones.push(f);
      }
      if (operacion === "enter") {
        if (flagTransicion) {
          f = new Promise(function (resolveFn) {
            Promise.all([
              crearLineasGrupo(grupo, miniGridBox, flagTransicion, factor),
            ]).finally(function () {
              resolveFn(0);
            });
          });
        } else {
          f = function () {
            crearLineasGrupo(grupo, miniGridBox, flagTransicion, factor);
          };
        }
        arregloFunciones.push(f);
      }
    });
    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all(arregloFunciones).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      _.each(arregloFunciones, function (f) {
        f();
      });
    }
  }

  function crearLineasGrupo(grupo, caja, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let data = grupo.datum();

    let escalaX = d3.scaleLinear().range([0, caja.content.width]);
    escalaX.domain(getDomainX(data));

    let escalaY = d3.scaleLinear().range([caja.content.height, 0]);
    escalaY.domain(getDomainY(data));

    let generadorD = d3
      .line()
      .curve(d3.curveCatmullRom.alpha(0.5))
      .x((d) => escalaX(d.year))
      .y((d) => escalaY(d.domestic));

    let generadorF = d3
      .line()
      .curve(d3.curveCatmullRom.alpha(0.5))
      .x((d) => escalaX(d.year))
      .y((d) => escalaY(d.foreign));

    grupo
      .select("g.name")
      .append("text")
      .attr("text-anchor", "start")
      .attr("dominant-baseline", "hanging")
      .datum({ code: data[0].code, country: data[0].country })
      .attr("x", 0)
      .attr("y", 0)
      .text((d) => d.country);

    actualizarVoronoiGrupo(
      grupo,
      data,
      escalaX,
      escalaY,
      caja.content.width,
      caja.content.height
    );

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          crearEjeXViajes(grupo, caja, flagTransicion, factor),
          crearEjeYViajes(grupo, caja, flagTransicion, factor),
          crearPathViajes(
            grupo,
            data,
            generadorD,
            "domestic",
            flagTransicion,
            factor
          ),
          crearPathViajes(
            grupo,
            data,
            generadorF,
            "foreign",
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          grupo
            .select("g.covid")
            .append("line")
            .attr("x1", escalaX(2020))
            .attr("y1", 0)
            .attr("x2", escalaX(2020))
            .attr("y2", caja.content.height)
            .style("stroke", "#000000")
            .style("stroke-width", "3px")
            .style("stroke-dasharray", "5, 5");
          grupo
            .select("g.covid")
            .append("text")
            .attr("x", escalaX(2020) - 10)
            .attr("y", 0)
            .text("Pandemic starts");
          resolveFn(0);
        });
      });
    } else {
      crearEjeXViajes(grupo, caja);
      crearEjeYViajes(grupo, caja);
      crearPathViajes(grupo, data, generadorD, "domestic");
      crearPathViajes(grupo, data, generadorF, "foreign");
      grupo
        .select("g.covid")
        .append("line")
        .attr("x1", escalaX(2020))
        .attr("y1", 0)
        .attr("x2", escalaX(2020))
        .attr("y2", caja.content.height)
        .style("stroke", "#000000")
        .style("stroke-width", "3px")
        .style("stroke-dasharray", "5, 5");
      grupo
        .select("g.covid")
        .append("text")
        .attr("x", escalaX(2020) - 10)
        .attr("y", 0)
        .text("Pandemic starts");
    }
  }

  function crearPathViajes(
    grupo,
    data,
    generador,
    type,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "path:actualizacion";

    let path = grupo
      .select("g.main")
      .append("path")
      .attr("class", type)
      .datum(data)
      .attr("d", generador)
      .attr("stroke-width", "3px")
      .attr("stroke", type === "domestic" ? "#165ce9" : "#4fc9a4");

    if (flagTransicion) {
      path
        .attr("stroke-dasharray", function () {
          let length = d3.select(this).node().getTotalLength();
          return `${length} ${length}`;
        })
        .attr("stroke-dashoffset", function () {
          return d3.select(this).node().getTotalLength();
        });

      return new Promise(function (resolveFn) {
        path
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("stroke-dashoffset", 0)
          .end()
          .catch((e) => {
            console.log(
              `eliminarPathViaje: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            path.attr("stroke-dasharray", null).attr("stroke-dashoffset", null);
            resolveFn(0);
          });
      });
    }
  }

  function crearEjeYViajes(grupo, caja, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "g.y:actualizacion";

    let escalaYFin = d3
      .scaleLinear()
      .domain(getDomainY(grupo.datum()))
      .range([caja.content.height, 0]);
    let domain = escalaYFin.domain();
    let n = domain[1];

    grupo.select("g.y").select("path").attr("stroke", "transparent");

    if (flagTransicion) {
      let escalaYIni = d3.scaleLinear().domain([0, 0]).range([0, 0]);
      return new Promise(function (resolveFn) {
        grupo
          .select("g.y")
          .attr(
            "transform",
            `translate(${caja.padding.left}, ${
              caja.content.height + caja.padding.top
            })`
          )
          .call(d3.axisBottom(escalaYIni).tickValues([]))
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .call(
            d3
              .axisLeft(escalaYFin)
              .tickValues([0, n / 4, n / 2, (n * 3) / 4, n])
          )
          .attr(
            "transform",
            `translate(${caja.padding.left}, ${caja.padding.top})`
          )
          .end()
          .catch((e) => {
            console.log(
              `cearEjeYViajes: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            grupo
              .select("g.y")
              .call(
                d3
                  .axisLeft(escalaYFin)
                  .tickValues([0, n / 4, n / 2, (n * 3) / 4, n])
              );
            grupo.select("g.y").select("path").attr("stroke", "transparent");
            resolveFn(0);
          });
      });
    } else {
      grupo
        .select("g.y")
        .call(
          d3.axisLeft(escalaYFin).tickValues([0, n / 4, n / 2, (n * 3) / 4, n])
        )
        .attr(
          "transform",
          `translate(${caja.padding.left}, ${caja.padding.top})`
        );
      grupo.select("g.y").select("path").attr("stroke", "transparent");
    }
  }

  function crearEjeXViajes(grupo, caja, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "g.x:actualizacion";

    let escalaXFin = d3
      .scaleLinear()
      .domain(getDomainX(grupo.datum()))
      .range([0, caja.content.width]);

    grupo.select("g.x").select("path").attr("stroke", "transparent");

    if (flagTransicion) {
      let escalaXIni = d3.scaleLinear().domain([0, 0]).range([0, 0]);
      return new Promise(function (resolveFn) {
        grupo
          .select("g.x")
          .call(
            d3.axisBottom(escalaXIni).tickFormat(d3.format("d")).tickValues([])
          )
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .call(
            d3
              .axisBottom(escalaXFin)
              .tickValues([2013, 2016, 2019, 2022])
              .tickFormat(d3.format("d"))
          )
          .end()
          .catch((e) => {
            console.log(
              `cearEjeXViajes: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            grupo
              .select("g.x")
              .call(
                d3
                  .axisBottom(escalaXFin)
                  .tickValues([2013, 2016, 2019, 2022])
                  .tickFormat(d3.format("d"))
              );
            grupo.select("g.x").select("path").attr("stroke", "transparent");
            resolveFn(0);
          });
      });
    } else {
      grupo
        .select("g.x")
        .call(
          d3
            .axisBottom(escalaXFin)
            .tickValues([2013, 2016, 2019, 2022])
            .tickFormat(d3.format("d"))
        );
      grupo.select("g.x").select("path").attr("stroke", "transparent");
    }
  }

  function eliminarLineasGrupo(grupo, caja, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let pathD = grupo.select("g.main").select("path.domestic");
    let pathF = grupo.select("g.main").select("path.foreign");

    grupo.select("g.name").select("text").remove();
    grupo.select("g.covid").select("line").remove();
    grupo.select("g.covid").select("text").remove();

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          eliminarEjeXViajes(grupo, flagTransicion, factor),
          eliminarEjeYViajes(grupo, caja, flagTransicion, factor),
          eliminarPathViajes(pathD, flagTransicion, factor),
          eliminarPathViajes(pathF, flagTransicion, factor),
        ]).finally(function () {
          grupo.select("g.voronoi").selectAll("path").remove();
          resolveFn(0);
        });
      });
    } else {
      eliminarEjeXViajes(grupo);
      eliminarEjeYViajes(grupo, caja);
      eliminarPathViajes(pathD);
      eliminarPathViajes(pathF);
      grupo.select("g.voronoi").selectAll("path").remove();
    }
  }

  function eliminarPathViajes(path, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "path:actualizacion";

    if (flagTransicion) {
      path
        .attr("stroke-dasharray", function () {
          let length = d3.select(this).node().getTotalLength();
          return `${length} ${length}`;
        })
        .attr("stroke-dashoffset", 0);

      return new Promise(function (resolveFn) {
        path
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("stroke-dashoffset", function () {
            return d3.select(this).node().getTotalLength();
          })
          .end()
          .catch((e) => {
            console.log(
              `eliminarPathViaje: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            path.remove();
            resolveFn(0);
          });
      });
    } else {
      path.remove();
    }
  }

  function eliminarEjeXViajes(grupo, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "g.x:actualizacion";

    if (flagTransicion) {
      let escalaX = d3.scaleLinear().domain([0, 0]).range([0, 0]);

      return new Promise(function (resolveFn) {
        grupo
          .select("g.x")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .call(
            d3.axisBottom(escalaX).tickFormat(d3.format("d")).tickValues([])
          )
          .end()
          .catch((e) => {
            console.log(
              `eliminarEjeXViajes: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            grupo.select("g.x").select("path").remove();
            grupo.select("g.x").selectAll("g.tick").remove();
            resolveFn(0);
          });
      });
    } else {
      grupo.select("g.x").select("path").remove();
      grupo.select("g.x").selectAll("g.tick").remove();
    }
  }

  function eliminarEjeYViajes(grupo, caja, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "g.y:actualizacion";

    if (flagTransicion) {
      let escalaY = d3.scaleLinear().domain([0, 0]).range([0, 0]);

      return new Promise(function (resolveFn) {
        grupo
          .select("g.y")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .call(d3.axisLeft(escalaY))
          .attr(
            "transform",
            `translate(${caja.padding.left}, ${
              caja.content.height + caja.padding.top
            })`
          )
          .end()
          .catch((e) => {
            console.log(
              `eliminarEjeYViajes: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            grupo.select("g.y").select("path").remove();
            grupo.select("g.y").selectAll("g.tick").remove();
            resolveFn(0);
          });
      });
    } else {
      grupo.select("g.y").select("path").remove();
      grupo.select("g.y").selectAll("g.tick").remove();
    }
  }

  function actualizarLineasGrupo(grupo, caja, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    let data = grupo.datum();

    let escalaX = d3.scaleLinear().range([0, caja.content.width]);
    escalaX.domain(getDomainX(data));

    let escalaY = d3.scaleLinear().range([caja.content.height, 0]);
    escalaY.domain(getDomainY(data));

    let generadorD = d3
      .line()
      .curve(d3.curveCatmullRom.alpha(0.5))
      .x((d) => escalaX(d.year))
      .y((d) => escalaY(d.domestic));

    let generadorF = d3
      .line()
      .curve(d3.curveCatmullRom.alpha(0.5))
      .x((d) => escalaX(d.year))
      .y((d) => escalaY(d.foreign));

    grupo
      .select("g.name")
      .select("text")
      .datum({ code: data[0].code, country: data[0].country })
      .text((d) => d.country);

    actualizarVoronoiGrupo(
      grupo,
      data,
      escalaX,
      escalaY,
      caja.content.width,
      caja.content.height
    );

    let pathD = grupo.select("g.main").select("path.domestic");
    let pathF = grupo.select("g.main").select("path.foreign");

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          actualizarEjeYViajes(grupo, escalaY, flagTransicion, factor),
          actualizarPathViajes(pathD, data, generadorD, flagTransicion, factor),
          actualizarPathViajes(pathF, data, generadorF, flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      actualizarEjeYViajes(grupo, escalaY);
      actualizarPathViajes(pathD, data, generadorD);
      actualizarPathViajes(pathF, data, generadorF);
    }
  }

  function actualizarEjeYViajes(grupo, escalaY, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "g.y:actualizacion";
    let domain = escalaY.domain();
    let n = domain[1];

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        grupo
          .select("g.y")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .call(
            d3.axisLeft(escalaY).tickValues([0, n / 4, n / 2, (n * 3) / 4, n])
          )
          .end()
          .catch((e) => {
            console.log(
              `actualizarEjeYViajes: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            grupo
              .select("g.y")
              .call(
                d3
                  .axisLeft(escalaY)
                  .tickValues([0, n / 4, n / 2, (n * 3) / 4, n])
              );
            grupo.select("g.y").selectAll("line").style("fill", "grey");
            grupo.select("g.y").select("path").attr("stroke", "transparent");
            grupo
              .select("g.y")
              .selectAll("text")
              .style("fill", "grey")
              .style("font-size", "24px");
            resolveFn(0);
          });
      });
    } else {
      grupo
        .select("g.y")
        .call(
          d3.axisLeft(escalaY).tickValues([0, n / 4, n / 2, (n * 3) / 4, n])
        );
      grupo.select("g.y").selectAll("line").style("fill", "grey");
      grupo.select("g.y").select("path").attr("stroke", "transparent");
      grupo
        .select("g.y")
        .selectAll("text")
        .style("fill", "grey")
        .style("font-size", "24px");
    }
  }

  function actualizarPathViajes(path, data, generador, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "path:actualizacion";

    path.datum(data);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        path
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("d", generador)
          .end()
          .catch((e) => {
            console.log(
              `actualizarPathViajes: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            path.attr("d", generador);
            resolveFn(0);
          });
      });
    } else {
      path.attr("d", generador);
    }
  }

  function actualizarVoronoiGrupo(grupo, data, escalaX, escalaY, ancho, alto) {
    let v = getVoronoiGrid(data, escalaX, escalaY, ancho, alto);

    let pathVoronoi = grupo.select("g.voronoi").selectAll("path").data(v.data);

    pathVoronoi
      .enter()
      .append("path")
      .attr("d", (d, i) => v.voronoi.renderCell(i))
      .attr("fill", "transparent")
      .attr("stroke", "transparent")
      .on("mouseover", crearTooltipVoronoi)
      .on("mouseout", deshacerTooltipVoronoi);

    pathVoronoi.attr("d", (d, i) => v.voronoi.renderCell(i));

    pathVoronoi.exit().remove();
  }

  function crearLineasGrid(grupo, caja) {
    let escalaY = d3.scaleLinear().range([caja.content.height, 0]);
    escalaY.domain(getDomainY(grupo.datum()));

    let escalaX = d3.scaleLinear().range([0, caja.content.width]);
    escalaX.domain(getDomainX(grupo.datum()));

    let pathDomestico = d3
      .line()
      .curve(d3.curveCatmullRom.alpha(0.5))
      .x((d) => escalaX(d.year))
      .y((d) => escalaY(d.domestic));
    let pathForaneo = d3
      .line()
      .curve(d3.curveCatmullRom.alpha(0.5))
      .x((d) => escalaX(d.year))
      .y((d) => escalaY(d.foreign));

    crearLineasGridGeneral(
      grupo,
      escalaX,
      escalaY,
      pathDomestico,
      pathForaneo,
      miniGridBox
    );
  }

  function crearLineasGridGeneral(
    grupo,
    escalaX,
    escalaY,
    pathDomestico,
    pathForaneo,
    caja
  ) {
    let data = grupo.datum();
    let flagHide = grupo.node().classList.contains("svg") ? false : true;
    flagHide = false;

    grupo
      .append("g")
      .attr("class", "name")
      .style("font-size", "34px")
      .style("font-weight", 700)
      .style("fill", "black")
      .call(hideCondicional, flagHide)
      .append("text")
      .attr("text-anchor", "start")
      .attr("dominant-baseline", "hanging")
      .datum({ code: data[0].code, country: data[0].country })
      .attr("x", 0)
      .attr("y", 0)
      .text((d) => d.country);

    grupo
      .append("g")
      .attr("class", "x")
      .style("font-size", "22px")
      .attr("fill", "grey")
      .call(
        d3
          .axisBottom(escalaX)
          .tickValues([2013, 2016, 2019, 2022])
          .tickFormat(d3.format("d"))
      )
      .attr(
        "transform",
        `translate(${caja.padding.left}, ${
          caja.content.height + caja.padding.top
        })`
      )
      .call(hideCondicional, flagHide)
      .select("path")
      .attr("stroke", "transparent");

    let domain = escalaY.domain();
    let n = domain[1];
    grupo
      .append("g")
      .attr("class", "y")
      .style("font-size", "22px")
      .attr("fill", "grey")
      .call(d3.axisLeft(escalaY).tickValues([0, n / 4, n / 2, (n * 3) / 4, n]))
      .attr("transform", `translate(${caja.padding.left}, ${caja.padding.top})`)
      .call(hideCondicional, flagHide)
      .select("path")
      .attr("stroke", "transparent");

    grupo
      .append("g")
      .attr("class", "covid ignore")
      .attr("transform", `translate(${caja.padding.left}, ${caja.padding.top})`)
      .call(hideCondicional, flagHide)
      .append("line")
      .attr("x1", escalaX(2020))
      .attr("y1", 0)
      .attr("x2", escalaX(2020))
      .attr("y2", caja.content.height)
      .style("stroke", "#000000")
      .style("stroke-width", "3px")
      .style("stroke-dasharray", "5, 5");

    grupo
      .select("g.covid")
      .style("font-size", "26px")
      .style("font-weight", 700)
      .style("fill", "grey")
      .attr("text-anchor", "end")
      .attr("dominant-baseline", "middle")
      .append("text")
      .attr("x", escalaX(2020) - 10)
      .attr("y", 0)
      .text("Pandemic starts");

    grupo
      .append("g")
      .attr("class", "main")
      .attr("transform", `translate(${caja.padding.left}, ${caja.padding.top})`)
      .call(hideCondicional, flagHide);

    grupo
      .append("g")
      .attr("class", "voronoi")
      .attr("transform", `translate(${caja.padding.left}, ${caja.padding.top})`)
      .call(hideCondicional, flagHide);

    grupo.select("g.main").attr("fill", "none").attr("stroke-linecap", "round");

    grupo
      .select("g.main")
      .append("path")
      .attr("class", "domestic")
      .datum(data)
      .attr("d", pathDomestico)
      .attr("stroke-width", "3px")
      .attr("stroke", "#165ce9");

    grupo
      .select("g.main")
      .append("path")
      .attr("class", "foreign")
      .datum(data)
      .attr("d", pathForaneo)
      .attr("stroke-width", "3px")
      .attr("stroke", "#4fc9a4");

    actualizarVoronoiGrupo(
      grupo,
      data,
      escalaX,
      escalaY,
      caja.content.width,
      caja.content.height
    );
  }

  function getVoronoiGrid(data, escalaX, escalaY, ancho, alto) {
    let voronoiData = [];

    _.each(data, function (d) {
      voronoiData.push({
        x: escalaX(d.year),
        y: escalaY(d.domestic),
        value: formatter.format(d.domestic),
        type: "domestic",
        year: d.year,
      });
      voronoiData.push({
        x: escalaX(d.year),
        y: escalaY(d.foreign),
        value: formatter.format(d.foreign),
        type: "foreign",
        year: d.year,
      });
    });

    let delaunay = d3.Delaunay.from(
      voronoiData,
      (d) => d.x,
      (d) => d.y
    );
    let voronoi = delaunay.voronoi([0, 0, ancho, alto]);

    return { data: voronoiData, voronoi: voronoi };
  }

  function hide(el) {
    el.style("display", "none").style("opacity", 0);
  }

  function hideCondicional(el, flagHide) {
    flagHide = flagHide || false;
    if (flagHide) {
      el.style("display", "none").style("opacity", 0);
    }
  }

  function unhide(el) {
    el.style("display", null).style("opacity", null);
  }

  function mostrarCapas(capa, params) {
    capas.anterior = capas.actual;
    capas.actual = capa || null;
    console.dir(capas);

    if (capas.actual == capas.anterior) {
      return 0;
    }

    let flagTransicion = !isReduced;

    if (capas.anterior == null && capas.actual == "base") {
      startTimerGlobe(flagTransicion);
    }

    if (capas.anterior == null && capas.actual == "france") {
      transicionBaseAGlobo(flagTransicion);
    }

    if (capas.anterior == null && capas.actual == "zoom") {
      transicionBaseAZoom(params, flagTransicion);
    }

    if (capas.anterior == null && capas.actual == "globe") {
      transicionBaseAGlobo(flagTransicion);
    }

    if (capas.anterior == null && capas.actual == "arrows") {
      transicionBaseAFlechas(params, flagTransicion);
    }

    if (capas.anterior == null && capas.actual == "sankey_france") {
      transicionBaseASankey(
        getSankey(params.sankeysPorPais, "FR"),
        params,
        flagTransicion
      );
    }

    if (capas.anterior == null && capas.actual == "sankey_eu") {
      transicionBaseASankey(
        getSankey(params.sankeysPorPais, "EU"),
        params,
        flagTransicion
      );
    }

    if (capas.anterior == null && capas.actual == "sankey_var") {
      transicionBaseASankeyConControles(
        getSankey(params.sankeysPorPais, params.codigo),
        params,
        flagTransicion
      );
    }

    if (capas.anterior == null && capas.actual == "line_chart") {
      transicionBaseALineas(
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == null && capas.actual == "highlight_lines_1") {
      transicionBaseAGrid_1(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == null && capas.actual == "highlight_lines_2") {
      transicionBaseAGrid_2(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == null && capas.actual == "highlight_lines_3") {
      transicionBaseAGrid_3(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == null && capas.actual == "highlight_lines_4") {
      transicionBaseAGrid_4(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "base" && capas.actual == "france") {
      transicionBaseAGlobo(flagTransicion);
    }

    if (capas.anterior == "base" && capas.actual == "zoom") {
      transicionBaseAZoom(params, flagTransicion);
    }

    if (capas.anterior == "base" && capas.actual == "globe") {
      transicionBaseAGlobo(flagTransicion);
    }

    if (capas.anterior == "base" && capas.actual == "arrows") {
      transicionBaseAFlechas(params, flagTransicion);
    }

    if (capas.anterior == "base" && capas.actual == "sankey_france") {
      transicionBaseASankey(
        getSankey(params.sankeysPorPais, "FR"),
        params,
        flagTransicion
      );
    }

    if (capas.anterior == "base" && capas.actual == "sankey_eu") {
      transicionBaseASankey(
        getSankey(params.sankeysPorPais, "EU"),
        params,
        flagTransicion
      );
    }

    if (capas.anterior == "base" && capas.actual == "sankey_var") {
      transicionBaseASankeyConControles(
        getSankey(params.sankeysPorPais, params.codigo),
        params,
        flagTransicion
      );
    }

    if (capas.anterior == "base" && capas.actual == "line_chart") {
      transicionBaseALineas(
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "base" && capas.actual == "highlight_lines_1") {
      transicionBaseAGrid_1(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "base" && capas.actual == "highlight_lines_2") {
      transicionBaseAGrid_2(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "base" && capas.actual == "highlight_lines_3") {
      transicionBaseAGrid_3(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "base" && capas.actual == "highlight_lines_4") {
      transicionBaseAGrid_4(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "france" && capas.actual == "zoom") {
      transicionGloboAZoom(params, flagTransicion);
    }

    if (capas.anterior == "france" && capas.actual == "arrows") {
      transicionGloboAFlechas(params, flagTransicion);
    }

    if (capas.anterior == "france" && capas.actual == "sankey_france") {
      transicionFranciaASankey(
        getSankey(params.sankeysPorPais, "FR"),
        flagTransicion
      );
    }

    if (capas.anterior == "france" && capas.actual == "sankey_eu") {
      transicionFranciaASankey(
        getSankey(params.sankeysPorPais, "EU"),
        flagTransicion
      );
    }

    if (capas.anterior == "france" && capas.actual == "sankey_var") {
      transicionFranciaASankeyConControles(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "france" && capas.actual == "line_chart") {
      transicionFranciaALineas(
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "france" && capas.actual == "highlight_lines_1") {
      transicionFranciaAGrid_1(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "france" && capas.actual == "highlight_lines_2") {
      transicionFranciaAGrid_2(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "france" && capas.actual == "highlight_lines_3") {
      transicionFranciaAGrid_3(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "france" && capas.actual == "highlight_lines_4") {
      transicionFranciaAGrid_4(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "zoom" && capas.actual == "france") {
      transicionZoomAGlobo(flagTransicion);
    }

    if (capas.anterior == "zoom" && capas.actual == "globe") {
      transicionZoomAGlobo(flagTransicion);
    }

    if (capas.anterior == "zoom" && capas.actual == "arrows") {
      transicionZoomAFlechas(params, flagTransicion);
    }

    if (capas.anterior == "zoom" && capas.actual == "sankey_france") {
      transicionZoomASankey(
        getSankey(params.sankeysPorPais, "FR"),
        flagTransicion
      );
    }

    if (capas.anterior == "zoom" && capas.actual == "sankey_eu") {
      transicionZoomASankey(
        getSankey(params.sankeysPorPais, "EU"),
        flagTransicion
      );
    }

    if (capas.anterior == "zoom" && capas.actual == "sankey_var") {
      transicionZoomASankeyConControles(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "zoom" && capas.actual == "line_chart") {
      transicionZoomALineas(
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "zoom" && capas.actual == "highlight_lines_1") {
      transicionZoomAGrid_1(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "zoom" && capas.actual == "highlight_lines_2") {
      transicionZoomAGrid_2(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "zoom" && capas.actual == "highlight_lines_3") {
      transicionZoomAGrid_3(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "zoom" && capas.actual == "highlight_lines_4") {
      transicionZoomAGrid_4(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "globe" && capas.actual == "zoom") {
      transicionGloboAZoom(params, flagTransicion);
    }

    if (capas.anterior == "globe" && capas.actual == "arrows") {
      transicionGloboAFlechas(params, flagTransicion);
    }

    if (capas.anterior == "globe" && capas.actual == "sankey_france") {
      transicionFranciaASankey(
        getSankey(params.sankeysPorPais, "FR"),
        flagTransicion
      );
    }

    if (capas.anterior == "globe" && capas.actual == "sankey_eu") {
      transicionFranciaASankey(
        getSankey(params.sankeysPorPais, "EU"),
        flagTransicion
      );
    }

    if (capas.anterior == "globe" && capas.actual == "sankey_var") {
      transicionFranciaASankeyConControles(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "globe" && capas.actual == "line_chart") {
      transicionFranciaALineas(
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "globe" && capas.actual == "highlight_lines_1") {
      transicionFranciaAGrid_1(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "globe" && capas.actual == "highlight_lines_2") {
      transicionFranciaAGrid_2(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "globe" && capas.actual == "highlight_lines_3") {
      transicionFranciaAGrid_3(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "globe" && capas.actual == "highlight_lines_4") {
      transicionFranciaAGrid_4(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "arrows" && capas.actual == "france") {
      transicionFlechasAGlobo(flagTransicion);
    }

    if (capas.anterior == "arrows" && capas.actual == "zoom") {
      transicionFlechasAZoom(params, flagTransicion);
    }

    if (capas.anterior == "arrows" && capas.actual == "globe") {
      transicionFlechasAGlobo(flagTransicion);
    }

    if (capas.anterior == "arrows" && capas.actual == "sankey_france") {
      transicionFlechasASankey(
        getSankey(params.sankeysPorPais, "FR"),
        flagTransicion
      );
    }

    if (capas.anterior == "arrows" && capas.actual == "sankey_eu") {
      transicionFlechasASankey(
        getSankey(params.sankeysPorPais, "EU"),
        flagTransicion
      );
    }

    if (capas.anterior == "arrows" && capas.actual == "sankey_var") {
      transicionFlechasASankeyConControles(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "arrows" && capas.actual == "line_chart") {
      transicionFlechasALineas(
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "arrows" && capas.actual == "highlight_lines_1") {
      transicionFlechasAGrid_1(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "arrows" && capas.actual == "highlight_lines_2") {
      transicionFlechasAGrid_2(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "arrows" && capas.actual == "highlight_lines_3") {
      transicionFlechasAGrid_3(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "arrows" && capas.actual == "highlight_lines_4") {
      transicionFlechasAGrid_4(
        params.viajesComparadosPorPais,
        getSankey(params.sankeysPorPais, params.codigo),
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_france" && capas.actual == "france") {
      transicionSankeyAGlobo(flagTransicion);
    }

    if (capas.anterior == "sankey_france" && capas.actual == "zoom") {
      transicionSankeyAZoom(params, flagTransicion);
    }

    if (capas.anterior == "sankey_france" && capas.actual == "globe") {
      transicionSankeyAGlobo(flagTransicion);
    }

    if (capas.anterior == "sankey_france" && capas.actual == "arrows") {
      transicionSankeyAFlechas(params, flagTransicion);
    }

    if (capas.anterior == "sankey_france" && capas.actual == "sankey_eu") {
      actualizarSankey(getSankey(params.sankeysPorPais, "EU"), flagTransicion);
    }

    if (capas.anterior == "sankey_france" && capas.actual == "sankey_var") {
      actualizarSankeyConControles(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_france" && capas.actual == "line_chart") {
      transicionSankeyALineas(
        params.viajesComparadosPorPais["FR"],
        flagTransicion
      );
    }

    if (
      capas.anterior == "sankey_france" &&
      capas.actual == "highlight_lines_1"
    ) {
      transicionSankeyAGrid_1(
        params.viajesComparadosPorPais,
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (
      capas.anterior == "sankey_france" &&
      capas.actual == "highlight_lines_2"
    ) {
      transicionSankeyAGrid_2(
        params.viajesComparadosPorPais,
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (
      capas.anterior == "sankey_france" &&
      capas.actual == "highlight_lines_3"
    ) {
      transicionSankeyAGrid_3(
        params.viajesComparadosPorPais,
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (
      capas.anterior == "sankey_france" &&
      capas.actual == "highlight_lines_4"
    ) {
      transicionSankeyAGrid_4(
        params.viajesComparadosPorPais,
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_eu" && capas.actual == "france") {
      transicionSankeyAGlobo(flagTransicion);
    }

    if (capas.anterior == "sankey_eu" && capas.actual == "zoom") {
      transicionSankeyAZoom(params, flagTransicion);
    }

    if (capas.anterior == "sankey_eu" && capas.actual == "globe") {
      transicionSankeyAGlobo(flagTransicion);
    }

    if (capas.anterior == "sankey_eu" && capas.actual == "arrows") {
      transicionSankeyAFlechas(params, flagTransicion);
    }

    if (capas.anterior == "sankey_eu" && capas.actual == "sankey_france") {
      actualizarSankey(getSankey(params.sankeysPorPais, "FR"), flagTransicion);
    }

    if (capas.anterior == "sankey_eu" && capas.actual == "sankey_var") {
      actualizarSankeyConControles(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_eu" && capas.actual == "line_chart") {
      transicionSankeyALineas(
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion,
        40
      );
    }

    if (capas.anterior == "sankey_eu" && capas.actual == "highlight_lines_1") {
      transicionSankeyAGrid_1(
        params.viajesComparadosPorPais,
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_eu" && capas.actual == "highlight_lines_2") {
      transicionSankeyAGrid_2(
        params.viajesComparadosPorPais,
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_eu" && capas.actual == "highlight_lines_3") {
      transicionSankeyAGrid_3(
        params.viajesComparadosPorPais,
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_eu" && capas.actual == "highlight_lines_4") {
      transicionSankeyAGrid_4(
        params.viajesComparadosPorPais,
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_var" && capas.actual == "france") {
      transicionSankeyConControlesAGlobo(flagTransicion);
    }

    if (capas.anterior == "sankey_var" && capas.actual == "zoom") {
      transicionSankeyConControlesAZoom(params, flagTransicion);
    }

    if (capas.anterior == "sankey_var" && capas.actual == "globe") {
      transicionSankeyConControlesAGlobo(flagTransicion);
    }

    if (capas.anterior == "sankey_var" && capas.actual == "arrows") {
      transicionSankeyConControlesAFlechas(params, flagTransicion);
    }

    if (capas.anterior == "sankey_var" && capas.actual == "sankey_france") {
      actualizarSankeySinControles(
        getSankey(params.sankeysPorPais, "FR"),
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_var" && capas.actual == "sankey_eu") {
      actualizarSankeySinControles(
        getSankey(params.sankeysPorPais, "EU"),
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_var" && capas.actual == "line_chart") {
      transicionSankeyALineas(
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_var" && capas.actual == "highlight_lines_1") {
      transicionSankeyAGrid_1(
        params.viajesComparadosPorPais,
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_var" && capas.actual == "highlight_lines_2") {
      transicionSankeyAGrid_2(
        params.viajesComparadosPorPais,
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_var" && capas.actual == "highlight_lines_3") {
      transicionSankeyAGrid_3(
        params.viajesComparadosPorPais,
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "sankey_var" && capas.actual == "highlight_lines_4") {
      transicionSankeyAGrid_4(
        params.viajesComparadosPorPais,
        params.viajesComparadosPorPais[params.codigo],
        flagTransicion
      );
    }

    if (capas.anterior == "line_chart" && capas.actual == "france") {
      transicionLineasAGlobo(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "line_chart" && capas.actual == "zoom") {
      transicionLineasAZoom(
        params,
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "line_chart" && capas.actual == "globe") {
      transicionLineasAGlobo(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "line_chart" && capas.actual == "arrows") {
      transicionLineasAFlechas(
        params,
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "line_chart" && capas.actual == "sankey_france") {
      transicionLineasASankey(
        getSankey(params.sankeysPorPais, "FR"),
        flagTransicion
      );
    }

    if (capas.anterior == "line_chart" && capas.actual == "sankey_eu") {
      transicionLineasASankey(
        getSankey(params.sankeysPorPais, "EU"),
        flagTransicion
      );
    }

    if (capas.anterior == "line_chart" && capas.actual == "sankey_var") {
      transicionLineasASankeyConControles(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "line_chart" && capas.actual == "highlight_lines_1") {
      transicionLineasAGrid_1(params.viajesComparadosPorPais, flagTransicion);
    }

    if (capas.anterior == "line_chart" && capas.actual == "highlight_lines_2") {
      transicionLineasAGrid_2(params.viajesComparadosPorPais, flagTransicion);
    }

    if (capas.anterior == "line_chart" && capas.actual == "highlight_lines_3") {
      transicionLineasAGrid_3(params.viajesComparadosPorPais, flagTransicion);
    }

    if (capas.anterior == "line_chart" && capas.actual == "highlight_lines_4") {
      transicionLineasAGrid_4(params.viajesComparadosPorPais, flagTransicion);
    }

    if (capas.anterior == "highlight_lines_1" && capas.actual == "france") {
      transicionGridAGlobo(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_1" && capas.actual == "zoom") {
      transicionGridAZoom(
        params,
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_1" && capas.actual == "globe") {
      transicionGridAGlobo(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_1" && capas.actual == "arrows") {
      transicionGridAFlechas(
        params,
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (
      capas.anterior == "highlight_lines_1" &&
      capas.actual == "sankey_france"
    ) {
      transicionGridASankey(
        getSankey(params.sankeysPorPais, "FR"),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_1" && capas.actual == "sankey_eu") {
      transicionGridASankey(
        getSankey(params.sankeysPorPais, "EU"),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_1" && capas.actual == "sankey_var") {
      transicionGridASankeyConControles(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_1" && capas.actual == "line_chart") {
      transicionGridALineas(flagTransicion);
    }

    if (
      capas.anterior == "highlight_lines_1" &&
      capas.actual == "highlight_lines_2"
    ) {
      transicionAGrid_2(params.viajesComparadosPorPais, flagTransicion);
    }

    if (
      capas.anterior == "highlight_lines_1" &&
      capas.actual == "highlight_lines_3"
    ) {
      transicionAGrid_3(params.viajesComparadosPorPais, flagTransicion);
    }

    if (
      capas.anterior == "highlight_lines_1" &&
      capas.actual == "highlight_lines_4"
    ) {
      transicionAGrid_4(params.viajesComparadosPorPais, flagTransicion);
    }

    if (capas.anterior == "highlight_lines_2" && capas.actual == "france") {
      transicionGridAGlobo(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_2" && capas.actual == "zoom") {
      transicionGridAZoom(
        params,
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_2" && capas.actual == "globe") {
      transicionGridAGlobo(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_2" && capas.actual == "arrows") {
      transicionGridAFlechas(
        params,
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (
      capas.anterior == "highlight_lines_2" &&
      capas.actual == "sankey_france"
    ) {
      transicionGridASankey(
        getSankey(params.sankeysPorPais, "FR"),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_2" && capas.actual == "sankey_eu") {
      transicionGridASankey(
        getSankey(params.sankeysPorPais, "EU"),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_2" && capas.actual == "sankey_var") {
      transicionGridASankeyConControles(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_2" && capas.actual == "line_chart") {
      transicionGridALineas(flagTransicion);
    }

    if (
      capas.anterior == "highlight_lines_2" &&
      capas.actual == "highlight_lines_1"
    ) {
      transicionAGrid_1(params.viajesComparadosPorPais, flagTransicion);
    }

    if (
      capas.anterior == "highlight_lines_2" &&
      capas.actual == "highlight_lines_3"
    ) {
      transicionAGrid_3(params.viajesComparadosPorPais, flagTransicion);
    }

    if (
      capas.anterior == "highlight_lines_2" &&
      capas.actual == "highlight_lines_4"
    ) {
      transicionAGrid_4(params.viajesComparadosPorPais, flagTransicion);
    }

    if (capas.anterior == "highlight_lines_3" && capas.actual == "france") {
      transicionGridAGlobo(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_3" && capas.actual == "zoom") {
      transicionGridAZoom(
        params,
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_3" && capas.actual == "globe") {
      transicionGridAGlobo(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_3" && capas.actual == "arrows") {
      transicionGridAFlechas(
        params,
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (
      capas.anterior == "highlight_lines_3" &&
      capas.actual == "sankey_france"
    ) {
      transicionGridASankey(
        getSankey(params.sankeysPorPais, "FR"),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_3" && capas.actual == "sankey_eu") {
      transicionGridASankey(
        getSankey(params.sankeysPorPais, "EU"),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_3" && capas.actual == "sankey_var") {
      transicionGridASankeyConControles(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_3" && capas.actual == "line_chart") {
      transicionGridALineas(flagTransicion);
    }

    if (
      capas.anterior == "highlight_lines_3" &&
      capas.actual == "highlight_lines_1"
    ) {
      transicionAGrid_1(params.viajesComparadosPorPais, flagTransicion);
    }

    if (
      capas.anterior == "highlight_lines_3" &&
      capas.actual == "highlight_lines_2"
    ) {
      transicionAGrid_2(params.viajesComparadosPorPais, flagTransicion);
    }

    if (
      capas.anterior == "highlight_lines_3" &&
      capas.actual == "highlight_lines_4"
    ) {
      transicionAGrid_4(params.viajesComparadosPorPais, flagTransicion);
    }

    if (capas.anterior == "highlight_lines_4" && capas.actual == "france") {
      transicionGridAGlobo(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_4" && capas.actual == "zoom") {
      transicionGridAZoom(
        params,
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_4" && capas.actual == "globe") {
      transicionGridAGlobo(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_4" && capas.actual == "arrows") {
      transicionGridAFlechas(
        params,
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (
      capas.anterior == "highlight_lines_4" &&
      capas.actual == "sankey_france"
    ) {
      transicionGridASankey(
        getSankey(params.sankeysPorPais, "FR"),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_4" && capas.actual == "sankey_eu") {
      transicionGridASankey(
        getSankey(params.sankeysPorPais, "EU"),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_4" && capas.actual == "sankey_var") {
      transicionGridASankeyConControles(
        getSankey(params.sankeysPorPais, params.codigo),
        flagTransicion
      );
    }

    if (capas.anterior == "highlight_lines_4" && capas.actual == "line_chart") {
      transicionGridALineas(flagTransicion);
    }

    if (
      capas.anterior == "highlight_lines_4" &&
      capas.actual == "highlight_lines_1"
    ) {
      transicionAGrid_1(params.viajesComparadosPorPais, flagTransicion);
    }
    if (
      capas.anterior == "highlight_lines_4" &&
      capas.actual == "highlight_lines_2"
    ) {
      transicionAGrid_2(params.viajesComparadosPorPais, flagTransicion);
    }
    if (
      capas.anterior == "highlight_lines_4" &&
      capas.actual == "highlight_lines_3"
    ) {
      transicionAGrid_3(params.viajesComparadosPorPais, flagTransicion);
    }
  }

  window.mostrarCapas = mostrarCapas;

  function transicionGloboZoomIn(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "circle:proyeccion";

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        gGlobo
          .select("circle")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("r", projectionFrance.scale())
          .end()
          .catch((e) => {
            console.log(
              `transicionGloboZoomIn: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            gGlobo.select("circle").attr("r", projectionFrance.scale());
            resolveFn(0);
          });
      });
    } else {
      gGlobo.select("circle").attr("r", projectionFrance.scale());
    }
  }

  function transicionGloboZoomOut(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "circle:proyeccion";

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        gGlobo
          .select("circle")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attr("r", projectionGlobe.scale())
          .end()
          .catch((e) => {
            console.log(
              `transicionGloboZoomOut: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            gGlobo.select("circle").attr("r", projectionGlobe.scale());
            resolveFn(0);
          });
      });
    } else {
      gGlobo.select("circle").attr("r", projectionGlobe.scale());
    }
  }

  function transicionFranciaBordeGrueso(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "france:stroke-width";

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        gMapa
          .select("#france")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("stroke-width", 4)
          .end()
          .catch((e) => {
            console.log(
              `transicionFranciaBordeGrueso: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            gMapa.select("#france").style("stroke-width", 4);
            resolveFn(0);
          });
      });
    } else {
      gMapa.select("#france").style("stroke-width", 4);
    }
  }

  function transicionFranciaBordeDelgado(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "france:stroke-width";

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        gMapa
          .select("#france")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("stroke-width", (d) => d.properties["stroke-width"])
          .end()
          .catch((e) => {
            console.log(
              `transicionFranciaBordeDelgado: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            gMapa
              .select("#france")
              .style("stroke-width", (d) => d.properties["stroke-width"]);
            resolveFn(0);
          });
      });
    } else {
      gMapa
        .select("#france")
        .style("stroke-width", (d) => d.properties["stroke-width"]);
    }
  }

  function transicionFranciaBordeColor(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "france:stroke";

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        gMapa
          .select("#france")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("stroke", (d) => d.properties.stroke)
          .end()
          .catch((e) => {
            console.log(
              `transicionFranciaBordeColor: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            gMapa.select("#france").style("stroke", (d) => d.properties.stroke);
            resolveFn(0);
          });
      });
    } else {
      gMapa.select("#france").style("stroke", (d) => d.properties.stroke);
    }
  }

  function transicionPaisesZoomIn(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "paths:proyeccion";

    let projectionTransition = projectionFrance;
    let pathTransition = pathFrance;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        gMapa
          .selectAll("path")
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .attrTween("d", function (d) {
            let r = d3.interpolate(
              projectionGlobe.rotate(),
              projectionTransition.rotate()
            );
            let s = d3.interpolate(
              projectionGlobe.scale(),
              projectionTransition.scale()
            );
            return function (t) {
              projectionTransition.rotate(r(t)).scale(s(t));
              pathTransition.projection(projectionTransition);
              return pathTransition(d);
            };
          })
          .end()
          .catch((e) => {
            console.log(
              `transicionPaisesZoomIn: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            gMapa.selectAll("path").attr("d", pathFrance);
            resolveFn(0);
          });
      });
    } else {
      gMapa.selectAll("path").attr("d", pathFrance);
    }
  }

  function transicionPaisesZoomOut(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "paths:proyeccion";

    let projectionTransition = projectionGlobe;
    let pathTransition = pathGlobe;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        gMapa
          .selectAll("path")
          .transition(nombreTransicion)
          .duration(duracion)
          .attrTween("d", function (d) {
            let s = d3.interpolate(
              projectionFrance.scale(),
              projectionTransition.scale()
            );
            return function (t) {
              projectionTransition.scale(s(t));
              pathTransition.projection(projectionTransition);
              return pathTransition(d);
            };
          })
          .end()
          .catch((e) => {
            console.log(
              `transicionPaisesZoomOut: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            gMapa.selectAll("path").attr("d", pathGlobe);
            resolveFn(0);
          });
      });
    } else {
      gMapa.selectAll("path").attr("d", pathGlobe);
    }
  }

  function transicionGloboAZoom(params, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionGloboZoomIn(true, factor),
          transicionFranciaBordeGrueso(true, factor),
          transicionPaisesZoomIn(true, factor),
          quitarEtiqueta(true, factor),
        ]).finally(function () {
          Promise.all([
            crearDepartamentos(params, true, factor),
            crearCirculos(params, true, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionGloboZoomIn();
      transicionFranciaBordeGrueso();
      transicionPaisesZoomIn();
      crearDepartamentos(params);
      crearCirculos(params);
    }
  }

  function transicionZoomAGlobo(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionFranciaBordeDelgado(true, factor),
          quitarDepartamentos(true, factor),
          quitarCirculos(true, factor),
        ]).finally(function () {
          Promise.all([
            transicionGloboZoomOut(true, factor),
            transicionPaisesZoomOut(true, factor),
          ]).finally(function () {
            Promise.all([crearEtiqueta(true, factor)]).finally(function () {
              resolveFn(0);
            });
          });
        });
      });
    } else {
      quitarDepartamentos();
      quitarCirculos();
      transicionFranciaBordeDelgado();
      transicionGloboZoomOut();
      transicionPaisesZoomOut();
      crearEtiqueta();
    }
  }

  function transicionZoomAFlechas(params, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      new Promise(function (resolveFn) {
        Promise.all([transicionZoomAGlobo(true, factor)]).finally(function () {
          Promise.all([transicionGloboAFlechas(params, true, factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
      });
    } else {
      transicionZoomAGlobo();
      transicionGloboAFlechas(params);
    }
  }

  function transicionGloboAFlechas(params, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          quitarEtiqueta(true, factor),
          crearFlechas(params, true, factor),
        ]).finally(function () {
          startTimerGlobe(true);
          resolveFn(0);
        });
      });
    } else {
      quitarEtiqueta();
      crearFlechas(params);
      startTimerGlobe();
    }
  }

  function transicionFlechasAGlobo(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    stopTimerGlobe();

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          quitarFlechas(true, factor),
          transicionFranciaCentrar(true, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      quitarFlechas();
      transicionFranciaCentrar();
    }
  }

  function transicionFlechasAZoom(params, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      new Promise(function (resolveFn) {
        Promise.all([transicionFlechasAGlobo(true, factor)]).finally(
          function () {
            Promise.all([transicionGloboAZoom(params, true, factor)]).finally(
              function () {
                resolveFn(0);
              }
            );
          }
        );
      });
    } else {
      transicionFlechasAGlobo();
      transicionGloboAZoom();
    }
  }

  function transicionBaseAZoom(params, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionBaseAGlobo(true, factor)]).finally(function () {
          Promise.all([transicionGloboAZoom(params, true, factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
      });
    } else {
      transicionBaseAGlobo();
      transicionGloboAZoom(params);
    }
  }

  function transicionBaseAFlechas(params, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionBaseAGlobo(true, factor)]).finally(function () {
          Promise.all([transicionGloboAFlechas(params, true, factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
      });
    } else {
      transicionBaseAGlobo();
      transicionGloboAFlechas(params);
    }
  }

  function transicionBaseALineas(
    dataSankey,
    dataViajes,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionBaseASankeyConControles(dataSankey, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([
            transicionSankeyALineas(dataViajes, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionBaseASankeyConControles(dataSankey);
      transicionSankeyALineas(dataViajes);
    }
  }

  function transicionLineasAGrid(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    gMiniGrid.selectAll("g.cell").call(unhide);
    gMiniGrid.selectAll("g.cell").select("g.name").call(unhide);
    gMiniGrid.selectAll("g.cell").select("g.x").call(unhide);
    gMiniGrid.selectAll("g.cell").select("g.y").call(unhide);
    gMiniGrid.selectAll("g.cell").select("g.covid").call(unhide);
    gMiniGrid.selectAll("g.cell").select("g.main").call(unhide);
    gMiniGrid.selectAll("g.cell").select("g.voronoi").call(unhide);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          ocultarGrupoControles(flagTransicion, factor),
          ocultarGrupoLineas(flagTransicion, factor),
          ocultarGrupoName(flagTransicion, factor),
          ocultarGrupoX(flagTransicion, factor),
          ocultarGrupoY(flagTransicion, factor),
          ocultarGrupoCovid(flagTransicion, factor),
          ocultarGrupoMain(flagTransicion, factor),
          ocultarGrupoVoronoi(flagTransicion, factor),
          mostrarGrupoMiniGrid(flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      ocultarGrupoControles();
      ocultarGrupoLineas();
      ocultarGrupoName();
      ocultarGrupoX();
      ocultarGrupoY();
      ocultarGrupoCovid();
      ocultarGrupoMain();
      ocultarGrupoVoronoi();
      mostrarGrupoMiniGrid();
    }
  }

  function transicionGridALineas(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          mostrarGrupoControles(flagTransicion, factor),
          mostrarGrupoLineas(flagTransicion, factor),
          mostrarGrupoName(flagTransicion, factor),
          mostrarGrupoX(flagTransicion, factor),
          mostrarGrupoY(flagTransicion, factor),
          mostrarGrupoCovid(flagTransicion, factor),
          mostrarGrupoMain(flagTransicion, factor),
          mostrarGrupoVoronoi(flagTransicion, factor),
          ocultarGrupoMiniGrid(flagTransicion, factor),
        ]).finally(function () {
          gMiniGrid.selectAll("g.cell").call(hide);
          gMiniGrid.selectAll("g.cell").select("g.name").call(hide);
          gMiniGrid.selectAll("g.cell").select("g.x").call(hide);
          gMiniGrid.selectAll("g.cell").select("g.y").call(hide);
          gMiniGrid.selectAll("g.cell").select("g.covid").call(hide);
          gMiniGrid.selectAll("g.cell").select("g.main").call(hide);
          gMiniGrid.selectAll("g.cell").select("g.voronoi").call(hide);
          resolveFn(0);
        });
      });
    } else {
      mostrarGrupoControles();
      mostrarGrupoLineas();
      mostrarGrupoName();
      mostrarGrupoX();
      mostrarGrupoY();
      mostrarGrupoCovid();
      mostrarGrupoMain();
      mostrarGrupoVoronoi();
      ocultarGrupoMiniGrid();
      gMiniGrid.selectAll("g.cell").call(hide);
      gMiniGrid.selectAll("g.cell").select("g.name").call(hide);
      gMiniGrid.selectAll("g.cell").select("g.x").call(hide);
      gMiniGrid.selectAll("g.cell").select("g.y").call(hide);
      gMiniGrid.selectAll("g.cell").select("g.covid").call(hide);
      gMiniGrid.selectAll("g.cell").select("g.main").call(hide);
      gMiniGrid.selectAll("g.cell").select("g.voronoi").call(hide);
    }
  }
  window.transicionGridALineas = transicionGridALineas;

  function transicionAGrid_1(viajes, flagTransicion, factor) {
    actualizarLineasGrid(
      [
        getViajes(viajes, "AT"),
        getViajes(viajes, "DE"),
        getViajes(viajes, "NL"),
        getViajes(viajes, "SI"),
      ],
      flagTransicion,
      factor
    );
  }

  function transicionAGrid_2(viajes, flagTransicion, factor) {
    actualizarLineasGrid(
      [
        getViajes(viajes, "EL"),
        getViajes(viajes, "IT"),
        getViajes(viajes, "PL"),
        getViajes(viajes, "SK"),
      ],
      flagTransicion,
      factor
    );
  }

  function transicionAGrid_3(viajes, flagTransicion, factor) {
    actualizarLineasGrid(
      [getViajes(viajes, "IE"), null, null, null],
      flagTransicion,
      factor
    );
  }

  function transicionAGrid_4(viajes, flagTransicion, factor) {
    actualizarLineasGrid(
      [getViajes(viajes, "IE"), getViajes(viajes, "LT"), null, null],
      flagTransicion,
      factor
    );
  }

  function transicionSankeyAZoom(params, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionSankeyAGlobo(flagTransicion, factor)]).finally(
          function () {
            Promise.all([
              transicionGloboAZoom(params, flagTransicion, factor),
            ]).finally(function () {
              resolveFn(0);
            });
          }
        );
      });
    } else {
      transicionSankeyAGlobo();
      transicionGloboAZoom(params);
    }
  }

  function transicionSankeyConControlesAZoom(params, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionSankeyConControlesAGlobo(flagTransicion, factor),
        ]).finally(function () {
          Promise.all([
            transicionGloboAZoom(params, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionSankeyConControlesAGlobo();
      transicionGloboAZoom(params);
    }
  }

  function transicionSankeyAFlechas(params, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionSankeyAGlobo(flagTransicion, factor)]).finally(
          function () {
            Promise.all([
              transicionGloboAFlechas(params, flagTransicion, factor),
            ]).finally(function () {
              resolveFn(0);
            });
          }
        );
      });
    } else {
      transicionSankeyAGlobo();
      transicionGloboAFlechas(params);
    }
  }

  function transicionSankeyConControlesAFlechas(
    params,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionSankeyConControlesAGlobo(flagTransicion, factor),
        ]).finally(function () {
          Promise.all([
            transicionGloboAFlechas(params, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionSankeyConControlesAGlobo();
      transicionGloboAFlechas(params);
    }
  }

  function transicionSankeyAGlobo(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      gMapa
        .append("path")
        .attr("d", interpoladorNodoFranciaAFrancia(0))
        .attr("id", "contorno")
        .style("fill", "#165ce9")
        .style("stroke", "#165ce9")
        .style("stroke-width", 1)
        .style("opacity", 1);
      ocultarGrupoCountries();
      mostrarGrupoMapa();

      return new Promise(function (resolveFn) {
        Promise.all([vaciarSankey(flagTransicion, factor)]).finally(
          function () {
            ocultarGrupoSankey();
            ocultarGrupoSankeyOrigin();
            ocultarGrupoSankeyTrips();
            Promise.all([transicionSankeyOriginAFrancia(factor)]).finally(
              function () {
                resolveFn(0);
              }
            );
          }
        );
      });
    } else {
      ocultarGrupoSankey();
      ocultarGrupoSankeyOrigin();
      ocultarGrupoSankeyTrips();
      mostrarGrupoGlobo();
      mostrarGrupoMapa();
    }
  }

  function transicionSankeyOriginAFrancia(factor) {
    factor = factor || 1;

    let nombreTransicion = "france:transition";
    let francia = gMapa.select("#contorno");

    return new Promise(function (resolveFn) {
      francia
        .transition(nombreTransicion)
        .duration(duracion * factor)
        .attrTween("d", function () {
          return interpoladorNodoFranciaAFrancia;
        })
        .style("fill", "transparent")
        .style("stroke", "black")
        .style("stroke-width", 2)
        .style("opacity", 0.8)
        .end()
        .catch((e) => {
          console.log(
            `transicionSankeyOriginAFrancia: transition('${nombreTransicion}') failed`
          );
          console.log(e);
        })
        .finally(function () {
          Promise.all([
            mostrarGrupoGlobo(true, factor),
            mostrarGrupoCountries(true, factor),
          ]).finally(function () {
            francia.remove();
            resolveFn(0);
          });
        });
    });
  }

  function transicionSankeyConControlesAGlobo(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      gMapa
        .append("path")
        .attr("d", interpoladorNodoFranciaAFrancia(0))
        .attr("id", "contorno")
        .style("fill", "#165ce9")
        .style("stroke", "#165ce9")
        .style("stroke-width", 1)
        .style("opacity", 1);
      ocultarGrupoCountries();
      mostrarGrupoMapa();

      return new Promise(function (resolveFn) {
        Promise.all([
          vaciarSankey(flagTransicion, factor),
          ocultarGrupoControles(flagTransicion, factor),
        ]).finally(function () {
          ocultarGrupoSankey();
          ocultarGrupoSankeyOrigin();
          ocultarGrupoSankeyTrips();
          Promise.all([transicionSankeyOriginAFrancia(factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
      });
    } else {
      ocultarGrupoControles();
      ocultarGrupoSankey();
      ocultarGrupoSankeyOrigin();
      ocultarGrupoSankeyTrips();
      mostrarGrupoGlobo();
      mostrarGrupoMapa();
    }
  }

  function transicionFlechasASankey(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionFlechasAGlobo(flagTransicion, factor)]).finally(
          function () {
            Promise.all([
              transicionFranciaASankey(data, flagTransicion, factor),
            ]).finally(function () {
              resolveFn(0);
            });
          }
        );
      });
    } else {
      transicionFlechasAGlobo();
      transicionFranciaASankey(data);
    }
  }

  function transicionFlechasASankeyConControles(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionFlechasAGlobo(flagTransicion, factor)]).finally(
          function () {
            Promise.all([
              transicionFranciaASankeyConControles(
                data,
                flagTransicion,
                factor
              ),
            ]).finally(function () {
              resolveFn(0);
            });
          }
        );
      });
    } else {
      transicionFlechasAGlobo();
      transicionFranciaASankeyConControles(data);
    }
  }

  function transicionZoomASankey(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionZoomAGlobo(flagTransicion, factor)]).finally(
          function () {
            Promise.all([
              transicionFranciaASankey(data, flagTransicion, factor),
            ]).finally(function () {
              resolveFn(0);
            });
          }
        );
      });
    } else {
      transicionZoomAGlobo();
      transicionFranciaASankey(data);
    }
  }

  function transicionZoomASankeyConControles(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionZoomAGlobo(flagTransicion, factor)]).finally(
          function () {
            Promise.all([
              transicionFranciaASankeyConControles(
                data,
                flagTransicion,
                factor
              ),
            ]).finally(function () {
              resolveFn(0);
            });
          }
        );
      });
    } else {
      transicionZoomAGlobo();
      transicionFranciaASankeyConControles(data);
    }
  }

  function transicionBaseASankey(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionBaseAGlobo(flagTransicion, factor)]).finally(
          function () {
            Promise.all([
              transicionFranciaASankey(data, flagTransicion, factor),
            ]).finally(function () {
              resolveFn(0);
            });
          }
        );
      });
    } else {
      transicionBaseAGlobo();
      transicionFranciaASankey(data);
    }
  }

  function transicionBaseASankeyConControles(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionBaseAGlobo(flagTransicion, factor)]).finally(
          function () {
            Promise.all([
              transicionFranciaASankeyConControles(
                data,
                flagTransicion,
                factor
              ),
            ]).finally(function () {
              resolveFn(0);
            });
          }
        );
      });
    } else {
      transicionBaseAGlobo();
      transicionFranciaASankeyConControles(data);
    }
  }

  function actualizarSankeyConControles(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          mostrarGrupoControles(flagTransicion, factor),
          actualizarSankey(data, flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      mostrarGrupoControles();
      actualizarSankey(data);
    }
  }

  function actualizarSankeySinControles(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          ocultarGrupoControles(flagTransicion, factor),
          actualizarSankey(data, flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      ocultarGrupoControles();
      actualizarSankey(data);
    }
  }

  function transicionBaseAGlobo(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    stopTimerGlobe();
    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionFranciaCentrar(true, factor),
          transicionFranciaBordeColor(true, factor),
          transicionFranciaBordeDelgado(true, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      transicionFranciaCentrar();
      transicionFranciaBordeColor();
      transicionFranciaBordeDelgado();
    }
  }

  /*
  TEMPLATE
function transicion() {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
        return new Promise(function (resolveFn, rejectFn) {
                Promise.all([
                ]).finally(function () {
                    Promise.all([
                    ]).finally(function () {
                    resolveFn(0);
                    });
                });
              });
    } else {
    }
  }
   */

  function transicionGridAGlobo(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionGridALineas(flagTransicion, factor)]).finally(
          function () {
            Promise.all([
              transicionLineasAGlobo(data, flagTransicion, factor),
            ]).finally(function () {
              resolveFn(0);
            });
          }
        );
      });
    } else {
      transicionGridALineas();
      transicionLineasAGlobo(data);
    }
  }

  function transicionGridAZoom(params, data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionGridALineas(flagTransicion, factor)]).finally(
          function () {
            Promise.all([
              transicionLineasAZoom(params, data, flagTransicion, factor),
            ]).finally(function () {
              resolveFn(0);
            });
          }
        );
      });
    } else {
      transicionGridALineas();
      transicionLineasAZoom(params, data);
    }
  }

  function transicionGridAFlechas(params, data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionGridALineas(flagTransicion, factor)]).finally(
          function () {
            Promise.all([
              transicionLineasAFlechas(params, data, flagTransicion, factor),
            ]).finally(function () {
              resolveFn(0);
            });
          }
        );
      });
    } else {
      transicionGridALineas();
      transicionLineasAFlechas(params, data);
    }
  }

  function transicionGridASankey(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionGridALineas(flagTransicion, factor)]).finally(
          function () {
            Promise.all([
              transicionLineasASankey(data, flagTransicion, factor),
            ]).finally(function () {
              resolveFn(0);
            });
          }
        );
      });
    } else {
      transicionGridALineas();
      transicionLineasASankey(data);
    }
  }

  function transicionGridASankeyConControles(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([transicionGridALineas(flagTransicion, factor)]).finally(
          function () {
            Promise.all([
              transicionLineasASankeyConControles(data, flagTransicion, factor),
            ]).finally(function () {
              resolveFn(0);
            });
          }
        );
      });
    } else {
      transicionGridALineas();
      transicionLineasASankeyConControles(data);
    }
  }

  function transicionLineasAFlechas(params, data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionLineasAGlobo(data, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([
            transicionGloboAFlechas(params, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionLineasAGlobo(data);
      transicionGloboAFlechas(params);
    }
  }

  function transicionLineasAZoom(params, data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionLineasAGlobo(data, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([
            transicionGloboAZoom(params, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionLineasAGlobo(data);
      transicionGloboAZoom(params);
    }
  }

  function transicionLineasAGlobo(data, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionLineasASankeyConControles(data, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([
            transicionSankeyConControlesAGlobo(flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionLineasASankeyConControles(data);
      transicionSankeyConControlesAGlobo();
    }
  }

  function transicionZoomAGrid_1(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_1(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionZoomALineas(dataSankey, dataLineas, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([transicionLineasAGrid(flagTransicion, factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
      });
    } else {
      transicionZoomALineas(dataSankey, dataLineas);
      transicionLineasAGrid();
    }
  }

  function transicionZoomAGrid_2(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_2(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionZoomALineas(dataSankey, dataLineas, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([transicionLineasAGrid(flagTransicion, factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
      });
    } else {
      transicionZoomALineas(dataSankey, dataLineas);
      transicionLineasAGrid();
    }
  }

  function transicionZoomAGrid_3(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_3(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionZoomALineas(dataSankey, dataLineas, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([transicionLineasAGrid(flagTransicion, factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
      });
    } else {
      transicionZoomALineas(dataSankey, dataLineas);
      transicionLineasAGrid();
    }
  }

  function transicionZoomAGrid_4(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_4(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionZoomALineas(dataSankey, dataLineas, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([transicionLineasAGrid(flagTransicion, factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
      });
    } else {
      transicionZoomALineas(dataSankey, dataLineas);
      transicionLineasAGrid();
    }
  }

  function transicionBaseAGrid_1(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_1(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionBaseALineas(dataSankey, dataLineas, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([transicionLineasAGrid(flagTransicion, factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
      });
    } else {
      transicionBaseALineas(dataSankey, dataLineas);
      transicionLineasAGrid();
    }
  }

  function transicionBaseAGrid_2(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_2(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionBaseALineas(dataSankey, dataLineas, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([transicionLineasAGrid(flagTransicion, factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
      });
    } else {
      transicionBaseALineas(dataSankey, dataLineas);
      transicionLineasAGrid();
    }
  }

  function transicionBaseAGrid_3(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_3(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionBaseALineas(dataSankey, dataLineas, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([transicionLineasAGrid(flagTransicion, factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
      });
    } else {
      transicionBaseALineas(dataSankey, dataLineas);
      transicionLineasAGrid();
    }
  }

  function transicionBaseAGrid_4(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_4(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionBaseALineas(dataSankey, dataLineas, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([transicionLineasAGrid(flagTransicion, factor)]).finally(
            function () {
              resolveFn(0);
            }
          );
        });
      });
    } else {
      transicionBaseALineas(dataSankey, dataLineas);
      transicionLineasAGrid();
    }
  }

  function transicionFranciaAGrid_1(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_1(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionFranciaALineas(
            dataSankey,
            dataLineas,
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          Promise.all([
            transicionLineasAGrid(viajes, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionFranciaALineas(dataSankey, dataLineas);
      transicionLineasAGrid(viajes);
    }
  }

  function transicionFranciaAGrid_2(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_2(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionFranciaALineas(
            dataSankey,
            dataLineas,
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          Promise.all([
            transicionLineasAGrid(viajes, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionFranciaALineas(dataSankey, dataLineas);
      transicionLineasAGrid(viajes);
    }
  }

  function transicionFranciaAGrid_3(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_3(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionFranciaALineas(
            dataSankey,
            dataLineas,
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          Promise.all([
            transicionLineasAGrid(viajes, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionFranciaALineas(dataSankey, dataLineas);
      transicionLineasAGrid(viajes);
    }
  }

  function transicionFranciaAGrid_4(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_4(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionFranciaALineas(
            dataSankey,
            dataLineas,
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          Promise.all([
            transicionLineasAGrid(viajes, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionFranciaALineas(dataSankey, dataLineas);
      transicionLineasAGrid(viajes);
    }
  }

  function transicionFlechasAGrid_1(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_1(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionFlechasALineas(
            dataSankey,
            dataLineas,
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          Promise.all([
            transicionLineasAGrid(viajes, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionFlechasALineas(dataSankey, dataLineas);
      transicionLineasAGrid(viajes);
    }
  }

  function transicionFlechasAGrid_2(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_2(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionFlechasALineas(
            dataSankey,
            dataLineas,
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          Promise.all([
            transicionLineasAGrid(viajes, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionFlechasALineas(dataSankey, dataLineas);
      transicionLineasAGrid(viajes);
    }
  }

  function transicionFlechasAGrid_3(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_3(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionFlechasALineas(
            dataSankey,
            dataLineas,
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          Promise.all([
            transicionLineasAGrid(viajes, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionFlechasALineas(dataSankey, dataLineas);
      transicionLineasAGrid(viajes);
    }
  }

  function transicionFlechasAGrid_4(
    viajes,
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_4(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionFlechasALineas(
            dataSankey,
            dataLineas,
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          Promise.all([
            transicionLineasAGrid(viajes, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionFlechasALineas(dataSankey, dataLineas);
      transicionLineasAGrid(viajes);
    }
  }

  function transicionSankeyAGrid_1(viajes, dataLineas, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_1(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionSankeyALineas(dataLineas, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([
            transicionLineasAGrid(viajes, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionSankeyALineas(dataLineas);
      transicionLineasAGrid(viajes);
    }
  }

  function transicionSankeyAGrid_2(viajes, dataLineas, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_2(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionSankeyALineas(dataLineas, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([
            transicionLineasAGrid(viajes, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionSankeyALineas(dataLineas);
      transicionLineasAGrid(viajes);
    }
  }

  function transicionSankeyAGrid_3(viajes, dataLineas, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_3(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionSankeyALineas(dataLineas, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([
            transicionLineasAGrid(viajes, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionSankeyALineas(dataLineas);
      transicionLineasAGrid(viajes);
    }
  }

  function transicionSankeyAGrid_4(viajes, dataLineas, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_4(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionSankeyALineas(dataLineas, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([
            transicionLineasAGrid(viajes, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionSankeyALineas(dataLineas);
      transicionLineasAGrid(viajes);
    }
  }

  function transicionLineasAGrid_1(viajes, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_1(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionLineasAGrid(viajes, flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      transicionLineasAGrid(viajes);
    }
  }

  function transicionLineasAGrid_2(viajes, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_2(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionLineasAGrid(viajes, flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      transicionLineasAGrid(viajes);
    }
  }

  function transicionLineasAGrid_3(viajes, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_3(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionLineasAGrid(viajes, flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      transicionLineasAGrid(viajes);
    }
  }

  function transicionLineasAGrid_4(viajes, flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    transicionAGrid_4(viajes);

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionLineasAGrid(viajes, flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      transicionLineasAGrid(viajes);
    }
  }

  function transicionFlechasALineas(
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionFlechasASankeyConControles(
            dataSankey,
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          Promise.all([
            transicionSankeyALineas(dataLineas, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionFlechasASankeyConControles(dataSankey);
      transicionSankeyALineas(dataLineas);
    }
  }

  function transicionFranciaALineas(
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionFranciaASankeyConControles(
            dataSankey,
            flagTransicion,
            factor
          ),
        ]).finally(function () {
          Promise.all([
            transicionSankeyALineas(dataLineas, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionFranciaASankeyConControles(dataSankey);
      transicionSankeyALineas(dataLineas);
    }
  }

  function transicionZoomALineas(
    dataSankey,
    dataLineas,
    flagTransicion,
    factor
  ) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        Promise.all([
          transicionZoomASankeyConControles(dataSankey, flagTransicion, factor),
        ]).finally(function () {
          Promise.all([
            transicionSankeyALineas(dataLineas, flagTransicion, factor),
          ]).finally(function () {
            resolveFn(0);
          });
        });
      });
    } else {
      transicionZoomASankeyConControles(dataSankey);
      transicionSankeyALineas(dataLineas);
    }
  }

  function ocultarElemento(el, flagTransicion, nombreTransicion, factor) {
    flagTransicion = flagTransicion || false;
    nombreTransicion = nombreTransicion || "el";
    nombreTransicion = el + ":hide";
    factor = factor || 1;
    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        el.transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 0)
          .end()
          .catch((e) => {
            console.log(
              `ocultarElemento: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            el.call(hide);
            resolveFn(0);
          });
      });
    } else {
      el.call(hide);
    }
  }

  function mostrarElemento(el, flagTransicion, nombreTransicion, factor) {
    flagTransicion = flagTransicion || false;
    nombreTransicion = nombreTransicion || "el";
    nombreTransicion = el + ":unhide";
    factor = factor || 1;
    if (flagTransicion) {
      return new Promise(function (resolveFn) {
        el.style("display", null)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 1)
          .end()
          .catch((e) => {
            console.log(
              `mostrarElemento: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            el.call(unhide);
            resolveFn(0);
          });
      });
    } else {
      el.call(unhide);
    }
  }

  function ocultarGrupoGlobo(flagTransicion, factor) {
    return ocultarElemento(gGlobo, flagTransicion, "gGlobo", factor);
  }

  function mostrarGrupoGlobo(flagTransicion, factor) {
    return mostrarElemento(gGlobo, flagTransicion, "gGlobo", factor);
  }

  function ocultarGrupoMapa(flagTransicion, factor) {
    return ocultarElemento(gMapa, flagTransicion, "gMapa", factor);
  }

  function mostrarGrupoMapa(flagTransicion, factor) {
    return mostrarElemento(gMapa, flagTransicion, "gMapa", factor);
  }
  function ocultarGrupoCountries(flagTransicion, factor) {
    return ocultarElemento(
      gMapa.select("g.countries"),
      flagTransicion,
      "g.countries",
      factor
    );
  }

  function mostrarGrupoCountries(flagTransicion, factor) {
    return mostrarElemento(
      gMapa.select("g.countries"),
      flagTransicion,
      "g.countries",
      factor
    );
  }

  function ocultarGrupoControles(flagTransicion, factor) {
    return ocultarElemento(gControles, flagTransicion, "gControles", factor);
  }

  function mostrarGrupoControles(flagTransicion, factor) {
    return mostrarElemento(gControles, flagTransicion, "gControles", factor);
  }

  function ocultarGrupoSankey(flagTransicion, factor) {
    return ocultarElemento(gSankey, flagTransicion, "gSankey", factor);
  }

  function mostrarGrupoSankey(flagTransicion, factor) {
    return mostrarElemento(gSankey, flagTransicion, "gSankey", factor);
  }

  function ocultarGrupoSankeyOrigin(flagTransicion, factor) {
    return ocultarElemento(
      gSankey.select("g.origin"),
      flagTransicion,
      "g.origin",
      factor
    );
  }

  function mostrarGrupoSankeyOrigin(flagTransicion, factor) {
    return mostrarElemento(
      gSankey.select("g.origin"),
      flagTransicion,
      "g.origin",
      factor
    );
  }

  function ocultarGrupoSankeyTrips(flagTransicion, factor) {
    return ocultarElemento(
      gSankey.select("g.trips"),
      flagTransicion,
      "g.trips",
      factor
    );
  }

  function mostrarGrupoSankeyTrips(flagTransicion, factor) {
    return mostrarElemento(
      gSankey.select("g.trips"),
      flagTransicion,
      "g.trips",
      factor
    );
  }

  function ocultarGrupoLineas(flagTransicion, factor) {
    return ocultarElemento(gLineas, flagTransicion, "gLineas", factor);
  }

  function mostrarGrupoLineas(flagTransicion, factor) {
    return mostrarElemento(gLineas, flagTransicion, "gLineas", factor);
  }

  function ocultarGrupoName(flagTransicion, factor) {
    return ocultarElemento(
      gLineas.select("g.name"),
      flagTransicion,
      "g.name",
      factor
    );
  }

  function mostrarGrupoName(flagTransicion, factor) {
    return mostrarElemento(
      gLineas.select("g.name"),
      flagTransicion,
      "g.name",
      factor
    );
  }

  function ocultarGrupoX(flagTransicion, factor) {
    return ocultarElemento(
      gLineas.select("g.x"),
      flagTransicion,
      "g.x",
      factor
    );
  }

  function mostrarGrupoX(flagTransicion, factor) {
    return mostrarElemento(
      gLineas.select("g.x"),
      flagTransicion,
      "g.x",
      factor
    );
  }

  function ocultarGrupoY(flagTransicion, factor) {
    return ocultarElemento(
      gLineas.select("g.y"),
      flagTransicion,
      "g.y",
      factor
    );
  }

  function mostrarGrupoY(flagTransicion, factor) {
    return mostrarElemento(
      gLineas.select("g.y"),
      flagTransicion,
      "g.y",
      factor
    );
  }

  function ocultarGrupoCovid(flagTransicion, factor) {
    return ocultarElemento(
      gLineas.select("g.covid"),
      flagTransicion,
      "g.covid",
      factor
    );
  }

  function mostrarGrupoCovid(flagTransicion, factor) {
    return mostrarElemento(
      gLineas.select("g.covid"),
      flagTransicion,
      "g.covid",
      factor
    );
  }

  function ocultarGrupoMain(flagTransicion, factor) {
    return ocultarElemento(
      gLineas.select("g.main"),
      flagTransicion,
      "g.main",
      factor
    );
  }

  function mostrarGrupoMain(flagTransicion, factor) {
    return mostrarElemento(
      gLineas.select("g.main"),
      flagTransicion,
      "g.main",
      factor
    );
  }

  function ocultarGrupoVoronoi(flagTransicion, factor) {
    return ocultarElemento(
      gLineas.select("g.voronoi"),
      flagTransicion,
      "g.voronoi",
      factor
    );
  }

  function mostrarGrupoVoronoi(flagTransicion, factor) {
    return mostrarElemento(
      gLineas.select("g.voronoi"),
      flagTransicion,
      "g.voronoi",
      factor
    );
  }

  function ocultarGrupoMiniGrid(flagTransicion, factor) {
    return ocultarElemento(gMiniGrid, flagTransicion, "gMiniGrid", factor);
  }

  function mostrarGrupoMiniGrid(flagTransicion, factor) {
    return mostrarElemento(gMiniGrid, flagTransicion, "gMiniGrid", factor);
  }

  setup();
});
