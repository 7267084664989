/*
Funciona como el modelo de cajas con box-sizing: border-box
O sea, el ancho y alto INCLUYEN el padding
https://developer.mozilla.org/en-US/docs/Learn/CSS/Introduction_to_CSS/Box_model#Advanced_box_manipulation
*/

let Box = function (options) {
  options = options || {};
  let size = options.size || {};
  let padding = options.padding || {};
  let margin = options.margin || {};
  let exterior = {};
  let content = {};
  let box = {};

  if (Number.isInteger(margin)) {
    let value = margin;
    margin = {};
    margin.top = value;
    margin.right = value;
    margin.bottom = value;
    margin.left = value;
  } else {
    margin.top = margin.top || 0;
    margin.right = margin.right || 0;
    margin.bottom = margin.bottom || 0;
    margin.left = margin.left || 0;
  }

  if (Number.isInteger(padding)) {
    let value = padding;
    padding = {};
    padding.top = value;
    padding.right = value;
    padding.bottom = value;
    padding.left = value;
  } else {
    padding.top = padding.top || 0;
    padding.right = padding.right || 0;
    padding.bottom = padding.bottom || 0;
    padding.left = padding.left || 0;
  }

  if (Number.isInteger(size)) {
    let value = size;
    content = {};
    content.width = value - (padding.left + padding.right);
    content.height = value - (padding.top + padding.bottom);
  } else {
    content.width = size.width - (padding.left + padding.right) || 0;
    content.height = size.height - (padding.top + padding.bottom) || 0;
  }

  content.width = content.width >= 0 ? content.width : 0;
  content.height = content.height >= 0 ? content.height : 0;

  box.width = content.width + padding.left + padding.right;
  box.height = content.height + padding.top + padding.bottom;

  exterior.width = box.width + margin.left + margin.right;
  exterior.height = box.height + margin.top + margin.bottom;

  box.margin = margin;
  box.padding = padding;
  box.content = content;
  box.exterior = exterior;

  return box;
};
