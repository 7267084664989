windowReady.ready(function () {
  let duracion = isMobile.phone ? 600 : 400;
  let debounceTimer = 200;
  let debouncedCalcularMedidas = null;

  let scroller1 = null;
  let scroller2 = null;
  let capas = { anterior: null, actual: null };
  let isReduced = null;

  let container_4_4 = null;

  let svg = null;

  function setup() {
    container_4_4 = d3.select("div[data-container='story-4-4']");
    if (container_4_4.node()) {
      isReduced =
        window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
        window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
      if (isReduced) {
        duracion = 0;
      }
      setupHistoria();
      debouncedCalcularMedidas = _.debounce(calcularMedidas, debounceTimer);
      window.onresize = debouncedCalcularMedidas;
    }
  }

  function calcularMedidas() {
    calcularAlturaSVG();
    scroller1.resize();
    scroller2.resize();
  }

  function isMobileSize() {
    return window.innerWidth <= 480;
  }

  function calcularAlturaSVG() {
    let box = container_4_4.node().getBoundingClientRect();
    let root = document.documentElement;
    if (isMobileSize()) {
      root.style.setProperty(
        "--svg-height",
        `calc(8rem + ${2 * box.height}px)`
      );
    } else {
      root.style.setProperty("--svg-height", `calc(8rem + ${box.height}px)`);
    }
  }

  function setupHistoria() {
    iniciarSVGDiagrama();
  }

  function iniciarSVGDiagrama() {
    svg = container_4_4.select("svg");
    //svg.selectAll(".level_1").call(hide);
    svg.selectAll(".level_1_a_2").call(hide);
    svg.selectAll(".level_2").call(hide);
    svg.selectAll(".level_2_a_3").call(hide);
    svg.selectAll(".level_3").call(hide);
    svg.selectAll(".level_3_a_4").call(hide);
    svg.selectAll(".level_4").call(hide);
    crearScroller();
  }

  function mostrarCapasUp(response) {
    if (response.direction == "up") {
      mostrarCapas(response.element.getAttribute("data-capas"));
    }
  }

  function mostrarCapasDown(response) {
    if (response.direction == "down") {
      mostrarCapas(response.element.getAttribute("data-capas"));
    }
  }

  function crearScroller() {
    let elementos = d3.selectAll("[data-capas]");
    if (elementos.size() > 0) {
      let debouncedMostrarCapasUp = _.debounce(mostrarCapasUp, debounceTimer);
      let debouncedMostrarCapasDown = _.debounce(
        mostrarCapasDown,
        debounceTimer
      );

      scroller1 = scrollama();
      scroller1
        .setup({
          step: "[data-capas]",
          offset: 0.9,
          debug: false,
        })
        .onStepEnter(debouncedMostrarCapasDown);

      scroller2 = scrollama();
      scroller2
        .setup({
          step: "[data-capas]",
          offset: 0.2,
          debug: false,
        })
        .onStepEnter(debouncedMostrarCapasUp);
    }
  }

  function mostrarCapas(capa) {
    capas.anterior = capas.actual;
    capas.actual = capa || null;
    console.dir(capas);

    if (capas.actual == capas.anterior) {
      return 0;
    }

    let flagTransicion = !isReduced;

    if (capas.anterior == null && capas.actual == "level_1") {
      svg.selectAll(".level_1").call(unhide);
    }

    if (capas.anterior == null && capas.actual == "level_2") {
      svg.selectAll(".level_1").call(unhide);
      svg.selectAll(".level_1_a_2").call(unhide);
      svg.selectAll(".level_2").call(unhide);
    }

    if (capas.anterior == null && capas.actual == "level_3") {
      svg.selectAll(".level_1").call(unhide);
      svg.selectAll(".level_1_a_2").call(unhide);
      svg.selectAll(".level_2").call(unhide);
      svg.selectAll(".level_2_a_3").call(unhide);
      svg.selectAll(".level_3").call(unhide);
      svg.selectAll(".level_3_a_4").call(unhide);
      svg.selectAll(".level_4").call(unhide);
    }

    if (capas.anterior == "level_1" && capas.actual == "level_2") {
      transicionNivel_1_a_2(flagTransicion);
    }

    if (capas.anterior == "level_1" && capas.actual == "level_3") {
      transicionNivel_1_a_3(flagTransicion);
    }

    if (capas.anterior == "level_2" && capas.actual == "level_3") {
      transicionNivel_2_a_3(flagTransicion);
    }

    if (capas.anterior == "level_3" && capas.actual == "level_2") {
      transicionNivel_3_a_2(flagTransicion);
    }

    if (capas.anterior == "level_3" && capas.actual == "level_1") {
      transicionNivel_3_a_1(flagTransicion);
    }

    if (capas.anterior == "level_2" && capas.actual == "level_1") {
      transicionNivel_2_a_1(flagTransicion);
    }
  }

  function muestraLevel_2(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "level_2:update";

    let coleccion = svg.selectAll(".level_2");

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        coleccion
          .style("display", null)
          .style("opacity", 0)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 1)
          .end()
          .catch((e) => {
            console.log(
              `muestraLevel_2: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            coleccion.call(unhide);
            resolveFn(0);
          });
      });
    } else {
      coleccion.call(unhide);
    }
  }

  function muestraLevel_3(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "level_3:update";

    let coleccion = svg.selectAll(".level_3");

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        coleccion
          .style("display", null)
          .style("opacity", 0)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 1)
          .end()
          .catch((e) => {
            console.log(
              `muestraLevel_3: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            coleccion.call(unhide);
            resolveFn(0);
          });
      });
    } else {
      coleccion.call(unhide);
    }
  }

  function muestraLevel_4(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "level_4:update";

    let coleccion = svg.selectAll(".level_4");

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        coleccion
          .style("display", null)
          .style("opacity", 0)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 1)
          .end()
          .catch((e) => {
            console.log(
              `muestraLevel_4: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            coleccion.call(unhide);
            resolveFn(0);
          });
      });
    } else {
      coleccion.call(unhide);
    }
  }

  function muestraLevel_1_a_2(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "level_1_a_2:update";

    let coleccion = svg.selectAll(".level_1_a_2");

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        coleccion
          .style("display", null)
          .style("opacity", 0)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 1)
          .end()
          .catch((e) => {
            console.log(
              `muestraLevel_1_a_2: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            coleccion.call(unhide);
            resolveFn(0);
          });
      });
    } else {
      coleccion.call(unhide);
    }
  }

  function muestraLevel_2_a_3(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "level_2_a_3:update";

    let coleccion = svg.selectAll(".level_2_a_3");

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        coleccion
          .style("display", null)
          .style("opacity", 0)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 1)
          .end()
          .catch((e) => {
            console.log(
              `muestraLevel_2_a_3: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            coleccion.call(unhide);
            resolveFn(0);
          });
      });
    } else {
      coleccion.call(unhide);
    }
  }

  function muestraLevel_3_a_4(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "level_3_a_4:update";

    let coleccion = svg.selectAll(".level_3_a_4");

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        coleccion
          .style("display", null)
          .style("opacity", 0)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 1)
          .end()
          .catch((e) => {
            console.log(
              `muestraLevel_3_a_4: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            coleccion.call(unhide);
            resolveFn(0);
          });
      });
    } else {
      coleccion.call(unhide);
    }
  }

  function transicionNivel_1_a_2(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          muestraLevel_1_a_2(flagTransicion, factor),
          muestraLevel_2(flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      muestraLevel_1_a_2();
      muestraLevel_2();
    }
  }

  function transicionNivel_1_a_3(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          muestraLevel_1_a_2(flagTransicion, factor),
          muestraLevel_2(flagTransicion, factor),
          muestraLevel_2_a_3(flagTransicion, factor),
          muestraLevel_3(flagTransicion, factor),
          muestraLevel_4(flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      muestraLevel_1_a_2();
      muestraLevel_2();
      muestraLevel_2_a_3();
      muestraLevel_3();
      muestraLevel_4();
    }
  }

  function transicionNivel_2_a_3(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          muestraLevel_2_a_3(flagTransicion, factor),
          muestraLevel_3(flagTransicion, factor),
          muestraLevel_3_a_4(flagTransicion, factor),
          muestraLevel_4(flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      muestraLevel_2_a_3();
      muestraLevel_3();
      muestraLevel_3_a_4();
      muestraLevel_4();
    }
  }

  function ocultaLevel_2(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "level_2:update";

    let coleccion = svg.selectAll(".level_2").style("display", null);

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        coleccion
          .style("opacity", 1)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 0)
          .end()
          .catch((e) => {
            console.log(
              `muestraLevel_2: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            coleccion.call(hide);
            resolveFn(0);
          });
      });
    } else {
      coleccion.call(unhide);
    }
  }

  function ocultaLevel_3(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "level_3:update";

    let coleccion = svg.selectAll(".level_3").style("display", null);

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        coleccion
          .style("opacity", 1)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 0)
          .end()
          .catch((e) => {
            console.log(
              `muestraLevel_3: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            coleccion.call(hide);
            resolveFn(0);
          });
      });
    } else {
      coleccion.call(unhide);
    }
  }

  function ocultaLevel_4(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "level_4:update";

    let coleccion = svg.selectAll(".level_4").style("display", null);

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        coleccion
          .style("opacity", 1)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 0)
          .end()
          .catch((e) => {
            console.log(
              `muestraLevel_4: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            coleccion.call(hide);
            resolveFn(0);
          });
      });
    } else {
      coleccion.call(unhide);
    }
  }

  function ocultaLevel_1_a_2(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "level_1_a_2:update";

    let coleccion = svg.selectAll(".level_1_a_2").style("display", null);

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        coleccion
          .style("opacity", 1)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 0)
          .end()
          .catch((e) => {
            console.log(
              `muestraLevel_1_a_2: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            coleccion.call(hide);
            resolveFn(0);
          });
      });
    } else {
      coleccion.call(unhide);
    }
  }

  function ocultaLevel_2_a_3(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "level_2_a_3:update";

    let coleccion = svg.selectAll(".level_2_a_3").style("display", null);

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        coleccion
          .style("opacity", 1)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 0)
          .end()
          .catch((e) => {
            console.log(
              `muestraLevel_2_a_3: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            coleccion.call(hide);
            resolveFn(0);
          });
      });
    } else {
      coleccion.call(unhide);
    }
  }

  function ocultaLevel_3_a_4(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;
    let nombreTransicion = "level_3_a_4:update";

    let coleccion = svg.selectAll(".level_3_a_4").style("display", null);

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        coleccion
          .style("opacity", 1)
          .transition(nombreTransicion)
          .duration(duracion * factor)
          .style("opacity", 0)
          .end()
          .catch((e) => {
            console.log(
              `muestraLevel_3_a_4: transition('${nombreTransicion}') failed`
            );
            console.log(e);
          })
          .finally(function () {
            coleccion.call(hide);
            resolveFn(0);
          });
      });
    } else {
      coleccion.call(unhide);
    }
  }

  function transicionNivel_2_a_1(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          ocultaLevel_1_a_2(flagTransicion, factor),
          ocultaLevel_2(flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      ocultaLevel_1_a_2();
      ocultaLevel_2();
    }
  }

  function transicionNivel_3_a_2(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          ocultaLevel_2_a_3(flagTransicion, factor),
          ocultaLevel_3(flagTransicion, factor),
          ocultaLevel_3_a_4(flagTransicion, factor),
          ocultaLevel_4(flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      ocultaLevel_2_a_3();
      ocultaLevel_3();
      ocultaLevel_3_a_4();
      ocultaLevel_4();
    }
  }

  function transicionNivel_3_a_1(flagTransicion, factor) {
    flagTransicion = flagTransicion || false;
    factor = factor || 1;

    if (flagTransicion) {
      return new Promise(function (resolveFn, rejectFn) {
        Promise.all([
          ocultaLevel_1_a_2(flagTransicion, factor),
          ocultaLevel_2(flagTransicion, factor),
          ocultaLevel_2_a_3(flagTransicion, factor),
          ocultaLevel_3(flagTransicion, factor),
          ocultaLevel_4(flagTransicion, factor),
        ]).finally(function () {
          resolveFn(0);
        });
      });
    } else {
      ocultaLevel_1_a_2();
      ocultaLevel_2();
      ocultaLevel_2_a_3();
      ocultaLevel_3();
      ocultaLevel_4();
    }
  }

  function hide(el) {
    el.style("display", "none").style("opacity", 0);
  }

  function unhide(el) {
    el.style("display", null).style("opacity", null);
  }

  setup();
});
